import { FC, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'

import { User } from '../pages/modules/user'
import { UpdateUser } from '../pages/modules/user/update'
import { ViewStudent } from '../pages/modules/student/view'

const StudentRoutes = () => {

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path='/student'
          element={
            <SuspensedView>
              <User model='Student' />
            </SuspensedView>
          }
        />

        <Route
          path='/student/details/:id/:name'
          element={
            <SuspensedView>
              <ViewStudent />
            </SuspensedView>
          }
        />
        <Route
          path='/student/details/:id/:name'
          element={
            <SuspensedView>
              <ViewStudent />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { StudentRoutes }
