
import React, { useRef } from 'react';


export const UploadeMedia = (props: any) => {
    const { setSelectImage, selectimage, setSelectFiles, selectFiles } = props
    const inputUploadFile: any = useRef(null);

    const onFileChange = async (e: any) => {
        const arr = selectimage ? [...selectimage] : []
        const files = selectFiles ? [...selectFiles] : []

        for (const key of Object.keys(e.target.files)) {
            const file = e.target.files[key];
            files.push(file);
            let imageDataUrl: any = await readFile(file);
            arr.push(imageDataUrl)
        }
        setSelectImage(arr);
        setSelectFiles(files)
    }
    function readFile(file: any) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    }

    const changeFile = () => {
        inputUploadFile.current.click();
    };
    return (
        <form className="mb-2 mt-4" >
            <div className="" style={{ justifyContent: "right" }}>
                <button type='button' onClick={() => changeFile()} className="btn bg-primary text-white btn-sm">
                    Click here to upload
                </button>

                <input
                    type="file"
                    accept="image/jpeg,image/gif,image/png,image/jpg/video/mp4,video/x-m4v,video/*"
                    onChange={onFileChange}
                    hidden
                    ref={inputUploadFile}
                    multiple
                />
            </div>
            <p className='justify-end flex my-2'>You can select multiple files</p>
        </form>
    )

}