import { CompanyDataForm } from '../../company/components/data-form'
import { InstitutionDataForm } from '../../institution/components/data-form'
import { MentorDataForm } from '../../mentor/components/data-form'
import { StudentDataForm } from '../../student/components/data-form'

export const UserDataForm = (props: any) => {
  const { handleChange, data, action, isSubmitted, setUserType, userType , setFormData} = props



  return (
    <>
      <div className='input-data my-3 col-md-12 col-12'>
        <label htmlFor='country' className='fs-5 me-3'>
          Select User Type
        </label>
        <select
          className='form-select'
          aria-label='Select example'
          name='country'
          onChange={(e: any) => {
            handleChange(e.target.value, 'model')
            setUserType(e.target.value)
          }}
        >
          <option disabled selected>
            -- Select an option --
          </option>
          <option value={'Mentor'}>Mentor</option>
          <option value={'Student'}>Student or Professional</option>
          <option value={'Company'}>Company</option>
          <option value={'Institution'}>Institution</option>
        </select>
      </div>
      {userType === 'Mentor' && (
        <MentorDataForm
          handleChange={handleChange}
          data={data}
          action={action}
          isSubmitted={isSubmitted}
          isLoadingData={false}
        />
      )}
      {userType === 'Student' && (
        <StudentDataForm
          handleChange={handleChange}
          data={data}
          action={action}
          isSubmitted={isSubmitted}
        />
      )}
      {userType === 'Institution' && (
        <InstitutionDataForm
          handleChange={handleChange}
          data={data}
          action={action}
          isSubmitted={isSubmitted} 
          setFormData={setFormData}
          />
      )
      }
       {userType === 'Company' && (
        <CompanyDataForm
          handleChange={handleChange}
          data={data}
          action={action}
          isSubmitted={isSubmitted}  
          setFormData={setFormData}       
          />
      )
      }
    </>
  )
}
