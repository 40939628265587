/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {formatDate} from '../../../../utils/DateFormatter'
import {JobApplications} from '../../job-application'
import {Delete} from '../delete'
import {deleteJob, updateJobStatusFromAdmin} from '../../../../services/job'
import {useNavigate} from 'react-router-dom'

type Props = {
  jobData?: any
}

export default function JobHeader(props: Props) {
  const {jobData} = props
  const [showModal, setShowModal] = useState(false)
  const [_id, setId] = useState<string>('')
  const [active, setActive] = useState(0)
  const [loading, setLoading] = useState<any>(false)
  const navigate = useNavigate()
  const [loadingApproved, setLoadingApproved] = useState<any>(false)
  const [loadingDeclined, setLoadingDeclined] = useState<any>(false)
  // const roleDescription: any = jobData?.roleDescription ? new DOMParser().parseFromString(jobData?.roleDescription, "text/xml").firstChild : 'N/A'
  // const moreDetails: any = jobData?.moreDetails ? new DOMParser().parseFromString(jobData?.moreDetails, "text/xml").firstChild : 'N/A'
  // const benefits: any = jobData?.benefits ? new DOMParser().parseFromString(jobData?.benefits, "text/html") : null
  useEffect(() => {
    if (jobData) {
      let descriptionID = document.getElementById('description') as HTMLElement
      descriptionID.innerHTML = jobData.roleDescription
      let detailsID = document.getElementById('details') as HTMLElement
      detailsID.innerHTML = jobData.moreDetails
      let benefitsID = document.getElementById('benefits') as HTMLElement
      benefitsID.innerHTML = jobData.benefits
    }
  }, [jobData])

  const viewModal = (_id: any) => {
    setId(_id)
    setShowModal(true)
  }
  const handleCancel = () => {
    setShowModal(false)
  }

  const handleDelete = () => {
    handleDeleteJob()
    setShowModal(false)
  }
  const handleDeleteJob = async () => {
    setLoading(true)
    const response = await deleteJob(_id)
    if (response) {
      handleCancel()
      setLoading(false)
      navigate('/jobs')
    }
  }
  const updateStatus = async (_id: any, status: string) => {
    setId(_id)
    status === 'declined' ? setLoadingDeclined(true) : setLoadingApproved(true)
    const response = await updateJobStatusFromAdmin(_id, status)
    if (response) {
      status === 'declined' ? setLoadingDeclined(false) : setLoadingApproved(false)
      // listAll()
      navigate('/jobs')
    }
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      {showModal && (
        <Delete
          show={showModal}
          onCancel={handleCancel}
          onDelete={handleDelete}
          loading={loading}
        />
      )}

      <div className='card-body pt-9 pb-0'>
        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <span
                onClick={() => {
                  setActive(0)
                }}
                className={`nav-link text-active-primary me-6 ` + (active === 0 && 'active')}
              >
                Overview
              </span>
            </li>
            <li
              className='nav-item'
              onClick={() => {
                setActive(1)
              }}
            >
              <span className={`nav-link text-active-primary me-6 ` + (active === 1 && 'active')}>
                Question
              </span>
            </li>

            <li
              className='nav-item'
              onClick={() => {
                setActive(3)
              }}
            >
              <span className={`nav-link text-active-primary me-6 ` + (active === 3 && 'active')}>
                Job Applicants
              </span>
            </li>
          </ul>
        </div>
      </div>

      {active === 0 && (
        <div className='card mb-5 mt-5 mb-xl-10 position-relative' id='kt_profile_details_view'>
          <span
            className={`d-flex align-items-center position-absolute me-5 text-white p-2 rounded  bg-success text-capitalize`}
            style={{top: '13px', right: '0px'}}
          >
            {jobData?.applicationType} application
          </span>

          <div className='card-body p-9'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex justify-content-start mb-4 flex-shrink-0'>
                    {jobData?.status === 'pending' && (
                      <>
                        <button
                          onClick={() => updateStatus(jobData._id, 'approved')}
                          // to={`/mentoring-program-options/update/${item._id}`}
                          className='btn  btn-bg-success text-white btn-sm me-1 me-2'
                        >
                          {loadingApproved && jobData._id === _id ? (
                            <div className='spinner-border text-light' role='status'>
                              <span className='sr-only'>Loading...</span>
                            </div>
                          ) : (
                            'Approve'
                          )}{' '}
                        </button>

                        <button
                          onClick={() => updateStatus(jobData._id, 'declined')}
                          className='btn btn-bg-danger text-white btn-sm me-2'
                        >
                          {loadingDeclined && jobData._id === _id ? (
                            <div className='spinner-border text-light' role='status'>
                              <span className='sr-only'>Loading...</span>
                            </div>
                          ) : (
                            'Reject'
                          )}
                        </button>
                      </>
                    )}
                    {jobData?.status === 'approved' && (
                      <>
                        <span className='d-flex align-items-center justify-content-center bg-green-100 text-success px-4 btn-sm '>
                          {' '}
                          Approved{' '}
                        </span>
                      </>
                    )}

                    {jobData?.status === 'declined' && (
                      <>
                        <span className='d-flex align-items-center justify-content-center bg-green-100 text-danger px-4 btn-sm '>
                          {' '}
                          Declined{' '}
                        </span>
                      </>
                    )}
                    <Link
                      to={`/jobs/update/${jobData._id}`}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-5'
                    >
                      <i className='bi bi-pencil-square text-info fs-2'></i>
                    </Link>

                    <button
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      onClick={() => viewModal(jobData._id)}
                    >
                      <i className='bi bi-trash text-danger fs-2'></i>
                    </button>
                  </div>
                  <div className='d-block align-items-center mb-2'>
                    <a className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1 mb-8'>
                      {jobData.name}
                    </a>
                    <div className='d-flex mt-4'>
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2 w-auto'>
                        <strong className='text-primary me-1'> Company Name : </strong>{' '}
                        {jobData?.company?.subModel?.name}
                      </span>
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2 w-auto'>
                        <strong className='text-primary me-1'> Company ID : </strong>{' '}
                        {jobData?.company?._id}
                      </span>
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2 w-auto'>
                        <strong className='text-primary me-1'> Job ID : </strong> {jobData._id}
                      </span>
                    </div>
                    <div className='d-flex '>
                     
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2 w-auto'>
                        <strong className='text-primary me-1'> Job Type : </strong>{' '}
                        {jobData.jobType?.nameEn}
                      </span>
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2 w-auto'>
                        <strong className='text-primary me-1'> Work type : </strong>{' '}
                        {jobData.workType?.nameEn}
                      </span>
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'> Location : </strong>{' '}
                        {jobData?.city?.nameEn}, {jobData?.country?.nameEn}
                      </span>
                    </div>
                    <div className='d-flex '>
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2 w-auto'>
                        <strong className='text-primary me-1'> Posted At : </strong>{' '}
                        {formatDate(jobData.datePosted)}
                      </span>
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2 w-auto'>
                        <strong className='text-primary me-1'> Start Date : </strong>{' '}
                        {jobData.startDate ? formatDate(jobData.startDate) : 'N/A'}
                      </span>
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2 w-auto'>
                        <strong className='text-primary me-1'> End Date : </strong>{' '}
                        {jobData.endDate ? formatDate(jobData.endDate) : 'N/A'}
                      </span>
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'> Deadline : </strong>{' '}
                        {jobData.deadLine ? formatDate(jobData.deadLine) : 'N/A'}
                      </span>
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2 w-auto'>
                        <strong className='text-primary me-1'> Work Authorization : </strong>{' '}
                        {jobData.workAuthorization === 1
                          ? 'Yes'
                          : jobData.workAuthorization === 2
                          ? 'No'
                          : 'Will be provided'}
                      </span>
                    </div>
                    <div className='d-flex '>
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'> Major : </strong>{' '}
                        {jobData?.major?.nameEn}
                      </span>
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'>
                          {' '}
                          Location Preference for Role :{' '}
                        </strong>{' '}
                        {jobData?.jobPreference?.location ? 'Yes' : 'No'}
                      </span>
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'>
                          {' '}
                          Work Authorization Required in Country :{' '}
                        </strong>{' '}
                        {jobData?.jobPreference?.workAuthorization ? 'Yes' : 'No'}
                      </span>
                    </div>
                    <div className='d-flex '>
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'> Language Fluency : </strong>{' '}
                        {jobData?.jobPreference?.fluentLanguage?.nameEn || 'N/A'}
                      </span>
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'> Language Proficiency : </strong>{' '}
                        {jobData?.jobPreference?.languageProficiency?.nameEn || 'N/A'}
                      </span>
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'> Minimum GPA Required : </strong>{' '}
                        {jobData?.jobPreference.gpa || 'N/A'}
                      </span>
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'>
                          {' '}
                          Graduation Date Requirement :{' '}
                        </strong>{' '}
                        {jobData?.jobPreference.graduationDate
                          ? formatDate(jobData?.jobPreference.graduationDate)
                          : 'N/A'}
                      </span>
                    </div>

                    {jobData?.applicationUrl && (
                      <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'>Application URL : </strong>{' '}
                        <a href={jobData?.applicationUrl}>{jobData?.applicationUrl}</a>
                      </span>
                    )}
                    {/* <div className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2 w-auto'>
                      <strong className='text-primary me-1'>General Requirements for Role : </strong>
                      {roleDescription.innerHTML}
                    </div> */}
                  </div>
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <strong className='fw-bold fs-6 text-gray-400'>Estimated Salary</strong>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>
                          {jobData?.salary ? jobData?.salary.toLocaleString('en-US') : 'Unpaid'}{' '}
                          {jobData?.currency?.nameEn}
                        </div>
                      </div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <strong className='fw-bold fs-6 text-gray-400'>Hours per week</strong>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>{jobData?.hoursPerWeek} </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=' mb-5 notice d-flex   py-6 page-header'>
              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-bold'>
                  <h4 className='text-gray-800 fw-bolder'>Job Description</h4>
                  <div className='fs-6 text-gray-600' id='description'></div>
                </div>
              </div>
            </div>
            <div className=' mb-5 notice d-flex   py-6 page-header'>
              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-bold'>
                  <h4 className='text-gray-800 fw-bolder'>Additional Comments</h4>
                  <div className='fs-6 text-gray-600'>{jobData.additionalComments}.</div>
                </div>
              </div>
            </div>
            <div className=' mb-5 notice d-flex   py-6 page-header'>
              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-bold'>
                  <h4 className='text-gray-800 fw-bolder'>More Details</h4>
                  <div className='fs-6 text-gray-600' id='details'></div>
                </div>
              </div>
            </div>
            <div className=' mb-5 notice d-flex   py-6'>
              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-bold'>
                  <h4 className='text-gray-800 fw-bolder'>Benefits</h4>
                  <div className='fs-6 text-gray-600' id='benefits'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {active === 1 && (
        <div className='card mb-5 mt-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-body p-9'>
            <table className='table table-row-dashed table-row-gray-300 gy-7'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th className='' style={{width: '50%'}}>
                    Question name
                  </th>
                  <th style={{width: '10%'}}>Question type</th>
                  <th style={{width: '40%'}}>Question Choices</th>
                </tr>
              </thead>
              <tbody>
                {jobData.questions.map((el: any) => (
                  <tr>
                    <td className='text-gray-800 fw-bolder w-30'>{el.question}</td>
                    <td className='text-gray-800 fw-bolder'>{el.questionType}</td>
                    {el?.questionChoices?.length > 0 ? (
                      <td className=' fw-bolder '>
                        <div className='d-flex'>
                          {el?.questionChoices.map((choice: any, index: number) => {
                            return (
                              <span
                                className='bg-gray-300 me-2 my-2 p-2 rounded'
                                style={{fontSize: '11px'}}
                              >
                                {choice}
                              </span>
                            )
                          })}
                        </div>
                      </td>
                    ) : (
                      <td>N/A</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            {jobData.questions.length === 0 && (
              <div className='flex p-6 text-center'>No Questions available</div>
            )}
          </div>
        </div>
      )}

      {active === 3 && <JobApplications jobID={jobData._id} />}
    </div>
  )
}
