import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Table} from './components/table'
import {ICurrency} from './module.interface'
import {getAllCurrency} from '../../../services/currency'
import {PageTitle} from '../../../components/pageTitle'
import {searchInTable} from '../../../utils/useSearchTable'
import SearchInput from '../../../components/SearchInput'

export const Currency = () => {
  const [currencyList, setCurrencyList] = useState<Array<ICurrency>>([])
  const [loading, setLoading] = useState<any>(false)
  const [searchValue, setSearchValue] = useState('')
  const [filteredData, setFilteredData] = useState([])



  const listCurrency = async () => {
    setLoading(true)
    const response = await getAllCurrency()
    setCurrencyList(response.data.data)
    setFilteredData(response.data.data)
    setLoading(false)
  }
  const setDataResult = (response: any) => {
    setCurrencyList(response.data.data.queryResult)
    setFilteredData(response.data.data.queryResult)
    setLoading(false)
    setLoading(false)
  }
  useEffect(() => {
    listCurrency()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Currency' />
        <Link to='/currency/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
          
      <SearchInput 
      model={'currency'} 
      setLoading={setLoading} 
      setDataResult={setDataResult}
       ListAll={listCurrency}
        />

          <Table list={currencyList} listALl={listCurrency} skelton={loading} />
    </>
  )
}
