import axios from '../axios'
import {BASEURL} from '../environments/environment'


  export const searchAllModels = (model:any,term:any=0,page:any=null,pageSize:any=null) => {
    const pagination =  page!=null && pageSize ? `&page=${page}&pageSize=${pageSize}`: ''
    
      return axios
        .get(`${BASEURL}search?model=${model}&term=${term}${pagination}`)
        .then((res: any) => res)
        .catch((err: any) => err?.response)
    }
  