/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { getNumberOfApplications, getNumberOfCompletedProfiles, getNumberOfInstitutionUsersViews, getNumberOfMentorUsersViews, getNumberOfUsersByType, getNumberOfUsersFollowedCompany, getNumberOfUsersFollowedInstitution, getNumberOfUsersHired, getNumberOfUsersMentorshipSession, getNumberOfUsersViews } from '../../../../services/analyticsService'
import moment from 'moment'
import { formatDate } from '../../../../utils/DateFormatter'


type Props = {
    className: string
    description: string
    color: string
    img?: string
    icon: any
    value: any
}

export const CardsWidget20 = ({ className, description, color, img, value, icon }: Props) => (
    <div
        className={`d-flex justify-content-between p-4 bgi-no-repeat bgi-size-contain bgi-position-x-end  ${className}`}
        style={{
            minHeight: '150px',
            backgroundColor: color,
            backgroundImage: `url('${img}')`,
            borderRadius: '8px'
        }}
    >
        <div className='d-flex flex-column justify-content-between' style={{
            minHeight: '150px'
        }}>
            <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{value}</span>

            <span className='text-white  pt-1 fw-semibold fs-6'>{description}</span>
        </div>
        <div>
            {icon}
        </div>

    </div>
)

const AnalyticsCompany = (props: any) => {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 30)

    const [startDate, setStartDate] = useState<any>(new Date(yesterday));
    const [endDate, setEndDate] = useState<any>(new Date());
    const [data, setData] = useState<any>({});

     
  const numberOfUsersViews = async () => {
    const response = await getNumberOfUsersViews(props.id, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfUsersViews': response?.data?.data }))
    }

  }
  const numberOfApplications = async () => {
    const response = await getNumberOfApplications(props.id, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfApplications': response?.data?.data }))
    }

  }
  const numberOfUsersFollowedCompany = async () => {
    const response = await getNumberOfUsersFollowedCompany(props.id, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfUsersFollowedCompany': response?.data?.data }))
    }

  }
 
  const numberOfUsersHired = async () => {
    const response = await getNumberOfUsersHired(props.id, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfUsersHired': response?.data?.data }))
    }
  }

  useEffect(() => {
    numberOfApplications()
      numberOfUsersFollowedCompany()
      numberOfUsersHired()
      numberOfUsersViews()
      
  }, [endDate,startDate]);

    return (
        <>
            <div className='d-flex w-100 justify-content-end mb-4'>
                <div className='input-data me-3'>
                    <label htmlFor='startDate' className='fs-5 me-3'>
                        From
                    </label>
                    <input
                        type='date'
                        name='startDate'
                        className='form-control'
                        value={formatDate(startDate)}
                        defaultValue={formatDate(startDate)}
                        onChange={(e: any) => setStartDate(e.target.value)}
                    />

                </div>
                <div className='input-data '>
                    <label htmlFor='endDate' className='fs-5 me-3'>
                        To
                    </label>
                    <input
                        type='date'
                        name='endDate'
                        className='form-control'
                        value={formatDate(endDate)}
                        defaultValue={formatDate(endDate)}
                        min={startDate}
                        onChange={(e: any) => setEndDate(e.target.value)}
                    />

                </div>
            </div>
            {/* begin::Row */}
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                {/* begin::Col */}
                <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                    <CardsWidget20
                        className=''
                        color='#4145f1'
                        description="Users Views"
                        value={data.numberOfUsersViews || 0}
                        icon={
                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="white" className="bi bi-people-fill" viewBox="0 0 16 16">
                                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                            </svg>
                        }

                    />
                </div>
                <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                    <CardsWidget20
                        className=''
                        color='#F1416C'
                        description="Users followed company"
                        value={data.numberOfUsersFollowedCompany || 0}
                     
                      icon={
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="white" className="bi bi-hand-index-thumb" viewBox="0 0 16 16">
                        <path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1zM8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002zm2.094 2.025z"/>
                      </svg>
                    }
                    />
                </div>
                <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                    <CardsWidget20
                        className=''
                        color='#41e6f6'
                        description="Students hired"
                        value={data.numberOfUsersHired || 0}
                        icon={
                          <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="white" className="bi bi-people-fill" viewBox="0 0 16 16">
                              <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                          </svg>
                      }
                    />
                </div>
                <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
            <CardsWidget20
              className=''
              color='#50cd89'
              description="Job applications"
              value={data.numberOfApplications || 0}
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="white" className="bi bi-file-earmark-person" viewBox="0 0 16 16">
                  <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2v9.255S12 12 8 12s-5 1.755-5 1.755V2a1 1 0 0 1 1-1h5.5v2z" />
                </svg>
              }

            />

          </div>
            </div>
        </>

    )
}

export { AnalyticsCompany }
