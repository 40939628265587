import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Autocomplete, Chip, styled, TextField } from "@mui/material";
import { getAllIndustry } from '../../../../services/industry'
import { getAllSubIndustry } from '../../../../services/subIndustry'

interface IndustriesUIProps {
    Userindustries: any
    handleSelectChange: any

    required?: any
    single?: any
}
export default function IndustriesUI(props: IndustriesUIProps) {
    const { Userindustries, handleSelectChange, required, single } = props
    const [allindustry, setAllIndustry] = useState<any>({});
    const [loadingIndustry, setloadingIndustry] = useState(true)
    const [selectedSubIndstry, setSetSelectedSubIndstry] = useState<any>([])
    const [allSubIndustry, setAllSubIndustry] = useState<any>([]);
    const [addInd, setAddInd] = useState(false);
    const [count, setCount] = useState(0);
    // console.log()
    const handleOnChange = (event: any, value: any) => {

        if (single) {
            // setUserData((_data: any) => ({ ..._data, 'subIndustry': value?._id }));
            // setUserData((_data: any) => ({ ..._data, 'industry': value?.industry }));
        }
        else {
            const data = { 'event': event, 'value': value }
            const NewUserindustries: any = []
            const groupByIndustry = value.reduce((group: any, sub: any) => {
                const { industry } = sub;
                group[industry] = group[industry] ?? [];
                group[industry].push(sub);
                return group;
            }, {});

            setSetSelectedSubIndstry(groupByIndustry)
            Object.keys(groupByIndustry).forEach(function (key, index) {
                NewUserindustries.push(
                    {
                        'industry': key,
                        'subIndustries': groupByIndustry[key].map((item: any) => item._id)
                    }
                )
            });
            handleSelectChange(NewUserindustries, 'industries')
            // setUserData((_data: any) => ({ ..._data, 'industries': NewUserindustries }));

        }

    };


    const listAllIndustry = async () => {
        setloadingIndustry(true)
        const response: any = await getAllIndustry()
        if (response) {
            const data = response.data.data
            const obj: any = {};

            data.forEach((element: any, index: any) => {
                obj[element._id] = element.nameEn
            });
            setAllIndustry(obj)
        }
    }
    const listASubIndustry = async () => {
        setloadingIndustry(true)
        const response: any = await getAllSubIndustry()
        if (response) {
            const data = response.data.data.queryResult
            // .map((item: any) => ({ 'label': item.nameEn, 'id': item._id }))

            setAllSubIndustry(data)
            setloadingIndustry(false)

        }
    }

    useEffect(() => {
        listAllIndustry()
        listASubIndustry()
    }, []);

    const GroupHeader = styled('div')(({ theme }) => ({
        padding: '4px 10px',
        color: 'white',
        background: '#009ef7',
        height: '40px',
        display: 'flex',
        alignItems: 'center'
    }));

    const GroupItems = styled('ul')({

    });

    return (
        <div className='input-data my-3'>
            <div >
                <label>  <span className='text-danger'>*</span> Industries </label>
                <Grid container columnSpacing='3'>
                    <Autocomplete
                        onChange={handleOnChange}
                        id="grouped-demo"
                        multiple={single ? false : true}
                        defaultValue={Userindustries?.map((item: any) => item.subIndustries).flat()}
                        options={allSubIndustry.sort((a: any, b: any) => -b.industry.localeCompare(a.industry))}
                        groupBy={(option: any) => allindustry[option.industry]}
                        getOptionLabel={(option) => option.nameEn}
                        sx={{ minHeight: '40px', borderRadius: '8px', width: '100%' }}
                        renderInput={(params) => <TextField  {...params} name={'industry'} margin="normal" placeholder={`${'select'}`} />}
                        renderGroup={(params: any) => (
                            <li key={params._id}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                            </li>)}
                        isOptionEqualToValue={(option: any, value: any) => option._id === value._id}
                    />

                </Grid>

            </div>
            {((Userindustries && Userindustries?.length !== 0) || Object.keys(selectedSubIndstry).length !== 0) && !single &&
                <div className=' mt-4'>
                    <label>Selected Industries</label>
                    <div className="p-2 border rounded-xl">
                        {
                            Object.keys(selectedSubIndstry).length > 0 ?
                                Object.keys(selectedSubIndstry).map((key: any) => {
                                    return (
                                        <div className="mb-4">
                                            <span className="block">{allindustry[key]}</span>
                                            <div className="mt-2 d-flex gap-2 flex-wrap">
                                                {selectedSubIndstry[key].map((item: any) => {
                                                    return (
                                                        <Chip label={item.nameEn} />
                                                        // <span className="">{item?.nameEn}</span>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                Userindustries?.map((key: any) => {
                                    return (
                                        <div className="mb-4">
                                            <span className="block">{allindustry[key?.industry?._id]}</span>
                                            <div className="mt-2 d-flex gap-2 flex-wrap">
                                                {key?.subIndustries.map((item: any) => {
                                                    return (
                                                        <Chip label={item.nameEn}
                                                        />
                                                        // <span className="">{item?.nameEn}</span>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                    </div>
                </div>
            }

       </div>

    );
}
