import {ChangeEvent, useState} from 'react'

import {DataForm} from './components/data-form'
import {useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../components/pageTitle'
import {createAdmin} from '../../../services/admin'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

export const CreateAdmin = () => {
  const [formData, setFormData] = useState<any>()
  const [disabled, setdisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const req = ['password', 'role', 'email']
  const navigate = useNavigate()

  const adminRoles:any = {
    SUPER_ADMIN: 0,
    STUDENT_ADMIN: 1,
    COMPANY_ADMIN: 2,
    INSTITUTION_ADMIN: 3,
    MENTOR_ADMIN: 4,

  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((_data: any) => ({..._data, [e.target.name]: e.target.name==='role'?adminRoles[e.target.value]:e.target.value}))
    setdisabled(false)
  }

  const onClickSubmitBtn = (e: any) => {
    setIsSubmitted(true)
    e.preventDefault()
    // newAdmin()
    req.every((key) => key in formData) && newAdmin()
  }

  const newAdmin = async () => {
    setLoading(true)
    const response = await createAdmin(
      formData.firstName,
      formData.lastName,
      formData.password,
      formData.role,
      formData.email
    )
    if (response.status === 201) {
      navigate('/admins')
      toast.success('Done successfully !', {
         position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
       theme: "colored",
        });
      setLoading(false)
    } else {
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setLoading(false)
    }
  }

  const breadCrampList = [
    {
      name: 'Admins',
      route: '/admins',
    },
  ]
  return (
    <>
       <ToastContainer/>
      <PageTitle breadCrampList={breadCrampList} active='Create' />
      <div className=' w-75 m-auto'>
        <DataForm
          onClickSubmit={onClickSubmitBtn}
          handleChange={handleChange}
          data={formData}
          ButtonText='Create'
          action='create'
          Show={disabled}
          loading={loading}
          isSubmitted={isSubmitted}
        />
      </div>
    </>
  )
}
