import React, {useEffect, useState} from 'react'
type Props = {
  handleChange: any
  data: any
}

export default function Addresses(props: Props) {
  const {handleChange, data} = props
  const [addresses, setAddresses] = useState<any>()
  const [errorMsg, setErrorMsg] = useState<any>('')

  const handleKeyDown = (e: any) => {

    if (e.key === 'Enter') {
      if (addresses) {
        if (!addresses.includes(e.target.value)) {
          addresses ? setAddresses([...addresses, e.target.value]) : setAddresses([e.target.value])
          e.target.value=''
        } else {
          setErrorMsg('Already Exist')
        }
      } else {
        setAddresses([e.target.value])
      }
    }
  }

  useEffect(() => {
    if(addresses&&addresses?.length!=0) handleChange(addresses, 'addresses')
  }, [addresses])

  useEffect(() => {

    data && setAddresses(data)
  }, [data])

  const handleRemoveItem = (item: any) => {
    let arr = addresses
    arr = arr.filter((el: any) => el !== item)
    setAddresses([...arr])
  }

  return (
    <div className='input-data my-3'>
      <label htmlFor='address' className='fs-5 me-3'>
      <span className='text-danger'>*</span>    Address
      </label>
      <input
        type='text'
        name='address'
        className='form-control'
        onKeyDown={handleKeyDown}
        onChange={() => setErrorMsg(null)}
      />
      <p>Press Enter to add address</p>
      {errorMsg && (
        <div className='alert alert-danger px-4 p-1' role='alert'>
          {errorMsg}
        </div>
      )}
      <div className='d-flex gap-4 mt-5 flex-wrap '>
        {addresses &&
          addresses.map((item: any) => (
            // <button className='btn btn-primary btn-sm'>
            <li
              className='d-flex gap-4 h-4 mb-2 list-item btn btn-primary btn-sm '
              onClick={() => handleRemoveItem(item)}
            >
              {item}
              <i className='la la-remove'></i>
            </li>
            // </button>
          ))}
      </div>
    </div>
  )
}
