import {ChangeEvent, useEffect, useState} from 'react'
import {IEducationLevel} from './module.interface'
import {DataForm} from './components/data-form'
import {useNavigate, useParams} from 'react-router-dom'
import {PageTitle} from '../../../components/pageTitle'
import {getEducationLevel, updateEducationLevel} from '../../../services/educationLevel'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
export const UpdateEducationLevel = () => {
  const [disabled, setdisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)

  const navigate = useNavigate()
  const {id} = useParams()
  const req = ['order', 'nameEn', 'nameAr', 'enrolledHighSchool', 'educationType']
  const [formData, setFormData] = useState<IEducationLevel>({
    order: 0,
    nameEn: '',
    nameAr: '',
    enrolledHighSchool: false,
    educationType: '',
  })
  type ObjectKey = keyof typeof formData
  const [isSubmitted, setIsSubmitted] = useState<any>(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((_data) => ({..._data, [e.target.name]: e.target.value}))
    setdisabled(false)
    if (e.target.name === 'enrolledHighSchool') {
      setFormData((_data) => ({..._data, [e.target.name]: e.target.checked}))
    }
  }

  useEffect(() => {
    if (id) {
      handleGetEducationLevel()
    }
  }, [id])

  const handleGetEducationLevel = async () => {
    const response = await getEducationLevel(id)
    if (response) {
      const data = {
        order: response.data.data.order,
        nameEn: response.data.data.nameEn,
        nameAr: response.data.data.nameAr,
        enrolledHighSchool: response.data.data.enrolledHighSchool,
        educationType: response.data.data.educationType,
      }
      setFormData(data)
    }
  }

  const onClickSubmitBtn = (e: any) => {
    setIsSubmitted(true)
    e.preventDefault()
    req.every((key) => formData.hasOwnProperty(key) && formData[key as ObjectKey] !== '') &&
      handleUpdateEducationLevel()
  }

  const handleUpdateEducationLevel = async () => {
    setLoading(true)
    const response = await updateEducationLevel(id, formData)
    if (response.status == 200) {
      toast.success('Done successfully !', {
         position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
       theme: "colored",
        });
      navigate('/education-level')
      setLoading(false)
    } else {
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setLoading(false)
    }
  }

  const breadCrampList = [
    {
      name: 'Education Level',
      route: '/education-level',
    },
  ]

  return (
    <>
       <ToastContainer/>
      <PageTitle breadCrampList={breadCrampList} active='Update' />
      <div className='w-75 m-auto'>
        <DataForm
          onClickSubmit={onClickSubmitBtn}
          handleChange={handleChange}
          data={formData}
          ButtonText='Update'
          Show={disabled}
          loading={loading}
          isSubmitted={isSubmitted}
        />
      </div>
    </>
  )
}
