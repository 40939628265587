import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { searchAllModels } from '../services/search'

function SearchInput(props: any) {
  const location = useLocation()
  const { model, setLoading, setDataResult, ListAll, currentPage, pageSize, setSearchActive, setCurrentPage } = props
  const [searchValue, setSearchValue] = useState<any>(null)
  const [loading, setLoadingPage] = useState<any>(false)
  const handleSearchChange = async () => {
    setLoading(true)
    const response = await searchAllModels(model, searchValue, currentPage, pageSize)
    setDataResult(response)
  }
  useEffect(() => {
    setLoadingPage(true)
    setSearchValue(null)

    setTimeout(() => {
      setLoadingPage(false)
    }, 100)
  }, [location.pathname])

  useEffect(() => {
    if (searchValue == '') {
      ListAll()
      setSearchActive && setSearchActive(false)
      return
    }
    if (searchValue != null) {
      setSearchActive && setSearchActive(true)
      const getData = setTimeout(() => {
        handleSearchChange()
      }, 800)
      return () => clearTimeout(getData)
    }
  }, [searchValue, pageSize, currentPage])
  const handleInput = (e: any) => {
    setCurrentPage && setCurrentPage(0)
    setSearchValue(e)
  }
  return (
    <div className='d-flex align-items-center position-relative my-4'>
      {!loading &&
        <input
          autoComplete="off"
          
           type='text'
          name='searchBar'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px  border bg-white'
          placeholder='Search'
          value={searchValue}
          onChange={e => handleInput(e.target.value)}
        />}
    </div>
  )
}

export default SearchInput
