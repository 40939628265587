import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Delete } from '../delete'

import { KTSVG } from '../../../../../_metronic/helpers'
import { deleteUser, updateUser } from '../../../../services/user'
import ChangePasswordModal from '../../../../components/ChangePasswordModal'
import Pagination from '../../../../components/Pagination'
import { exportTableToExcel } from '../../../../utils/export'
import { formatDate } from '../../../../utils/DateFormatter'
import { objectToFormData, sortByKey } from '../../../../utils/helpers'
import TableBodyLoading from '../../../../components/tableBodyLoading'

type Props = {
  list: any[]
  model: any
  // listALl:any
  length: any
  skelton: boolean
}

export const Table = (props: Props) => {
  const { list, model, length, skelton } = props // listALl
  const [loading, setLoading] = useState<any>(false)

  const [showModal, setShowModal] = useState(false)
  const [_id, setId] = useState<string>('')

  const [sortedData, setSortedData] = useState<any>(list)


  const cancelFn = () => {
    setShowModal(false)
  }

  const deleteGetOfferFn = async () => {
    setLoading(true)
    const response = await deleteUser(_id)
    if (response) {
      cancelFn()
      setLoading(false)
      // listALl()
    }
  }

  const handleExport = (id: string) => {
    exportTableToExcel(id, 'table.xlsx')
  }

  const viewModal = (_id: any) => {
    setId(_id)
    setShowModal(true)
  }


  const delteFn = () => {
    deleteGetOfferFn()
    setShowModal(false)
  }

  const approveUser = async (id: any) => {
    const res = await updateUser(id, objectToFormData({ approved: true }))
    if (res.status === 200) {
      window.location.reload()
    }
  }


  const modelTableCol: any = {
    Student: {
      name: ['Student Name', 'Student ID', 'Level of Education', 'Major', 'Graduation Date'],
      values: ['fullName', '_id', 'educationLevel', 'major', 'graduationDate'],
    },
    Company: {
      name: ['Employer/Organization', 'Status'],
      values: ['name', 'approved'],
    },
    Institution: {
      name: ['Institution Name', 'Email',],
      values: ['name', 'careerAdvisingEmail',],
    },
    Mentor: {
      name: ['Full Name', 'Email', 'Alma Mater (Institution)', 'Expertise', 'Featured on Platform'],
      values: ['fullName', 'email', 'almaMater', 'expertise', 'featuredAsPublic'],
    },
    users: {
      name: ['User Type', 'Full Name', 'Email', 'Role', 'Organization', 'Message'],
      values: ['model', 'fullName', 'email', 'role', 'organization', 'featuredAsPublic'],
    },
  }

  const getModelTableValue = (item: any, name: any) => {
    switch (name) {
      case 'graduationDate':
        return item?.subModel?.[name] ? formatDate(item?.subModel?.[name]) : item?.[name] ? formatDate(item?.[name]) : 'N/A'
      case 'approved':
        return item?.[name] ? 'Approved' : 'Pending'
      case 'country':
        return item?.country?.nameEn || 'N/A'
      case 'almaMater':
        return item?.subModel?.almaMater?.subModel?.name || 'N/A'

      case 'educationLevel':
        return item?.subModel?.educationLevel?.nameEn || item?.educationLevel?.nameEn || 'N/A'
      case 'major':
        return item?.subModel?.major?.nameEn || item?.major?.nameEn || 'N/A'
      case 'expertise':
        return item?.subModel?.major?.nameEn || 'N/A'
      case 'fullName':
        return `${item?.subModel?.firstName || item?.firstName} ${item?.subModel?.lastName || item?.lastName}` || 'NA'

      case 'featuredAsPublic':
        return item?.subModel?.featuredAsPublic ? 'Yes' : 'No'
      default:
        return item?.subModel?.[name]
    }
  }
  useEffect(() => {
    setSortedData(list)
  }, [list])

  return (
    <>
      <a
        className='text-danger d-flex justify-content-end mx-3 mb-4 align-items-center'
        style={{ fontSize: '14px', fontWeight: 'bold', cursor: 'pointer' }}
        onClick={() => {
          handleExport(`${model}-list`)
        }}
      >
        Export table <i className="bi bi-arrow-bar-up text-danger mx-2"></i>
      </a>
      <div>

        {showModal && (
          <Delete
            show={showModal}
            onCancel={cancelFn}
            onDelete={delteFn}
            loading={loading}
            model={model}
          />
        )}
        <div className={`card `}>
          {/* begin::Body */}
          <div className='card-body py-3 '>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table
                className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
                id={`${model}-list`}
              >
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    {modelTableCol[model].name.map((el: any, index: any) => (
                      <th
                        className='min-w-150px text-hover-primary'
                        onClick={() => {
                          setSortedData(sortByKey(list, modelTableCol[model].values[index]))
                        }}
                      >
                        {el}
                        <i className='bi bi-arrow-down'></i>
                      </th>
                    ))}
                    <th className='min-w-100px text-center'>Actions</th>
                  </tr>
                </thead>
                {skelton ?
                  <TableBodyLoading count={modelTableCol[model].name.length} />
                  :
                  <tbody>
                    {sortedData.length > 0 &&
                      sortedData.map((item: any) => {
                        return (

                          <tr>
                            {modelTableCol[model].values.map((val: any) => (
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <div className='text-dark fw-bold text-hover-primary fs-6'>
                                      {getModelTableValue(item, val) || item[val] || 'N/A'}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            ))}

                            <td>
                              <div className='d-flex justify-content-start flex-shrink-0'>
                                {/* <button
                                className='btn btn-danger btn-sm btn-sm me-1 me-5 d-flex justify-content-end'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_1'
                                type='button'
                              >
                                Change password
                              </button> */}
                                <Link
                                  to={`/student/details/${item._id}/${item.name ? item.name : `${item?.subModel?.firstName} ${item?.subModel?.middleName}${item?.subModel?.lastName}`}`}
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-2'
                                >
                                  <i className='bi bi-eye-fill text-primary fs-2'></i>
                                </Link>
                                <Link
                                  to={`/student/update/${item._id}`}
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-2'
                                >
                                  <i className='bi bi-pencil-square text-info fs-2'></i>
                                </Link>



                                <button
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                                  onClick={() => viewModal(item._id)}
                                >
                                  <i className='bi bi-trash text-danger fs-2'></i>
                                </button>
                                {!item.approved ? (
                                  <>
                                    <button
                                      className='btn  btn-bg-light btn-active-color-primary btn-sm'
                                      onClick={() => approveUser(item._id)}
                                    >
                                      {/* <i className='bi bi-check2 fs-2 text-success'></i> */}
                                      Approve
                                    </button>
                                  </>
                                ) : (
                                  <>

                                  </>
                                )}
                              </div>
                            </td>
                          </tr>


                        )
                      })}
                  </tbody>
                }

              </table>
              {sortedData.length == 0 && <div className='flex p-6 text-center'>
                No data available</div>}


            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      </div>
    </>
  )
}
