import {MouseEventHandler, useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Delete} from '../delete'

import {deleteCity} from '../../../../services/city'
import {KTSVG} from '../../../../../_metronic/helpers'
import {exportTableToExcel} from '../../../../utils/export'
import {sortByKey} from '../../../../utils/helpers'
import {useThemeMode} from '../../../../../_metronic/partials'
import {toast} from 'react-toastify'
import {deleteNotificationType} from '../../../../services/notificationsType'

type Props = {
  list: any[]
  listALl: any
}

export const Table = (props: Props) => {
  const {list, listALl} = props
  const [loading, setLoading] = useState<any>(false)

  const [showModal, setShowModal] = useState(false)
  const [_id, setId] = useState<string>('')

  const viewModal = (_id: any) => {
    setId(_id)
    setShowModal(true)
  }

  const cancelFn = () => {
    setShowModal(false)
  }

  const delteFn = () => {
    deleteNotificationTypeFn()
    setShowModal(false)
  }

  const deleteNotificationTypeFn = async () => {
    setLoading(true)
    const response = await deleteNotificationType(_id)
    if (response.status === 200) {
      toast.success('Deleted successfully !', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })
      cancelFn()
      setLoading(false)
      listALl()
    } else {
      setLoading(false)
      cancelFn()
      toast.error(response.data.error, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })
    }
  }
  const handleExport = (id: string) => {
    exportTableToExcel(id, `${id}.xlsx`)
  }
  type Data = typeof list
  type SortKeys = keyof Data[0]
  type SortOrder = 'ascn' | 'desc'
  function sortData({
    tableData,
    sortKey,
    reverse,
  }: {
    tableData: Data
    sortKey: SortKeys
    reverse: boolean
  }) {
    if (!sortKey) return tableData

    const sortedData = shownData.sort((a: any, b: any) => {
      return a[sortKey] > b[sortKey] ? 1 : -1
    })

    if (reverse) {
      return sortedData.reverse()
    }

    return sortedData
  }

  function SortButton({
    sortOrder,
    columnKey,
    sortKey,
    onClick,
  }: {
    sortOrder: SortOrder
    columnKey: SortKeys
    sortKey: SortKeys
    onClick: MouseEventHandler<HTMLButtonElement>
  }) {
    const {mode} = useThemeMode()
    return (
      <button
        onClick={onClick}
        className={`${mode === 'dark' ? 'text-white' : 'text-dark'}  ${
          sortKey === columnKey && sortOrder === 'desc' ? 'sort-button sort-reverse' : 'sort-button'
        }`}
      >
        ▲
      </button>
    )
  }
  const [sortKey, setSortKey] = useState<any>('order')
  const [sortOrder, setSortOrder] = useState<SortOrder>('ascn')

  const sortedData = useCallback(
    () => sortData({tableData: shownData, sortKey, reverse: sortOrder === 'desc'}),
    [list, sortKey, sortOrder]
  )

  function changeSort(key: SortKeys) {
    setSortOrder(sortOrder === 'ascn' ? 'desc' : 'ascn')
    setSortKey(key)
  }

  const [shownData, setShownData] = useState<any>([])

  const headers: {key: SortKeys; label: string}[] = [
    {key: 'kind', label: 'Kind'},
    {key: 'name', label: 'Name'},
    {key: 'message', label: 'Message'},
  ]
  useEffect(() => {
    const data = sortedData().map((item: any) => ({
      _id: item?._id,
      kind: item?.kind,
      name: item?.name,
      message: item?.message,
    }))
    setShownData(data)
  }, [sortedData])
  useEffect(() => {
    if (list) {
      const data = list.map((item: any) => ({
        _id: item?._id,
        kind: item?.kind,
        name: item?.name,
        message: item?.message,
      }))
      setShownData(data)
    }
  }, [list])
  return (
    <>
      <div>
        {/* <button
          className='btn btn-danger d-flex justify-content-end'
          onClick={() => {
            handleExport(`notificationType-list`)
          }}
        >
          Export
        </button> */}
        <div className={`card `}>
          {/* begin::Body */}
          <div className='card-body py-3 '>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    {headers.map((row) => {
                      return (
                        <td
                          className={`cursor-pointer ${sortKey === row.key ? 'text-primary' : ''}`}
                          onClick={() => changeSort(row.key)}
                          {...{
                            sortOrder,
                            sortKey,
                          }}
                        >
                          {row.label}

                          <SortButton
                            columnKey={row.key}
                            onClick={() => changeSort(row.key)}
                            {...{
                              sortOrder,
                              sortKey,
                            }}
                          />
                        </td>
                      )
                    })}
                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {shownData?.map((item: any) => {
                  return (
                    <>
                      {/* begin::Table body */}
                      <tbody>
                        <tr>
                          <td style={{width: '10%'}}>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark fw-bold text-hover-primary fs-6'>
                                  {item.kind}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='text-dark fw-bold text-hover-primary d-block fs-6 '>
                              {item.name}
                            </div>
                          </td>
                          <td>
                            <div className='text-dark fw-bold text-hover-primary d-block fs-6 '>
                              {item.message}
                            </div>
                          </td>

                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <Link
                                to={`/notification-type/update/${item._id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-5'
                              >
                                <i className='bi bi-pencil-square text-info fs-2'></i>
                              </Link>

                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                onClick={() => viewModal(item._id)}
                              >
                                <i className='bi bi-trash text-danger fs-2'></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      {/* end::Table body */}
                    </>
                  )
                })}
              </table>

              {showModal && (
                <Delete show={showModal} onCancel={cancelFn} onDelete={delteFn} loading={loading} />
              )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      </div>
    </>
  )
}
