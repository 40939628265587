/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { CardsWidget17 } from '../../../_metronic/partials/widgets';
import { UpdateAppState, getAppState, getNumberOfCompletedProfiles, getNumberOfUsersAdded, getNumberOfUsersApplied, getNumberOfUsersByType, getNumberOfUsersHiredAdmin, getNumberOfUsersShipSession, getNumberOfJobs, getNumberOfUsersShipSessionCompleted } from '../../services/analyticsService'
import moment from 'moment'
import { formatDate } from '../../utils/DateFormatter'
import Alert from '../../components/alert'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type Props = {
  className: string
  description: string
  color: string
  img?: string
  icon: any
  value: any
}
export const CardsWidget20 = ({ className, description, color, img, value, icon }: Props) => (
  <div
    className={`d-flex justify-content-between p-4 bgi-no-repeat bgi-size-contain bgi-position-x-end  ${className}`}
    style={{
      minHeight: '150px',
      backgroundColor: color,
      backgroundImage: `url('${img}')`,
      borderRadius: '8px'
    }}
  >
    <div className='d-flex flex-column justify-content-between' style={{
      minHeight: '150px'
    }}>
      <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{value}</span>
      <span className='text-white  pt-1 fw-semibold fs-6'>{description}</span>
    </div>
    <div>
      {icon}
    </div>
  </div>
)
const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 365)
  const [startDate, setStartDate] = useState<any>(new Date(yesterday));
  const [endDate, setEndDate] = useState<any>(new Date());
  const [data, setData] = useState<any>({});
  const [alertMsg, setAlertMsg] = useState<any>(null);

  const numberOfCompletedProfiles = async () => {
    const response = await getNumberOfCompletedProfiles(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfCompletedProfiles': response?.data?.data }))
    }
  }
  const numberOfJobs = async () => {
    const response = await getNumberOfJobs(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfJobs': response?.data?.data }))
    }
  }
  const numberOfUsersAdded = async () => {
    const response = await getNumberOfUsersAdded(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfUsersAdded': response?.data?.data }))
    }
  }
  const numberOfUsersApplied = async () => {
    const response = await getNumberOfUsersApplied(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfUsersApplied': response?.data?.data }))
    }
  }
  const numberOfUsersHiredAdmin = async () => {
    const response = await getNumberOfUsersHiredAdmin(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfUsersHiredAdmin': response?.data?.data }))
    }
  }
  const numberOfUsersShipSession = async () => {
    const response = await getNumberOfUsersShipSession(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfUsersShipSession': response?.data?.data }))
    }
  }
  const numberOfUsersShipSessionCompleted = async () => {
    const response = await getNumberOfUsersShipSessionCompleted(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfUsersShipSessionCompleted': response?.data?.data }))
    }
  }
  const appState = async () => {
    const response = await getAppState()
    if (response) {
      setData((_data: any) => ({ ..._data, 'appState': response?.data?.data?.inMaintenance }))
    }
  }
  const ChangeAppState = async () => {
    setAlertMsg(null)
    const response: any = await UpdateAppState()
    if (response) {
      setAlertMsg(`Edqan app now is ${data?.appState ? 'in maintenance mode' : 'Active'}`)
      appState()
    }
  }
  const allUsers = async (type: any) => {
    const response = await getNumberOfUsersByType(type)
    if (response) {
      if (type === 'Student') {
        setData((_data: any) => ({ ..._data, 'students': response?.data?.data }))
      }
      else if (type === 'Mentor') {
        setData((_data: any) => ({ ..._data, 'mentor': response?.data?.data }))
      }
      else if (type === 'Institution') {
        setData((_data: any) => ({ ..._data, 'institution': response?.data?.data }))
      }
      else {
        setData((_data: any) => ({ ..._data, 'company': response?.data?.data }))
      }
    }
  }
  useEffect(() => {
    numberOfCompletedProfiles()
    numberOfJobs()
    numberOfUsersShipSessionCompleted()
    numberOfUsersAdded()
    numberOfUsersApplied()
    numberOfUsersHiredAdmin()
    numberOfUsersShipSession()
  }, [endDate, startDate])

  useEffect(() => {
    allUsers('Student')
    allUsers('Mentor')
    allUsers('Company')
    allUsers('Institution')
    appState()
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <>
        <div className='mt-4'>{alertMsg && <Alert alertTitle='Done!' alertMsg={alertMsg} type='success' />}</div>
        <div className='d-flex w-auto '>
          <h6 className='me-8'>App State ( In Maintenance Mode )</h6>
          <div className="form-check form-switch ">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              defaultChecked={data?.appState}
              onClick={() => ChangeAppState()} />
          </div>
        </div>
        <div className='d-flex w-100 justify-content-end mb-4'>
          <div className='input-data me-3'>
            <label htmlFor='startDate' className='fs-5 me-3'>
              From
            </label>
            {/* <input
              type='date'
              name='startDate'
              className='form-control'
              value={formatDate(startDate)}
              defaultValue={formatDate(startDate)}
              onSelect={(e: any) => setStartDate(e.target.value)}
            /> */}
            <DatePicker
              className='form-control'
              selected={startDate}
              
              onChange={(e: any) => setStartDate(e)} 
            />
          </div>
          <div className='input-data '>
            <label htmlFor='endDate' className='fs-5 me-3'>
              To
            </label>
            
            {/* <input
              type='date'
              name='endDate'
              className='form-control'
              value={formatDate(endDate)}
              defaultValue={formatDate(endDate)}
              min={startDate}
              onInput={(e: any) => setEndDate(e.target.value)}
            /> */}
            <DatePicker
              className='form-control'
              selected={endDate}
              
              onChange={(e: any) => setEndDate(e)} //only when value has changed
            />
          </div>
        </div>
        {/* begin::Row */}
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          {/* begin::Col */}
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-4'>
            <CardsWidget20
              className=''
              color='#4145f1'
              description="All users"
              value={data.numberOfUsersAdded || 0}
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="white" className="bi bi-people-fill" viewBox="0 0 16 16">
                  <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                </svg>
              }
            />
          </div>
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-4'>
            <CardsWidget20
              className='mb-4'
              color='#656677'
              description="Number of jobs posted"
              value={data.numberOfJobs || 0}
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="white" className="bi bi-people-fill" viewBox="0 0 16 16">
                  <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                </svg>
              }
            />
          </div>
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-4'>
            <CardsWidget20
              className=''
              color='#d65aaa'
              description=" Number of users that have completed mentorship sessions"
              value={data.numberOfUsersShipSession || 0}
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="white" className="bi bi-people-fill" viewBox="0 0 16 16">
                  <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                </svg>
              }
            />
          </div>
          {/* end::Col */}
          {/* begin::Col */}
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-4'>
            <CardsWidget20
              className=''
              color='#F1416C'
              description="Completed profiles"
              value={data.numberOfCompletedProfiles || 0}
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="white" className="bi bi-person-bounding-box" viewBox="0 0 16 16">
                  <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5z" />
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                </svg>
              }
            />

          </div>
          {/* end::Col */}
          {/* begin::Col */}
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-4'>
            <CardsWidget20
              className=''
              color='#50cd89'
              description="Users applied to jobs"
              value={data.numberOfUsersApplied || 0}
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="white" className="bi bi-file-earmark-person" viewBox="0 0 16 16">
                  <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2v9.255S12 12 8 12s-5 1.755-5 1.755V2a1 1 0 0 1 1-1h5.5v2z" />
                </svg>
              }
            />
          </div>
          {/* end::Col */}
          {/* begin::Col */}
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-4'>
            <CardsWidget20
              className=''
              color='#ffc700'
              description="Users hired"
              value={data.numberOfUsersHiredAdmin || 0}
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="white" className="bi bi-person-workspace" viewBox="0 0 16 16">
                  <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                  <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                </svg>
              }
            />
          </div>
          {/* end::Col */}
          {/* begin::Col */}
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-4'>
            <CardsWidget20
              className=''
              color='#5913dc'
              description="Number of users requesting mentors"
              value={data.numberOfUsersShipSession || 0}
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="white" className="bi bi-laptop" viewBox="0 0 16 16">
                  <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />
                </svg>
              }
            />
          </div>
          {/* begin::Col */}
          <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-8 mb-md-5 mb-xl-10'>
            <CardsWidget17 data={data} />
          </div>
        </div>
      </>
    </>
  )
}
export { DashboardWrapper }
