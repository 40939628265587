import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {PageTitle} from '../../../../components/pageTitle'
import {updateMeetingStatusFromAdmin} from '../../../../services/mentoringProgramOptions'
import {getMentorShipProgram} from '../../../../services/user'
import {clearMenteeRating, clearMentorRating} from '../../../../services/meeting'
import {formatDate} from '../../../../utils/DateFormatter'

export const MentoringProgramView = () => {
  const [formData, setFormData] = useState<any>()
  const {id} = useParams()
  const [loading, setLoading] = useState<any>(false)
  const [loadingDeclined, setLoadingDeclined] = useState<any>(false)

  const handleGetJobApplication = async () => {
    const response = await getMentorShipProgram(id)
    if (response) {
      setFormData(response.data.data)
    }
  }

  const updateMeetingStatus = async (_id: any, status: string) => {
    status === 'declined' ? setLoadingDeclined(true) : setLoading(true)
    const response = await updateMeetingStatusFromAdmin(_id, status)
    if (response) {
      status === 'declined' ? setLoadingDeclined(false) : setLoading(false)
      handleGetJobApplication()
    }
  }

  const clearMenteeRatingHandler = async () => {
    const response = await clearMenteeRating(id)
    handleGetJobApplication()
  }

  const clearMentorRatingHandler = async () => {
    const response = await clearMentorRating(id)
    handleGetJobApplication()
  }

  useEffect(() => {
    if (id) {
      handleGetJobApplication()
    }
  }, [id])

  return (
    <>
      {formData && (
        <>
          <div className='d-flex justify-content-between flex-col my-3'>
            <PageTitle active={`Mentorship program details`} />
          </div>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
              <span
                className={`d-flex align-items-center position-absolute me-5 text-white p-2 rounded  bg-success ${
                  formData?.mentorShipSession?.status === 'declined' ? 'bg-danger' : 'bg-success'
                } `}
                style={{top: '13px', right: '0px'}}
              >
                <strong className='me-2 d-block'> Status :</strong>
                <span className='text-capitalize'>{formData?.mentorShipSession?.status}</span>
              </span>
              <div className='d-flex justify-content-start mb-4 flex-shrink-0'>
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                    <span className='text-primary me-1 text-capitalize'> Meeting ID : </span>{' '}
                    {formData?.mentorShipSession?._id}
                  </span>

                  <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                    <span className='text-primary me-1'> Meeting Date : </span>{' '}
                    {formatDate(formData.updatedAt)}
                  </span>
                  <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                    <span className='text-primary me-1 text-capitalize'> Meeting Type : </span>{' '}
                    {formData?.meetingType || 'N/A'}
                  </span>
                </div>
              </div>
              <div className='p-4 border rounded'>
                <div className='d-block  mb-6'>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 w-100'>
                    <span className='d-block align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2 w-100'>
                      <span className='d-block  text-primary me-1 text-capitalize'>
                        {' '}
                        What would student/professional like support in?{' '}
                      </span>{' '}
                      {formData?.mentorShipSession?.mentorShipType?.nameEn || 'N/A'}
                    </span>
                  </div>
                </div>
                <div className='d-block  mb-6'>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='d-block w-100 align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                      <span className='d-block text-primary me-1 text-capitalize'>
                        In which language student/professional prefer to communicate?{' '}
                      </span>{' '}
                      {formData?.mentorShipSession?.preferredLanguage?.nameEn || 'N/A'}
                    </span>
                  </div>
                </div>
                <div className='d-block  mb-6'>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 w-100'>
                    <span className='d-block align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2 w-100'>
                      <span className='d-block  text-primary me-1 text-capitalize'>
                        {' '}
                        Why student/professional would like mentorship?{' '}
                      </span>{' '}
                      {formData?.mentorShipSession?.WhyYouWouldLikeMentorShip || 'N/A'}
                    </span>
                  </div>
                </div>
                <div className='d-block  mb-6'>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='d-block w-100 align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                      <span className='d-block text-primary me-1 text-capitalize'>
                        {' '}
                        What is the outcome student/professional is looking for?{' '}
                      </span>{' '}
                      {formData?.mentorShipSession?.whatIsOutcomeYouAreLookingFor || 'N/A'}
                    </span>
                  </div>
                </div>
                <div className='d-block  mb-6'>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='d-block w-100 align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                      <span className='d-block text-primary me-1 text-capitalize'>
                        Linkedin or social media site{' '}
                      </span>
                      <a
                        className='text-black'
                        href={
                          formData?.mentorShipSession?.enterYourLinkedinOrSocialMediaSite || 'N/A'
                        }
                        target='_blank'
                      >
                        {' '}
                        {formData?.mentorShipSession?.enterYourLinkedinOrSocialMediaSite || 'N/A'}
                      </a>
                    </span>
                  </div>
                </div>
                <div className='d-block  mb-6'>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='d-block w-100 align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                      <span className='d-block text-primary me-1 text-capitalize'>Domain </span>
                      <a
                        className='text-black'
                        href={formData?.mentorShipSession?.domain || 'N/A'}
                        target='_blank'
                      >
                        {' '}
                        {formData?.mentorShipSession?.domain || 'N/A'}
                      </a>
                    </span>
                  </div>
                </div>
                <div className='d-block  mb-6'>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='d-block w-100 align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                      <span className='d-block text-primary me-1 text-capitalize'>
                        Startup email{' '}
                      </span>
                      <a
                        className='text-black'
                        href={`mailto:${formData?.mentorShipSession?.startUpEmail}` || 'N/A'}
                        target='_blank'
                      >
                        {' '}
                        {formData?.mentorShipSession?.startUpEmail || 'N/A'}
                      </a>
                    </span>
                  </div>
                </div>
                <div className='d-block  mb-6'>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='d-block w-100 align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                      <span className='d-block text-primary me-1 text-capitalize'>
                        Additional Information{' '}
                      </span>{' '}
                      {formData?.mentorShipSession?.additionalInformation || 'N/A'}
                    </span>
                  </div>
                </div>
              </div>

              <div className='d-flex justify-content-between flex-wrap flex-sm-nowrap mb-3'>
                <div>
                  <strong className='text-primary mb-4 d-block'>Applicant Info </strong>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'>
                      Applicant Name :
                      <span className='fw-normal'>
                        {formData?.user1?.user?.subModel?.firstName || 'N/A'}{' '}
                        {formData?.user1?.user?.subModel?.middleName}{' '}
                        {formData?.user1?.user?.subModel?.lastName}
                      </span>
                    </span>
                  </div>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'>
                      Applicant Email :{' '}
                      <span className='fw-normal'>{formData?.user1?.user?.email || 'N/A'}</span>
                    </span>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'>
                      Applicant ID : <span className='fw-normal'>{formData?.user1?.user?._id}</span>
                    </span>
                  </div>
                </div>
                <div
                  style={{borderRight: '1px dashed #c1c1c1', width: '2px', height: '160px'}}
                ></div>
                <div>
                  <strong className='text-primary  mb-4 d-block'>
                    Mentor/Potential Mentor Info{' '}
                  </strong>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'>
                      Mentor Name :{' '}
                      <span className='fw-normal'>
                        {formData?.user2?.user?.subModel?.firstName || 'N/A'}{' '}
                        {formData?.user2?.user?.subModel?.middleName}{' '}
                        {formData?.user2?.user?.subModel?.lastName}
                      </span>
                    </span>
                  </div>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'>
                      Mentor Email :{' '}
                      <span className='fw-normal'>{formData?.user2?.user?.email || 'N/A'}</span>
                    </span>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'>
                      Mentor ID :{' '}
                      <span className='fw-normal'>{formData?.user2?.user?._id || 'N/A'}</span>
                    </span>
                  </div>
                </div>
              </div>

              <br />
              <br />
              <br />
              <br />

              <div className='d-flex justify-content-between flex-wrap flex-sm-nowrap my-6'>
                <div style={{width: '50%'}}>
                  <strong className='text-primary mb-4 d-block'>Mentee Rating </strong>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'>
                      Rating :{' '}
                      <span className='fw-normal'>
                        {formData?.mentorShipSession?.menteeRating?.rate || 'N/A'}
                        {formData?.mentorShipSession?.menteeRating?.rate && '/5'}
                      </span>
                    </span>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'>
                      What you liked :{' '}
                      <span className='fw-normal'>
                        {formData?.mentorShipSession?.menteeRating?.whatYouLiked || 'N/A'}
                      </span>
                    </span>
                  </div>

                  {/* <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'>
                      Improvement suggestion :{' '}
                      <span className='fw-normal'>
                        {formData?.mentorShipSession?.menteeRating?.improvementSuggestion || 'N/A'}
                      </span>
                    </span>
                  </div> */}
                  {formData?.mentorShipSession?.menteeRating?.rate && (
                    <button
                      onClick={() => clearMenteeRatingHandler()}
                      className='btn btn-bg-danger text-white btn-sm'
                    >
                      Delete
                    </button>
                  )}
                </div>

                {/* <div
                  style={{borderRight: '1px dashed #c1c1c1', width: '2px', height: '160px'}}
                ></div> */}
                <div style={{width: '50%'}}>
                  <strong className='text-primary  mb-4 d-block'>Mentor Rating </strong>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'>
                      Rating :{' '}
                      <span className='fw-normal'>
                        {formData?.mentorShipSession?.mentorRating?.rate || 'N/A'}
                        {formData?.mentorShipSession?.mentorRating?.rate && '/5'}
                      </span>
                    </span>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <div className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'>
                      What you liked :{' '}
                      <span className='fw-normal'>
                        {formData?.mentorShipSession?.mentorRating?.whatYouLiked || 'N/A'}
                      </span>
                    </div>
                  </div>

                  {/* <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'>
                      Improvement suggestion :{' '}
                      <span className='fw-normal'>
                        {formData?.mentorShipSession?.mentorRating?.improvementSuggestion || 'N/A'}
                      </span>
                    </span>
                  </div> */}
                  {formData?.mentorShipSession?.mentorRating?.rate && (
                    <button
                      onClick={() => clearMentorRatingHandler()}
                      className='btn btn-bg-danger text-white btn-sm'
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>

              <br />
              <br />
              <br />

              <div className='d-flex justify-content-end mb-4'>
                {formData?.mentorShipSession?.approved === 'pending' && (
                  <div>
                    <button
                      onClick={() => updateMeetingStatus(id, 'approved')}
                      // to={`/mentoring-program-options/update/${item._id}`}
                      className='btn  btn-bg-success text-white btn-sm me-1 me-5'
                    >
                      {loading ? (
                        <div className='spinner-border text-light' role='status'>
                          <span className='sr-only'>Loading...</span>
                        </div>
                      ) : (
                        'Approve'
                      )}{' '}
                    </button>

                    <button
                      onClick={() => updateMeetingStatus(id, 'declined')}
                      className='btn btn-bg-danger text-white btn-sm'
                    >
                      {loadingDeclined ? (
                        <div className='spinner-border text-light' role='status'>
                          <span className='sr-only'>Loading...</span>
                        </div>
                      ) : (
                        'Reject'
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
