import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Table} from './components/tablInst'
import {PageTitle} from '../../../components/pageTitle'
import {getAllUsers} from '../../../services/user'
import SearchInput from '../../../components/SearchInput'
import Pagination from '../../../components/Pagination'

type Props = {
  model: string
}

export const InstitutionStudents = (props: Props) => {
  const {model} = props
  const [userList, seUserList] = useState<Array<any>>([])
  const [loading, setLoading] = useState<any>(false)
  const [dataLength, setDataLength] = useState<any>(0)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)



  const handlePaginationChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleCountChange = (e: any) => {
    setPageSize(e.target.value)
    listAllUser(e.target.value)
  }

  useEffect(() => {
    listAllUser(pageSize)
  }, [model, currentPage])

  const listAllUser = async (size: any) => {
    const type = model === 'users' ? 'Student,Mentor' : model
    setLoading(true)
    const response = await getAllUsers(type, currentPage, size)
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / size))
    setDataLength(response.data.data.queryDataLength)
    seUserList(response.data.data.queryResult)
    setLoading(false)
  }
  const setDataResult = (response: any) => {
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / pageSize))
    setDataLength(response.data.data.queryDataLength)
    seUserList(response.data.data.queryResult)
    setLoading(false)
  }
  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active={'Institutions Students List'} />
        <Link to={`/${model}/create/`}>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
     
      <SearchInput model={model.toLowerCase()} setLoading={setLoading} setDataResult={setDataResult} ListAll={listAllUser} />

          <Table list={userList} model={model} length={dataLength} skelton={loading} />
          <div className='d-flex justify-content-between'>

              <>
                <div className='flex justify-end my-6'>
                  <Pagination
                    totalCount={totalPages}
                    onPageChange={handlePaginationChange}
                    currentPage={currentPage}
                  />
                </div>
                <select
                  className='form-select mr-4 mt-4'
                  style={{width: '75px', height: '50px'}}
                  onChange={handleCountChange}
                  value={pageSize}
                >
                  <option value='10'>10</option>
                  <option value='20'>20</option>
                  <option value='30'>30</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                </select>
              </>
       
          </div>
        </>
  )
}
