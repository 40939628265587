import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Table } from './components/table'
import { PageTitle } from '../../../components/pageTitle'
import { getAllCities } from '../../../services/city'
import SearchInput from '../../../components/SearchInput'
import Pagination from '../../../components/Pagination'
export const City = () => {
  const [cityList, setCityList] = useState<Array<any>>([])
  const [loading, setLoading] = useState<any>(false)
  const [dataLength, setDataLength] = useState<any>(0)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(5)
  const [searchActive, setSearchActive] = useState(false)
  const handlePaginationChange = (page: number) => {
    setCurrentPage(page)
  }
  const listAllCity = async () => {
    setLoading(true)
    const response = await getAllCities(currentPage, pageSize)
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / 5))
    setCityList(response.data.data.queryResult)
    setLoading(false)
  }
  const setDataResult = (response: any) => {
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / pageSize))
    setDataLength(response.data.data.queryDataLength)
    setCityList(response.data.data.queryResult)
    setLoading(false)
  }
  useEffect(() => {
    !searchActive && listAllCity()
  }, [currentPage, pageSize])

  const handleCountChange = (e: any) => {
    setPageSize(e.target.value)

  }
  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Cities' />
        <Link to='/city/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
      <SearchInput
        model={'city'}
        setLoading={setLoading}
        setDataResult={setDataResult}
        ListAll={listAllCity}
        currentPage={currentPage}
        pageSize={pageSize}
        setSearchActive={setSearchActive}
        setCurrentPage={setCurrentPage}
      
      />

      <Table list={cityList} listALl={listAllCity} length={dataLength} skelton={loading} />
      <div className='d-flex justify-content-between'>

          <>
            <div className='flex justify-end my-6'>
              <Pagination
                totalCount={totalPages}
                onPageChange={handlePaginationChange}
                currentPage={currentPage}
              />
            </div>
            <select
              className='form-select mr-4 mt-4'
              style={{ width: '75px', height: '50px' }}
              onChange={handleCountChange}
              value={pageSize}
            >
              <option value={pageSize}>{pageSize}</option>
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='30'>30</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
          </>
   
      </div>
    </>
  )
}
