import ErrorMessage from '../../../../components/ErrorMessage'

type Props = {
  onClickSubmit: any
  handleChange: any
  data: any
  ButtonText: string
  Show: any
  loading: boolean
  isSubmitted: boolean
}

export const DataForm = (props: Props) => {
  const {onClickSubmit, handleChange, data, ButtonText, Show, loading, isSubmitted} = props

  return (
    <form className='form-control' onSubmit={onClickSubmit}>
      <div className='input-data my-3'>
        <label htmlFor='order' className='fs-5 me-3'>
        <span className='text-danger'>*</span> Order 
        </label>
        <input
          type='number'
          name='order'
          className='form-control'
          value={data.order}
          onChange={handleChange}
        />
        {isSubmitted && !data?.order && <ErrorMessage errorMsg='order' />}
      </div>
      <div className='input-data my-3'>
        <label htmlFor='nameEn' className='fs-5 me-3'>
        <span className='text-danger'>*</span>    Name English 
        </label>
        <input
          type='text'
          name='nameEn'
          className='form-control'
          value={data.nameEn}
          onChange={handleChange}
        />
        {isSubmitted && !data?.nameEn && <ErrorMessage errorMsg='Name in English' />}
      </div>
      <div className='input-data my-3'>
        <label htmlFor='nameAr' className='fs-5 me-3'>
        <span className='text-danger'>*</span>  Name Arabic 
        </label>
        <input
          type='text'
          name='nameAr'
          className='form-control'
          value={data.nameAr}
          onChange={handleChange}
        />
        {isSubmitted && !data?.nameAr && <ErrorMessage errorMsg='Name in Arabic' />}
      </div>

      <div className='d-flex justify-content-end'>
        <button className='btn btn-primary' disabled={Show} type='submit'>
          {!loading && ButtonText}
          {loading && (
            <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          )}
        </button>
      </div>
    </form>
  )
}
