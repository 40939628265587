import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useThemeMode } from '../../../../../_metronic/partials'
import Pagination from '../../../../components/Pagination'
import TableBodyLoading from '../../../../components/tableBodyLoading'
import { updateApplicationStatus } from '../../../../services/jobApplication'
import { deleteReview, updateReview } from '../../../../services/reviews'
import { exportTableToExcel } from '../../../../utils/export'
import { Delete } from '../delete'
import { IReview } from '../module.interface'

type Props = {
  list: IReview[]
  listAll: any
  length: any
  skelton: boolean
  companyID?: any
}

export const AllTable = (props: Props) => {

  const PageSize = 9
  const { list, listAll, length, skelton, companyID } = props
  const [loading, setLoading] = useState<any>(false)

  const [showModal, setShowModal] = useState(false)
  const [_id, setId] = useState<string>('')
  const [currentPage, setCurrentPage] = useState(1)
  const [loadingApprove, setLoadingApprove] = useState<any>(false)
  const [loadingDeclined, setLoadingDeclined] = useState<any>(false)

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return list.slice(firstPageIndex, lastPageIndex)
  }, [list, currentPage])

  const viewModal = (_id: any) => {
    setId(_id)
    setShowModal(true)
  }

  const handleCancel = () => {
    setShowModal(false)
  }

  const handleDelete = () => {
    handleDeleteReview()
    setShowModal(false)
  }

  const handleDeleteReview = async () => {
    setLoading(true)
    const response = await deleteReview(_id)
    if (response) {
      handleCancel()
      setLoading(false)
      listAll()
    }
  }

  const handleExport = (id: string) => {
    exportTableToExcel(id, `${id}.xlsx`)
  }

  type Data = typeof list;
  type SortKeys = keyof Data[0];
  type SortOrder = "ascn" | "desc";
  function sortData({
    tableData,
    sortKey,
    reverse,
  }: {
    tableData: Data;
    sortKey: SortKeys;
    reverse: boolean;
  }) {
    if (!sortKey) return tableData;

    const sortedData = shownData.sort((a: any, b: any) => {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  }

  function SortButton({
    sortOrder,
    columnKey,
    sortKey,
    onClick,
  }: {
    sortOrder: SortOrder;
    columnKey: SortKeys;
    sortKey: SortKeys;
    onClick: MouseEventHandler<HTMLButtonElement>;
  }) {
    const { mode } = useThemeMode()
    return (
      <button
        onClick={onClick}
        className={`${mode === 'dark' ? 'text-white' : 'text-dark'}  ${sortKey === columnKey && sortOrder === "desc"
          ? "sort-button sort-reverse"
          : "sort-button"
          }`}
      >
        ▲
      </button>
    );
  }
  const [sortKey, setSortKey] = useState<any>("model");
  const [sortOrder, setSortOrder] = useState<SortOrder>("ascn");


  const sortedData = useCallback(
    () => sortData({ tableData: shownData, sortKey, reverse: sortOrder === "desc" }),
    [list, sortKey, sortOrder]
  );

  function changeSort(key: SortKeys) {
    setSortOrder(sortOrder === "ascn" ? "desc" : "ascn");
    setSortKey(key);
  }

  const [shownData, setShownData] = useState<any>([])

  const headers: { key: SortKeys; label: string }[] = [
    { key: "user", label: "User" },
    { key: "jobTitle", label: "Job Title" },
    { key: "company", label: "Employer" },

  ];
  useEffect(() => {
    const data = sortedData().map((item: any) => (
      {
        _id: item?._id,
        user: item?.user,
        userId: item?.userId,
        jobTitle: item?.jobTitle,
        approved: item?.approved,
        company: item?.company


      }
    ))
    setShownData(data)
  }, [sortedData])
  useEffect(() => {
    if (list) {
      const data = list.map((item: any) => (
        {
          _id: item?._id,
          userId: item?.user?._id,
          user: item?.user?.subModel?.firstName + ' ' + item?.user?.subModel?.lastName,
          jobTitle: item?.jobTitle,
          approved: item?.approved,
          company: item?.company?.subModel?.name
        }
      ))
      setShownData(data)
    }

  }, [list])
  const changeApplicationStatus = async (id: any, status: any) => {
    setId(id)
    status == 'declined' ? setLoadingDeclined(true) : setLoadingApprove(true)
    const response = await updateReview(id, {
      "approved": status
    })
    if (response) {
      status == 'declined' ? setLoadingDeclined(false) : setLoadingApprove(false)
      listAll()
    }

  }
  return (
    <>
      <div>
        <a
          className='text-danger d-flex justify-content-end mx-3 mb-4 align-items-center'
          style={{ fontSize: '14px', fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => {
            handleExport(`Reveiws-list`)
          }}
        >
          Export table <i className="bi bi-arrow-bar-up text-danger mx-2"></i>
        </a>
        <div className={`card `}>
          {/* begin::Body */}
          <div className='card-body py-3 '>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>

                  <tr className='fw-bold text-muted'>
                    {headers.map((row) => {
                      return (
                        <td className={`cursor-pointer ${sortKey === row.key ? 'text-primary' : ''}`}
                          onClick={() => changeSort(row.key)}
                          {...{
                            sortOrder,
                            sortKey,
                          }}>
                          {row.label}

                          <SortButton
                            columnKey={row.key}
                            onClick={() => changeSort(row.key)}
                            {...{
                              sortOrder,
                              sortKey,
                            }}
                          />

                        </td>
                      );
                    })}
                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>

                </thead>
                {/* end::Table head */}

                {skelton ?
                  <TableBodyLoading count={2} />
                  :
                  <tbody>
                    {shownData.map((item: any) => {
                      return (

                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark  text-hover-primary fs-6'>

                                  <Link className='text-black text-capitalize fw-bold'
                                    to={`/student/details/${item.userId}/${item?.user}`}> {item.user}</Link>

                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='text-dark  text-hover-primary d-block fs-6 '>
                              {item.jobTitle}
                            </div>
                          </td>
                          <td>
                            <div className='text-dark  text-hover-primary d-block fs-6 '>
                              {item.company}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              {item?.approved === 'pending' && (
                                <>
                                  <button
                                    onClick={() => changeApplicationStatus(item._id, 'approved')}
                                    // to={`/mentoring-program-options/update/${item._id}`}
                                    className='btn  btn-bg-success text-white btn-sm me-1 me-2'
                                  >
                                    {loadingApprove && item._id === _id ? (
                                      <div className='spinner-border text-light' role='status'>
                                        <span className='sr-only'>Loading...</span>
                                      </div>
                                    ) : (
                                      'Approve'
                                    )}{' '}
                                  </button>

                                  <button
                                    onClick={() => changeApplicationStatus(item._id, 'declined')}
                                    className='btn btn-bg-danger text-white btn-sm me-2'
                                  >
                                    {loadingDeclined && item._id === _id ? (
                                      <div className='spinner-border text-light' role='status'>
                                        <span className='sr-only'>Loading...</span>
                                      </div>
                                    ) : (
                                      'Reject'
                                    )}
                                  </button>
                                </>
                              )}
                              {item?.approved === 'approved' && (
                                <>
                                  <span className='d-flex align-items-center justify-content-center bg-green-100 text-success px-4 btn-sm '>

                                    Approved
                                  </span>
                                </>
                              )}
                              {item?.approved === 'declined' && (
                                <>
                                  <span className='d-flex align-items-center justify-content-center bg-green-100 text-danger px-4 btn-sm '>

                                    Declined
                                  </span>
                                </>
                              )}
                              <Link
                                to={`/reviews/update/${item._id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-5'
                              >
                                <i className='bi bi-pencil-square text-info fs-2'></i>
                              </Link>

                              <Link
                                to={`/review/details/${item._id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-5'
                              >
                                <i className='bi bi-eye-fill text-primary fs-2'></i>
                              </Link>
                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                onClick={() => viewModal(item._id)}
                              >
                                <i className='bi bi-trash text-danger fs-2'></i>
                              </button>
                            </div>
                          </td>
                        </tr>

                      )
                    })}
                  </tbody>
                }
              </table>
              {shownData.length == 0 && <div className='flex p-6 text-center'>
                No data available</div>}




              {showModal && (
                <Delete
                  show={showModal}
                  onCancel={handleCancel}
                  onDelete={handleDelete}
                  loading={loading}
                />
              )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      </div>
    </>
  )
}
