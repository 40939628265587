import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import {getAllUsers} from '../../../services/user'

type Props = {
  onCancel: () => void
  onDelete: () => void
  show: boolean
  loading: boolean
  model: string
}

export const Delete = (props: Props) => {
  const {onCancel, onDelete, show, loading, model} = props

  const {id} = useParams()

  useEffect(() => {
    if (id) {
      getAllUsers(model)
    }
  }, [id])

  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>Delete Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Are you sure you want to delete this item ?</h4>
        <p>This action can't be reverted</p>
      </Modal.Body>
      <Modal.Footer>
        <button className=' btn btn-dark' onClick={onCancel}>
          Cancel
        </button>
        <button className=' btn btn-danger' onClick={() => onDelete()}>
          {!loading && 'Delete'}
          {loading && (
            <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
