import {ChangeEvent, useEffect, useState} from 'react'

import {DataForm} from './components/data-form'
import {useNavigate, useParams} from 'react-router-dom'
import {PageTitle} from '../../../components/pageTitle'
import {
  getNotificationMethod,
  updateNotificationMethods,
} from '../../../services/notificationsMethod'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
export const UpdateNotificationMethod = () => {
  const [disabled, setdisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)

  const navigate = useNavigate()
  const {id} = useParams()

  const [formData, setFormData] = useState<any>()
  const [isSubmitted, setIsSubmitted] = useState<any>(false)
  const req = ['kind', 'name']
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((_data: any) => ({..._data, [e.target.name]: e.target.value}))
    setdisabled(false)
  }

  useEffect(() => {
    if (id) {
      getNotificationMethodFn()
    }
  }, [id])

  const getNotificationMethodFn = async () => {
    const response = await getNotificationMethod(id)
    if (response) {
      setFormData(response.data.data)
    }
  }

  const onClickSubmitBtn = (e: any) => {
    setIsSubmitted(true)
    e.preventDefault()
    req.every((key) => key in formData) && updateNotificationMethodFn()
  }

  const updateNotificationMethodFn = async () => {
    setLoading(true)
    const response = await updateNotificationMethods(id, formData)
    if (response.status == 200) {
      toast.success('Done successfully !', {
         position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
       theme: "colored",
        });
      navigate('/notificationMethod')
      setLoading(false)
    } else {
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setLoading(false)
    }
  }

  const breadCrampList = [
    {
      name: 'Notification Methods',
      route: '/notificationMethod',
    },
  ]

  return (
    <>
       <ToastContainer/>
      <PageTitle breadCrampList={breadCrampList} active='Update' />
      <div className='w-75 m-auto'>
        <DataForm
          onClickSubmit={onClickSubmitBtn}
          handleChange={handleChange}
          data={formData}
          ButtonText='Update'
          Show={disabled}
          loading={loading}
          isSubmitted={isSubmitted}
        />
      </div>
    </>
  )
}
