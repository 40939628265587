import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllApplications = (jobID:any) => {
  return axios
    .get(`${BASEURL}application/job/${jobID}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
export const getAllApplicationsForUser = (userId:any) => {
  return axios
    .get(`${BASEURL}application/user/${userId}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getJobApplication = (ID:any) => {

  return axios
    .get(`${BASEURL}application/${ID}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
export const updateApplicationStatus = (ID:any,status:any) => {

  return axios
    .patch(`${BASEURL}application/${ID}`,{
      "status":status
    })
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
