import {useEffect, useState} from 'react'

import {Link, useParams} from 'react-router-dom'
import Alert from '../../../components/alert'
import {PageTitle} from '../../../components/pageTitle'
import {ResetPasswordForUser} from '../../../services/admin'
import {CompanyFollowers, getCompany} from '../../../services/company'
import {Review} from '../review'
import {AnalyticsCompany} from './components/analytics'
import {Delete} from '../user/delete'
import {deleteUser, updateUser} from '../../../services/user'
import {numberWithCommas, objectToFormData} from '../../../utils/helpers'
import {JobsTab} from './components/jobs-tabe'
import {UploadeMedia} from './components/uploade-media'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const ViewCompany = () => {
  const [selectimage, setSelectImage] = useState<any>([])
  const [selectFiles, setSelectFiles] = useState<any>([])
  const [hover, setHover] = useState<any>(null)
  const [selected, setSelected] = useState<any>(null)
  const [toBeDeletedImages, setToBeDeletedImages] = useState<any>([])
  const [userData, setUserData] = useState<any>({})

  const [formData, setFormData] = useState<any | undefined>()
  const [loading, setLoading] = useState<any>(false)
  const [loadingSave, setLoadingSave] = useState<any>(false)

  const [followers, setFollowers] = useState<any>()
  const [active, setActive] = useState<any>(0)
  const {id} = useParams()
  const background: any = formData?.subModel?.background
    ? new DOMParser().parseFromString(formData?.subModel?.background, 'text/xml').firstChild
    : 'N/A'
  useEffect(() => {
    if (id) {
      handleGetCompany()
      getCompanyFollowers()
    }
  }, [id])
  useEffect(() => {
    if (formData) {
      let backgroundID = document.getElementById('background') as HTMLElement
      backgroundID.innerHTML = formData?.subModel?.background
        ? formData?.subModel?.background
        : 'N/A'
    }
  }, [formData])
  const handleGetCompany = async () => {
    const response = await getCompany(id)
    if (response) {
      setFormData(response.data.data)
    }
  }
  const getCompanyFollowers = async () => {
    const response = await CompanyFollowers(id)
    if (response) setFollowers(response.data.data)
  }
  const [showModal, setShowModal] = useState(false)
  const [_id, setId] = useState<string>('')
  const [reset, setReset] = useState<any>(null)

  const removeMedia = (index: number) => {
    const images = [...selectimage]
    const files = [...selectFiles]
    images.splice(index, 1)
    files.splice(index, 1)
    setSelectFiles(files)
    setSelectImage(images)
  }
  const removeMediaIndexs = (index: number) => {
    const deletedImages = [...toBeDeletedImages]
    deletedImages.push(index)
    setToBeDeletedImages(deletedImages)
    setUserData((_data: any) => ({..._data, toBeDeletedImages: deletedImages}))
  }

  const viewModal = () => {
    setId(_id)
    setShowModal(true)
  }
  const delteFn = () => {
    deleteGetOfferFn()
    setShowModal(false)
  }

  const deleteGetOfferFn = async () => {
    setLoading(true)
    const response = await deleteUser(id)
    if (response) {
      cancelFn()
      setLoading(false)
      // listALl()
    }
  }

  const cancelFn = () => {
    setShowModal(false)
  }
  const handleClick = async () => {
    setLoading(true)
    const response = await ResetPasswordForUser(formData?.email)
    if (response.status === 200) {
      setLoading(false)
      setReset(true)
      setTimeout(() => {
        setReset(null)
      }, 3000)
    } else {
      setLoading(false)
      setReset(false)
    }
  }
  const approveUser = async () => {
    const res = await updateUser(id, objectToFormData({approved: true}))
    if (res.status === 200) {
      handleGetCompany()
    }
  }
  const handleUpdateUser = async () => {
    const theFormData = new FormData()
    if (selectFiles.length > 0) {
      for (const image of selectFiles) {
        theFormData.append(`companyMedia`, image)
      }
    }
    if (userData?.toBeDeletedImages && userData.toBeDeletedImages.length > 0) {
      theFormData.append('toBeDeletedImages', JSON.stringify(userData?.toBeDeletedImages))
    }

    setLoadingSave(true)

    const response = await updateUser(id, theFormData)
    if (response.status == 200) {
      setSelectImage([])
      setSelectFiles([])
      setToBeDeletedImages([])
      setUserData((_data: any) => ({..._data, toBeDeletedImages: []}))
      handleGetCompany()
      toast.success('Updated successfully !', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })

      setLoadingSave(false)
    } else {
      toast.error(response.data.error, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })

      setLoadingSave(false)
    }
  }
  const isImage = (url: any) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)
  }

  return (
    <>
      <ToastContainer />
      <div className='d-flex justify-content-between flex-col my-3'>
        <PageTitle active={`${formData?.subModel?.name} Information`} />
      </div>
      {showModal && (
        <Delete
          show={showModal}
          onCancel={cancelFn}
          onDelete={delteFn}
          loading={loading}
          model={'company'}
        />
      )}
      {reset && (
        <Alert
          alertTitle={'Success'}
          alertMsg={`Edqan sent a password reset link to "${formData.email}".`}
          type='success'
        />
      )}
      {reset == false && (
        <Alert
          alertTitle={'Error'}
          alertMsg={`Something Went wrong, try again later`}
          type='error'
        />
      )}

      <div className='row'>
        <div className='col-12'>
          <div>
            <div className='d-flex overflow-auto h-55px mb-5'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item'>
                  <span
                    onClick={() => {
                      setActive(0)
                    }}
                    className={`nav-link text-active-primary me-6 ` + (active === 0 && 'active')}
                  >
                    Details
                  </span>
                </li>
                <li
                  className='nav-item'
                  onClick={() => {
                    setActive(4)
                  }}
                >
                  <span
                    className={`nav-link text-active-primary me-6 ` + (active === 4 && 'active')}
                  >
                    Reviews
                  </span>
                </li>

                <li
                  className='nav-item'
                  onClick={() => {
                    setActive(6)
                  }}
                >
                  <span
                    className={`nav-link text-active-primary me-6 ` + (active === 6 && 'active')}
                  >
                    Job Posting
                  </span>
                </li>
                <li
                  className='nav-item'
                  onClick={() => {
                    setActive(5)
                  }}
                >
                  <span
                    className={`nav-link text-active-primary me-6 ` + (active === 5 && 'active')}
                  >
                    Statistics
                  </span>
                </li>
              </ul>
            </div>
            {active === 0 && (
              <div className='card mb-5 mb-xl-10' id='kt_profile_details_view relative'>
                <div className='mb-4  border'>
                  <div className='w-100 symbol-fixed position-relative'>
                    <img
                      src={formData?.subModel.coverPhoto}
                      alt='cover Photo'
                      className='w-100'
                      style={{height: '250px'}}
                    />
                  </div>
                </div>

                <div className='card-body p-9 position-relative'>
                  <div className='me-7 mb-4'>
                    <div
                      className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border'
                      style={{borderRadius: '50%'}}
                    >
                      {formData?.subModel?.logo ? (
                        <img
                          src={formData?.subModel?.logo}
                          alt='profile Image'
                          style={{borderRadius: '50%'}}
                        />
                      ) : (
                        <div
                          className='bg-info text-white d-flex align-items-center justify-content-center '
                          style={{
                            fontSize: '50px',
                            padding: '10px',
                            borderRadius: '50%',
                            height: '150px',
                            width: '150px',
                          }}
                        >
                          {formData?.subModel?.name.charAt(0).toUpperCase()}
                        </div>
                      )}
                    </div>
                    <span
                      className={`d-flex align-items-center position-absolute me-5 text-white p-2 rounded  ${
                        formData?.approved ? 'bg-success' : 'bg-danger'
                      }`}
                      style={{top: '20px', right: '5px', zIndex: '9999'}}
                    >
                      <strong className='me-2 d-block'> Status :</strong>{' '}
                      {formData?.approved ? 'Approved' : 'Pending'}
                    </span>
                  </div>
                  <div className='d-flex flex-column'>
                    <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                      <strong className='text-primary me-1'>Company Name : </strong>{' '}
                      {formData?.subModel?.name}
                    </strong>

                    <div className='d-flex '>
                      <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'> Company ID : </strong>{' '}
                        {formData?._id}
                      </strong>
                      <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'>Website URL : </strong>{' '}
                        <a href={formData?.subModel?.webSite} target='_blank'>
                          {formData?.subModel?.webSite || 'N/A'}
                        </a>
                      </strong>
                    </div>

                    <div className='d-flex'>
                      <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'> Email : </strong> {formData?.email}
                      </strong>
                      {/* <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'>Phone : </strong>   {formData?.subModel?.careerAdvisingPhone}
                      </strong> */}
                      <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'>Country : </strong>{' '}
                        {formData?.country?.nameEn || 'N/A'}
                      </strong>
                      <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'>City : </strong>{' '}
                        {formData?.city?.nameEn || 'N/A'}
                      </strong>
                    </div>
                    <div className='d-flex'>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-2 fw-bolder'>
                            {formData?.subModel?.companySize
                              ? numberWithCommas(formData?.subModel?.companySize)
                              : 'N/A'}
                          </div>
                        </div>

                        <div className='fw-bold fs-6 text-gray-400'>Number of Employees</div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-2 fw-bolder'>{followers ? followers : 0}</div>
                        </div>

                        <div className='fw-bold fs-6 text-gray-400'>Followers</div>
                      </div>
                    </div>
                    <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                      <strong className='text-primary me-1'>HQ Location : </strong>{' '}
                      {formData?.subModel?.headQuarters?.country?.nameEn || 'N/A'},{' '}
                      {formData?.subModel?.headQuarters?.city?.nameEn || 'N/A'}
                    </strong>
                    <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                      <strong className='text-primary me-1'>Branch Locations : </strong>
                      {formData?.subModel?.addresses?.length > 0
                        ? formData?.subModel?.addresses?.map((item: any) => {
                            return (
                              <span className='fw-bolder fs-6  bg-primary text-white p-2 rounded  me-2 '>
                                {item}
                              </span>
                            )
                          })
                        : 'N/A'}
                    </strong>
                    <div>
                      <div className='d-block align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2 w-auto'>
                        <strong className='text-primary me-1 d-block'>
                          Background of Company :{' '}
                        </strong>
                        {/* {background.innerHTML} */}
                        <p id='background'></p>
                      </div>
                    </div>
                    <div className='d-block page-header'>
                      <strong className='my-4 d-block text-primary'> Contact Information </strong>
                      <div className='d-flex'>
                        {/* <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                            <strong className='text-primary me-1'> Email : </strong> {formData?.subModel?.phoneNumber}
                          </strong> */}
                        <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                          <strong className='text-primary me-1'>Phone : </strong>{' '}
                          {formData?.subModel?.phoneNumber}
                        </strong>
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <label className='col-lg-4 fw-bold '>Industries</label>

                      <div className='col-lg-8'>
                        {formData?.subModel?.industries?.length > 0
                          ? formData?.subModel?.industries?.map((item: any) => {
                              return (
                                <div className='d-block bg-gray-300 p-2 rounded p-3 mb-2'>
                                  <span className='fw-bolder fs-6 text-dark'>
                                    {item?.industry?.nameEn}
                                  </span>
                                  <div>
                                    <span className='me-2'> Sub Industries:</span>
                                    {item.subIndustries?.map((item: any) => {
                                      return (
                                        <span className='fw-bolder fs-6 text-white bg-primary p-2 me-2 rounded'>
                                          {item?.nameEn}
                                        </span>
                                      )
                                    })}
                                  </div>
                                </div>
                              )
                            })
                          : 'N/A'}
                      </div>
                    </div>
                    <div className='row my-7'>
                      <label className='col-lg-4 fw-bold '>Address</label>

                      <div className='col-lg-8'>
                        {formData?.subModel?.addresses?.length > 0
                          ? formData?.subModel?.addresses?.map((item: any) => {
                              return (
                                <span className='fw-bolder fs-6 text-dark bg-gray-300 p-2 rounded  me-2 mb-2 w-full d-block'>
                                  {item}
                                </span>
                              )
                            })
                          : 'N/A'}
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold '>Privacy Settings</label>

                      <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>
                          {formData?.subModel?.accountVisibility || 'N/A'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='page-header'></div>
                  <div>
                    <div className='d-flex justify-content-between '>
                      <h3 className='fw-bold text-gray-900 mb-4 mt-4'>Company Media</h3>
                      <UploadeMedia
                        setSelectImage={setSelectImage}
                        selectimage={selectimage}
                        setSelectFiles={setSelectFiles}
                        selectFiles={selectFiles}
                      />
                    </div>
                    <div className='d-flex flex-wrap'>
                      {formData?.subModel?.companyMedia?.length > 0
                        ? formData?.subModel?.companyMedia.map((item: any, index: number) => {
                            return (
                              <>
                                {!toBeDeletedImages.includes(index) && (
                                  <div
                                    className='w-full '
                                    key={index}
                                    style={{
                                      width: '200px',
                                      height: '200px',
                                      border: '1px solid #e9e9e9',
                                      marginRight: '20px',
                                      marginBottom: '10px',
                                      borderRadius: '8px',
                                    }}
                                  >
                                    <div
                                      className='position-relative '
                                      onMouseEnter={() => {
                                        setHover(index)
                                        setSelected(1)
                                      }}
                                      onMouseLeave={() => {
                                        setHover(null)
                                        setSelected(null)
                                      }}
                                    >
                                      {isImage(item) ? (
                                        <img
                                          src={item}
                                          style={{
                                            width: '200px',
                                            height: '200px',
                                            border: '1px solid #e9e9e9',
                                            marginRight: '20px',
                                            marginBottom: '10px',
                                            borderRadius: '8px',
                                          }}
                                        />
                                      ) : (
                                        <video
                                          controls
                                          src={item}
                                          style={{
                                            width: '200px',
                                            height: '200px',
                                            border: '1px solid #e9e9e9',
                                            marginRight: '20px',
                                            marginBottom: '10px',
                                            borderRadius: '8px',
                                          }}
                                        />
                                      )}
                                      <div
                                        className={`top-0 end-0 position-absolute bg-[#0000006e] ${
                                          hover === index && selected === 1 ? 'flex' : 'hidden'
                                        }  z-50`}
                                      >
                                        <a
                                          className='h-[fit-content] ml-auto bg-[#303030] text-white cursor-pointer'
                                          onClick={() => {
                                            removeMediaIndexs(index)
                                          }}
                                        >
                                          <i className='bi bi-x-square-fill'></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            )
                          })
                        : ''}
                      {selectimage?.length > 0 &&
                        selectimage.map((item: any, index: number) => {
                          return (
                            <div
                              className='w-full '
                              key={index}
                              style={{
                                width: '200px',
                                height: '200px',
                                border: '1px solid #e9e9e9',
                                marginRight: '20px',
                                marginBottom: '10px',
                                borderRadius: '8px',
                              }}
                            >
                              <div
                                className='position-relative '
                                onMouseEnter={() => {
                                  setHover(index)
                                  setSelected(1)
                                }}
                                onMouseLeave={() => {
                                  setHover(null)
                                  setSelected(null)
                                }}
                              >
                                {item.includes('video') ? (
                                  <video
                                    controls
                                    src={item}
                                    style={{
                                      width: '200px',
                                      height: '200px',
                                      border: '1px solid #e9e9e9',
                                      marginRight: '20px',
                                      marginBottom: '10px',
                                      borderRadius: '8px',
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={item}
                                    style={{
                                      width: '200px',
                                      height: '200px',
                                      border: '1px solid #e9e9e9',
                                      marginRight: '20px',
                                      marginBottom: '10px',
                                      borderRadius: '8px',
                                    }}
                                  />
                                )}
                                <div
                                  className={`top-0 end-0 position-absolute bg-[#0000006e] ${
                                    hover === index && selected === 1 ? 'flex' : 'hidden'
                                  }  z-50`}
                                >
                                  <a
                                    className='h-[fit-content] ml-auto bg-[#303030] text-white cursor-pointer'
                                    onClick={() => {
                                      removeMedia(index)
                                    }}
                                  >
                                    <i className='bi bi-x-square-fill'></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          )
                        })}

                      {formData?.subModel?.companyMedia?.length === 0 &&
                        selectimage?.length === 0 &&
                        'N/A'}
                    </div>
                  </div>
                  {(selectimage.length > 0 || userData?.toBeDeletedImages?.length > 0) && (
                    <div className='d-flex justify-content-end'>
                      <button
                        className='btn bg-primary btn-small text-white'
                        onClick={() => handleUpdateUser()}
                      >
                        {loadingSave ? (
                          <div className='spinner-border text-light' role='status'>
                            <span className='sr-only'>Loading...</span>
                          </div>
                        ) : (
                          'Save Changes'
                        )}
                      </button>
                    </div>
                  )}
                  {loadingSave ? (
                    <p className='d-flex justify-content-end mt-2 text-danger'>
                      Please wait this action may take some time depends on your media size
                    </p>
                  ) : (
                    ''
                  )}
                  <div className='page-header mb-4'></div>
                  <div>
                    <h3 className='fw-bold text-gray-900 mb-4 mt-4'>Social Media</h3>
                    {formData?.socialMediaLinks?.length > 0
                      ? formData?.socialMediaLinks?.map((item: any) => {
                          return (
                            <div className='mb-4'>
                              <label className='col-lg-4 fw-bold text-capitalize fs-4 mb-2 text-info'>
                                {item?.socialMedia.nameEn}
                              </label>
                              {item?.url.map((el: any) => {
                                return (
                                  <a href={el} target='_blank' className='d-block'>
                                    {el}
                                  </a>
                                )
                              })}
                            </div>
                          )
                        })
                      : 'N/A'}
                  </div>
                  <div className='page-header mb-4'></div>
                  <div>
                    <h3 className='fw-bold text-gray-900 mb-4 mt-4'>Details on Company</h3>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Agreed to terms</label>

                      <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>
                          {formData?.subModel?.agreeToTerms ? (
                            <span className='text-success'>Yes</span>
                          ) : (
                            <span className='text-danger'>No</span>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Open to Messages</label>

                      <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>
                          {formData?.subModel?.receiveMessage ? (
                            <span className='text-success'>Yes</span>
                          ) : (
                            <span className='text-danger'>No</span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end w-100'>
                    <div className='d-flex justify-content-start flex-shrink-0'>
                      <Link
                        to={`/company/update/${id}`}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-5'
                      >
                        <i className='bi bi-pencil-square text-info fs-2'></i>
                      </Link>

                      <button
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-4'
                        onClick={() => viewModal()}
                      >
                        <i className='bi bi-trash text-danger fs-2'></i>
                      </button>
                      {!formData?.approved ? (
                        <>
                          <button
                            className='btn  btn-bg-light btn-active-color-primary btn-sm me-2'
                            onClick={() => approveUser()}
                          >
                            Approve
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                      <button
                        className='btn btn-danger btn-sm btn-sm me-1  ml-2 d-flex justify-content-center me-2'
                        type='button'
                        onClick={() => handleClick()}
                      >
                        {loading ? (
                          <div className='spinner-border text-light' role='status'>
                            <span className='sr-only'>Loading...</span>
                          </div>
                        ) : (
                          'Send password reset to user'
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {active === 4 && (
              <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <Review companyID={id} />
              </div>
            )}
            {active === 5 && (
              <div className='card mb-5 p-9 mb-xl-10' id='kt_profile_details_view'>
                <AnalyticsCompany id={id} />
              </div>
            )}
            {active === 6 && (
              <div className='card mb-5 p-9 mb-xl-10' id='kt_profile_details_view'>
                <JobsTab />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
