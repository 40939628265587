import { ChangeEvent, useState } from 'react'
import { ToastContainer } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { PageTitle } from '../../../components/pageTitle'
import { createIndustry } from '../../../services/industry'
import { DataForm } from './components/data-form'
import { IIndustry } from './module.interface'

export default function CreateIndustry() {
  const [formData, setFormData] = useState<IIndustry>({
    order: 0,
    nameEn: '',
    nameAr: '',
  })
  const [disabled, setDisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)
  const [isSubmitted, setIsSubmitted] = useState<any>(false)
  const req = ['order', 'nameEn', 'nameAr']
  const navigate = useNavigate()
  type ObjectKey = keyof typeof formData

  const breadCrampList = [
    {
      name: 'Industries',
      route: '/universal-fields',
    },
  ]

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((_data) => ({ ..._data, [e.target.name]: e.target.value }))
    setDisabled(false)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setIsSubmitted(true)
    req.every((key) => formData.hasOwnProperty(key) && formData[key as ObjectKey] !== '') &&
      newIndustry()
  }

  const newIndustry = async () => {
    setLoading(true)
    const response = await createIndustry(formData.order, formData.nameEn, formData.nameAr)
    if (response.status === 201) {
      toast.success('Done successfully !', {
         position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
       theme: "colored",
      });
      navigate('/universal-fields')
      setLoading(false)
    } else {
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false)
    }
  }

  return (
    <>
      <ToastContainer />
      <PageTitle breadCrampList={breadCrampList} active='Create' />
      <div className=' w-75 m-auto'>
        <DataForm
          onClickSubmit={handleSubmit}
          handleChange={handleChange}
          data={DataForm}
          ButtonText='Create'
          Show={disabled}
          loading={loading}
          isSubmitted={isSubmitted}
        />
      </div>
    </>
  )
}
