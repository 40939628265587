import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllJobRole = (page:number=0,pageSize:number=0) => {
    const pagination =  page || pageSize ? `?page=${page}&pageSize=${pageSize}`: ''
  return axios
    .get(`${BASEURL}jobRole${pagination}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getJobRole = (_id: any) => {
  return axios
    .get(`${BASEURL}jobRole/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateJobRole = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}jobRole/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createJobRole = (order: number, nameEn: string, nameAr: string) => {
  return axios
    .post(`${BASEURL}jobRole`, {order: order, nameEn: nameEn, nameAr: nameAr})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteJobRole = (_id: string) => {
  return axios
    .delete(`${BASEURL}jobRole/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
