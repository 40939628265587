import {useEffect, useState} from 'react'

import {useNavigate, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../components/pageTitle'

import {getJob} from '../../../services/job'
import JobHeader from './components/jobHeader'

export const ViewJob = () => {
  const [formData, setFormData] = useState<any>()

  const {id} = useParams()

  useEffect(() => {
    if (id) {
      handleGetJob()
    }
  }, [id])

  const handleGetJob = async () => {
    const response = await getJob(id)
    if (response) {
      setFormData(response.data.data)
    }
  }

  return (
    <>
      {formData && (
        <>
          <div className='d-flex justify-content-between flex-col my-3'>
            <PageTitle active={`Job Post Details `} />
          </div>
          <JobHeader jobData={formData} />
        </>
      )}
    </>
  )
}
