export function objectToFormData(obj: any) {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]: any) => {
    formData.append(key, value);
  });

  return formData;
}


export function sortByKey(objects: any[], keyToSort: string): any[] {
  const key = keyToSort === 'fullName' ? 'firstName' : keyToSort
  return objects.slice().sort((a, b) => {
    const valueA = getValue(a, key);
    const valueB = getValue(b, key)
    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  });
}
export function numberWithCommas(x:number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getValue: any = (obj: any, path: any) => {
  if (!path) return obj;
  const properties = path.split('.');
  return getValue(obj[properties.shift()], properties.join('.'))
}







