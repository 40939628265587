import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllWorkType = (page:number=0,pageSize:number=0) => {
  const pagination =  page || pageSize ? `?page=${page}&pageSize=${pageSize}`: ''
  return axios
    .get(`${BASEURL}workType${pagination}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getWorkType = (_id: any) => {
  return axios
    .get(`${BASEURL}workType/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateWorkType = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}workType/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createWorkType = (order: number, nameEn: string, nameAr: string) => {
  return axios
    .post(`${BASEURL}workType`, {order: order, nameEn: nameEn, nameAr: nameAr})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteworkType = (_id: string) => {
  return axios
    .delete(`${BASEURL}workType/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
