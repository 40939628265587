import { useEffect, useState } from 'react'
import Alert from '../../../components/alert'

import { Link, useNavigate, useParams } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../components/pageTitle'

import { CompanyFollowers, getCompany, getUserNewData } from '../../../services/company'
import { getMentor } from '../../../services/mentor'
import { approveUpdateUser, deleteUser, getUser, updateUser } from '../../../services/user'
import { Analytics } from './components/analytics'
import { InstitutionStudentsList } from '../user/institutions-students-list'
import { objectToFormData } from '../../../utils/helpers'
import { Delete } from '../user/delete'
import { ResetPasswordForUser } from '../../../services/admin'
import { toast } from 'react-toastify'

export const ViewInstitutionApproval = () => {
  const [formData, setFormData] = useState<any | undefined>()
  const [newData, setNewData] = useState<any | undefined>()
  const [loading, setLoading] = useState<any>(false)
  const [active, setActive] = useState<any>(0)
  const { id } = useParams()
  const [showModal, setShowModal] = useState(false)
  const [reset, setReset] = useState<any>(null)
  const [loadingApprove, setLoadingApprove] = useState(false)
  const [loadingDecline, setLoadingDecline] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    if (id) {
      handleGetInstitution()
      handleUserNewData()
    }
  }, [id])

  const handleGetInstitution = async () => {
    const response = await getUser(id)
    if (response) {
      setFormData(response.data.data)
    }
  }
  const handleUserNewData = async () => {
    const response = await getUserNewData(id)
    if (response) {
      setNewData(response.data.data)
    }
  }
  const approveUser = async (approved: any) => {
    approved ? setLoadingApprove(true) : setLoadingDecline(true)
    const res = await approveUpdateUser(id, approved)
    if (res.status === 200) {
      toast.success(`${approved ? 'Approved' : 'Declined'} successfully !`, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      navigate('/all-users-changes')

    }
    else {
      toast.error(res.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    approved ? setLoadingApprove(false) : setLoadingDecline(false)
  }
  const delteFn = () => {
    deleteGetOfferFn()
    setShowModal(false)
  }
  const viewModal = () => {
    setShowModal(true)
  }

  const cancelFn = () => {
    setShowModal(false)
  }
  const deleteGetOfferFn = async () => {
    setLoading(true)
    const response = await deleteUser(id)
    if (response) {
      cancelFn()
      setLoading(false)
      // listALl()
    }
  }

  const handleClick = async () => {
    setLoading(true)
    const response = await ResetPasswordForUser(formData?.email)
    if (response.status === 200) {
      setLoading(false)
      setReset(true)
      setTimeout(() => {
        setReset(null)
      }, 3000)
    } else {
      setLoading(false)
      setReset(false)
    }
  }


  return (
    <>

      {formData && (
        <>
          <div className='d-flex justify-content-between flex-col my-3'>
            <PageTitle active={`Institution Information Changes`} />
          </div>
          {showModal && (
            <Delete
              show={showModal}
              onCancel={cancelFn}
              onDelete={delteFn}
              loading={loading}
              model={'student'}
            />
          )}
          {reset && <Alert alertTitle={'Success'} alertMsg={`Edqan sent a password reset link to "${formData.email}".`} type='success' />}
          {reset == false && <Alert alertTitle={'Error'} alertMsg={`Something Went wrong, try again later`} type='error' />}
          <div className='row'>

            <div className='col-sm-12 col-md-6 '>
            <h4 className='mt-6 mb-2 '>Old Data</h4>
              <div className='card mb-5 mb-xl-10 relative' id='kt_profile_details_view'>


                <div className=' mb-4  border' >
                  <div className='w-100 symbol-fixed position-relative'>
                    <img src={formData?.subModel.banner} alt='banner' className='w-100' style={{ height: '250px' }} />
                  </div>
                </div>
                <div className='card-body p-9'>

                  <div className=' mb-4 position-relative'>
                    <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border' style={{ borderRadius: '50%' }}>
                      {formData?.subModel?.logo ?
                        <img src={formData?.subModel?.logo} alt='profile Image' style={{ borderRadius: '50%' , objectFit: 'contain'}} />:
                        <div className='bg-info text-white d-flex align-items-center justify-content-center ' style={{ fontSize: '50px', padding: '10px', borderRadius: '50%', height: '150px', width: '150px' }}>
                          {formData?.subModel?.name.charAt(0).toUpperCase()}
                        </div>}
                    </div>
                  
                  </div>
                  <div className='d-flex flex-column'>
                    <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                      <strong className='text-primary me-1'>School Name : </strong> {formData?.subModel?.name}
                    </strong>
                    <div className='d-flex '>
                      <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'> School ID : </strong> {formData?._id}
                      </strong>
                     


                    </div> 
                    <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'>Website URL : </strong>  {formData?.subModel?.schoolUrl}
                      </strong>
              
                      <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'> Email : </strong> {formData?.email}
                      </strong>
                      <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'>Phone : </strong>   {formData?.subModel?.careerAdvisingPhone}
                      </strong>
                      <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'>Country : </strong>   {formData?.country?.nameEn || 'N/A'}
                      </strong>
                      <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'>City : </strong>   {formData?.city?.nameEn || 'N/A'}
                      </strong>
                    
                    <div className='d-block page-header'>
                      <strong className='my-4 d-block text-primary'>  Contact Information ( Career Advising )</strong>
                  
                        <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                          <strong className='text-primary me-1'> Email : </strong> {formData?.subModel?.careerAdvisingEmail}
                        </strong>
                        <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                          <strong className='text-primary me-1'>Phone : </strong>   {formData?.subModel?.careerAdvisingPhone}
                        </strong>
                        <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                          <strong className='text-primary me-1'>Location : </strong>   {formData?.subModel?.careerAdvisingLocation?.country?.nameEn || 'N/A'},  {formData?.subModel?.careerAdvisingLocation?.city?.nameEn || 'N/A'}
                        </strong>


                      <strong className='d-block  text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1 d-block'> Background : </strong> {formData?.subModel?.background}
                      </strong>
                    </div>

                    {/* <span className='d-flex align-items-center text-black border p-2 mt-4 rounded  me-2 mb-2'>
                   <strong className='text-primary me-1'> Agree to Terms : </strong>

                   <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>{formData?.subModel?.agreeToTerms ? 'Yes' : 'No'}
                   </span>
                 </span> */}
                    <div className='row my-7'>
                      <label className='col-lg-4 fw-bold '>Address</label>

                      <div className='col-lg-8'>
                        {formData?.subModel?.addresses?.length > 0 ?
                          formData?.subModel?.addresses?.map((item: any) => {
                            return <span className='d-block fw-bolder fs-6 text-dark'>{item}</span>
                          }) : 'N/A'}
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold '>Privacy</label>

                      <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>{formData?.subModel?.accountVisibility || 'N/A'}</span>
                      </div>
                    </div>

                  </div>
                  <div className='page-header mb-4'></div>
                  <div>
                    <h3 className='fw-bold text-gray-900 mb-4 mt-4' >Social Media</h3>
                    {formData?.socialMediaLinks?.length > 0 ? formData?.socialMediaLinks?.map((item: any) => {
                      return (
                        <div className='mb-4'>
                          <label className='col-lg-4 fw-bold text-capitalize fs-4 mb-2 text-info'>
                            {item?.socialMedia.nameEn}
                          </label>
                          {item?.url.map((el: any) => {
                            return <a href={el} target='_blank' className='d-block'>{el}</a>
                          })}
                        </div>
                      )
                    }) : 'N/A'}
                  </div>
                  <div className='page-header mb-4'></div>
                
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6'>
            <h4 className='mt-6 mb-2 '>New Data</h4>
              <div className='card mb-5 mb-xl-10 relative' id='kt_profile_details_view'>


                <div className=' mb-4  border' >
                  <div className='w-100 symbol-fixed position-relative'>
                    <img src={newData?.subModel.banner} alt='banner' className='w-100' style={{ height: '250px' }} />
                  </div>
                </div>
                <div className='card-body p-9'>

                  <div className=' mb-4 position-relative'>
                    <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border' style={{ borderRadius: '50%' }}>
                      {newData?.subModel?.logo ?
                        <img src={newData?.subModel?.logo} alt='profile Image' style={{ borderRadius: '50%' , objectFit: 'contain'}} /> :
                        <div className='bg-info text-white d-flex align-items-center justify-content-center ' style={{ fontSize: '50px', padding: '10px', borderRadius: '50%', height: '150px', width: '150px' }}>
                          {newData?.subModel?.name.charAt(0).toUpperCase()}
                        </div>}
                    </div>
                   
                  </div>
                  <div className='d-flex flex-column'>
                    <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                      <strong className='text-primary me-1'>School Name : </strong> {newData?.subModel?.name}
                    </strong>
                    <div className='d-flex '>
                      <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'> School ID : </strong> {newData?._id}
                      </strong>
                     


                    </div> 
                    <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'>Website URL : </strong>  {newData?.subModel?.schoolUrl}
                      </strong>
                      <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'> Email : </strong> {newData?.email}
                      </strong>
                      <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'>Phone : </strong>   {newData?.subModel?.careerAdvisingPhone}
                      </strong>
                      <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'>Country : </strong>   {newData?.country?.nameEn || 'N/A'}
                      </strong>
                      <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'>City : </strong>   {newData?.city?.nameEn || 'N/A'}
                      </strong>
                    <div className='d-block page-header'>
                      <strong className='my-4 d-block text-primary'>  Contact Information ( Career Advising )</strong>
                
                        <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                          <strong className='text-primary me-1'> Email : </strong> {newData?.subModel?.careerAdvisingEmail}
                        </strong>
                        <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                          <strong className='text-primary me-1'>Phone : </strong>   {newData?.subModel?.careerAdvisingPhone}
                        </strong>
                        <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                          <strong className='text-primary me-1'>Location : </strong>   {newData?.subModel?.careerAdvisingLocation?.country?.nameEn || 'N/A'},  {newData?.subModel?.careerAdvisingLocation?.city?.nameEn || 'N/A'}
                        </strong>

                      <strong className='d-block  text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1 d-block'> Background : </strong> {newData?.subModel?.background}
                      </strong>
                    </div>

                    {/* <span className='d-flex align-items-center text-black border p-2 mt-4 rounded  me-2 mb-2'>
                   <strong className='text-primary me-1'> Agree to Terms : </strong>
                 
                   <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>{newData?.subModel?.agreeToTerms ? 'Yes' : 'No'}
                   </span>
                 </span> */}
                    <div className='row my-7'>
                      <label className='col-lg-4 fw-bold '>Address</label>

                      <div className='col-lg-8'>
                        {newData?.subModel?.addresses?.length > 0 ?
                          newData?.subModel?.addresses?.map((item: any) => {
                            return <span className='d-block fw-bolder fs-6 text-dark'>{item}</span>
                          }) : 'N/A'}
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold '>Privacy</label>

                      <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>{newData?.subModel?.accountVisibility || 'N/A'}</span>
                      </div>
                    </div>

                  </div>
                  <div className='page-header mb-4'></div>
                  <div>
                    <h3 className='fw-bold text-gray-900 mb-4 mt-4' >Social Media</h3>
                    {newData?.socialMediaLinks?.length > 0 ? newData?.socialMediaLinks?.map((item: any) => {
                      return (
                        <div className='mb-4'>
                          <label className='col-lg-4 fw-bold text-capitalize fs-4 mb-2 text-info'>
                            {item?.socialMedia.nameEn}
                          </label>
                          {item?.url.map((el: any) => {
                            return <a href={el} target='_blank' className='d-block'>{el}</a>
                          })}
                        </div>
                      )
                    }) : 'N/A'}
                  </div>
                  <div className='page-header mb-4'></div>
              
                </div>
              </div>
            </div>
            <div className='col-12 bg-white p-2'>
              <div className='d-flex justify-content-end w-100'>
                <div className='d-flex justify-content-start flex-shrink-0'>
                  <button
                    className='btn  btn-bg-light btn-active-color-primary btn-sm me-2'
                    onClick={() => approveUser(true)}
                  >
                    {loadingApprove ?
                      <div className="spinner-border text-dark" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      : 'Approve'
                    }

                  </button>


                  <button
                    onClick={() => approveUser(false)}
                    className='btn  btn-bg-light btn-active-color-primary btn-sm me-5 text-danger'>

                    {loadingDecline ?
                      <div className="spinner-border text-dark" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      : 'Decline'
                    }
                  </button>
                </div>

              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
