import {useEffect, useState} from 'react'
import {Table} from './components/table'
import {IReview} from './module.interface'
import {listAllCompanyReviews} from '../../../services/company'

export const Review = (props: any) => {
  const {companyID} = props
  const [reviewsList, setReviewsList] = useState<Array<IReview>>([])
  const [loading, setLoading] = useState<any>(false)
  const [dataLength, setDataLength] = useState<any>(0)

  const listReviews = async () => {
    setLoading(true)
    
    const response = await listAllCompanyReviews(companyID)
    setDataLength(response.data.data.queryDataLength)
    setReviewsList(response.data.data.queryResult)
    setLoading(false)
  }

  useEffect(() => {
    listReviews()
  }, [])

  return (

     <div className='pt-4'>
     <Table list={reviewsList} listAll={listReviews} length={dataLength} skelton={loading} companyID={companyID}/>
     </div>

  )
}
