import React, {useEffect, useState} from 'react'
import ErrorMessage from '../../../../components/ErrorMessage'
import {getAllCities} from '../../../../services/city'
import {getAllCountries} from '../../../../services/Country'
import {UserDataForm} from '../../users/components/data-form'
import {CompanyDataForm} from '../../company/components/data-form'
import {InstitutionDataForm} from '../../institution/components/data-form'
import {MentorDataForm} from '../../mentor/components/data-form'
import {StudentDataForm} from '../../student/components/data-form'
import SocialMedia from './socialMedia'
import UploadeProfileImage from './uploadeProfileImage'
import {Dropdown} from 'react-bootstrap'

type Props = {
  onClickSubmit: any
  handleChange: any
  data: any
  ButtonText: string
  loading: boolean
  model: string
  action?: string
  setUserData: any
  userData?: any
  isSubmitted: boolean
  setFormData: any
  setUserType?: any
  userType?: any
  isLoadingData: boolean
}

export const DataForm = (props: Props) => {
  const {
    onClickSubmit,
    handleChange,
    data,
    ButtonText,
    loading,
    model,
    action,
    setUserData,
    userData,
    isSubmitted,
    setFormData,
    setUserType,
    userType,
    isLoadingData,
  } = props

  const [countryList, setCountryList] = useState<any>([])

  const [citiesOfCountry, setCitiesOfCountry] = useState<any>([])
  const [socialMediaLinks, setSocialMediaLinks] = useState<any>({})
  const [file, setImagefile] = useState<File>()
  const [cityPage, setCityPage] = useState<any>(0)
  const [allCitiesCount, setAllCitiesCount] = useState<any>(0)
  const [selectedCity, setSelectedCity] = useState<any>(null)
  const [loadingCities, setLoadingCities] = useState<any>(false)
  const [citySearch, setCitySearch] = useState<any>(null)
  const handleCitySearch = (e: any) => {
    setCitySearch(e.target.value)
    e.stopPropagation()
  }
  useEffect(() => {
    getCountriesList()
  }, [])

  useEffect(() => {
    if (userData?.country || data?.country) {
      getCitiesList()
    }
  }, [userData?.country, data?.country])

  useEffect(() => {
    if (file) {
      setFormData((_data: any) => ({..._data, profileImage: file}))
    }
  }, [file])

  const getCountriesList = async () => {
    const response = await getAllCountries()
    setCountryList(response.data.data.queryResult)
  }

  const getCitiesList = async (page: any = null) => {
    setLoadingCities(true)
    let cities: any = [...citiesOfCountry]
    const response = await getAllCities(null, null, data?.country || userData?.country?._id)
    cities = [...cities, ...response.data.data]

    setCitiesOfCountry(cities)
    // setAllCitiesCount(response.data.data.wholeModelDataCount)
    setLoadingCities(false)
  }

  const handleOnCitySelect = (name: any, city: any) => {
    setSelectedCity(city)
    handleChange(city._id, name)
  }
  const handleSeeMore = (e: any) => {
    setCityPage(cityPage + 1)
    e.stopPropagation()
    getCitiesList(cityPage + 1)
  }
  function getModelForm() {
    if (model) {
      switch (model.toLocaleLowerCase()) {
        case 'company':
          return (
            <CompanyDataForm
              handleChange={handleChange}
              setUserData={setUserData}
              userData={userData}
              data={data}
              isSubmitted={isSubmitted}
              action={action}
              setFormData={setFormData}
              isLoadingData={isLoadingData}
            />
          )
        case 'mentor':
          return (
            <MentorDataForm
              handleChange={handleChange}
              data={userData}
              formData={data}
              action={action}
              isSubmitted={isSubmitted}
              isLoadingData={isLoadingData}
            />
          )

        case 'institution':
          return (
            <InstitutionDataForm
              handleChange={handleChange}
              data={data}
              action={action}
              setUserData={setUserData}
              userData={userData}
              setFormData={setFormData}
            />
          )

        case 'student':
          return (
            <StudentDataForm
              handleChange={handleChange}
              data={userData}
              formData={data}
              action={action}
              isSubmitted={isSubmitted}
            />
          )

        default:
          return (
            <UserDataForm
              handleChange={handleChange}
              data={userData}
              action={action}
              isSubmitted={isSubmitted}
              setUserType={setUserType}
              userType={userType}
              setFormData={setFormData}
            />
          )
      }
    }
  }

  return (
    <div className='form-control d-flex row gx-4 justify-content-center'>
      <div className='input-data my-3 col-md-6 col-12'>
        <label htmlFor='country' className='fs-5 me-3'>
          <span className='text-danger'>*</span> Country
        </label>
        <select
          className='form-select'
          aria-label='Select example'
          name='country'
          onChange={(e: any) => {
            setCitiesOfCountry([])
            setSelectedCity(null)
            handleChange(null, 'city')
            setUserData((_data: any) => ({..._data, city: null}))
            handleChange(e)
          }}
          defaultValue={data?.country}
        >
          <option disabled selected>
            -- Select Country --
          </option>
          {countryList.map((country: any) => (
            <option value={country._id} selected={userData?.country?._id === country?._id}>
              {country?.nameEn}
            </option>
          ))}
        </select>
        {isSubmitted && !userData?.country && <ErrorMessage errorMsg='this field' />}
      </div>
      <div className='input-data my-3 col-md-6 col-12'>
        <label htmlFor='city' className='fs-5 me-3'>
          <span className='text-danger'>*</span> City
        </label>
        <Dropdown>
          <Dropdown.Toggle className='dropdown-city' id='dropdown-basic'>
            {selectedCity
              ? selectedCity?.nameEn
              : userData?.city
              ? userData?.city?.nameEn
              : '-- Select city --'}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <div className='input-data my-3  m-2'>
              <input
                type='text'
                name='search'
                placeholder='Search city'
                className='form-control w-100'
                onChange={handleCitySearch}
              />
            </div>
            {(citySearch != '' && citySearch != null
              ? citiesOfCountry.filter((ele: any) =>
                  ele?.nameEn?.toLowerCase()?.includes(citySearch?.toLowerCase())
                )
              : citiesOfCountry
            ).map((city: any) => (
              <Dropdown.Item
                onClick={() => handleOnCitySelect('city', city)}
                className={
                  selectedCity
                    ? selectedCity?._id === city?._id
                      ? 'selected'
                      : ''
                    : userData?.city?._id === city?._id
                    ? 'selected'
                    : ''
                }
              >
                {city?.nameEn}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        {isSubmitted && !userData?.city && <ErrorMessage errorMsg='this field' />}
      </div>

      {/* USER */}
      {/* Social Media */}

      {model.toLocaleLowerCase() === 'company' ||
      userType === 'Company' ||
      userType === 'Institution' ||
      model.toLocaleLowerCase() === 'institution' ? (
        <SocialMedia
          data={data}
          handleChange={handleChange}
          setSocialMedia={setSocialMediaLinks}
          setUserData={setFormData}
          userData={userData}
        />
      ) : (
        ''
      )}

      <p className='text-bg-info p-2 rounded text-center'>User's Info </p>
      {(userType?.toLocaleLowerCase() === 'student' ||
        userType?.toLocaleLowerCase() === 'mentor') && (
        <UploadeProfileImage
          isUser={true}
          setImagefile={setImagefile}
          type='logo'
          path={userData?.profileImage}
        />
      )}

      {getModelForm()}

      <div className='d-flex justify-content-end mt-4'>
        <button className='btn btn-primary' onClick={onClickSubmit}>
          {!loading && ButtonText}
          {loading && (
            <div className='spinner-border text-light' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          )}
        </button>
      </div>
    </div>
  )
}
