import { PageTitle } from '../../../../components/pageTitle'
import { AreaInterest } from '../../area-interest'
import { SocialMedia } from '../social-media'

export const CompanyRelated = () => {
  return (
    <>
      <div className='d-flex justify-content-center pb-4 mb-8 page-header'>
        <PageTitle active='Company related' />

      </div>
      <div className='mt-8'>
        <AreaInterest />
      </div>
      <div className='mt-8'>
        <SocialMedia />
      </div>
  

    </>
  )
}
