import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Pagination from '../../../../components/Pagination'
import { getAllJobs, getAllJobsByCompany } from '../../../../services/job'
import { JobsTable } from '../../job/components/table'

export const JobsTab = () => {
  const [jobsList, setJobsList] = useState<Array<any>>([])
  const [loading, setLoading] = useState<any>(false)
  const [dataLength, setDataLength] = useState<any>(0)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [searchValue, setSearchValue] = useState<any>(null)
  const { id } = useParams()
  const listJobs = async (size?: any) => {
    const searchTerm = searchValue ? `&searchTerm=${searchValue}` : null
    setLoading(true)
    const response = await getAllJobsByCompany(id,currentPage, (size ? size : pageSize), searchTerm)
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / (size ? size : pageSize)))
    setDataLength(response.data.data.queryDataLength)
    setJobsList(response.data.data.queryResult)
    setLoading(false)

  }


  const handlePaginationChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleCountChange = (e: any) => {
    setPageSize(e.target.value)
    listJobs(e.target.value)
  }

  useEffect(() => {
    listJobs(pageSize)
  }, [currentPage])
  useEffect(() => {
    if (searchValue == '') {
      listJobs()
      return
    }
    if (searchValue != null) {
      const getData = setTimeout(() => {
        listJobs()
      }, 800)

      return () => clearTimeout(getData)
    }


  }, [searchValue])
  return (
    <>
      <div className='d-flex align-items-center position-relative my-4'>
        <input
          type='text'
          name='searchBar'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px  border-2 bg-white'
          placeholder='Search '
          autoComplete='off'
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}

        />
      </div>


      <JobsTable list={jobsList} length={dataLength} skelton={loading} listAll={listJobs} />
      <div className='d-flex justify-content-between'>

        <>
          <div className='flex justify-end my-6'>
            <Pagination
              totalCount={totalPages}
              onPageChange={handlePaginationChange}
              currentPage={currentPage}
            />
          </div>
          <select
            className='form-select mr-4 mt-4'
            style={{ width: '75px', height: '50px' }}
            onChange={handleCountChange}
            value={pageSize}
          >
            <option value='10'>10</option>
            <option value='20'>20</option>
            <option value='30'>30</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        </>

      </div>


    </>
  )
}
