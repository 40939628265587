import * as React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from './editor.module.scss'
interface TextEditorProps {
  Change?: any;
  value?: any;
  name?: string
  defaultValue?: string;
  formik?: any
  submitClicked?: boolean;
  error?: any

}
export default function TextEditor(props: TextEditorProps) {
  const { Change, name, submitClicked, formik, error, value } = props
 
  return (
    <>
      <ReactQuill placeholder="Enter text here..."
        value={value}
        className={styles['ql-container']}
      
        onChange={e => Change(e)}
      />
      {error && submitClicked && (
        <span className='text-[#ff000e] text-sm'>This field is required</span>
      )}

    </>


  )
}