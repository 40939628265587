import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Table} from './components/table'
import {PageTitle} from '../../../components/pageTitle'
import {getAllNotificationTypes} from '../../../services/notificationsType'
import SearchInput from '../../../components/SearchInput'
export const NotificationsType = () => {
  const [notificationTypes, setNotificationTypes] = useState<Array<any>>([])
  const [loading, setLoading] = useState<any>(false)
  const listAllNotificationTypes = async () => {
    setLoading(true)
    const response = await getAllNotificationTypes()
    setNotificationTypes(response.data.data)
    setLoading(false)
  }
  const setDataResult = (response: any) => {
    setNotificationTypes(response.data.data.queryResult)
    setLoading(false)
  }
  useEffect(() => {
    listAllNotificationTypes()
  }, [])
  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Notification Types' />
        <Link to='/notification-type/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
      <SearchInput
        model={'notificationType'}
        setLoading={setLoading}
        setDataResult={setDataResult}
        ListAll={listAllNotificationTypes}
      />
      {loading ? (
        <div className="spinner-border text-light" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      ) : (
        <Table list={notificationTypes} listALl={listAllNotificationTypes} />
      )}
    </>
  )
}
