import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllMentorShipGuideLine = () => {
  return axios
    .get(`${BASEURL}MentorShipGuideLine`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getMentorShipGuideLine = (_id: any) => {
  return axios
    .get(`${BASEURL}MentorShipGuideLine/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateMentorShipGuideLine = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}MentorShipGuideLine/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createMentorShipGuideLine = (nameEn: string, nameAr: string) => {
  return axios
    .post(`${BASEURL}MentorShipGuideLine`, {nameEn: nameEn, nameAr: nameAr})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteMentorShipGuideLine = (_id: string) => {
  return axios
    .delete(`${BASEURL}MentorShipGuideLine/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
