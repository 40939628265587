/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  getNumberOfCompletedProfiles,
  getNumberOfMentorUsersViews,
  getNumberOfUsersByType,
  getNumberOfUsersMentorshipSession,
} from '../../../../services/analyticsService'
import moment from 'moment'
import {formatDate} from '../../../../utils/DateFormatter'

type Props = {
  className: string
  description: string
  color: string
  img?: string
  icon: any
  value: any
}

export const CardsWidget20 = ({className, description, color, img, value, icon}: Props) => (
  <div
    className={`d-flex justify-content-between p-4 bgi-no-repeat bgi-size-contain bgi-position-x-end  ${className}`}
    style={{
      minHeight: '150px',
      backgroundColor: color,
      backgroundImage: `url('${img}')`,
      borderRadius: '8px',
    }}
  >
    <div
      className='d-flex flex-column justify-content-between'
      style={{
        minHeight: '150px',
      }}
    >
      <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{value}</span>

      <span className='text-white  pt-1 fw-semibold fs-6'>{description}</span>
    </div>
    <div>{icon}</div>
  </div>
)

const Analytics = (props: any) => {
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 30)

  const [startDate, setStartDate] = useState<any>(new Date(yesterday))
  const [endDate, setEndDate] = useState<any>(new Date())
  const [data, setData] = useState<any>({})

  const numberOfUsersViews = async () => {
    const response = await getNumberOfMentorUsersViews(
      props.id,
      moment(startDate).format('YYYY-MM-DD'),
      moment(endDate).format('YYYY-MM-DD')
    )
    if (response) {
      setData((_data: any) => ({..._data, numberOfUsersViews: response?.data?.data}))
    }
  }
  const numberOfUsersMentorshipSession = async (status: any = null) => {
    const response = await getNumberOfUsersMentorshipSession(
      props.id,
      moment(startDate).format('YYYY-MM-DD'),
      moment(endDate).format('YYYY-MM-DD'),
      status
    )
    if (response) {
      if (status === 'completed') {
        setData((_data: any) => ({..._data, mentorshipSessionCompleted: response?.data?.data}))
      } else if (status === 'ongoing') {
        setData((_data: any) => ({..._data, mentorshipSessionOngoing: response?.data?.data}))
      } else {
        setData((_data: any) => ({..._data, numberOfUsersMentorshipSession: response?.data?.data}))
      }
    }
  }

  useEffect(() => {
    numberOfUsersViews()
    numberOfUsersMentorshipSession()
    numberOfUsersMentorshipSession('completed')
    numberOfUsersMentorshipSession('ongoing')
  }, [endDate, startDate])
  return (
    <>
      <div className='d-flex w-100 justify-content-end mb-4'>
        <div className='input-data me-3'>
          <label htmlFor='startDate' className='fs-5 me-3'>
            From
          </label>
          <input
            type='date'
            name='startDate'
            className='form-control'
            value={formatDate(startDate)}
            defaultValue={formatDate(startDate)}
            onChange={(e: any) => setStartDate(e.target.value)}
          />
        </div>
        <div className='input-data '>
          <label htmlFor='endDate' className='fs-5 me-3'>
            To
          </label>
          <input
            type='date'
            name='endDate'
            className='form-control'
            value={formatDate(endDate)}
            defaultValue={formatDate(endDate)}
            min={startDate}
            onChange={(e: any) => setEndDate(e.target.value)}
          />
        </div>
      </div>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3'>
          <CardsWidget20
            className=''
            color='#4145f1'
            description='Users Views'
            value={data.numberOfUsersViews || 0}
            icon={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='60'
                height='60'
                fill='white'
                className='bi bi-people-fill'
                viewBox='0 0 16 16'
              >
                <path d='M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z' />
              </svg>
            }
          />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3'>
          <CardsWidget20
            className=''
            color='#F1416C'
            description='Total Mentorship Sessions'
            value={data.numberOfUsersMentorshipSession || 0}
            icon={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='60'
                height='60'
                fill='white'
                className='bi bi-laptop'
                viewBox='0 0 16 16'
              >
                <path d='M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z' />
              </svg>
            }
          />
        </div>
        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3'>
          <CardsWidget20
            className=''
            color='#ffc700'
            description='Mentorship Sessions Ongoing'
            value={data.mentorshipSessionOngoing || 0}
            icon={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='60'
                height='60'
                fill='white'
                className='bi bi-laptop'
                viewBox='0 0 16 16'
              >
                <path d='M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z' />
              </svg>
            }
          />
        </div>
        {/* end::Col */}
        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3'>
          <CardsWidget20
            className=''
            color='#50cd89'
            description='Mentorship Sessions Completed'
            value={data.mentorshipSessionCompleted || 0}
            icon={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='60'
                height='60'
                fill='white'
                className='bi bi-laptop'
                viewBox='0 0 16 16'
              >
                <path d='M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z' />
              </svg>
            }
          />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3'>
          <CardsWidget20
            className=''
            color='#50cd89'
            description='Overall rating'
            value={props.rating || 0}
            icon={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='60'
                height='60'
                fill='white'
                className='bi bi-laptop'
                viewBox='0 0 16 16'
              >
                <path d='M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z' />
              </svg>
            }
          />
        </div>
      </div>
    </>
  )
}

export {Analytics}
