import { FC, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'

import { User } from '../pages/modules/user'
import { UpdateUser } from '../pages/modules/user/update'
import { CreateUser } from '../pages/modules/user/create'
import { ViewInstitution } from '../pages/modules/institution/view'
import { ViewMentor } from '../pages/modules/mentor/view'

const MentorRoutes = () => {

    return (
        <Routes>
            <Route element={<MasterLayout />}>
            <Route  path='/mentors'
          element={
            <SuspensedView>
              <User model='Mentor' />
            </SuspensedView>
          }
        />
        <Route
          path='/mentor/details/:id/:name'
          element={
            <SuspensedView>
              <ViewMentor />
            </SuspensedView>
          }
        />
      
        <Route
          path='/mentor/update/:id'
          element={
            <SuspensedView>
              <UpdateUser model={'Mentor'} />
            </SuspensedView>
          }
        />
        <Route
          path='/mentor/create/'
          element={
            <SuspensedView>
              <CreateUser model={'Mentor'} />
            </SuspensedView>
          }
        />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { MentorRoutes }
