import { useEffect, useState } from 'react'

import { getAllCities } from '../../../../services/city'
import { getAllCountries } from '../../../../services/Country'
import { getAllIndustry } from '../../../../services/industry'
import { getAllSubIndustry } from '../../../../services/subIndustry'
import UploadeProfileImage from '../../user/components/uploadeProfileImage'

import Industries from './Industries'
import Addresses from '../../../../components/Inputs/Addresses'
import ErrorMessage from '../../../../components/ErrorMessage'
import TextEditor from '../../../../components/textEditor'
import { Dropdown } from 'react-bootstrap'
import IndustriesUI from './SelectedIndustrieies'

type Props = {
  handleChange: any
  data: any
  setUserData?: any
  userData?: any
  isSubmitted: boolean
  action?: string
  setFormData?: any
  isLoadingData?: any
}

export const CompanyDataForm = (props: Props) => {
  const { handleChange, data, setUserData, userData, isSubmitted, action, setFormData, isLoadingData } = props
  const [selectList, setSelectList] = useState<any>([])
  const [countryList, setCountryList] = useState<any>([])
  const [cityList, setCityList] = useState<any>([])
  const [allCitiesCount, setAllCitiesCount] = useState<any>(0)

  const [citiesOfCountry, setCitiesOfCountry] = useState<any>([])
  const [addressesList, setAddressesList] = useState<string[]>([])
  const [headQuarter, setHeadQuarter] = useState<any>({})
  const [subIndustries, setSubIndustries] = useState<any>({})
  const [logo, setLogo] = useState<any>('')

  const [coverPhoto, setCoverPhoto] = useState<any>('')
  const [cityPage, setCityPage] = useState<any>(0)
  const [selectedCity, setSelectedCity] = useState<any>(null)
  const [loadingCities, setLoadingCities] = useState<any>(false)
  const [citySearch, setCitySearch] = useState<any>(null)
  const handleCitySearch = (e: any) => {
    setCitySearch(e.target.value)
    e.stopPropagation();
  }
  useEffect(() => {
    getIndustriesList()
    getCountriesList()
    getSubIndustriesList()
  }, [])

  useEffect(() => {
    headQuarter?.country && getCitiesList()
  }, [headQuarter?.country])

  useEffect(() => {
    if (logo || logo == 'null') {
      setFormData((_data: any) => ({ ..._data, logo: logo != 'null' ? logo : '' }))
    }
  }, [logo])
  useEffect(() => {
    if (coverPhoto || coverPhoto == 'null') {
      setFormData((_data: any) => ({ ..._data, coverPhoto: coverPhoto != 'null' ? coverPhoto : '' }))
    }


  }, [coverPhoto])

  const getIndustriesList = async () => {
    const response = await getAllIndustry()
    setSelectList(response.data.data)
  }

  const getSubIndustriesList = async () => {
    const response = await getAllSubIndustry()
    setSubIndustries(response.data.data.queryResult)
  }

  const getCountriesList = async () => {
    const response = await getAllCountries()

    setCountryList(response.data.data.queryResult)
  }
  const getCitiesList = async (page: any = null) => {
    setLoadingCities(true)
    let cities: any = [...citiesOfCountry]
    const response = await getAllCities(null, null, headQuarter?.country || userData?.subModel?.headQuarters?.country?._id)
    cities = [...cities, ...response.data.data]
    setCitiesOfCountry(cities)
    setAllCitiesCount(response.data.data.wholeModelDataCount)
    setLoadingCities(false)
  }

  const handleHeadQuarterChange = (e: any) => {
    setHeadQuarter({ ...headQuarter, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (headQuarter?.city && headQuarter?.country) {
      handleChange(headQuarter, 'headQuarters')
    }
  }, [headQuarter?.city, headQuarter?.country])

  useEffect(() => {
    if (data) setAddressesList(data?.subModel?.addresses)
  }, [data])


  const handleOnCitySelect = (name: any, city: any) => {
    setSelectedCity(city)
    setHeadQuarter({ ...headQuarter, 'city': city._id })
  }
  const handleSeeMore = (e: any) => {
    setCityPage(cityPage + 1)
    e.stopPropagation();
    getCitiesList(cityPage + 1)
  }
  return (
    <>
      {isLoadingData ?
        <div className="spinner-border text-dark" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        :
        <div className='form-control'>
          <div className='mb-5'>
            <UploadeProfileImage
              setImagefile={setCoverPhoto}
              type='coverPhoto'
              path={userData?.subModel?.coverPhoto}
              model="Company"
            />
          </div>

          <UploadeProfileImage model="Company" setImagefile={setLogo} type='logo' path={userData?.subModel?.logo} />

          <div className='input-data my-3'>
            <label htmlFor='name' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Organization's Name
            </label>
            <input
              type='text'
              name='name'
              className='form-control'
              defaultValue={userData?.subModel?.name}
              onChange={handleChange}
            />

            {isSubmitted && ((!data?.name) && (!userData?.subModel?.name)) && <ErrorMessage errorMsg='this field' />}
          </div>
          <div className='input-data my-3'>
            <label htmlFor='email' className='fs-5 me-3'>
              <span className='text-danger'>*</span>Organization's contact email
            </label>
            <input
              type='email'
              name='email'
              className='form-control'
              defaultValue={userData?.email}
              onChange={handleChange}
            />
            {isSubmitted && (!(data?.email) && (!userData?.email)) && <ErrorMessage errorMsg='this field' />}
          </div>
          <div className='input-data my-3'>
            <label htmlFor='webSite' className='fs-5 me-3'>
              <span className='text-danger'>*</span>  Website
            </label>
            <input
              type='text'
              name='webSite'
              className='form-control'
              defaultValue={userData?.subModel?.webSite}
              onChange={handleChange}
            />
            {isSubmitted && ((!data?.webSite) && (!userData?.subModel?.webSite)) && <ErrorMessage errorMsg='this field' />}
          </div>
          <div className='input-data my-3'>
            <label htmlFor='companySize' className='fs-5 me-3'>
              <span className='text-danger'>*</span>   Company size
            </label>
            <input
              type='number'
              name='companySize'
              className='form-control'
              defaultValue={userData?.subModel?.companySize}
              onChange={handleChange}
            />
            {isSubmitted && ((!data?.companySize) && (!userData?.subModel?.companySize)) && <ErrorMessage errorMsg='this field' />}
          </div>
          {/* 
        <Industries
          data={userData?.subModel?.industries}
          handleChange={handleChange}
        /> */}
          <IndustriesUI Userindustries={userData?.subModel?.industries}
            handleSelectChange={handleChange}
            single={false}
          />
          <div className='input-data my-3'>
            <label htmlFor='phoneNumber' className='fs-5 me-3'>
              <span className='text-danger'>*</span>  Phone number
            </label>
            <input
              type='phone'
              name='phoneNumber'
              className='form-control'
              defaultValue={userData?.subModel?.phoneNumber}
              onChange={handleChange}
            />
            {isSubmitted && ((!data?.phoneNumber) && (!userData?.subModel?.phoneNumber)) && <ErrorMessage errorMsg='this field' />}
          </div>

          <div className='input-data my-3'>
            <Addresses handleChange={handleChange} data={userData?.subModel?.addresses} />
          </div>
          <div>
            <p>Headquarters</p>
            <div className='input-data my-3'>
              <label htmlFor='country' className='fs-5 me-3'>
                <span className='text-danger'>*</span>  Country
              </label>
              <select
                className='form-select'
                aria-label='Select example'
                name='country'
                onChange={handleHeadQuarterChange}
              >
                <option disabled selected>
                  -- select an option --
                </option>
                {countryList.map((country: any) => (
                  <option
                    value={country._id}
                    selected={userData?.subModel?.headQuarters?.country?._id === country?._id}
                  >
                    {country.nameEn}
                  </option>
                ))}
              </select>
              {isSubmitted && ((!data?.headQuarters?.country) && (!userData?.subModel?.headQuarters?.country)) && (
                <ErrorMessage errorMsg='this field' />
              )}
            </div>
            <div className='input-data my-3'>
              <label htmlFor='city' className='fs-5 me-3'>
                <span className='text-danger'>*</span> City
              </label>
              <Dropdown>
                <Dropdown.Toggle className='dropdown-city' id="dropdown-basic">
                  {selectedCity ?
                    selectedCity?.nameEn
                    : userData?.city ? userData?.city?.nameEn : '-- Select city --'}
                </Dropdown.Toggle>

                <Dropdown.Menu>

                  <div className='input-data my-3  m-2'>
                    <input
                      type='text'
                      name='search'
                      placeholder="Search city"
                      className='form-control w-100'
                      onChange={handleCitySearch}
                    />
                  </div>
                  {((citySearch != '' && citySearch != null) ?
                    citiesOfCountry.filter((ele: any) => ele?.nameEn?.toLowerCase()?.includes(citySearch?.toLowerCase())) :
                    citiesOfCountry).map((city: any) => (
                      <Dropdown.Item onClick={() => handleOnCitySelect('city', city)}
                        className={selectedCity ? selectedCity?._id === city?._id ? 'selected' : '' : userData?.subModel?.headQuarters?.city?._id === city?._id ? 'selected' : ''} >
                        {city?.nameEn}
                      </Dropdown.Item>

                    ))}
                </Dropdown.Menu>
              </Dropdown>

              {isSubmitted && ((!data?.headQuarters?.city) && (!userData?.subModel?.headQuarters?.city)) && <ErrorMessage errorMsg='this field' />}
            </div>
          </div>
          {!action && (
            <div className='input-data my-3'>
              <label htmlFor='password' className='fs-5 me-3'>
                <span className='text-danger'>*</span>   Password
              </label>
              <input
                type='password'
                name='password'
                className='form-control'
                defaultValue={userData?.subModel?.password}
                onChange={handleChange}
              />
              {isSubmitted && (!(data?.password) && (!userData?.subModel?.password)) && <ErrorMessage errorMsg='this field' />}
            </div>
          )}

          <div className='input-data my-3'>
            <label htmlFor='backGround' className='fs-5 me-3'>
              Background
            </label>

            <TextEditor
              value={data?.background ? data?.background : userData?.subModel?.background}
              defaultValue={userData?.subModel?.background}
              Change={(e: any) => handleChange(e, 'background')} />

          </div>

          <div className='input-data my-3 col-md-12 col-12'>
            <label htmlFor='accountVisibility' className='fs-5 me-3'>
              Account Visibility
            </label>
            <select
              className='form-select'
              aria-label='Select example'
              name='accountVisibility'
              onChange={handleChange}
              defaultValue={userData?.subModel?.accountVisibility}
            >
              <option disabled selected>
                -- select an option --
              </option>
              <option selected={userData?.subModel?.accountVisibility === 'public'} value="public">Public</option>
              {/* <option  selected={data?.subModel?.accountVisibility === 'employer'}  value="employer">Employers and Educational Institutions</option> */}
              <option selected={userData?.subModel?.accountVisibility === 'private'} value="private">Private</option>

            </select>
          </div>

          <div className='input-data my-3'>
            <div className='form-check-solid form-check-sm'>
              <input
                className='form-check-input'
                type='checkbox'
                defaultChecked={userData?.subModel?.agreeToTerms}
                defaultValue={userData?.subModel?.agreeToTerms}
                name='agreeToTerms'
                onClick={(e: any) => handleChange(e.target.checked, 'agreeToTerms')}
              />
              <label className=' fs-5 mx-3' htmlFor='agreeToTerms'>
                Agree to terms
              </label>
            </div>
          </div>
          <div className='input-data my-3'>
            <div className='form-check-solid form-check-sm'>
              <input
                className='form-check-input'
                type='checkbox'
                defaultChecked={userData?.subModel?.receiveMessage}
                defaultValue={userData?.subModel?.receiveMessage}
                name='receiveMessage'
                onClick={(e: any) => handleChange(e.target.checked, 'receiveMessage')}
              />
              <label className='fs-5 mx-3' htmlFor='receiveMessage'>
                Are you open to receiving job related messages?
              </label>
            </div>
          </div>
        </div>
      }
    </>
  )
}
