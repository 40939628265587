import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../components/pageTitle'
import {getReview} from '../../../services/reviews'

export default function ReviewDetails() {
  const [formData, setFormData] = useState<any>()
  const [active, setActive] = useState(0)
  const {id} = useParams()
  useEffect(() => {
    if (id) {
      handleGetReview()
    }
  }, [id])

  const handleGetReview = async () => {
    const response = await getReview(id)
    if (response) {
      setFormData(response.data.data)
    }
  }
  return (
    <div>
      {' '}
      {formData && (
        <>
          <div className='d-flex justify-content-between flex-col my-3'>
            <PageTitle active={`Review `} />
          </div>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <span
                      onClick={() => {
                        setActive(0)
                      }}
                      className={`nav-link text-active-primary me-6 ` + (active === 0 && 'active')}
                    >
                      Overview
                    </span>
                  </li>
                  <li
                    className='nav-item'
                    onClick={() => {
                      setActive(1)
                    }}
                  >
                    <span
                      className={`nav-link text-active-primary me-6 ` + (active === 1 && 'active')}
                    >
                      User Details
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            {active === 0 && (
              <>
                <div className='card mb-5 mt-5 mb-xl-10' id='kt_profile_details_view'>
                  <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                      <h3 className='fw-bolder m-0'>Review Details</h3>
                    </div>
                  </div>

                  <div className='card-body p-9'>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Title</label>

                      <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>{formData.jobTitle}</span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Get an offer</label>

                      <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>{formData.didYouGetOffer?.answer}</span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>End Date</label>

                      <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>{
                         moment(formData.endDate).format('YYYY-MM-DD')
                        }</span>
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>How often did you get paid</label>

                      <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>
                          {formData.howOftenDidYouGetPaid}
                        </span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Start Date</label>

                      <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>{
                         moment(formData.startDate).format('YYYY-MM-DD')
                         }</span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>End Date</label>

                      <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark'>{
                         moment(formData.endDate).format('YYYY-MM-DD')
                         }</span>
                        {/* <span className='fw-bolder fs-6 text-dark'>{formData.endDate}</span> */}
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Salary</label>

                      <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>{formData.salary}</span>
                      </div>
                    </div>

                    <div className='row mb-10'>
                      <label className='col-lg-4 fw-bold text-muted'>Work Type</label>

                      <div className='col-lg-8'>
                        <span className='fw-bold fs-6'>{formData.typeOfWork}</span>
                      </div>
                    </div>
                    <div className=' mb-5 notice d-flex   py-6'>
                      <div className='d-flex flex-stack flex-grow-1'>
                        <div className='fw-bold'>
                          <h4 className='text-gray-800 fw-bolder'>Advice for others</h4>
                          <div className='fs-6 text-gray-600'>{formData.advicesForOthers}.</div>
                        </div>
                      </div>
                    </div>

                    <div className=' mb-5 notice d-flex   py-6'>
                      <div className='d-flex flex-stack flex-grow-1'>
                        <div className='fw-bold'>
                          <h4 className='text-gray-800 fw-bolder'>Liked and Learned</h4>
                          <div className='fs-6 text-gray-600'>
                            {formData.whatYouLikedAndLearned}.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {active === 1 && (
              <>
                <div className='card mb-5 mt-5 mb-xl-10' id='kt_profile_details_view'>
                  <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                      <h3 className='fw-bolder m-0'>User Details</h3>
                    </div>
                  </div>

                  <div className='card-body p-9'>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Email</label>

                      <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>{formData.user.email}</span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Location</label>

                      <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>
                          {formData.user.country?.nameEn} ,{formData.user.city?.nameEn}
                        </span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Type</label>

                      <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>{formData.user.model}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-5 mt-5 mb-xl-10' id='kt_profile_details_view'>
                  <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                      <h3 className='fw-bolder m-0'>Company Details</h3>
                    </div>
                  </div>

                  <div className='card-body p-9'>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Email</label>

                      <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>{formData.company.email}</span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Location</label>

                      <div className='col-lg-8 fv-row'>
                        <span className='fw-bold fs-6'>
                          {formData.company.country.nameEn} ,{formData.company.city.nameEn}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-5 mt-5 mb-xl-10' id='kt_profile_details_view'>
                  <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                      <h3 className='fw-bolder m-0'>Key Takeaways</h3>
                    </div>
                  </div>

                  <div className='card-body p-9'>
                    {formData.keyWays.map((el: any) => (
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Name</label>

                        <div className='col-lg-8 fv-row'>
                          <span className='fw-bold fs-6'>{el.nameEn}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}
