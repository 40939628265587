import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllEducationLevels = (page:number=0,pageSize:number=0) => {
    const pagination =  page || pageSize ? `?page=${page}&pageSize=${pageSize}`: ''
  return axios
    .get(`${BASEURL}educationLevel${pagination}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getEducationLevel = (_id: any) => {
  return axios
    .get(`${BASEURL}educationLevel/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateEducationLevel = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}educationLevel/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createEducationLevel = (order: number, nameEn: string, nameAr: string,enrolledHighSchool:boolean,educationType:string) => {
  return axios
    .post(`${BASEURL}educationLevel`, {order: order, nameEn: nameEn, nameAr: nameAr,enrolledHighSchool:enrolledHighSchool,educationType:educationType})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteEducationLevel = (_id: string) => {
  return axios
    .delete(`${BASEURL}educationLevel/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
