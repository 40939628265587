import {useEffect, useState} from 'react'

import {useNavigate, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../components/pageTitle'

import {getJob} from '../../../services/job'
import {getJobApplication} from '../../../services/jobApplication'
import JobApplicationHeader from './components/jobHeader'

export const ViewJobApplication = () => {
  const [formData, setFormData] = useState<any>()
  const {id} = useParams()

  useEffect(() => {
    if (id) {
      handleGetJobApplication()
    }
  }, [id])

  const handleGetJobApplication = async () => {
    const response = await getJobApplication(id)
    if (response) {
      setFormData(response.data.data)
    }
  }

  return (
    <>
      {formData && (
        <>
          <div className='d-flex justify-content-between flex-col my-3'>
            <PageTitle active={`Job Application Details`} />
          </div>
          <JobApplicationHeader jobData={formData} handleGetJobApplication={handleGetJobApplication}/>
        </>
      )}

    
    </>
  )
}
