import { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import Addresses from '../../../../components/Inputs/Addresses'
import { getAllCities } from '../../../../services/city'
import { getAllCountries } from '../../../../services/Country'
import UploadeProfileImage from '../../user/components/uploadeProfileImage'
type Props = {
  handleChange: any
  data: any
  action: any
  setUserData?: any
  userData?: any
  setFormData?: any
  isSubmitted?: any
}
export const InstitutionDataForm = (props: Props) => {
  const { handleChange, data, action, setUserData, userData, setFormData } = props
  const [countryList, setCountryList] = useState<any>([])
  const [citiesOfCountry, setCitiesOfCountry] = useState<any>([])
  const [headQuarter, setHeadQuarter] = useState<any>({})
  const [coverPhoto, setCoverPhoto] = useState<any>('')
  const [logo, setLogo] = useState<any>('')
  const [cityPage, setCityPage] = useState<any>(0)
  const [selectedCity, setSelectedCity] = useState<any>(null)
  const [loadingCities, setLoadingCities] = useState<any>(false)
  const [allCitiesCount, setAllCitiesCount] = useState<any>(0)
  const [citySearch, setCitySearch] = useState<any>(null)
  const handleCitySearch = (e: any) => {
    setCitySearch(e.target.value)
    e.stopPropagation();
  }
  useEffect(() => {
    getCountriesList()
  }, [])
  useEffect(() => {
    headQuarter?.country && getCitiesList()
  }, [headQuarter?.country])

  const handleSeeMore = (e: any) => {
    setCityPage(cityPage + 1)
    e.stopPropagation();
    getCitiesList(cityPage + 1)
  }
  const getCountriesList = async () => {
    const response = await getAllCountries()
    setCountryList(response.data.data.queryResult)
  }
  const getCitiesList = async (page: any = null) => {
    setLoadingCities(true)
    let cities: any = [...citiesOfCountry]
    const response = await getAllCities(null, null, headQuarter?.country || userData?.subModel?.careerAdvisingLocation?.country?._id)
    cities = [...cities, ...response.data.data]
    setCitiesOfCountry(cities)
    setAllCitiesCount(response.data.data.wholeModelDataCount)
    setLoadingCities(false)
  }
  const handleHeadQuarterChange = (e: any) => {
    setSelectedCity(null)
    setCitiesOfCountry([])
    setHeadQuarter({ ...headQuarter, [e.target.name]: e.target.value })
  }
  const handleOnCitySelect = (name: any, city: any) => {
    setSelectedCity(city)
    setHeadQuarter({ ...headQuarter, 'city': city._id })
  }
  useEffect(() => {
    if (headQuarter?.city && headQuarter?.country) {
      handleChange(headQuarter, 'careerAdvisingLocation')
    }
  }, [headQuarter?.city, headQuarter?.country])
  useEffect(() => {
    if (logo || logo == 'null') {
      setFormData((_data: any) => ({ ..._data, logo: logo != 'null' ? logo : '' }))
    }
  }, [logo])
  useEffect(() => {
    if (coverPhoto || coverPhoto == 'null') {
      setFormData((_data: any) => ({ ..._data, banner: coverPhoto != 'null' ? coverPhoto : '' }))
    }


  }, [coverPhoto])
  return (
    <>
      {
        <div className='form-control d-flex row'>
          <div className='mb-5'>
            <UploadeProfileImage
              setImagefile={setCoverPhoto}
              type='coverPhoto'
              path={userData?.subModel?.banner}
              model="School"
            />
          </div>
          <UploadeProfileImage model="School" setImagefile={setLogo} type='logo' path={userData?.subModel?.logo} />
          <div className='input-data my-3 '>
            <label htmlFor='name' className='fs-5 me-3'>
              <span className='text-danger'>*</span>   Organization's Name
            </label>
            <input
              type='text'
              name='name'
              className='form-control'
              defaultValue={userData?.subModel?.name}
              onChange={handleChange}
            />
          </div>
          <div className='input-data my-3 '>
            <label htmlFor='email' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Organization's contact email
            </label>
            <input
              type='email'
              name='email'
              className='form-control'
              defaultValue={userData?.email}
              onChange={handleChange}
            />
          </div>
          {/* {action && (
            <div className='input-data my-3'>
              <label htmlFor='emailVerifiedAt' className='fs-5 me-3'>
                emailVerifiedAt
              </label>
              <input
                type='date'
                name='emailVerifiedAt'
                className='form-control'
                defaultValue={userData?.subModel?.emailVerifiedAt}
                disabled={true}
              />
            </div>
          )} */}
          <Addresses handleChange={handleChange} data={userData?.subModel?.addresses} />
          <div className='input-data my-3'>
            <label htmlFor='background' className='fs-5 me-3'>
              <span className='text-danger'>*</span>     Background
            </label>
            <textarea
              name='background'
              className='form-control'
              defaultValue={userData?.subModel?.background}
              onChange={handleChange}
            />
          </div>
          {action !== 'update' && (
            <div className='input-data my-3'>
              <label htmlFor='password' className='fs-5 me-3'>
                <span className='text-danger'>*</span>   Password
              </label>
              <input
                type='password'
                name='password'
                className='form-control'
                defaultValue={data?.subModel?.password}
                onChange={handleChange}
              />
            </div>
          )}
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='careerAdvisingPhone' className='fs-5 me-3'>
              <span className='text-danger'>*</span>  Career Advising Phone
            </label>
            <input
              type='phone'
              name='careerAdvisingPhone'
              className='form-control'
              defaultValue={userData?.subModel?.careerAdvisingPhone}
              onChange={handleChange}
            />
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='careerAdvisingEmail' className='fs-5 me-3'>
              Career Advising Email
            </label>
            <input
              type='email'
              name='careerAdvisingEmail'
              className='form-control'
              defaultValue={userData?.subModel?.careerAdvisingEmail}
              onChange={handleChange}
            />
          </div>
          <h4 className='my-3'>Career Advising Location</h4>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='country' className='fs-5 me-3'>
              <span className='text-danger'>*</span>  Country
            </label>
            <select
              className='form-select'
              aria-label='Select example'
              name='country'
              onChange={handleHeadQuarterChange}
              defaultValue={userData?.subModel?.headQuarters?.country}
            >
              <option disabled selected>
                -- select an option --
              </option>
              {countryList?.map((country: any) => (
                <option
                  value={country._id}
                  selected={
                    userData?.subModel?.careerAdvisingLocation?.country?._id === country._id
                  }
                >
                  {country.nameEn}
                </option>
              ))}
            </select>
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='city' className='fs-5 me-3'>
              <span className='text-danger'>*</span>   City
            </label>

            <Dropdown>
              <Dropdown.Toggle className='dropdown-city' id="dropdown-basic">
                {selectedCity ?
                  selectedCity?.nameEn
                  : userData?.city ? userData?.city?.nameEn : '-- Select city --'}
              </Dropdown.Toggle>

              <Dropdown.Menu>

                <div className='input-data my-3  m-2'>
                  <input
                    type='text'
                    name='search'
                    placeholder="Search city"
                    className='form-control w-100'
                    onChange={handleCitySearch}
                  />
                </div>
                {((citySearch != '' && citySearch != null) ?
                  citiesOfCountry.filter((ele: any) => ele?.nameEn?.toLowerCase()?.includes(citySearch?.toLowerCase())) :
                  citiesOfCountry).map((city: any) => (
                    <Dropdown.Item onClick={() => handleOnCitySelect('city', city)}
                    className={selectedCity ? selectedCity?._id === city?._id ? 'selected' : '' : userData?.subModel?.careerAdvisingLocation?.city?._id === city?._id ? 'selected' : ''} >
                    {city?.nameEn}
                  </Dropdown.Item>

                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className='input-data my-3'>
            <label htmlFor='schoolUrl' className='fs-5 me-3'>
              School URL
            </label>
            <input
              type='url'
              name='schoolUrl'
              className='form-control'
              defaultValue={userData?.subModel?.schoolUrl}
              onChange={handleChange}
            />
          </div>
          <div className='input-data my-3 col-md-12 col-12'>
            <label htmlFor='accountVisibility' className='fs-5 me-3'>
              Account Visibility
            </label>
            <select
              className='form-select'
              aria-label='Select example'
              name='accountVisibility'
              onChange={handleChange}
              defaultValue={userData?.subModel?.accountVisibility}
            >
              <option disabled selected>
                -- select an option --
              </option>
              <option selected={userData?.subModel?.accountVisibility === 'community'} value="community">Community</option>
              {/* <option  selected={data?.subModel?.accountVisibility === 'employer'}  value="employer">Employers and Educational Institutions</option> */}
              <option selected={userData?.subModel?.accountVisibility === 'private'} value="private">Private</option>

            </select>
          </div>
          {/* <div className='input-data my-3'>
              <div className='form-check-solid form-check-sm'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  defaultChecked={userData?.subModel?.agreeToTerms}
                  defaultValue={userData?.subModel?.agreeToTerms}
                  name='agreeToTerms'
                  onChange={handleChange}
                />
                <label className=' fs-5 mx-3' htmlFor='agreeToTerms'>
                Agree to terms
                </label>
              </div>
            </div> */}
        </div>
      }
    </>
  )
}
