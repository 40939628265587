import axios from '../axios'
import {BASEURL} from '../environments/environment'
 
export const getAllSocialMedia = () => {
  return axios
    .get(`${BASEURL}socialMedia`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}


export const getSocialMedia = (_id: any) => {
  return axios
    .get(`${BASEURL}socialMedia/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateSocialMedia = (_id:any ,data: any) => {
  return axios
    .patch(`${BASEURL}socialMedia/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createSocialMedia = (order: number, nameEn: string, nameAr: string) => {
  return axios
    .post(`${BASEURL}socialMedia`, {order:order, nameEn: nameEn , nameAr:nameAr})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteSocialMedia = (_id: string) => {
  return axios
    .delete(`${BASEURL}socialMedia/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
