import { useEffect, useState } from 'react'
import { getAllSocialMedia } from '../../../../services/socialMedia'

type Props = {
  handleChange: any
  data: any
  setSocialMedia: any
  setUserData: any
  userData: any
}

export default function SocialMedia(props: Props) {
  const { setUserData, userData } = props

  const [socialMediaList, setSocialMediaList] = useState<any>([])
  const [oldList, setAllList] = useState<any>([])
  const [count, setCount] = useState<any>({})

  useEffect(() => {
    getSocialMediaList()
  }, [])

  const getSocialMediaList = async () => {
    const response = await getAllSocialMedia()
    setSocialMediaList(response.data.data)
  }

  const handleInputEdit = (e: any, index: number, id: string, urlIndex: number) => {
    if (e.target.value == '') {
      handleInputRemove(index, id, urlIndex)
    } else {
      const allList = oldList
      let temp =
        (oldList.length > 0 && oldList[index])
          ? oldList[index]?.url
          : []

      temp[urlIndex] = (e.target.value)
      const item = {
        socialMedia: id,
        url: temp,
      }
      allList[index] = item
      setUserData((_data: any) => ({ ..._data, socialMediaLinks: allList }))
    }


  }

  const handleInputRemove = (index: number, id: string, urlIndex: number) => {
    const allList = oldList
    let temp =
      (oldList.length > 0 && oldList[index])
        ? oldList[index]?.url
        : []

    temp.splice(urlIndex, 1)
    const item = {
      socialMedia: id,
      url: temp,
    }
    if (temp.length > 0) {
      allList[index] = item
    }
    else {
      allList.splice(index,1)
    }
    setUserData((_data: any) => ({ ..._data, socialMediaLinks: allList }))

  }

  useEffect(() => {
    if ((userData?.socialMediaLinks?.length > 0) && (oldList.length === 0)) {
      let tempArr: any = []
      console.log(oldList.length)
      for (let i = 0; i < userData?.socialMediaLinks.length; i++) {
        const temp =
          (userData?.socialMediaLinks && userData?.socialMediaLinks[i])
            ? userData?.socialMediaLinks[i]?.url
            : []
        const item = {
          socialMedia: userData?.socialMediaLinks[i].socialMedia._id,
          url: temp,
        }
        tempArr.push(item)
        setAllList(tempArr)
      }

    }
  }, [userData?.socialMediaLinks])

  const handleAddInput = (index: number, id: any) => {
    const allList = oldList
    let temp = oldList[index]?.url || []
    temp.push('')
    const item = {
      socialMedia: id,
      url: temp,
    }
    allList[index] = item
    setUserData((_data: any) => ({ ..._data, socialMediaLinks: allList }))
  }
  return (
    <div className='row d-flex page-header mb-3'>
      <p className='my-4'>Social Media Links</p>

      {socialMediaList.map((item: any, index: number) => {
        return (
          <div className=' col-md-12 col-12  mb-3'>
            {oldList[index]?.url.length > 0 ?
              oldList[index]?.url?.map((url: any, i: number) => {
                return (


                  <div className='input-data w-100 '>
                    <label htmlFor='url' className='fs-5 me-3'>
                      {item.nameEn}
                    </label>
                    <div className='d-flex align-items-center justify-items-center'>
                      <input
                        defaultValue={url}
                        type='url'
                        name='url'
                        className='form-control '
                        onInput={(e: any) => handleInputEdit(e, index, item?._id, i)}
                      />
                      <button
                        onClick={() => handleInputRemove(index, item?._id, i)}
                        className='mx-2 btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <i className='bi bi-trash text-danger fs-2'></i>
                      </button>
                    </div>

                  </div>
                )
              }) :
              <div className='input-data '>
                <label htmlFor='url' className='fs-5 me-3'>
                  {item.nameEn}
                </label>
                <div className='d-flex align-items-center justify-items-center'>
                  <input
                    type='url'
                    name='url'
                    className='form-control '
                    onInput={(e: any) => handleInputEdit(e, index, item?._id, 0)}
                  />
                  <button
                    onClick={() => handleInputRemove(index, item?._id, 0)}
                    className='mx-2 btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  >
                    <i className='bi bi-trash text-danger fs-2'></i>
                  </button>
                </div>
              </div>
            }
            {/* {[...Array(count[item?._id] ? count[item?._id] : oldList[index]?.url.length > 0 ? 0 : 1)].map((url: any, urlIndex: number) => {
              return (
                <div className='input-data '>
                  <label htmlFor='url' className='fs-5 me-3'>
                    {item.nameEn}
                  </label>
                  <input
                    type='url'
                    name='url'
                    className='form-control '
                    onInput={(e: any) => handleInputChange(e, index, item?._id, urlIndex)}
                  />
                </div>
              )
            })} */}
            <div className=' small-btn block actions-end '>
              <button
                className='btn btn-bg-primary text-white my-4'
                onClick={(e: any) =>
                  handleAddInput(index, item?._id)
                  // setCount((_data: any) => ({
                  //   ..._data,
                  //   [item?._id]: count[item?._id] ? count[item?._id] + 1 : 1,
                  // }))
                }
              >{`Add another ${item?.nameEn} link`}</button>
            </div>
          </div>
        )
      })}

      {/* {Object.keys(socialAddedMediaList).map((item: any) => {
        return (
          <ul className='list-unstyled'>
            {item.nameEn} :
            {socialAddedMediaList[item].map((link: string) => {
              return (
                <li
                  className='d-flex justify-content-between h-4 mb-2 list-item'
                  onClick={() => handleRemoveItem(item, link)}
                >
                  {link}
                  <i className='la la-remove'></i>
                </li>
              )
            })}
          </ul>
        )
      })} */}
    </div>
  )
}
