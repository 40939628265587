import {useEffect, useState} from 'react'
import ErrorMessage from '../../../../components/ErrorMessage'
import {getAllCountries} from '../../../../services/Country'

type Props = {
  onClickSubmit: any
  handleChange: any
  data: any
  ButtonText: string
  Show: any
  loading: boolean
  isSubmitted: boolean
}

export const DataForm = (props: Props) => {
  const {onClickSubmit, handleChange, data, ButtonText, Show, loading, isSubmitted} = props
  const [countryList, setCountryList] = useState<any>([])

  useEffect(() => {
    getCountriesList()
  }, [])



  const getCountriesList = async () => {
    const response = await getAllCountries()
    setCountryList(response.data.data.queryResult)
  }

  return (
    <form className='form-control' onSubmit={onClickSubmit}>
      <div className='input-data my-3'>
        <label htmlFor='order' className='fs-5 me-3'>
        <span className='text-danger'>*</span>  Order 
        </label>
        <input
          type='phone'
          name='order'
          className='form-control'
          value={data?.order}
          onChange={handleChange}
        />
      </div>
      {isSubmitted && !data?.order && <ErrorMessage errorMsg='Order' />}
      <div className='input-data my-3'>
        <label htmlFor='nameEn' className='fs-5 me-3'>
        <span className='text-danger'>*</span>    Name English 
        </label>
        <input
          type='text'
          name='nameEn'
          className='form-control'
          value={data?.nameEn}
          onChange={handleChange}
        />
        {isSubmitted && !data?.nameEn && <ErrorMessage errorMsg='Name in English' />}
      </div>
      <div className='input-data my-3'>
        <label htmlFor='nameAr' className='fs-5 me-3'>
        <span className='text-danger'>*</span>   Name Arabic 
        </label>
        <input
          type='text'
          name='nameAr'
          className='form-control'
          value={data?.nameAr}
          onChange={handleChange}
        />
        {isSubmitted && !data?.nameAr && <ErrorMessage errorMsg='Name in Arabic' />}
      </div>
      <div className='input-data my-3'>
        <label htmlFor='country' className='fs-5 me-3'>
          Country *
        </label>
        <select
          className='form-select'
          aria-label='Select example'
          name='country'
          onChange={handleChange}
          value={data?.country}
          placeholder='Select'
        >
          <option disabled selected>
            -- select a Country --
          </option>
          {countryList.map((country: any) => (
            <option value={country._id} selected={data?.country?._id === country._id}>
              {country.nameEn}
            </option>
          ))}
        </select>
        {isSubmitted && !data?.country && <ErrorMessage errorMsg='Country' />}
      </div>
      <div className='d-flex justify-content-end'>
        <button className='btn btn-primary' disabled={Show} type='submit'>
          {!loading && ButtonText}
          {loading && (
             <div className="spinner-border text-light" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
          )}
        </button>
      </div>
    </form>
  )
}
