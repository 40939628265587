import { ChangeEvent, useEffect, useState } from 'react'

import { DataForm } from './components/data-form'
import { useNavigate, useParams } from 'react-router-dom'
import { PageTitle } from '../../../components/pageTitle'

import { getAdmin, updateAdmin } from '../../../services/admin'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
export const UpdateAdmin = () => {
  const [disabled, setdisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)

  const navigate = useNavigate()
  const { id } = useParams()
  const req = ['password', 'role', 'email']
  const [formData, setFormData] = useState<any>()
  const [isSubmitted, setIsSubmitted] = useState<any>(false)
  const adminRoles: any = {
    SUPER_ADMIN: 0,
    STUDENT_ADMIN: 1,
    COMPANY_ADMIN: 2,
    INSTITUTION_ADMIN: 3,
    MENTOR_ADMIN: 4,

  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((_data: any) => ({ ..._data, [e.target.name]: e.target.value }))
    setdisabled(false)
  }

  useEffect(() => {
    if (id) {
      getAdminFn()
    }
  }, [id])

  const getAdminFn = async () => {
    const response = await getAdmin(id)
    if (response) {
      setFormData(response.data.data)
    }
  }

  const onClickSubmitBtn = (e: any) => {
    setIsSubmitted(true)
    e.preventDefault()
    req.every((key) => key in formData) && updateAdminFn()
  }

  const updateAdminFn = async () => {
    setLoading(true)
    const updatedData = {
      firstName:formData?.firstName,
      lastName:formData?.lastName,
      email: formData.email,
      role: adminRoles[formData.role]
    }
    const response = await updateAdmin(id, updatedData)
    if (response.status === 200) {
      toast.success('Done successfully !', {
         position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
       theme: "colored",
        });
      navigate('/admins')
      setLoading(false)
    } else {
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setLoading(false)
    }
  }

  const breadCrampList = [
    {
      name: 'Admins',
      route: '/admins',
    },
  ]

  return (
    <>
      <PageTitle breadCrampList={breadCrampList} active='Update' />
      <ToastContainer/>
      <div className='w-75 m-auto'>
        <DataForm
          onClickSubmit={onClickSubmitBtn}
          handleChange={handleChange}
          data={formData}
          ButtonText='Update'
          Show={disabled}
          loading={loading}
          isSubmitted={isSubmitted}
        />
      </div>
    </>
  )
}
