import {ChangeEvent, useEffect, useState} from 'react'
import {IReview} from './module.interface'
import {DataForm} from './components/data-form'
import {useNavigate, useParams} from 'react-router-dom'
import {PageTitle} from '../../../components/pageTitle'
import {getReview, updateReview} from '../../../services/reviews'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
export const UpdateReview = () => {
  const [disabled, setdisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)
  const [getOffer, setGetOffer] = useState({})

  const navigate = useNavigate()
  const {id} = useParams()

  const [formData, setFormData] = useState<any>([])
  const [keyways, setKeyways] = useState<any>()
  const [isSubmitted, setIsSubmitted] = useState<any>(false)
  const req = [
    'jobTitle',
    'resourcesFindingJob',
    'advicesForOthers',
    // 'typeOfWork',
    'company',
    'startDate',
    'agreeToTerms',
  ]
  const handleChange = (e: any, name = '') => {
    if (name !== '') {
      setFormData((_data: any) => ({..._data, [name]: e}))
    } else {
      setFormData((_data: any) => ({..._data, [e.target.name]: e.target.value}))
      setdisabled(false)
      if (e.target.type === 'checkbox') {
        setFormData({...formData, [e.target.name]: e.target.checked})
      }
    }
  }

  useEffect(() => {
    if (id) {
      handleGetReview()
    }
  }, [id])

  const handleGetReview = async () => {
    const response = await getReview(id)
    if (response) {
      setFormData(response.data.data)
      //setFormData({...response.data.data, keyways: response.data.data.keyWays})
      setKeyways(response.data.data.keyWays)
      // setTimeout(() => {
      // }, 1000)
    }
  }

  const onClickSubmitBtn = (e: any) => {
    e.preventDefault()
    setIsSubmitted(true)
  
    req.every((key) => key in formData) && handleUpdateReview()
  }

  const handleUpdateReview = async () => {
    setLoading(true)
    const response = await updateReview(id, {...formData, didYouGetOffer: getOffer})
    if (response.status == 200) {
      toast.success('Done successfully !', {
         position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
       theme: "colored",
        });
      navigate('/reviews')
      setLoading(false)
    } else {
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setLoading(false)
    }
  }

  const breadCrampList = [
    {
      name: 'Reviews',
      route: '/reviews',
    },
  ]

  return (
    <>
       <ToastContainer/>
      <PageTitle breadCrampList={breadCrampList} active='Update' />
      <div className='w-75 m-auto'>
        <DataForm
          onClickSubmit={onClickSubmitBtn}
          handleChange={handleChange}
          setGetOffer={setGetOffer}
          data={formData}
          keyways={keyways}
          ButtonText='Update'
          Show={disabled}
          loading={loading}
          isSubmitted={isSubmitted}
        />
      </div>
    </>
  )
}
