import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllDomain = () => {
  return axios
    .get(`${BASEURL}domain`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}


export const getDomain = (_id: any) => {
  return axios
    .get(`${BASEURL}domain/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateDomain = (_id:any , data:any) => {
  return axios
    .patch(`${BASEURL}domain/${_id}`,data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createDomain = (name: string) => {
  return axios
    .post(`${BASEURL}domain`,{name:name})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteDomain = (_id:string) => {
  return axios
    .delete(`${BASEURL}domain/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}


