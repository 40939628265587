import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Delete } from '../delete'

import { KTSVG } from '../../../../../_metronic/helpers'
import { deleteAdmin, ResetPasswordForAdmin } from '../../../../services/admin'
import ChangePasswordModal from '../../../../components/ChangePasswordModal'
import Pagination from '../../../../components/Pagination'
import { exportTableToExcel } from '../../../../utils/export'
import TableBodyLoading from '../../../../components/tableBodyLoading'
import { formatDate } from '../../../../utils/DateFormatter'
import { useThemeMode } from '../../../../../_metronic/partials'
import Alert from '../../../../components/alert'
import { toast } from 'react-toastify'


type Props = {
  list: any[]
  listALl: any
  skelton?: boolean
}

export const Table = (props: Props) => {
  const PageSize = 9
  const { list, listALl, skelton } = props
  const [loading, setLoading] = useState<any>(false)

  const [showModal, setShowModal] = useState(false)
  const [_id, setId] = useState<string>('')
  const [currentPage, setCurrentPage] = useState(1)

 
  const viewModal = (_id: any) => {
    setId(_id)
    setShowModal(true)
  }

  const cancelFn = () => {
    setShowModal(false)
  }

  const delteFn = () => {
    deleteAdminFn()
    setShowModal(false)
  }

  const deleteAdminFn = async () => {
    setLoading(true)
    const response = await deleteAdmin(_id)
    if (response.status === 200) {
      toast.success('Deleted successfully !', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      cancelFn()
      setLoading(false)
      listALl()
    } else {
      setLoading(false)
      cancelFn()
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }
  const handleExport = (id: string) => {
    exportTableToExcel(id, 'Admins.xlsx')
  }

  const adminRoles: any = {
    0: 'Super Admin',
    1: 'Student Admin',
    2: 'Company Admin',
    3: 'Institution Admin',
    4: 'Mentor Admin',
  }
  type Data = typeof list;
  type SortKeys = keyof Data[0];
  type SortOrder = "ascn" | "desc";
  function sortData({
    tableData,
    sortKey,
    reverse,
  }: {
    tableData: Data;
    sortKey: SortKeys;
    reverse: boolean;
  }) {
    if (!sortKey) return tableData;

    const sortedData = shownData.sort((a: any, b: any) => {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  }

   function SortButton({
    sortOrder,
    columnKey,
    sortKey,
    onClick,
  }: {
    sortOrder: SortOrder;
    columnKey: SortKeys;
    sortKey: SortKeys;
    onClick: MouseEventHandler<HTMLButtonElement>;
  }) {
    const { mode } = useThemeMode()
    return (
      <button
        onClick={onClick}
        className={`${mode==='dark'?'text-white':'text-dark'}  ${sortKey === columnKey && sortOrder === "desc"
          ? "sort-button sort-reverse"
          : "sort-button"
          }`}
      >
        ▲
      </button>
    );
  }
  const [sortKey, setSortKey] = useState<any>("firstName");
  const [sortOrder, setSortOrder] = useState<SortOrder>("ascn");
  const [selectedEmail, setSelectedEmail] = useState<any>();
  const [loadingSend, setLoadingSend] = useState<any>(false)
  const [reset, setReset] = useState<any>(null)
  const sortedData = useCallback(
    () => sortData({ tableData: shownData, sortKey, reverse: sortOrder === "desc" }),
    [list, sortKey, sortOrder]
  );

  function changeSort(key: SortKeys) {
    setSortOrder(sortOrder === "ascn" ? "desc" : "ascn");
    setSortKey(key);
  }

  const [shownData, setShownData] = useState<any>([])

  const headers: { key: SortKeys; label: string }[] = [
    { key: "firstName", label: "First Name" },
    { key: "lastName", label: "Last Name" },
    { key: "email", label: "Email" },
    { key: "role", label: "Role" },


  ];
  useEffect(() => {
    const data = sortedData().map((item: any) => (
      {
        _id: item?._id,
        firstName:item?.firstName,
        lastName:item?.lastName,
        email: item?.email,
        role: item?.role,
       

      }
    ))
    setShownData(data)
  }, [sortedData])
  useEffect(() => {
    if (list) {
      const data = list.map((item: any) => (
        {

          _id: item?._id,
          firstName:item?.firstName,
          lastName:item?.lastName,
          email: item?.email,
          role: adminRoles[item.role]
        
        }
      ))
      setShownData(data)
    }

  }, [list])
  const handleClick = async (email:string) => {
    setSelectedEmail(email)
    setLoadingSend(true)
    const response = await ResetPasswordForAdmin(email)
    if (response.status === 200) {
      setLoadingSend(false)
      setReset(true)
      setTimeout(() => {
        setReset(null)
      }, 3000)
    } else {
      setLoadingSend(false)
      setReset(false)
    }
  }
  return (
    <>
     {reset && <Alert alertTitle={'Success'} alertMsg={`Edqan sent a password reset link to "${selectedEmail}".`} type='success' />}
      {reset == false && <Alert alertTitle={'Error'} alertMsg={`Something Went wrong, try again later`} type='error' />}

      <div>
     
        <a
          className='text-danger d-flex justify-content-end mx-3 mb-4 align-items-center'
          style={{ fontSize: '14px', fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => {
            handleExport(`Admins-list`)
          }}
        >
          Export table <i className="bi bi-arrow-bar-up text-danger mx-2"></i>
        </a>
        <div className={`card `}>
          {/* begin::Body */}
          <div className='card-body py-3 '>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                  {headers.map((row) => {
                      return (
                        <td className={`cursor-pointer ${sortKey===row.key?'text-primary':''}`}
                        onClick={() => changeSort(row.key)}
                        {...{
                          sortOrder,
                          sortKey,
                        }}>
                          {row.label} 
                         
                          <SortButton
                            columnKey={row.key}
                            onClick={() => changeSort(row.key)}
                            {...{
                              sortOrder,
                              sortKey,
                            }}
                          />
                       
                        </td>
                      );
                    })}
                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {skelton ?
                  <TableBodyLoading count={2} />
                  :
                  <tbody>
                    {shownData.map((item:any) => {
                      return (


                        <tr>
                          {/* <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark fw-bold text-hover-primary fs-6'>
                                  {item.username}
                                </div>
                              </div>
                            </div>
                          </td> */}
                           <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark fw-bold text-hover-primary fs-6'>
                                  {item.firstName||'N/A'}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark fw-bold text-hover-primary fs-6'>
                                  {item.lastName||'N/A'}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark fw-bold text-hover-primary fs-6'>
                                  {item.email}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='text-dark fw-bold text-hover-primary d-block fs-6 '>
                              {item.role}
                            </div>
                          </td>

                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                            <button
                            className='btn btn-danger btn-sm btn-sm me-1  ml-2 d-flex justify-content-center me-2'
                            type='button'
                            onClick={() => handleClick(item?.email)}
                          >
                            {loadingSend && selectedEmail===item?.email ?
                              <div className="spinner-border text-light" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                              : 'Send password reset to Admin'
                            }

                          </button>
                              <Link
                                to={`/admins/update/${item._id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-5'
                              >
                                <i className='bi bi-pencil-square text-info fs-2'></i>
                              </Link>

                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                onClick={() => viewModal(item._id)}
                              >
                                 <i className='bi bi-trash text-danger fs-2'></i>
                              </button>
                            </div>
                          </td>
                        </tr>



                      )
                    })}
                  </tbody>}
                <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                  <ChangePasswordModal />
                </div>
              </table>
              {shownData.length == 0 && !skelton && <div className='flex p-6 text-center'>
                No data available</div>}

              {showModal && (
                <Delete show={showModal} onCancel={cancelFn} onDelete={delteFn} loading={loading} />
              )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      </div>
    </>
  )
}
