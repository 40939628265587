import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Table } from './components/table'

import { PageTitle } from '../../../components/pageTitle'

import { getAllJobs } from '../../../services/job'
import Pagination from '../../../components/Pagination'
import { getAllApplications, getAllApplicationsForUser } from '../../../services/jobApplication'
import { UserApplicationsTableTable } from './components/userApplicatriondTable'
interface JobApplicationsProps {
  jobID?: any,
  StudentId?: any
}
export const JobApplications = (props: JobApplicationsProps) => {
  const { jobID, StudentId } = props
  const [applicationsList, setApplicationsList] = useState<Array<any>>([])
  const [loading, setLoading] = useState<any>(false)
  const [dataLength, setDataLength] = useState<any>(0)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const listApplications = async (size: any) => {
    setLoading(true)
    const response = jobID ? await getAllApplications(jobID) : await getAllApplicationsForUser(StudentId)
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / size))
    setDataLength(response.data.data.queryDataLength)
    setApplicationsList(response.data.data.queryResult)
    setLoading(false)
  }

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleCountChange = (e: any) => {
    setPageSize(e.target.value)
    listApplications(e.target.value)
  }

  useEffect(() => {
    listApplications(pageSize)
  }, [currentPage])

  return (
    <>
      <div className='d-flex justify-content-between my-3 px-4'>
      </div>
      {loading ? (
        <div className="spinner-border text-light" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      ) : (
        <>
         {jobID?
          <Table list={applicationsList} listAll={listApplications} length={dataLength} />:
          <UserApplicationsTableTable list={applicationsList} listAll={listApplications} length={dataLength}/>
         }
     {applicationsList.length>0&&     <div className='d-flex justify-content-between p-4'>
        
              <>
                <div className='flex justify-end my-6'>
                  <Pagination
                    totalCount={totalPages}
                    onPageChange={handlePaginationChange}
                    currentPage={currentPage}
                  />
                </div>
                <select
                  className='form-select mr-4 mt-4'
                  style={{ width: '75px', height: '50px' }}
                  onChange={handleCountChange}
                  value={pageSize}
                >
                  <option value='10'>10</option>
                  <option value='20'>20</option>
                  <option value='30'>30</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                </select>
              </>
         
          </div>
}
        </>
      )}
    </>
  )
}
