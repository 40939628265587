import { useEffect, useState } from 'react'
import { AttachmentsTable } from '../../../components/attachmentsTable'
import { PageTitle } from '../../../components/pageTitle'
import {  getUserAttachmentsBending } from '../../../services/user'


export const BendingAttachments = () => {
  const [attachments, setAttachments] = useState<any>([])

  useEffect(() => {
      listUserAttachments()
  }, [])


  const listUserAttachments = async () => {
    const response = await getUserAttachmentsBending()
    if (response) setAttachments(response.data.data)
  }


  return (
    <>
    
      <div className='d-flex justify-content-between flex-col my-3 page-header mb-8'>
        <PageTitle active={`Student Document Reviews`} />
      </div>
       <div className='row'>

      <AttachmentsTable list={attachments} handleListAll={listUserAttachments}/>
      </div>
    </>
  )
}
