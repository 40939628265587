import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Alert from '../../../components/alert'
import { MentorShipAvailability } from '../../../components/MentorShipAvalibilty'
import { MentorShipSessionTable } from '../../../components/MentorShipSessionsTable'
import { PageTitle } from '../../../components/pageTitle'
import Pagination from '../../../components/Pagination'
import { ResetPasswordForUser } from '../../../services/admin'
import { deleteUser, getUser, getUserSession, updateUser } from '../../../services/user'
import { objectToFormData } from '../../../utils/helpers'
import { Delete } from '../user/delete'
import AddAvailabilityTimeMentor from './AddAvailabilityTime'
import AddAvailabilityTime from './AddAvailabilityTime'

import { Analytics } from './components/analytics'

export const ViewMentor = () => {
  const [formData, setFormData] = useState<any | undefined>()
  const [loading, setLoading] = useState<any>(false)
  const [active, setActive] = useState<any>(0)
  const [sessions, setSessions] = useState<any>([])
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(5)
  const [reset, setReset] = useState<any>(null)
  const { id } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    if (id) {
      handleGetMentor()
      handleGetSessions(pageSize)
    }
  }, [id])

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleCountChange = (e: any) => {
    setPageSize(e.target.value)
    handleGetSessions(e.target.value)
  }

  const handleGetMentor = async () => {
    const response = await getUser(id)
    if (response) {
      setFormData(response.data.data)
    }
  }

  const handleGetSessions = async (size: any) => {
    const response = await getUserSession(id, true, currentPage, size)
    if (response) {
      setSessions(response.data.data.queryResult)
    }
  }
  const handleClick = async () => {
    setLoading(true)
    const response = await ResetPasswordForUser(formData?.email)
    if (response.status === 200) {
      setLoading(false)
      setReset(true)
      setTimeout(() => {
        setReset(null)
      }, 3000)
    } else {
      setLoading(false)
      setReset(false)
    }
  }

  const [showModal, setShowModal] = useState(false)

  const viewModal = () => {
    setShowModal(true)
  }

  const cancelFn = () => {
    setShowModal(false)
  }

  const approveUser = async () => {
    const res = await updateUser(id, objectToFormData({ approved: true }))
    if (res.status === 200) {
      handleGetMentor()
    }
  }
  const handleCancel = () => {
    setShowModal(false)
  }

  const delteFn = () => {
    deleteGetOfferFn()
    setShowModal(false)
  }

  const deleteGetOfferFn = async () => {
    setLoading(true)
    const response = await deleteUser(id)
    if (response) {
      cancelFn()
      setLoading(false)
      // listALl()
      navigate('/mentors')
    }
  }
  return (
    <>
      {showModal && (
        <Delete
          show={showModal}
          onCancel={handleCancel}
          onDelete={delteFn}
          loading={loading}
          model={'Mentor'}
        />
      )}
      <div className='d-flex justify-content-between flex-col my-3'>
        <PageTitle active={`Mentor Details`} />
      </div>
      {reset && (
        <Alert
          alertTitle={'Success'}
          alertMsg={`Edqan sent a password reset link to "${formData.email}".`}
          type='success'
        />
      )}
      {reset == false && (
        <Alert
          alertTitle={'Error'}
          alertMsg={`Something Went wrong, try again later`}
          type='error'
        />
      )}

      <div className='row'>
        <div className='col-sm-12 offset-sm-2 col-md-12 offset-md-0'>
          <div className='d-flex overflow-auto h-55px mb-5'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <span
                  onClick={() => {
                    setActive(0)
                  }}
                  className={`nav-link text-active-primary me-6 ` + (active === 0 && 'active')}
                >
                  Mentor Information
                </span>
              </li>
              <li
                className='nav-item'
                onClick={() => {
                  setActive(1)
                }}
              >
                <span className={`nav-link text-active-primary me-6 ` + (active === 1 && 'active')}>
                  Mentorship Sessions
                </span>
              </li>
              <li
                className='nav-item'
                onClick={() => {
                  setActive(2)
                }}
              >
                <span className={`nav-link text-active-primary me-6 ` + (active === 2 && 'active')}>
                  Mentor Availability
                </span>
              </li>
              <li
                className='nav-item'
                onClick={() => {
                  setActive(3)
                }}
              >
                <span className={`nav-link text-active-primary me-6 ` + (active === 3 && 'active')}>
                  Statistics
                </span>
              </li>
            </ul>
          </div>
          {active === 0 && (
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
              <div className='card mb-5 mb-xl-10 h-100 '>
                <div className='card-body pt-9 pb-0'>
                  <div className='d-flex justify-content-start w-100  position-relative'>
                    <span
                      className={`d-flex align-items-center position-absolute me-2 text-white p-2 rounded  ${formData?.approved ? 'bg-success' : 'bg-danger'
                        }`}
                      style={{ top: '-13px', right: '-30px' }}
                    >
                      <strong className='me-2 d-block'> Status :</strong>{' '}
                      {formData?.approved ? 'Approved' : 'Pending'}
                    </span>
                    <div className='d-flex justify-content-start flex-shrink-0 mb-4'>
                      <Link
                        to={`/mentor/update/${id}`}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-2'
                      >
                        <i className='bi bi-pencil-square text-info fs-2'></i>
                      </Link>

                      <button
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-4'
                        onClick={() => viewModal()}
                      >
                        <i className='bi bi-trash text-danger fs-2'></i>
                      </button>
                      {!formData?.approved ? (
                        <>
                          <button
                            className='btn  btn-bg-light btn-active-color-primary btn-sm'
                            onClick={() => approveUser()}
                          >
                            {/* <i className='bi bi-check2 fs-2 text-success'></i> */}
                            Approve
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                      <button
                        className='btn btn-danger btn-sm btn-sm me-1  ml-2 d-flex justify-content-center me-2'
                        type='button'
                        onClick={() => handleClick()}
                      >
                        {loading ? (
                          <div className='spinner-border text-light' role='status'>
                            <span className='sr-only'>Loading...</span>
                          </div>
                        ) : (
                          'Send password reset to user'
                        )}
                      </button>
                    </div>
                  </div>
                  <div className='h-100 mb-3 d-flex'>
                    <div className='me-7 mb-4 '>
                      <div
                        className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border'
                        style={{ borderRadius: '50%' }}
                      >
                        {formData?.profileImage ? (
                          <img
                            src={formData?.profileImage}
                            alt='profile Image'
                            style={{ borderRadius: '50%' }}
                          />
                        ) : (
                          <div
                            className='bg-info text-white d-flex align-items-center justify-content-center '
                            style={{
                              fontSize: '50px',
                              padding: '10px',
                              borderRadius: '50%',
                              height: '150px',
                              width: '150px',
                            }}
                          >
                            {formData?.subModel?.firstName.charAt(0).toUpperCase()}{' '}
                            {formData?.subModel?.lastName.charAt(0).toUpperCase()}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='flex-grow-1'>
                      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                            <div className='d-flex align-items-center mb-2'>
                              <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-2 mb-2'>
                                <span className='text-primary me-1'> First Name : </span>{' '}
                                {formData?.subModel?.firstName}
                              </span>
                              <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-2 mb-2'>
                                <span className='text-primary me-1'> Middle Name : </span>{' '}
                                {formData?.subModel?.middleName}
                              </span>
                              <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-2 mb-2'>
                                <span className='text-primary me-1'> Last Name : </span>{' '}
                                {formData?.subModel?.lastName}
                              </span>
                            </div>

                            <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-2 mb-2'>
                              <span className='text-primary me-1'> Email : </span> {formData?.email}
                            </span>
                            <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-2 mb-2'>
                              <span className='text-primary me-1'> Area of Expertise : </span>{' '}
                              {formData?.subModel?.currentTitle || 'N/A'}
                            </span>
                            {formData?.subModel?.company && (
                              <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-2 mb-2'>
                                <span className='text-primary me-1'> Company : </span>{' '}
                                {formData?.subModel?.company?.subModel?.name ||
                                  formData?.subModel?.company}
                              </span>
                            )}

                            <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-2 mb-2'>
                              <span className='text-primary me-1'> Gender : </span>{' '}
                              {formData?.subModel.gender}
                            </span>
                            <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-2 mb-2'>
                              <span className='text-primary me-1'> Location : </span>{' '}
                              {formData?.city.nameEn}, {formData?.country.nameEn}
                            </span>
                            <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-2 mb-2'>
                              <span className='text-primary me-1'> Alma Mater : </span>{' '}
                              {formData?.subModel?.almaMater
                                ? formData?.subModel?.almaMater?.subModel?.name
                                : 'N/A'}
                            </span>
                            <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded me-2 mb-2'>
                              <span className='text-primary me-1'> Personal URL : </span>{' '}
                              {formData?.subModel.linkedInUrl || 'N/A'}
                            </span>
                            <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  mb-2'>
                              <span className='text-primary me-1'> Phone Number : </span>{' '}
                              {formData?.subModel.phoneNumber || 'N/A'}
                            </span>
                          </div>
                          <div>
                            <div className='w-100 d-block align-items-center text-black bg-gray-300 p-2 rounded  me-2 mb-2 w-auto'>
                              <strong className='text-primary me-1 d-block'>
                                About {formData?.subModel?.firstName} :{' '}
                              </strong>
                              {formData?.subModel.about || 'N/A'}
                            </div>
                          </div>
                          <div className='d-block flex-wrap fw-bold fs-6 mb-4 pe-2'>
                            <div className='d-block  w-100'>
                              <span className='d-flex align-items-center text-black border p-2 rounded  me-2 mb-2'>
                                <strong className='text-primary me-1'>
                                  Preferred language for mentoring :{' '}
                                </strong>
                                {formData?.subModel.mentoringLanguages.length > 0 ? (
                                  formData?.subModel.mentoringLanguages?.map((item: any) => {
                                    return (
                                      <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>
                                        {item.nameEn}{' '}
                                      </span>
                                    )
                                  })
                                ) : (
                                  <span className='fw-bolder fs-6 text-dark '>N/A </span>
                                )}
                              </span>
                              {/* <span className='d-flex align-items-center text-black border p-2 rounded  me-2 mb-2'>
                                <strong className='text-primary me-1'>How to mentor : </strong>
                                {formData?.subModel.mentorShipTypes.length > 0 ? (
                                  formData?.subModel.mentorShipTypes?.map((item: any) => {
                                    return (
                                      <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>
                                        {item.nameEn}{' '}
                                      </span>
                                    )
                                  })
                                ) : (
                                  <span className='fw-bolder fs-6 text-dark '>N/A </span>
                                )}
                              </span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card-body p-9'>
                <div className='d-block  w-100'>
                  <span className='d-flex align-items-center text-black border p-2 rounded  me-2 mb-2'>
                    <strong className='text-primary me-1'>Majors you can mentor in : </strong>

                    {formData?.subModel.majors?.map((item: any) => {
                      return (
                        <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>
                          {item.nameEn}{' '}
                        </span>
                      )
                    }) || <span className='fw-bolder fs-6 text-dark '>N/A </span>}
                  </span>
                  <span className='d-flex align-items-center text-black border p-2 rounded  me-2 mb-2'>
                    <strong className='text-primary me-1'>Area of Interest : </strong>

                    {formData?.subModel.areaOfInterest.length > 0 ? (
                      formData?.subModel.areaOfInterest?.map((item: any) => {
                        return (
                          <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>
                            {item.nameEn}{' '}
                          </span>
                        )
                      })
                    ) : (
                      <span className='fw-bolder fs-6 text-dark '>N/A </span>
                    )}
                  </span>
                  <span className='d-flex align-items-center text-black border p-2 rounded  me-2 mb-2'>
                    <strong className='text-primary me-1'>Level of education: </strong>
                    <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>
                      {formData?.subModel.educationLevel?.nameEn || 'N/A'}
                    </span>
                  </span>
                  {formData?.subModel.graduationDate &&
                    <span className='d-flex align-items-center text-black border p-2 rounded  me-2 mb-2'>
                      <strong className='text-primary me-1'>Graduation date: </strong>
                      <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>
                        {moment(formData?.subModel.graduationDate).format('YYYY-MM-DD')
                        }
                      </span>
                    </span>
                  }
                  <span className='d-flex align-items-center text-black border p-2 rounded  me-2 mb-2'>
                    <strong className='text-primary me-1'>Industries you can mentor in: </strong>

                    {formData?.subModel.industries?.map((item: any) => {
                      return (
                        <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>
                          {item.nameEn}{' '}
                        </span>
                      )
                    }) || <span className='fw-bolder fs-6 text-dark '>N/A </span>}
                  </span>
                  <span className='d-flex align-items-center text-black border p-2 rounded  me-2 mb-2'>
                    <strong className='text-primary me-1'>Countries familiar living in: </strong>

                    {formData?.subModel.countries.length > 0 ? (
                      formData?.subModel.countries?.map((item: any) => {
                        return (
                          <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>
                            {item.nameEn}{' '}
                          </span>
                        )
                      })
                    ) : (
                      <span className='fw-bolder fs-6 text-dark '>N/A </span>
                    )}
                  </span>
                  <span className='d-flex align-items-center text-black border p-2 rounded  me-2 mb-2'>
                    <strong className='text-primary me-1'>Addresses : </strong>

                    {formData?.subModel.addresses.length > 0 ? (
                      formData?.subModel.addresses?.map((item: any) => {
                        return (
                          <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>
                            {item}{' '}
                          </span>
                        )
                      })
                    ) : (
                      <span className='fw-bolder fs-6 text-dark '>N/A </span>
                    )}
                  </span>
                  <span className='d-flex align-items-center text-black border p-2 rounded  me-2 mb-2'>
                    <strong className='text-primary me-1'> Agree to be featured on edqan : </strong>

                    <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>
                      {formData?.subModel?.featuredAsPublic ? 'Yes' : 'No'}
                    </span>
                  </span>
                  <span className='d-flex align-items-center text-black border p-2 rounded  me-2 mb-2'>
                    <strong className='text-primary me-1'>
                      {' '}
                      Agree to Terms and Service agreement :{' '}
                    </strong>

                    <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>
                      {formData?.subModel?.agreeToTerms ? 'Yes' : 'No'}
                    </span>
                  </span>
                  <span className='d-flex align-items-center text-black border p-2 rounded  me-2 mb-2'>
                    <strong className='text-primary me-1'>Agree to Terms : </strong>

                    <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>
                      {formData?.subModel?.guideLines ? 'Yes' : 'No'}
                    </span>
                  </span>
                  <span className='d-flex align-items-center text-black border p-2 rounded  me-2 mb-2'>
                    <strong className='text-primary me-1'>
                      {' '}
                      Video telling about {formData?.subModel?.firstName} :{' '}
                    </strong>

                    <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>
                      {formData?.subModel?.video ? (
                        <a href={formData?.subModel?.video} target='_blank'>
                          {formData?.subModel?.video}
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </span>
                  </span>
                  <span className='d-flex align-items-center text-black border p-2 rounded  me-2 mb-2'>
                    <strong className='text-primary me-1'> Privacy Settings : </strong>

                    <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>
                      {formData?.subModel?.accountVisibility || 'N/A'}
                    </span>
                  </span>
                </div>
                {/* 
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>Education Level</label>

                  <div className='col-lg-8 fv-row'>
                    <span className='fw-bold fs-6'>
                      {formData?.subModel.educationLevel?.nameEn || 'N/A'}
                    </span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>Privacy Settings</label>

                  <div className='col-lg-8 fv-row'>
                    <span className='fw-bold fs-6'>
                      {formData?.subModel?.accountVisibility|| 'N/A'}
                    </span>
                  </div>
                </div>
                

                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>Majors</label>

                  <div className='col-lg-8'>
                    {formData?.subModel.majors?.map((item: any) => {
                      return <li className='fw-bolder fs-6 text-dark'>{item.nameEn} </li>
                    })}
                  </div>
                </div>

                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>Industries</label>

                  <div className='col-lg-8'>
                    {formData?.subModel.industries?.map((item: any) => {
                      return <li className='fw-bolder fs-6 text-dark'>{item.nameEn} </li>
                    })}
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>Enrolled in high school</label>

                  <div className='col-lg-8 fv-row'>
                    <span className='fw-bold fs-6'>
                      {formData?.subModel.enrolledInHighSchool ? 'yes' : 'NO'}
                    </span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>Guidelines</label>

                  <div className='col-lg-8 fv-row'>
                    <span className='fw-bold fs-6'>
                      {formData?.subModel.agreeToTerms ? 'yes' : 'NO'}
                    </span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>Verified</label>

                  <div className='col-lg-8 fv-row'>
                    <span className='fw-bold fs-6'>{formData?.verified ? 'yes' : 'NO'}</span>
                  </div>
                </div> */}
              </div>
            </div>
          )}
          {active === 1 && (
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
              <div className='card-body p-9'>
                <div className='row mb-7'>
                  <MentorShipSessionTable list={sessions} />
                </div>
                <div className='d-flex justify-content-between '>
                  <div className='d-flex justify-end my-6'>
                    <Pagination
                      totalCount={totalPages}
                      onPageChange={handlePaginationChange}
                      currentPage={currentPage}
                    />
                  </div>
                  <select
                    className='form-select mr-4 mt-4'
                    style={{ width: '75px', height: '50px' }}
                    onChange={handleCountChange}
                    value={pageSize}
                  >
                    <option value='10'>10</option>
                    <option value='20'>20</option>
                    <option value='30'>30</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </div>
              </div>
            </div>
          )}
          {active === 2 && (
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
              <div className='card-body p-9'>
                {/* <div className='row mb-7'>
                  <MentorShipAvailability list={formData?.subModel?.availabilityTime} />
                  <div className='d-flex justify-content-between'></div>
                </div> */}
                <AddAvailabilityTimeMentor user={formData} handleGetMentor={handleGetMentor} />
              </div>
            </div>
          )}
          {active === 3 && (
            <div className='card p-9 mb-xl-10' id='kt_profile_details_view'>
              <Analytics rating={formData?.subModel.rating} id={id} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
