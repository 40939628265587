import {ChangeEvent, useEffect, useState} from 'react'
import {IDomainModel} from './module.interface'
import {PageTitle} from '../../../../components/pageTitle'
import {DataForm} from './components/data-form'
import {getDomain, updateDomain} from '../../../../services/domainServices'
import {useNavigate, useParams} from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
export const UpdateDomain = () => {
  const [disabled, setdisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)

  const navigate = useNavigate()
  const {id} = useParams()

  // const [name, setName] = useState<string>('')
  const [formData, setFormData] = useState<IDomainModel>({
    name: '',
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((_data) => ({..._data, [e.target.name]: e.target.value}))
    setdisabled(false)
  }

  useEffect(() => {
    if (id) {
      DomainFn()
    }
  }, [id])

  const DomainFn = async () => {
    const response = await getDomain(id)
    const data = {
      name: response.data.data.name
    }
    setFormData(data)
  }

  const onClickSubmitBtn = (e: any) => {
    e.preventDefault()
    updateDomainFn()
  }

  const updateDomainFn = async () => {
    setLoading(true)
    const response = await updateDomain(id, formData)
    if (response.status == 200) {
      toast.success('Done successfully !', {
         position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
       theme: "colored",
        });
      goToDomain()
      setLoading(false)
    } else {
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setLoading(false)
    }
   }

  const goToDomain = () => {
    navigate('/domain')
  }

  const breadCrampList = [
    {
      name: 'Domain',
      route: '/domain',
    },
  ]

  return (
    <>
       <ToastContainer/>
      <PageTitle breadCrampList={breadCrampList} active='Update' />
      <div className='w-75 m-auto'>
        <DataForm
          onClickSubmit={onClickSubmitBtn}
          handleChange={handleChange}
          data={formData}
          ButtonText='Update'
          Show={disabled}
          loading={loading}

        />
      </div>
    </>
  )
}
