import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Table} from './components/table'
import {PageTitle} from '../../../components/pageTitle'
import {getAllAdmins} from '../../../services/admin'
import {searchInTable} from '../../../utils/useSearchTable'
import SearchInput from '../../../components/SearchInput'
import Pagination from '../../../components/Pagination'

export const Admin = () => {
  const [adminsList, setAdminsList] = useState<Array<any>>([])
  const [loading, setLoading] = useState<any>(false)
  const [searchValue, setSearchValue] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)

  const handleSearchChange = (e: any) => {
    const inputValue = e.target.value
    setSearchValue(inputValue)
    const filteredRows = searchInTable(inputValue, adminsList)
    setFilteredData(filteredRows)
  }

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleCountChange = (e: any) => {
    setPageSize(e.target.value)
    listAllAdmins(e.target.value)
  }

  const listAllAdmins = async (size: any) => {
    setLoading(true)
    const response = await getAllAdmins(currentPage,(size?size:pageSize))
    response.data.data.wholeModelDataCount? setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / (size?size:pageSize))):setTotalPages(1)
    setAdminsList(response.data.data)
    setFilteredData(response.data.data)
    setLoading(false)
  }


  useEffect(() => {
    listAllAdmins(pageSize)
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='All Admins List' />
        <Link to='/admins/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
      
      {/* <SearchInput model={'admin'} setLoading={setLoading} setDataResult={setDataResult} ListAll={listAllAdmins} /> */}

          <Table list={adminsList} listALl={listAllAdmins} skelton={loading}/>

          <div className='d-flex justify-content-between'>
         
              <>
                <div className='flex justify-end my-6'>
                  <Pagination
                    totalCount={totalPages}
                    onPageChange={handlePaginationChange}
                    currentPage={currentPage}
                  />
                </div>
                <select
                  className='form-select mr-4 mt-4'
                  style={{width: '75px', height: '50px'}}
                  onChange={handleCountChange}
                  value={pageSize}
                >
                  <option value='10'>10</option>
                  <option value='20'>20</option>
                  <option value='30'>30</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                </select>
              </>
            
          </div>
        </>
     
  )
}
