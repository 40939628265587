import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Table} from './components/table'
import {IDidYouGetPaid} from './module.interface'
import {PageTitle} from '../../../../components/pageTitle'
import {getAllDidYouGetPaid} from '../../../../services/didYouGetPaid'

export const DidYouGetPaid = () => {
  const [didYouGetPaidList, setDidYouPaidList] = useState<Array<IDidYouGetPaid>>([])
  const [loading, setLoading] = useState<any>(false)

  const listAllIDidYouGetPaid = async () => {
    setLoading(true)

    const response = await getAllDidYouGetPaid()
    setDidYouPaidList(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    listAllIDidYouGetPaid()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Did You Get Paid' />
        <Link to='/did-you-get-paid/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>

      <Table list={didYouGetPaidList} listALl={listAllIDidYouGetPaid} />
    </>
  )
}
