import ErrorMessage from '../../../../components/ErrorMessage'

type Props = {
  onClickSubmit: any
  handleChange: any
  data: any
  ButtonText: string
  Show: any
  loading: boolean
  isSubmitted: boolean
}

export const DataForm = (props: Props) => {
  const {onClickSubmit, handleChange, data, ButtonText, Show, loading, isSubmitted} = props

  return (
    <form className='form-control' onSubmit={onClickSubmit}>
      <div className='input-data my-3'>
        <label htmlFor='order' className='fs-5 me-3'>
        <span className='text-danger'>*</span>  Order 
        </label>
        <input
          type='number'
          name='order'
          className='form-control'
          value={data.order}
          onChange={handleChange}
        />
        {isSubmitted && !data?.order && <ErrorMessage errorMsg='order' />}
      </div>
      <div className='input-data my-3'>
        <label htmlFor='nameEn' className='fs-5 me-3'>
        <span className='text-danger'>*</span>    Name English 
        </label>
        <input
          type='text'
          name='nameEn'
          className='form-control'
          value={data.nameEn}
          onChange={handleChange}
        />
        {isSubmitted && !data?.nameEn && <ErrorMessage errorMsg='Name in English' />}
      </div>
      <div className='input-data my-3'>
        <label htmlFor='nameAr' className='fs-5 me-3'>
        <span className='text-danger'>*</span>   Name Arabic 
        </label>
        <input
          type='text'
          name='nameAr'
          className='form-control'
          value={data.nameAr}
          onChange={handleChange}
        />
        {isSubmitted && !data?.nameAr && <ErrorMessage errorMsg='Name in Arabic' />}
      </div>
      <div className='input-data my-3'>
        <label htmlFor='educationType' className='fs-5 me-3'>
        <span className='text-danger'>*</span>   Education Type 
        </label>
        <select
          className='form-select'
          aria-label='Select example'
          name='educationType'
          onChange={handleChange}
          value={data?.educationType}
        >
          <option disabled selected>
            -- select an option --
          </option>
          <option value={'master'}>Master</option>
          <option value={'undergraduate'}>Undergraduate</option>
          <option value={'phd'}>PhD</option>
        </select>
        {isSubmitted && !data?.educationType && <ErrorMessage errorMsg='Education Type' />}
      </div>

      <div className='input-data my-3'>
        <div className='mb-10'>
          <div className='form-check form-check-custom form-check-solid form-check-sm'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={data.enrolledHighSchool}
              value={data.enrolledHighSchool}
              name='enrolledHighSchool'
              onChange={handleChange}
         
            />
            <label className='form-check-label fs-5 me-3' htmlFor='enrolledHighSchool'>
            <span className='text-danger'>*</span>  Enrolled in Higher Education
            </label>
            {isSubmitted && !data?.enrolledHighSchool && (
              <ErrorMessage errorMsg='enrolledHighSchool' />
            )}
          </div>
        </div>
      </div>

      <div className='d-flex justify-content-end'>
        <button className='btn btn-primary' disabled={Show} type='submit'>
          {!loading && ButtonText}
          {loading && (
             <div className="spinner-border text-light" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
          )}
        </button>
      </div>
    </form>
  )
}
