import {ChangeEvent, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../components/pageTitle'
import {createMeetingDuration} from '../../../services/meetingDuration'
import {DataForm} from './components/data-form'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
export default function CreateMeetingDuration() {
  const [formData, setFormData] = useState<any>({
    duration: null,
  })

  const [disabled, setDisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)
  const [isSubmitted, setIsSubmitted] = useState<any>(false)
  const req = ['duration']
  const navigate = useNavigate()

  const breadCrampList = [
    {
      name: 'Meeting Duration',
      route: '/universal-fields',
    },
  ]

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((_data:any) => ({..._data, [e.target.name]: e.target.value}))
    setDisabled(false)
  }

  const handleSubmit = (e: any) => {
    setIsSubmitted(true)
    e.preventDefault()
    req.every((key) => key in formData) && newDuration()
  }

  const newDuration = async () => {
    setLoading(true)
    const response = await createMeetingDuration(formData.duration)
    if (response.status === 201) {
      toast.success('Done successfully !', {
         position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
       theme: "colored",
        });
      navigate('/meeting-duration')
      setLoading(false)
    } else {
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setLoading(false)
    }
  }

  return (
    <>
       <ToastContainer/>
       <div className='page-header mb-6'>
       <PageTitle breadCrampList={breadCrampList} active='Create' />
       </div>
   
      <div className=' w-75 m-auto'>
        <DataForm
          onClickSubmit={handleSubmit}
          handleChange={handleChange}
          data={formData}
          ButtonText='Create'
          Show={disabled}
          loading={loading}
          isSubmitted={isSubmitted}
        />
      </div>
    </>
  )
}
