import {MouseEventHandler, useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Delete} from '../delete'
import {IMentoringProgram} from '../module.interface'
import {
  deleteMentoringProgramOptions,
  updateMeetingStatusFromAdmin,
} from '../../../../../services/mentoringProgramOptions'
import moment from 'moment'
import {formatDate} from '../../../../../utils/DateFormatter'
import {exportTableToExcel} from '../../../../../utils/export'
import TableBodyLoading from '../../../../../components/tableBodyLoading'

type Props = {
  list: IMentoringProgram[]
  listALl: any
  skelton: any
}

export const Table = (props: Props) => {
  const {list, listALl, skelton} = props
  const [loading, setLoading] = useState<any>(false)
  const [loadingDeclined, setLoadingDeclined] = useState<any>(false)
  const [showModal, setShowModal] = useState(false)
  const [id, setId] = useState<string>('')

  const updateMeetingStatus = async (_id: any, status: string) => {
    setId(_id)
    status === 'declined' ? setLoadingDeclined(true) : setLoading(true)
    const response = await updateMeetingStatusFromAdmin(_id, status)
    if (response) {
      status === 'declined' ? setLoadingDeclined(false) : setLoading(false)
      listALl()
    }
  }

  const [shownData, setShownData] = useState<any>([])
  type Data = typeof list
  type SortKeys = keyof Data[0]
  type SortOrder = 'ascn' | 'desc'

  function sortData({
    tableData,
    sortKey,
    reverse,
  }: {
    tableData: Data
    sortKey: any
    reverse: boolean
  }) {
    if (!sortKey) return tableData

    const sortedData = shownData.sort((a: any, b: any) => {
      return a[sortKey] > b[sortKey] ? 1 : -1
    })

    if (reverse) {
      return sortedData.reverse()
    }

    return sortedData
  }

  function SortButton({
    sortOrder,
    columnKey,
    sortKey,
    onClick,
  }: {
    sortOrder: SortOrder
    columnKey: any
    sortKey: SortKeys
    onClick: MouseEventHandler<HTMLButtonElement>
  }) {
    return (
      <button
        onClick={onClick}
        className={`${
          sortKey === columnKey && sortOrder === 'desc' ? 'sort-button sort-reverse' : 'sort-button'
        }`}
      >
        ▲
      </button>
    )
  }
  const [sortKey, setSortKey] = useState<any>('name')
  const [sortOrder, setSortOrder] = useState<SortOrder>('ascn')

  const sortedData = useCallback(
    () => sortData({tableData: shownData, sortKey, reverse: sortOrder === 'desc'}),
    [list, sortKey, sortOrder]
  )

  function changeSort(key: SortKeys) {
    setSortOrder(sortOrder === 'ascn' ? 'desc' : 'ascn')
    setSortKey(key)
  }
  const headers: {key: any; label: string}[] = [
    {key: 'name', label: 'Applicant Name'},
    {key: 'email', label: 'Email'},
    {key: 'date', label: 'Application Date'},
    {key: 'topic', label: 'Mentorship topic'},
    {key: 'deadline', label: 'Mentor/Potential Mentor'},
    {key: 'mentorRating', label: 'Mentor rating'},
    {key: 'MenteeRating', label: 'Mentee rating'},
  ]
  useEffect(() => {
    const data = sortedData().map((item: any) => ({
      _id: item?._id,
      name: item?.name,
      applicantId: item?.applicantId,
      email: item.email,
      topic: item.topic,
      date: item?.createdAt,
      mentorId: item?.mentorId,
      mentor: item?.mentor,
      approved: item?.approved,
      mentorRating: item?.mentorRating,
      menteeRating: item?.menteeRating,
    }))
    setShownData(data)
  }, [sortedData])
  useEffect(() => {
    if (list) {
      const data = list.map((item: any) => ({
        _id: item?._id,
        applicantId: item?.user1?.user?._id,
        name: item?.user1?.user?.subModel?.firstName + ' ' + item?.user1?.user?.subModel?.lastName,
        email: item?.user1?.user?.email || 'N/A',
        topic: item?.mentorShipSession?.mentorShipType?.nameEn || 'N/A',
        date: moment(item.date).format('DD/MM/yyyy'),
        mentorId: item?.user2?.user?._id,
        mentor: item?.user2?.user?.subModel?.firstName
          ? item?.user2?.user?.subModel?.firstName + ' ' + item?.user2?.user?.subModel?.lastName
          : 'N/A',
        approved: item?.mentorShipSession?.approved,

        mentorRating: item?.mentorShipSession?.mentorRating?.rate,
        menteeRating: item?.mentorShipSession?.menteeRating?.rate,
      }))
      setShownData(data)
    }
  }, [list])
  const handleExport = (id: string) => {
    exportTableToExcel(id, 'mentoring-program.xlsx')
  }
  return (
    <>
      <div>
        <a
          className='text-danger d-flex justify-content-end mx-3 mb-4 align-items-center'
          style={{fontSize: '14px', fontWeight: 'bold', cursor: 'pointer'}}
          onClick={() => {
            handleExport(`mentoring-program-list`)
          }}
        >
          Export table <i className='bi bi-arrow-bar-up text-danger mx-2'></i>
        </a>
        <div className={`card `}>
          {/* begin::Body */}
          <div className='card-body py-3 '>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table
                className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
                id='mentoring-program-list'
              >
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    {/* <th className='min-w-150px'>Applicant Name</th>
                    <th className='min-w-140px'>Email</th>
                    <th className='min-w-120px'>Application Date</th>
                    <th className='min-w-120px'>Mentorship topic</th>
                  
                    <th className='min-w-120px'>Mentor/Potential Mentor</th> */}
                    {headers.map((row) => {
                      return (
                        <td
                          className={`cursor-pointer ${sortKey === row.key ? 'text-primary' : ''}`}
                          onClick={() => changeSort(row.key)}
                          {...{
                            sortOrder,
                            sortKey,
                          }}
                        >
                          {row.label}
                          <SortButton
                            columnKey={row.key}
                            onClick={() => changeSort(row.key)}
                            {...{
                              sortOrder,
                              sortKey,
                            }}
                          />
                        </td>
                      )
                    })}
                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {skelton ? (
                  <TableBodyLoading count={5} />
                ) : (
                  shownData.map((item: any) => {
                    return (
                      <>
                        {/* begin::Table body */}
                        <tbody>
                          <tr>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <div className='text-dark  text-hover-primary fs-6 text-capitalize'>
                                    <Link
                                      className='text-black text-capitalize fw-bold'
                                      to={`/student/details/${item?.applicantId}/${item.name}`}
                                    >
                                      {' '}
                                      {item.name}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='text-dark  text-hover-primary d-block fs-6 '>
                                {item?.email}
                              </div>
                            </td>
                            <td>
                              <div className='text-dark  text-hover-primary d-block fs-6'>
                                {item?.date}
                              </div>
                            </td>
                            <td>
                              <div className='text-dark  text-hover-primary d-block fs-6'>
                                {item?.topic}
                              </div>
                            </td>

                            <td>
                              <div className='text-dark  text-hover-primary d-block fs-6'>
                                {item.mentor !== 'N/A' ? (
                                  <Link
                                    className='text-black text-capitalize fw-bold'
                                    to={`/mentor/details/${item?.mentorId}/${item.mentor}`}
                                  >
                                    {' '}
                                    {item.mentor}
                                  </Link>
                                ) : (
                                  item.mentor
                                )}
                              </div>
                            </td>

                            <td>
                              <div className='text-dark  text-hover-primary d-block fs-6'>
                                {item?.mentorRating}
                              </div>
                            </td>

                            <td>
                              <div className='text-dark  text-hover-primary d-block fs-6'>
                                {item?.menteeRating}
                              </div>
                            </td>

                            <td>
                              <div className='d-flex justify-content-end '>
                                {item?.approved === 'pending' && (
                                  <>
                                    <button
                                      onClick={() => updateMeetingStatus(item._id, 'approved')}
                                      // to={`/mentoring-program-options/update/${item._id}`}
                                      className='btn  btn-bg-success text-white btn-sm me-1 me-2'
                                    >
                                      {loading && item._id === id ? (
                                        <div className='spinner-border text-light' role='status'>
                                          <span className='sr-only'>Loading...</span>
                                        </div>
                                      ) : (
                                        'Approve'
                                      )}{' '}
                                    </button>

                                    <button
                                      onClick={() => updateMeetingStatus(item._id, 'declined')}
                                      className='btn btn-bg-danger text-white btn-sm me-2'
                                    >
                                      {loadingDeclined && item._id === id ? (
                                        <div className='spinner-border text-light' role='status'>
                                          <span className='sr-only'>Loading...</span>
                                        </div>
                                      ) : (
                                        'Reject'
                                      )}
                                    </button>
                                  </>
                                )}
                                {item?.approved === 'approved' && (
                                  <>
                                    <span className='d-flex align-items-center justify-content-center bg-green-100 text-success px-4 btn-sm '>
                                      {' '}
                                      Approved{' '}
                                    </span>
                                  </>
                                )}
                                {item?.approved === 'declined' && (
                                  <>
                                    <span className='d-flex align-items-center justify-content-center bg-green-100 text-danger px-4 btn-sm '>
                                      {' '}
                                      Declined{' '}
                                    </span>
                                  </>
                                )}
                                <Link
                                  // onClick={() => updateMeetingStatus(item._id, 'approved')}
                                  to={`/mentorship-program/${item._id}`}
                                  className='btn  btn-bg-info text-white btn-sm me-1'
                                >
                                  view
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        {/* end::Table body */}
                      </>
                    )
                  })
                )}
              </table>
              {/* <div className='d-flex flex-stack flex-wrap pt-10'>
                <div className='fs-6  text-gray-700'>Showing 1 to 10 of 50 entries</div>

                <ul className='pagination'>
                  <li className='page-item previous'>
                    <a href='#' className='page-link'>
                      <i className='previous'></i>
                    </a>
                  </li>

                  <li className='page-item active'>
                    <a href='#' className='page-link'>
                      1
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      2
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      3
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      4
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      5
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      6
                    </a>
                  </li>

                  <li className='page-item next'>
                    <a href='#' className='page-link'>
                      <i className='next'></i>
                    </a>
                  </li>
                </ul>
              </div> */}
              {/* end::Table */}

              {/* {showModal && <Delete show={showModal} onCancel={cancelFn} onDelete={delteFn} loading={loading} />} */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      </div>
    </>
  )
}
