import {useState} from 'react'
import {DataForm} from './components/data-form'
import {useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../components/pageTitle'
import {createUser} from '../../../services/user'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type Props = {
  model?: any
}

export const CreateUser = (props: Props) => {
  const {model} = props
  const [formData, setFormData] = useState<any>()
  const [userData, setUserData] = useState<any>({model: model})
  const [loading, setLoading] = useState<any>(false)
  const [isSubmitted, setIsSubmitted] = useState<any>(false)
  const [userType, setUserType] = useState('')
  console.log(props)
  const req = {
    Company: [
      'password',
      'name',
      'webSite',
      'headQuarters',
      'companySize',
      'phoneNumber',
      'email',
      // 'areaOfInterest',
    ],
    Mentor: ['firstName', 'lastName', 'email', 'currentTitle', 'password', 'linkedInUrl', 'about'],
    Student: ['firstName', 'lastName', 'email', 'password'],
    Institution: ['name', 'password', 'country', 'city', 'email'],
  }
  type ObjectKey = keyof typeof req
  const navigate = useNavigate()

  const handleChange = (e: any, name = '') => {
    if (name !== '') {
      setFormData((_data: any) => ({..._data, [name]: e}))
      setUserData((_data: any) => ({..._data, [name]: e}))
    } else {
      setFormData((_data: any) => ({..._data, [e.target.name]: e.target.value}))
      setUserData((_data: any) => ({..._data, [e.target.name]: e.target.value}))
      // setdisabled(false)
      if (e.target.type === 'checkbox') {
        setFormData((_data: any) => ({..._data, [e.target.name]: e.target.checked}))
        setUserData((_data: any) => ({..._data, [e.target.name]: e.target.checked}))
      }
    }
  }

  const onClickSubmitBtn = (e: any) => {
    setIsSubmitted(true)
    e.preventDefault()
    const formModel =
      model === 'users'
        ? userType.replace(userType.charAt(0), userType.charAt(0).toUpperCase())
        : model
    console.log(req)
    console.log(formModel)
    console.log(formData)
    if (req[formModel as ObjectKey].every((key: any) => key in formData && formData[key] !== '')) {
      newUser()
    } else {
      toast.error('Please fill all the required fields', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })
    }
  }

  const mapData = (data: any) => {
    const formDataBody = new FormData()
    if (model != 'users') {
      formDataBody.append('model', model)
    }
    for (const property in data) {
      if (typeof data[property] === 'string' || data[property] === 'boolean') {
        formDataBody.append(property, data[property])
      } else if (property === 'socialMediaLinks') {
        let final: any = []
        for (const item in data[property]) {
          final.push(data[property][item])
        }
        formDataBody.append(property, JSON.stringify(final))
      } else if (
        property === 'logo' ||
        property === 'coverPhoto' ||
        property === 'banner' ||
        property === 'profileImage'
      ) {
        formDataBody.append(property, data[property])
      } else {
        formDataBody.append(property, JSON.stringify(data[property]))
      }
    }

    return formDataBody
  }
  const getNavigationLink = () => {
    if (model) {
      switch (model.toLocaleLowerCase()) {
        case 'company':
          return '/companies'
        case 'mentor':
          return '/mentors'
        case 'institution':
          return '/institution'

        case 'student':
          return '/all-users'
        default:
          return '/all-users'
      }
    }
  }

  const newUser = async () => {
    const link: any = getNavigationLink()
    setLoading(true)
    const response = await createUser(model, mapData(formData))
    if (response.status === 201) {
      toast.success('Done successfully !', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })
      navigate(link)
      setLoading(false)
    } else {
      setLoading(false)
      toast.error(response.data.error, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })
    }
  }

  function getbreadCrampList() {
    if (model) {
      switch (model.toLocaleLowerCase()) {
        case 'company':
          return [
            {
              name: 'Companies',
              route: '/companies',
            },
          ]
        case 'mentor':
          return [
            {
              name: 'Mentors',
              route: '/mentors',
            },
          ]
        case 'institution':
          return [
            {
              name: 'Institution',
              route: '/institution',
            },
          ]
        case 'student':
          return [
            {
              name: 'Students & Professionals',
              route: '/student',
            },
          ]
      }
    }
  }

  return (
    <>
      <ToastContainer />
      <PageTitle breadCrampList={getbreadCrampList()} active='Create' />
      <div className=' w-75 m-auto'>
        <DataForm
          onClickSubmit={onClickSubmitBtn}
          handleChange={handleChange}
          data={formData}
          ButtonText='Create'
          setFormData={setFormData}
          loading={loading}
          model={model}
          setUserData={setUserData}
          userData={userData}
          isSubmitted={isSubmitted}
          setUserType={setUserType}
          userType={userType}
          isLoadingData={false}
        />
      </div>
    </>
  )
}
