import React, {ChangeEvent, useState} from 'react'

import {useNavigate} from 'react-router-dom'

import {PageTitle} from '../../../components/pageTitle'
import {createEducationLevel} from '../../../services/educationLevel'
import {DataForm} from './components/data-form'
import {IEducationLevel} from './module.interface'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
export default function CreateEducationLevel() {
  const [formData, setFormData] = useState<IEducationLevel>({
    order: 0,
    nameEn: '',
    nameAr: '',
    enrolledHighSchool: false,
    educationType: '',
  })
  type ObjectKey = keyof typeof formData
  const req = ['order', 'nameEn', 'nameAr', 'enrolledHighSchool', 'educationType']

  const [disabled, setDisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)
  const [isSubmitted, setIsSubmitted] = useState<any>(false)
  const navigate = useNavigate()

  const breadCrampList = [
    {
      name: 'Education Level',
      route: '/education-level',
    },
  ]

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((_data) => ({..._data, [e.target.name]: e.target.value}))
    setDisabled(false)
    if (e.target.name === 'enrolledHighSchool') {
      setFormData((_data) => ({..._data, [e.target.name]: e.target.checked}))
    }
  }

  const handleSubmit = (e: any) => {
    setIsSubmitted(true)
    e.preventDefault()
    req.every((key) => formData.hasOwnProperty(key) && formData[key as ObjectKey] !== '') &&
      newEducationLevel()
  }

  const newEducationLevel = async () => {
    setLoading(true)
    const response = await createEducationLevel(
      formData.order,
      formData.nameEn,
      formData.nameAr,
      formData.enrolledHighSchool,
      formData.educationType
    )
    if (response.status === 201) {
      toast.success('Done successfully !', {
         position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
       theme: "colored",
        });
      navigate('/education-level')
      setLoading(false)
    } else {
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setLoading(false)
    }
  }

  return (
    <>
       <ToastContainer/>
      <PageTitle breadCrampList={breadCrampList} active='Create' />
      <div className=' w-75 m-auto'>
        <DataForm
          onClickSubmit={handleSubmit}
          handleChange={handleChange}
          data={DataForm}
          ButtonText='Create'
          Show={disabled}
          loading={loading}
          isSubmitted={isSubmitted}
        />
      </div>
    </>
  )
}
