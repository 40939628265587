
import { Link } from 'react-router-dom'
import { PageTitle } from '../../../../components/pageTitle'
import { City } from '../../city'
import { Country } from '../../country'
import { Currency } from '../../currency'
import { TypeOfWork } from '../type-of-work'
import { WorkType } from '../../work-type'
import { JobRole } from '../../job-role'
import { JobType } from '../../job-type'
import { Language } from '../../language'
import { Major } from '../../major'
import { CompanyGuideLine } from '../../company-guide-line'
import { Industry } from '../../industry'
import { SubIndustry } from '../../sub-industry'
import { MeetingDuration } from '../../meetingDuration'
import { EducationLevel } from '../../educationLevel'
import { NotificationsMethod } from '../../notificationMethod'
import { NotificationsType } from '../../notificationType'
import { CompanyRelated } from '../company-related'
export const UniversalFields = () => {
  return (
    <>
      <div className='d-flex justify-content-center pb-4 mb-8 page-header'>
        <PageTitle active='Universal Fields' />

      </div>
      <div className='mt-8'>
        <Country />
      </div>
      <div className='mt-8'>
        <City />
      </div>
     
      <div className='mt-8'>
        <Currency />
      </div>
      {/* <div className='mt-8'>
        <TypeOfWork />
      </div> */}
      <div className='mt-8'>
        <WorkType />
      </div>
      <div className='mt-8'>
        <JobRole />
      </div>
      <div className='mt-8'>
        <JobType />
      </div>
      <div className='mt-8'>
        <Language />
      </div>
      <div className='mt-8'>
        <Major />
      </div>
     
      <div className='mt-8'>
        <Industry />
      </div>
      <div className='mt-8'>
        <SubIndustry />
      </div>
      <div className='mt-8'>
        <MeetingDuration />
      </div>
      <div className='mt-8'>
        <EducationLevel />
      </div>
      <div className='mt-8'>
        <NotificationsMethod />
      </div>
      <div className='mt-8'>
        <NotificationsType />
      </div>
      <div className='mt-8'>
      <CompanyRelated/>
      </div>

    </>
  )
}
