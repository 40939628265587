import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllUsers = (model:string,page:any=null,pageSize:number=0) => {
  const pagination =  page!=null && pageSize ? `&page=${page}&pageSize=${pageSize}`: ''
    return axios
      .get(`${BASEURL}user?model=${model}${pagination}`)
      .then((res: any) => res)
      .catch((err: any) => err?.response)
  }
  export const getAlStudentsInInstitution = (id:any,page:number=0,pageSize:number=0) => {
    const pagination =  page && pageSize ? `?page=${page}&pageSize=${pageSize}`: ''
      return axios
        .get(`${BASEURL}institution/${id}/student-list${pagination}`)
        .then((res: any) => res)
        .catch((err: any) => err?.response)
    }
  
export const getUser = (_id: any) => {
  return axios
    .get(`${BASEURL}user/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateUser = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}user/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
export const approveUpdateUser = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}user/approve-update/${_id}?approved=${data}`,)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createUser = (model:string, payload:any) => {
  return axios
    .post(`${BASEURL}user?model=${model}`, payload)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteUser = (_id: any) => {
  return axios
    .delete(`${BASEURL}user/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}


export const getUserSession =(id:any,isSession:boolean,page:any=null,pageSize:number=0)=>{
   const pagination =  page!=null && pageSize ? `?page=${page}&pageSize=${pageSize}`: ''
  return axios
    .get(`${BASEURL}meeting?userId=${id}&session=${isSession}${pagination}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
export const getMentorShipProgram =(id:any)=>{
 return axios
   .get(`${BASEURL}meeting/${id}`)
   .then((res: any) => res)
   .catch((err: any) => err?.response)
}

export const getSessions =(isSession:boolean,page:any=null,pageSize:any=null)=>{
  const pagination =  page!=null && pageSize ? `&page=${page}&pageSize=${pageSize}`: ''
  return axios
    .get(`${BASEURL}meeting${isSession?'?session=true':''}${pagination}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
export const getUserAttachments =(id:any)=>{
  
  return axios
    .get(`${BASEURL}userAttachment/user/${id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
export const getUserAttachmentsBending =()=>{
  
  return axios
    .get(`${BASEURL}userAttachment?status=pending`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateUserAttachmentsStatus =(id:any,status:any)=>{
  
  return axios
    .patch(`${BASEURL}userAttachment/${id}`,{
      "status": status
    })
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
export const deleteAttachments =(id:any)=>{
  return axios
    .delete(`${BASEURL}userAttachment/${id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
