import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllAdmins = (page:any=null,pageSize:any=null) => {
  const pagination =  page!=null || pageSize !=null? `?page=${page}&pageSize=${pageSize}`: ''
  return axios
    .get(`${BASEURL}admin${pagination}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getAdmin = (_id: any) => {
  return axios
    .get(`${BASEURL}admin/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateAdmin = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}admin/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createAdmin = (firstName: string,lastName: string, password: string, role: string,email:string) => {

  return axios
    .post(`${BASEURL}admin`, {email:email,firstName: firstName,lastName:lastName, password: password, role:role })
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteAdmin = (_id: string) => {
  return axios
    .delete(`${BASEURL}admin/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const adminGetResetPassword = (email:any) => {
  return axios
    .get(
      `${BASEURL}admin/reset-password/${email}`
    )
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const UserResetPassword = (id:any,passwordData: any) => {
  return axios
    .post(
      `${BASEURL}admin/reset-password/${id}`,
      passwordData
    )
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const ResetPasswordForUser = (email:any) => {
  return axios
    .get(
      `${BASEURL}user/reset-password/${email}`
    )
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const ResetPasswordForAdmin = (email:any) => {
  return axios
    .get(
      `${BASEURL}admin/reset-password/${email}`
    )
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};