import { FC, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'

import { User } from '../pages/modules/user'
import { UpdateUser } from '../pages/modules/user/update'
import { ViewStudent } from '../pages/modules/student/view'
import { ViewCompany } from '../pages/modules/company/view'
import { NotificationsType } from '../pages/modules/notificationType'
import CreateReview from '../pages/modules/review/create'
import { CreateUser } from '../pages/modules/user/create'

const CompaniesRoutes = () => {

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                <Route
                    path='/companies'
                    element={
                        <SuspensedView>
                            <User model='Company' />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/company/details/:id/:name'
                    element={
                        <SuspensedView>
                            <ViewCompany />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/Company/update/:id'
                    element={
                        <SuspensedView>
                            <UpdateUser model={'Company'} />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/Company/create/'
                    element={
                        <SuspensedView>
                            <CreateUser model={'Company'} />
                        </SuspensedView>
                    }
                />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { CompaniesRoutes }
