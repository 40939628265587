import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Table} from './components/table'
import {IDidYouGetOffer} from './module.interface'
import {PageTitle} from '../../../../components/pageTitle'
import {getAllDidYouGetOffer} from '../../../../services/didYouGetOffer'
import { KeyWays } from '../../key-ways'
import { DidYouGetPaid } from '../did-you-get-paid'
import { FindingJob } from '../resources-finding-job'

export const FormManagement = () => {
  const [didYouGetOfferList, setDidYouOfferList] = useState<Array<IDidYouGetOffer>>([])
  const [loading, setLoading] = useState<any>(false)

  const listAllIDidYouGetOffer = async () => {
    setLoading(true)
    const response = await getAllDidYouGetOffer()
    setDidYouOfferList(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    listAllIDidYouGetOffer()
  }, [])

  return (
    <>
   <div className='d-flex justify-content-start pb-4 mb-8 page-header'>
   <PageTitle active=' Write a review ' />
       
      </div>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Did You Get Offer' />
        <Link to='/did-you-get-offer/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
      {loading ? (
        <div className="spinner-border text-light" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      ) : (
        <Table list={didYouGetOfferList} listALl={listAllIDidYouGetOffer} />
      )}
      <div className='mt-8'>
      <KeyWays/>
      </div>
      <div className='mt-8'>
      <DidYouGetPaid/>
      </div>
      <div className='mt-8'>
        <FindingJob />
      </div>
    </>
  )
}
