import {useEffect, useState} from 'react'
import Multiselect from 'multiselect-react-dropdown'
import Addresses from '../../../../components/Inputs/Addresses'
import {getAllAreaInterest} from '../../../../services/areaInterest'
import {getAllCities} from '../../../../services/city'
import {getAllCountries} from '../../../../services/Country'
import {getAllEducationLevels} from '../../../../services/educationLevel'
import {getAllIndustry} from '../../../../services/industry'
import {getAllLanguage} from '../../../../services/language'
import {getAllMajor} from '../../../../services/major'
import {getAllMentorShipType} from '../../../../services/mentorShipType'
import {getAllUsers} from '../../../../services/user'
import AvailabilityTime from './AvailabilityTime'
import ErrorMessage from '../../../../components/ErrorMessage'
import {formatDate} from '../../../../utils/DateFormatter'

type Props = {
  handleChange: any
  data: any
  action?: any
  isSubmitted: boolean
  formData?: any
  isLoadingData: boolean
}

export const MentorDataForm = (props: Props) => {
  const {handleChange, data, action, isSubmitted, formData, isLoadingData} = props
  const [industryList, setIndustryList] = useState<any>([])
  const [countryList, setCountryList] = useState<any>([])

  const [cityList, setCityList] = useState<any>([])
  const [mentorShipTypes, setMentorShipTypes] = useState<any>([])
  const [major, setMajor] = useState<any>([])
  const [languages, setLanguages] = useState<any>([])
  const [areaOfInterest, setAreaOfInterest] = useState<any>([])
  const [educationLevels, setEducationLevels] = useState<any>([])
  const [almaMaterList, setAlmaMaterList] = useState<any>([])
  const [companiesList, setCompaniesList] = useState<any>([])

  useEffect(() => {
    getIndustriesList()
    getCountriesList()
    getCitiesList()
    getMentorShipTypeList()
    getMajorList()
    getLangList()
    getEducationList()
    getAreaOfInterestList()
    getAlmaMaterList()
    getCompaniesList()
  }, [])

  const getIndustriesList = async () => {
    const response = await getAllIndustry()
    setIndustryList(response.data.data)
  }
  const getCountriesList = async () => {
    const response = await getAllCountries()

    setCountryList(response.data.data.queryResult)
  }
  const getCitiesList = async () => {
    const response = await getAllCities()
    setCityList(response.data.data.queryResult)
  }

  const getMentorShipTypeList = async () => {
    const response = await getAllMentorShipType()
    setMentorShipTypes(response.data.data)
  }
  const getMajorList = async () => {
    const response = await getAllMajor()
    setMajor(response.data.data.queryResult)
  }
  const getLangList = async () => {
    const response = await getAllLanguage()
    setLanguages(response.data.data.queryResult)
  }
  const getEducationList = async () => {
    const response = await getAllEducationLevels()
    setEducationLevels(response.data.data.queryResult)
  }
  const getAreaOfInterestList = async () => {
    const response = await getAllAreaInterest()
    setAreaOfInterest(response.data.data.queryResult)
  }

  const getAlmaMaterList = async () => {
    const response = await getAllUsers('Institution')
    setAlmaMaterList(response.data.data.queryResult)
  }
  const getCompaniesList = async () => {
    const response = await getAllUsers('Company')
    setCompaniesList(response.data.data.queryResult)
  }

  return (
    <>
      {isLoadingData ? (
        <div className='spinner-border text-dark' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      ) : (
        <div className=' d-flex row'>
          <div className='input-data my-3 col-md-4 col-12'>
            <label htmlFor='firstName' className='fs-5 me-3'>
              <span className='text-danger'>*</span> First Name
            </label>
            <input
              type='text'
              name='firstName'
              className='form-control'
              defaultValue={data?.subModel?.firstName}
              onChange={handleChange}
            />
            {isSubmitted && !(data?.firstName || data?.subModel?.firstName) && (
              <ErrorMessage errorMsg='This field' />
            )}
          </div>

          <div className='input-data my-3 col-md-4 col-12'>
            <label htmlFor='middleName' className='fs-5 me-3'>
              Middle Name
            </label>
            <input
              type='text'
              name='middleName'
              className='form-control'
              defaultValue={data?.subModel?.middleName}
              onChange={handleChange}
            />
          </div>
          <div className='input-data my-3 col-md-4 col-12'>
            <label htmlFor='lastName' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Last Name
            </label>
            <input
              type='text'
              name='lastName'
              className='form-control'
              defaultValue={data?.subModel?.lastName}
              onChange={handleChange}
            />
            {isSubmitted && !(data?.lastName || data?.subModel?.lastName) && (
              <ErrorMessage errorMsg='This field' />
            )}
          </div>

          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='currentTitle' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Area of Expertise
            </label>
            <input
              type='text'
              name='currentTitle'
              className='form-control'
              defaultValue={data?.subModel?.currentTitle}
              onChange={handleChange}
            />
            {isSubmitted && !(data?.currentTitle || data?.subModel?.currentTitle) && (
              <ErrorMessage errorMsg='This field' />
            )}
          </div>

          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='linkedInUrl' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Professional Website or LinkedIn Profile
            </label>
            <input
              type='text'
              name='linkedInUrl'
              className='form-control'
              defaultValue={data?.subModel?.linkedInUrl}
              onChange={handleChange}
            />
            {isSubmitted && !(data?.linkedInUrl || data?.subModel?.linkedInUrl) && (
              <ErrorMessage errorMsg='This field' />
            )}
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='email' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Email
            </label>
            <input
              type='email'
              name='email'
              className='form-control'
              defaultValue={data?.email}
              onChange={handleChange}
            />
            {isSubmitted && !data?.email && <ErrorMessage errorMsg='This field' />}
          </div>
          {action !== 'update' && (
            <div className='input-data my-3'>
              <label htmlFor='password' className='fs-5 me-3'>
                <span className='text-danger'>*</span> Password
              </label>
              <input
                type='password'
                name='password'
                className='form-control'
                defaultValue={data?.subModel?.password}
                onChange={handleChange}
              />
              {isSubmitted && !data?.password && <ErrorMessage errorMsg='This field' />}
            </div>
          )}
          <div className='input-data my-3 col-md-12 col-12 '>
            <label htmlFor='company' className='fs-5 me-3'>
              Company
            </label>

            <select
              className='form-select'
              aria-label='Select example'
              name='company'
              onChange={(e) =>
                e.target.value != 'null' ? handleChange(e) : handleChange('', 'company')
              }
              defaultValue={data?.subModel?.company}
            >
              <option value={'null'} selected>
                -- No option --
              </option>
              {companiesList.map((item: any) => (
                <option value={item.name} selected={data?.subModel?.company == item.subModel?.name}>
                  {item.subModel.name}
                </option>
              ))}
            </select>
            <label htmlFor='company' className='fs-5 me-3'>
              If your company not in the list you can write it down here
            </label>
            <input
              type='company'
              name='company'
              className='form-control'
              defaultValue={formData?.company ? formData?.company : data?.subModel?.company}
              onChange={handleChange}
            />
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='gender' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Gender
            </label>
            <select
              className='form-select'
              aria-label='Select example'
              name='gender'
              onChange={handleChange}
              defaultValue={data?.subModel?.gender}
            >
              <option disabled selected>
                -- select an option --
              </option>
              <option value='male' selected={data?.subModel?.gender === 'male'}>Male</option>
              <option value='female' selected={data?.subModel?.gender === 'female'}>Female</option>
            </select>
            {isSubmitted && !(data?.gender || data?.subModel?.gender) && (
              <ErrorMessage errorMsg='This field' />
            )}
          </div>

          {/* <div className='input-data my-3'>
            <label htmlFor='mentorShipTypes' className='fs-5 me-3'>
              <span className='text-danger'>*</span>  Mentorship Types
            </label>
            <Multiselect
              displayValue='nameEn'
              onKeyPressFn={function noRefCheck() { }}
              onRemove={function noRefCheck() { }}
              onSelect={function noRefCheck(selectedList: any, selectedItem: any) {
                handleChange(
                  selectedList.map((obj: any) => obj._id),
                  'mentorShipTypes'
                )
              }}
              selectedValues={data?.subModel?.mentorShipTypes}
              options={mentorShipTypes}
            />
            {isSubmitted && !(data?.mentorShipTypes || data?.subModel?.mentorShipTypes) && <ErrorMessage errorMsg='This field' />}
          </div> */}
          <div className='input-data my-3'>
            <label htmlFor='almaMater' className='fs-5 me-3'>
              Alma mater
            </label>
            <select
              className='form-select'
              aria-label='Select example'
              name='almaMater'
              // onChange={handleChange}
              onChange={(e) =>
                e.target.value != 'null' ? handleChange(e) : handleChange('', 'almaMater')
              }
              defaultValue={data?.subModel?.almaMater}
            >
              <option value={'null'}>-- No option --</option>

              {almaMaterList.map((item: any) => (
                <option value={item._id} selected={data?.subModel?.almaMater?._id === item._id}>
                  {item.subModel.name}
                </option>
              ))}
            </select>
          </div>

          <div className='input-data my-3'>
            <label htmlFor='graduationDate' className='fs-5 me-3'>
              Graduation Date
            </label>

            <input
              type='date'
              name='graduationDate'
              className='form-control'
              defaultValue={formatDate(data?.subModel?.graduationDate)}
              onChange={handleChange}
            />
          </div>
          <div className='input-data my-3'>
            <label htmlFor='majors' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Majors
            </label>
            <Multiselect
              displayValue='nameEn'
              onKeyPressFn={function noRefCheck() {}}
              onRemove={function noRefCheck() {}}
              onSelect={function noRefCheck(selectedList: any, selectedItem: any) {
                handleChange(
                  selectedList.map((obj: any) => obj._id),
                  'majors'
                )
              }}
              selectedValues={data?.subModel?.majors}
              options={major}
            />
            {isSubmitted && !(data?.majors || data?.subModel?.majors) && (
              <ErrorMessage errorMsg='This field' />
            )}
          </div>

          <div className='input-data my-3'>
            <label htmlFor='industries' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Industries
            </label>
            <Multiselect
              displayValue='nameEn'
              onKeyPressFn={function noRefCheck() {}}
              onRemove={function noRefCheck() {}}
              onSelect={function noRefCheck(selectedList: any, selectedItem: any) {
                handleChange(
                  selectedList.map((obj: any) => obj._id),
                  'industries'
                )
              }}
              options={industryList}
              selectedValues={data?.subModel?.industries}
            />
            {isSubmitted && !(data?.industries || data?.subModel?.industries) && (
              <ErrorMessage errorMsg='This field' />
            )}
          </div>

          <div className='input-data my-3'>
            <label htmlFor='about' className='fs-5 me-3'>
              <span className='text-danger'>*</span> About
            </label>
            <textarea
              name='about'
              className='form-control'
              defaultValue={data?.subModel?.about}
              onChange={handleChange}
            />
            {isSubmitted && !(data?.about || data?.subModel?.about) && (
              <ErrorMessage errorMsg='This field' />
            )}
          </div>

          <div className='input-data my-3'>
            <label htmlFor='adasdasdas' className='fs-5 me-3'>
              <span className='text-danger'>*</span>Tell us how you want to mentor
            </label>
            <Multiselect
              displayValue='nameEn'
              onKeyPressFn={function noRefCheck() {}}
              onRemove={function noRefCheck(selectedList: any, selectedItem: any) {
                handleChange(null, 'areaOfInterest')
              }}
              onSelect={function noRefCheck(selectedList: any, selectedItem: any) {
                handleChange(
                  selectedList.map((obj: any) => obj._id),
                  'areaOfInterest'
                )
              }}
              options={areaOfInterest}
              selectedValues={data?.subModel?.areaOfInterest}
            />
            {isSubmitted && !(data?.areaOfInterest || data?.subModel?.areaOfInterest) && (
              <ErrorMessage errorMsg='This field' />
            )}
          </div>
          {action && (
            <>
              <div className='input-data my-3'>
                <label htmlFor='video' className='fs-5 me-3'>
                  Video
                </label>
                <input
                  type='text'
                  name='video'
                  className='form-control'
                  defaultValue={data?.subModel?.video}
                  onChange={handleChange}
                />
              </div>

              <div className='input-data my-3'>
                <label htmlFor='mentoringLanguages' className='fs-5 me-3'>
                  Mentoring Languages
                </label>
                <Multiselect
                  displayValue='nameEn'
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck() {}}
                  onSelect={function noRefCheck(selectedList: any, selectedItem: any) {
                    handleChange(
                      selectedList.map((obj: any) => obj._id),
                      'mentoringLanguages'
                    )
                  }}
                  options={languages}
                  selectedValues={data?.subModel?.mentoringLanguages}
                />
              </div>

              <div className='input-data my-3'>
                <label htmlFor='countries' className='fs-5 me-3'>
                  Countries
                </label>
                <Multiselect
                  displayValue='nameEn'
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck() {}}
                  onSelect={function noRefCheck(selectedList: any, selectedItem: any) {
                    handleChange(
                      selectedList.map((obj: any) => obj._id),
                      'countries'
                    )
                  }}
                  selectedValues={data?.subModel?.countries}
                  options={countryList}
                />
              </div>

              {/* <div className='input-data my-3'>
                <label htmlFor='major' className='fs-5 me-3'>
                  Major
                </label>
                <select
                  className='form-select'
                  aria-label='Select example'
                  name='major'
                  onChange={handleChange}
                  defaultValue={data?.subModel?.major}
                >
                  <option disabled selected>
                    -- select an option --
                  </option>
                  {major.map((item: any) => (
                    <option value={item._id} selected={data?.subModel?.major === item._id}>
                      {item.nameEn}
                    </option>
                  ))}
                </select>
              </div> */}

              {/* <div className='input-data my-3'>
                <div className='form-check form-check-custom form-check-solid form-check-sm'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    defaultChecked={data?.subModel?.enrolledInHighSchool}
                    defaultValue={data?.subModel?.enrolledInHighSchool}
                    name='enrolledInHighSchool'
                    onChange={handleChange}
                  />
                  <label className='form-check-label fs-5 me-3' htmlFor='enrolledInHighSchool'>
                    Enrolled In HighSchool
                  </label>
                </div>
              </div> */}
              <div className='input-data my-3'>
                <label htmlFor='educationLevel' className='fs-5 me-3'>
                  Education Level
                </label>
                <select
                  className='form-select'
                  aria-label='Select example'
                  name='educationLevel'
                  onChange={(e) =>
                    e.target.value != 'null' ? handleChange(e) : handleChange('', 'educationLevel')
                  }
                  // defaultValue={data?.subModel?.educationLevel}
                >
                  <option value={'null'}>-- No option --</option>
                  {educationLevels.map((item: any) => (
                    <option
                      value={item._id}
                      selected={data?.subModel?.educationLevel?._id === item._id}
                    >
                      {item.nameEn}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className='input-data my-3'>
                <label htmlFor='graduationDate' className='fs-5 me-3'>
                  Graduation Date
                </label>
                <input
                  type='date'
                  name='graduationDate'
                  className='form-control'
                 
                  defaultValue={formatDate(data?.subModel?.graduationDate)}
                  onChange={handleChange}
                />
              </div> */}
              {/* <div className='input-data my-3'>
                <label htmlFor='rating' className='fs-5 me-3'>
                  Rate
                </label>
                <input
                  type='number'
                  name='rating'
                  className='form-control'
                  
                  defaultValue={parseInt(data?.subModel?.rating)}
                  onChange={handleChange}
                />
              </div> */}

              {/* <div className='input-data my-3'>
                <label htmlFor='areaOfInterest' className='fs-5 me-3'>
                  Area of Interest
                </label>
                <select
                  className='form-select'
                  aria-label='Select example'
                  name='areaOfInterest'
                  onChange={handleChange}
                  defaultValue={data?.subModel?.areaOfInterest}
                >
                  <option disabled selected>
                    -- select an option --
                  </option>
                  {areaOfInterest.map((item: any) => (
                    <option
                      value={item._id}
                      selected={data?.subModel?.areaOfInterest._id === item._id}
                    >
                      {item.nameEn}
                    </option>
                  ))}
                </select>
              </div> */}
              <div className='input-data my-3'>
                <label htmlFor='phoneNumber' className='fs-5 me-3'>
                  Phone number
                </label>
                <input
                  type='number'
                  name='phoneNumber'
                  className='form-control'
                  defaultValue={data?.subModel?.phoneNumber}
                  onChange={handleChange}
                />
              </div>
              <div className='input-data my-3'>
                <Addresses handleChange={handleChange} data={data?.subModel?.addresses} />
              </div>

              {/* <AvailabilityTime
                handleChange={handleChange}
                data={data?.subModel?.availabilityTime}
              /> */}

              <div className='input-data my-3 col-md-12 col-12'>
                <label htmlFor='accountVisibility' className='fs-5 me-3'>
                  Account Visibility
                </label>
                <select
                  className='form-select'
                  aria-label='Select example'
                  name='accountVisibility'
                  onChange={handleChange}
                  defaultValue={data?.subModel?.accountVisibility}
                >
                  <option disabled selected>
                    -- select an option --
                  </option>
                  <option
                    selected={data?.subModel?.accountVisibility === 'community'}
                    value='community'
                  >
                    Students/Professionals & Mentors
                  </option>
                  {/* <option  selected={data?.subModel?.accountVisibility === 'employer'}  value="employer">Employers and Educational Institutions</option> */}
                  <option selected={data?.subModel?.accountVisibility === 'hidden'} value='hidden'>
                    Private
                  </option>
                </select>
              </div>
            </>
          )}
          <div className='d-flex justify-content-between'>
            <div className='input-data my-3'>
              <div className=' form-check-solid form-check-sm'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  defaultChecked={data?.subModel?.featuredAsPublic}
                  defaultValue={data?.subModel?.featuredAsPublic}
                  name='featuredAsPublic'
                  onChange={handleChange}
                />
                <label className=' fs-5 mx-3' htmlFor='featuredAsPublic'>
                  Featured as public
                </label>
              </div>
            </div>

            <div className='input-data my-3'>
              <div className='form-check-solid form-check-sm'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  defaultChecked={data?.subModel?.agreeToTerms}
                  defaultValue={data?.subModel?.agreeToTerms}
                  name='agreeToTerms'
                  onChange={handleChange}
                />
                <label className=' fs-5 mx-3' htmlFor='agreeToTerms'>
                  Agree to terms
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
