import { useEffect, useState } from 'react'

import { Link, useParams } from 'react-router-dom'
import Alert from '../../../components/alert'
import { AttachmentsTable } from '../../../components/attachmentsTable'
import { MentorShipSessionTable } from '../../../components/MentorShipSessionsTable'

import { PageTitle } from '../../../components/pageTitle'
import { ResetPasswordForUser } from '../../../services/admin'

import { CompanyFollowers, getCompany } from '../../../services/company'
import { deleteUser, getUserAttachments, getUserSession, updateUser } from '../../../services/user'
import { formatDate } from '../../../utils/DateFormatter'
import { objectToFormData } from '../../../utils/helpers'
import { JobApplications } from '../job-application'
import { Delete } from '../user/delete'
import Moment from "react-moment";
export const ViewStudent = () => {
  const [formData, setFormData] = useState<any | undefined>()
  const [loading, setLoading] = useState<any>(false)
  const [reset, setReset] = useState<any>(null)
  const [attachments, setAttachments] = useState<any>()
  const [active, setActive] = useState<any>(0)
  const [sessions, setSessions] = useState<any>([])
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      handleGetStudent()
      listUserAttachments()
      handleGetSessions()
    }
  }, [id])

  const handleGetStudent = async () => {
    const response = await getCompany(id)
    if (response) {
      setFormData(response.data.data)
    }
  }

  const listUserAttachments = async () => {
    const response = await getUserAttachments(id)
    if (response) setAttachments(response.data.data)
  }

  const handleGetSessions = async () => {
    const response = await getUserSession(id, true)
    if (response) {
      setSessions(response.data.data.queryResult)
    }
  }
  const handleClick = async () => {
    setLoading(true)
    const response = await ResetPasswordForUser(formData?.email)
    if (response.status === 200) {
      setLoading(false)
      setReset(true)
      setTimeout(() => {
        setReset(null)
      }, 3000)
    } else {
      setLoading(false)
      setReset(false)
    }
  }

  const [showModal, setShowModal] = useState(false)

  const viewModal = () => {
    setShowModal(true)
  }

  const cancelFn = () => {
    setShowModal(false)
  }

  const delteFn = () => {
    deleteGetOfferFn()
    setShowModal(false)
  }

  const deleteGetOfferFn = async () => {
    setLoading(true)
    const response = await deleteUser(id)
    if (response) {
      cancelFn()
      setLoading(false)
      // listALl()
    }
  }

  const approveUser = async () => {
    const res = await updateUser(id, objectToFormData({ approved: true }))
    if (res.status === 200) {
      handleGetStudent()
    }
  }

  return (
    <>
      {showModal && (
        <Delete
          show={showModal}
          onCancel={cancelFn}
          onDelete={delteFn}
          loading={loading}
          model={'student'}
        />
      )}
      <div className='d-flex justify-content-between flex-col my-3 page-header mb-8'>
        <PageTitle active={`Student/Professional Details`} />
      </div>
      {reset && (
        <Alert
          alertTitle={'Success'}
          alertMsg={`Edqan sent a password reset link to "${formData.email}".`}
          type='success'
        />
      )}
      {reset == false && (
        <Alert
          alertTitle={'Error'}
          alertMsg={`Something Went wrong, try again later`}
          type='error'
        />
      )}
      <div className='row'>
        <div className='col-sm-12  col-md-12 mt-8'>
          <div>
            <div className='d-flex overflow-auto h-55px mb-5'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item'>
                  <span
                    onClick={() => {
                      setActive(0)
                    }}
                    className={`nav-link text-active-primary me-6 ` + (active === 0 && 'active')}
                  >
                    Account Information
                  </span>
                </li>
                <li
                  className='nav-item'
                  onClick={() => {
                    setActive(2)
                  }}
                >
                  <span
                    className={`nav-link text-active-primary me-6 ` + (active === 2 && 'active')}
                  >
                    Profile Information
                  </span>
                </li>
                <li
                  className='nav-item'
                  onClick={() => {
                    setActive(1)
                  }}
                >
                  <span
                    className={`nav-link text-active-primary me-6 ` + (active === 1 && 'active')}
                  >
                    Mentorship Sessions
                  </span>
                </li>

                <li
                  className='nav-item'
                  onClick={() => {
                    setActive(3)
                  }}
                >
                  <span
                    className={`nav-link text-active-primary me-6 ` + (active === 3 && 'active')}
                  >
                    Job Applications
                  </span>
                </li>
                <li
                  className='nav-item'
                  onClick={() => {
                    setActive(4)
                  }}
                >
                  <span
                    className={`nav-link text-active-primary me-6 ` + (active === 4 && 'active')}
                  >
                    Attachments
                  </span>
                </li>
              </ul>
            </div>
            {active === 0 && (
              <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-body p-9'>
                  <div className='col-sm-12 col-md-12 '>
                    <div className='card mb-5 mb-xl-10 h-100 position-relative'>
                      <span
                        className={`d-flex align-items-center position-absolute me-5 text-white p-2 rounded  ${formData?.approved ? 'bg-success' : 'bg-danger'
                          }`}
                        style={{ top: '-13px', right: '-30px' }}
                      >
                        <strong className='me-2 d-block'> Status :</strong>{' '}
                        {formData?.approved ? 'Approved' : 'Pending'}
                      </span>
                      <div className='d-flex justify-content-start w-100'>
                        <div className='d-flex justify-content-start flex-shrink-0'>
                          <Link
                            to={`/student/update/${id}`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-5'
                          >
                            <i className='bi bi-pencil-square text-info fs-2'></i>
                          </Link>

                          <button
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-4'
                            onClick={() => viewModal()}
                          >
                            <i className='bi bi-trash text-danger fs-2'></i>
                          </button>
                          {!formData?.approved ? (
                            <>
                              <button
                                className='btn  btn-bg-light btn-active-color-primary btn-sm me-2'
                                onClick={() => approveUser()}
                              >
                                {/* <i className='bi bi-check2 fs-2 text-success'></i> */}
                                Approve
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                          <button
                            className='btn btn-danger btn-sm btn-sm me-1  ml-2 d-flex justify-content-center me-2'
                            type='button'
                            onClick={() => handleClick()}
                          >
                            {loading ? (
                              <div className='spinner-border text-light' role='status'>
                                <span className='sr-only'>Loading...</span>
                              </div>
                            ) : (
                              'Send password reset to user'
                            )}
                          </button>
                        </div>
                      </div>
                      <div className='card-body pt-9 pb-0'>
                        <div className='h-100 mb-3 d-flex'>
                          <div className='me-7 mb-4'>
                            <div
                              className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border'
                              style={{ borderRadius: '50%' }}
                            >
                              {formData?.profileImage ? (
                                <img
                                  src={formData?.profileImage}
                                  alt='profile Image'
                                  style={{ borderRadius: '50%' }}
                                />
                              ) : (
                                <div
                                  className='bg-info text-white d-flex align-items-center justify-content-center '
                                  style={{
                                    fontSize: '50px',
                                    padding: '10px',
                                    borderRadius: '50%',
                                    height: '150px',
                                    width: '150px',
                                  }}
                                >
                                  {formData?.subModel?.firstName.charAt(0).toUpperCase()}{' '}
                                  {formData?.subModel?.lastName.charAt(0).toUpperCase()}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className='flex-grow-1'>
                            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                              <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center mb-2 fw-bold '>
                                  <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                                    <span className='text-primary me-1'> First Name : </span>{' '}
                                    {formData?.subModel?.firstName}
                                  </span>
                                  <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                                    <span className='text-primary me-1'> Middle Name : </span>{' '}
                                    {formData?.subModel?.middleName}
                                  </span>
                                  <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                                    <span className='text-primary me-1'> Last Name : </span>{' '}
                                    {formData?.subModel?.lastName}
                                  </span>
                                </div>

                                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                  <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                                    <span className='text-primary me-1'> Primary Email : </span>{' '}
                                    {formData?.email}
                                  </span>
                                  <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                                    <span className='text-primary me-1'> Secondary Email : </span>{' '}
                                    {formData?.subModel?.secondaryEmail || 'N/A'}
                                  </span>
                                </div>
                                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                  <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                                    <span className='text-primary me-1'> Country : </span>{' '}
                                    {formData?.country.nameEn}
                                  </span>
                                  <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                                    <span className='text-primary me-1'> City : </span>{' '}
                                    {formData?.city.nameEn}
                                  </span>
                                  <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                                    <span className='text-primary me-1'> Gender : </span>{' '}
                                    {formData?.subModel.gender}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex  w-100'>
                    <span className='d-flex align-items-center text-black border p-2 rounded  me-5 mb-2'>
                      <strong className='text-primary me-1'>Language fluency : </strong>

                      {formData?.subModel.fluentLanguage?.map((item: any) => {
                        return (
                          <span className='fw-bolder fs-6 text-dark bg-gray-200 rounded me-2 p-2'>
                            {item.nameEn}{' '}
                          </span>
                        )
                      }) || <span className='fw-bolder fs-6 text-dark '>N/A </span>}
                    </span>
                  </div>

                  <div className='d-flex  w-100'>
                    <span className='d-flex align-items-center text-black border p-2 rounded  me-5 mb-2'>
                      <strong className='text-primary me-1'>Language proficiency : </strong>

                      {formData?.subModel.proficientLanguage?.map((item: any) => {
                        return (
                          <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>
                            {item.nameEn}{' '}
                          </span>
                        )
                      }) || <span className='fw-bolder fs-6 text-dark '>N/A </span>}
                    </span>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Address</label>

                    <div className='col-lg-8'>
                      {formData?.subModel.addresses?.map((item: any) => {
                        return <span className='fw-bolder fs-6 text-dark'>{item} , </span>
                      }) || <span className='fw-bolder fs-6 text-dark'>N/A </span>}
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      Educational institution
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6'>
                        {formData?.subModel.institution?.subModel?.name || 'N/A'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      Enrolled in Higher Education
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6'>
                        {formData?.subModel.enrolledInHighSchool ? 'Yes' : 'NO'}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Major/Area of Study</label>

                    <div className='col-lg-8 fv-row'>
                      {/* <span className='fw-bold fs-6'>{formData?.subModel?.major?.nameEn}</span> */}
                      <span className='fw-bold fs-6 d-flex flex-wrap'>
                        {formData?.subModel?.major?.length > 0
                          ? formData?.subModel?.major.map((item: any) => {
                            return (
                              <div className='d-block bg-gray-200 p-2 rounded me-2 mb-2'>
                                {formData?.subModel.enrolledInHighSchool ? item.nameEn : 'N/A'}
                              </div>
                            )
                          })
                          : 'N/A'}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Level of Education</label>

                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6'>
                        {formData?.subModel?.educationLevel?.nameEn}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Graduation Date</label>

                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6'>
                        {formData?.subModel.graduationDate
                          ? formatDate(formData?.subModel.graduationDate)
                          : 'N/A'
                        }
                      </span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Area of Interest</label>

                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6'>
                        {!formData?.subModel.enrolledInHighSchool
                          ? formData?.subModel?.areaOfInterest?.nameEn
                          : 'N/A'}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Job Role Preferences</label>

                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6 d-flex flex-wrap'>
                        {formData?.subModel?.jobMajors?.length > 0
                          ? formData?.subModel?.jobMajors.map((item: any) => {
                            return (
                              <div className='d-block bg-gray-200 p-2 rounded me-2 mb-2'>
                                {item.nameEn}
                              </div>
                            )
                          })
                          : 'N/A'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Interested Industries</label>

                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6'>
                        {formData?.subModel?.careerInterest
                          ? formData?.subModel?.careerInterest?.subIndustry.map((item: any) => {
                            return (
                              <span className='bg-gray-200 p-2 rounded me-2'>{item.nameEn}</span>
                            )
                          })
                          : 'N/A'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-7 '>
                    <label className='col-lg-4 fw-bold text-muted'> Interested Job Functions</label>

                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6'>
                        {formData?.subModel?.careerInterest
                          ? formData?.subModel?.careerInterest?.jobRole.map((item: any) => {
                            return (
                              <span className='bg-gray-200 p-2 rounded me-2'>{item.nameEn}</span>
                            )
                          })
                          : 'N/A'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-7 '>
                    <label className='col-lg-4 fw-bold text-muted'> Interested countries</label>

                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6'>
                        {formData?.subModel?.careerInterest
                          ? formData?.subModel?.careerInterest?.country.map((item: any) => {
                            return (
                              <span className='bg-gray-200 p-2 rounded me-2'>{item.nameEn}</span>
                            )
                          })
                          : 'N/A'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>GPA</label>

                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6'>{formData?.subModel.gpa || 'N/A'}</span>
                    </div>
                  </div>
               
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Agree to Terms</label>

                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6'>
                        {formData?.subModel?.agreeToTerms ? 'yes' : 'NO'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Privacy</label>

                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6 text-capitalize'>
                        {formData?.subModel?.accountVisibility === 'employer'
                          ? 'Employers and Educational Institutions'
                          : formData?.subModel?.accountVisibility}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {active === 1 && (
              <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-body p-9'>
                  <div className='row mb-7'>
                    <MentorShipSessionTable list={sessions} />
                  </div>
                </div>
              </div>
            )}
            {active === 2 && (
              <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-body p-9'>
                  <div className='d-flex justify-content-start w-100'>
                    <div className='d-flex justify-content-start flex-shrink-0'>
                      <Link
                        to={`/student/update/${id}`}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-5'
                      >
                        <i className='bi bi-pencil-square text-info fs-2'></i>
                      </Link>

                      <button
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-4'
                        onClick={() => viewModal()}
                      >
                        <i className='bi bi-trash text-danger fs-2'></i>
                      </button>
                      {!formData?.approved ? (
                        <>
                          <button
                            className='btn  btn-bg-light btn-active-color-primary btn-sm'
                            onClick={() => approveUser()}
                          >
                            {/* <i className='bi bi-check2 fs-2 text-success'></i> */}
                            Approve
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-12 '>
                    <div className='card mb-5 mb-xl-10 h-100 position-relative'>
                      <span
                        className={`d-flex align-items-center position-absolute me-5 text-white p-2 rounded  ${formData?.approved ? 'bg-success' : 'bg-danger'
                          }`}
                        style={{ top: '-13px', right: '-30px' }}
                      >
                        <strong className='me-2 d-block'> Status :</strong>{' '}
                        {formData?.approved ? 'Approved' : 'Rejected'}
                      </span>
                      <div className='d-flex justify-content-start w-100'></div>
                      <div className='card-body pt-9 pb-0'>
                        <div className='h-100 mb-3 d-flex'>
                          <div className='me-7 mb-4'>
                            <div
                              className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border'
                              style={{ borderRadius: '50%' }}
                            >
                              {formData?.profileImage ? (
                                <img
                                  src={formData?.profileImage}
                                  alt='profile Image'
                                  style={{ borderRadius: '50%' }}
                                />
                              ) : (
                                <div
                                  className='bg-info text-white d-flex align-items-center justify-content-center '
                                  style={{
                                    fontSize: '50px',
                                    padding: '10px',
                                    borderRadius: '50%',
                                    height: '150px',
                                    width: '150px',
                                  }}
                                >
                                  {formData?.subModel?.firstName.charAt(0).toUpperCase()}{' '}
                                  {formData?.subModel?.lastName.charAt(0).toUpperCase()}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className='flex-grow-1'>
                            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                              <div className='d-flex flex-column'>
                                <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                                  <strong className='text-primary me-1 text-capitalize'>
                                    About {formData?.subModel?.firstName} :{' '}
                                  </strong>{' '}
                                  {formData?.subModel.brief || 'N/A'}
                                </strong>
                                <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                                  <strong className='text-primary me-1'>Full Name : </strong>
                                  <span className='text-capitalize'>
                                    {formData?.subModel?.firstName} {formData?.subModel?.middleName}{' '}
                                    {formData?.subModel?.lastName}
                                  </span>
                                </strong>
                                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                  <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                                    <span className='text-primary me-1'> Primary Email : </span>{' '}
                                    {formData?.email}
                                  </span>
                                  <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                                    <span className='text-primary me-1'> Secondary Email : </span>{' '}
                                    {formData?.subModel?.secondaryEmail}
                                  </span>
                                </div>
                                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                  <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                                    <span className='text-primary me-1'> Gender : </span>{' '}
                                    {formData?.subModel.gender}
                                  </span>

                                  {/* {formData?.subModel.webSite && <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                          <span className='text-primary me-1'> WebSite : </span>  {formData?.subModel.webSite}
                        </span>} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='d-block  w-100'>
                    <span className=' d-flex align-items-center text-black border p-2 rounded  me-5 mb-2'>
                      <strong className='text-primary me-1'>Skills : </strong>

                      {formData?.subModel.skills?.map((item: any) => {
                        return (
                          <span className='fw-bolder fs-6 text-dark bg-gray-200 rounded me-2 p-2'>
                            {item}{' '}
                          </span>
                        )
                      }) || <span className='fw-bolder fs-6 text-dark '>N/A </span>}
                    </span>
                    <span className='d-flex align-items-center text-black border p-2 rounded  me-5 mb-2'>
                      <strong className='text-primary me-1'>Language Fluency : </strong>

                      {formData?.subModel.fluentLanguage?.map((item: any) => {
                        return (
                          <span className='fw-bolder fs-6 text-dark bg-gray-200 rounded me-2 p-2'>
                            {item.nameEn}{' '}
                          </span>
                        )
                      }) || <span className='fw-bolder fs-6 text-dark '>N/A </span>}
                    </span>
                    <span className='d-flex align-items-center text-black border p-2 rounded  me-5 mb-2'>
                      <strong className='text-primary me-1'>Language Proficiency: </strong>

                      {formData?.subModel.proficientLanguage?.map((item: any) => {
                        return (
                          <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>
                            {item.nameEn}{' '}
                          </span>
                        )
                      }) || <span className='fw-bolder fs-6 text-dark '>N/A </span>}
                    </span>
                  </div>
                </div>
                <div className='card p-9 page-header'>
                  <h4 className='card-title w-fit d-block' style={{ width: 'fit-content' }} >Education

                    <div className='mb-4 mt-2' style={{ borderBottom: '3px solid #009ef7', width: '60%' }}></div></h4>

                  {formData?.subModel.education.map((el: any) => (
                    <div className='card w-100' style={{ width: '18rem' }}>

                      <div className="mb-1 text-[#222222]">
                        <h3 className='card-title'>{el?.university}</h3>
                        <p className="font-light text-[15px] my-1 ">{el?.educationLevel?.nameEn}, {el?.major?.nameEn}</p>
                        <p className="font-light text-[15px] my-1"><Moment format="MMMM YYYY">{el?.startingDate}</Moment> - <Moment format="MMMM YYYY">{el?.endingDate}</Moment></p>
                        <p className="font-light text-[15px] my-1">
                          Minor/Concentration:{" "}
                          <span className="font-light">{el?.minor || "N/A"}</span>
                        </p>
                        {el?.gpa && <p className="font-light text-[15px] my-1">
                          GPA:{" "}
                          <span className="font-light">{el?.gpa}</span>
                        </p>}
                      </div>
                    </div>
                  ))}
                  {formData?.subModel.education.length == 0 && 'N/A'}
                </div>

                <div className='card p-9 page-header'>
                  {/* <h4 className='card-title'></h4> */}
                  <h4 className='card-title w-fit d-block' style={{ width: 'fit-content' }} >Work & Volunteer Experience

                    <div className='mb-4 mt-2' style={{ borderBottom: '3px solid #009ef7', width: '60%' }}></div></h4>
                  {formData?.subModel.volunteers.map((el: any) => (
                    <div className='card w-100' style={{ width: '18rem' }}>
                      <div className='card-body'>
                        <h5 className='card-title'>
                          {el?.role} - {el?.company}
                        </h5>
                        <h6 className='card-subtitle mb-2 text-muted'>
                          {el?.country?.nameEn} - {el?.city?.nameEn}
                        </h6>
                        <p className='card-text'> {el?.description}</p>
                        <p className='card-text'> {el?.duration}</p>
                      </div>
                    </div>
                  ))}
                  {formData?.subModel.volunteers.length == 0 && 'N/A'}
                </div>
                <div className='card p-9 page-header'>
                  {/* <h4 className='card-title'>Committees/Organizations</h4> */}
                  <h4 className='card-title w-fit d-block' style={{ width: 'fit-content' }} >Committees/Organizations

                    <div className='mb-4 mt-2' style={{ borderBottom: '3px solid #009ef7', width: '60%' }}></div></h4>
                  <div className='flex mt-4'>
                    {formData?.subModel.organizations.length > 0
                      ? formData?.subModel.organizations.map((el: any) => (
                        <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>
                          {el}{' '}
                        </span>
                      ))
                      : 'N/A'}
                  </div>
                </div>
                <div className='card p-9 page-header'>
                  {/* <h4 className='card-title'>Courses completed</h4>
                  <div className='mb-4' style={{borderBottom:'3px solid orange',width:'40px'}}></div> */}
                  <h4 className='card-title w-fit d-block' style={{ width: 'fit-content' }} >Courses completed

                    <div className='mb-4 mt-2' style={{ borderBottom: '3px solid #009ef7', width: '60%' }}></div></h4>
                  {formData?.subModel.courses.map((el: any) => (
                    <div className='card w-100' style={{ width: '18rem' }}>
                      <div className='card-body'>
                        <h5 className='card-title'>{el.name}</h5>
                        <h6 className='card-subtitle mb-2 text-muted'></h6>
                        <a href='#' className='card-link'>
                          {el.url}
                        </a>
                      </div>
                    </div>
                  ))}
                  {formData?.subModel.courses.length == 0 && 'N/A'}
                </div>
                <div className='card p-9 '>

                  <h4 className='card-title w-fit d-block' style={{ width: 'fit-content' }} >Notable Projects/Products

                    <div className='mb-4 mt-2' style={{ borderBottom: '3px solid #009ef7', width: '60%' }}></div></h4>
                  {formData?.subModel.projects.map((el: any) => (
                    <div className='card w-100' style={{ width: '18rem' }}>
                      <div className='card-body'>
                        <h5 className='card-title'>
                          {el.name}
                        </h5>
                        <a href={el?.url} target="blank" className="font-light text-[15px] my-1 main-link">{el?.url}</a>
                        <p className="card-text my-1 ">{el?.role}</p>
                        <p className="card-text ">{el?.owner}</p>
                        <p className="card-text"> <Moment format="MMMM YYYY">{el?.startingDate}</Moment> -  <Moment format="MMMM YYYY">{el?.endingDate}</Moment> ({el?.duration})</p>
                  
                        <h6 className='card-text mb-2 '>
                          {el?.country?.nameEn} - {el?.city?.nameEn}
                        </h6>
                        <p className='card-text'> {el.description}</p>
                       
                      </div>
                    </div>
                  ))}
                  {formData?.subModel.projects.length == 0 && 'N/A'}
                </div>
              </div>
            )}

            {/* {active === 3 && (
              <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>


                <div className='card-body p-9'>
                  <div className='row mb-7'>
                    <div className='col-lg-8'>
                      {formData?.subModel.industries?.map((item: any) => {
                        return (
                          <div>
                            <label className='col-lg-4 fw-bold text-muted'>{item.industry}</label>
                            {item.subIndustries.map((el: any) => {
                              return <p>{el}</p>
                            })}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )} */}
            {active === 3 && <JobApplications StudentId={id} />}
            {active === 4 && (
              <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <AttachmentsTable list={attachments} handleListAll={listUserAttachments} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
