import ErrorMessage from '../../../../components/ErrorMessage'

type Props = {
  onClickSubmit: any
  handleChange: any
  data: any
  ButtonText: string
  action?: string
  Show: any
  loading: boolean
  isSubmitted: boolean
}

export const DataForm = (props: Props) => {
  const { onClickSubmit, handleChange, data, ButtonText, Show, loading, action, isSubmitted } = props

  const adminRolesList = [
    {
      name: 'Super Admin',
      value: 'SUPER_ADMIN'
    },
    {
      name: 'Student Admin',
      value: 'STUDENT_ADMIN'
    },
    {
      name: 'Company Admin',
      value: 'COMPANY_ADMIN'
    }
    ,
    {
      name: 'Mentor Admin',
      value: 'MENTOR_ADMIN'
    },
    {
      name: 'Institution Admin',
      value: 'INSTITUTION_ADMIN'
    }
  ]
  const adminRoles:any = {
    SUPER_ADMIN: 0,
    STUDENT_ADMIN: 1,
    COMPANY_ADMIN: 2,
    INSTITUTION_ADMIN: 3,
    MENTOR_ADMIN: 4,

  }

  return (
    <form className='form-control' onSubmit={onClickSubmit}>
       <div className='input-data my-3'>
        <label htmlFor='email' className='fs-5 me-3'>
        First Name 
        </label>
        <input
          type='text'
          name='firstName'
          className='form-control'
          defaultValue={data?.firstName}
          onChange={handleChange}
        />
       
      </div>
      <div className='input-data my-3'>
        <label htmlFor='email' className='fs-5 me-3'>
         Last Name 
        </label>
        <input
          type='text'
          name='lastName'
          className='form-control'
          defaultValue={data?.lastName}
          onChange={handleChange}
        />
       
      </div>
      <div className='input-data my-3'>
        <label htmlFor='email' className='fs-5 me-3'>
        <span className='text-danger'>*</span>   Email 
        </label>
        <input
          type='email'
          name='email'
          className='form-control'
          defaultValue={data?.email}
          onChange={handleChange}
        />
        {isSubmitted && !data?.email && <ErrorMessage errorMsg='Email' />}
      </div>
      {/* <div className='input-data my-3'>
        <label htmlFor='username' className='fs-5 me-3'>
          User name
        </label>
        <input
          type='text'
          name='username'
          className='form-control'
          value={data?.username}
          onChange={handleChange}
        />
      </div> */}
      {action === 'create' && (
        <div className='input-data my-3'>
          <label htmlFor='password' className='fs-5 me-3'>
          <span className='text-danger'>*</span>   Password
          </label>
          <input
            type='password'
            name='password'
            className='form-control'
            value={data?.password}
            onChange={handleChange}
          />
          {isSubmitted && !data?.password && <ErrorMessage errorMsg='Password' />}
        </div>
      )}
      <div className='input-data my-3'>
        <label htmlFor='role' className='fs-5 me-3'>
        <span className='text-danger'>*</span>   Role
        </label>
        <select
          className='form-select'
          aria-label='Select example'
          name='role'
          onChange={handleChange}
          // value={data?.role}
        >
          <option disabled selected>
            -- select an option --
          </option>
          {
            adminRolesList.map((option: any) => {
              return (
                <option selected={data?.role===adminRoles[option.value]} className='py-4' value={option.value}>{option.name}</option>
              )
            })
          }

        </select>
        {isSubmitted && !data?.role && <ErrorMessage errorMsg='Role' />}
      </div>
      <div className='d-flex justify-content-end'>
        <button className='btn btn-primary' disabled={Show} type='submit'>
          {!loading && ButtonText}
          {loading && (
             <div className="spinner-border text-light" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
          )}
        </button>
      </div>
    </form>
  )
}
