import {useEffect, useState} from 'react'
import Multiselect from 'multiselect-react-dropdown'
import {getAllKeyWays} from '../../../../services/keyWays'
import {getAllUsers} from '../../../../services/user'
import {formatDate} from '../../../../utils/DateFormatter'
import ErrorMessage from '../../../../components/ErrorMessage'

type Props = {
  onClickSubmit: any
  handleChange: any
  setGetOffer?: any
  data: any
  ButtonText: string
  Show: any
  loading: boolean
  keyways?: any
  isSubmitted: boolean
}

export const DataForm = (props: Props) => {
  const {
    onClickSubmit,
    handleChange,
    setGetOffer,
    data,
    ButtonText,
    Show,
    loading,
    keyways,
    isSubmitted,
  } = props
  const [keywaysList, setKeywaysList] = useState<any>([])
  const [companiesList, setCompaniesList] = useState<any>([])
  const [studentsList, setStudentsList] = useState<any>([])

  useEffect(() => {
    getKeyways()
    getCompanies()
    getStudents()
  }, [])

  const getKeyways = async () => {
    const response = await getAllKeyWays()
    setKeywaysList(response.data.data)
  }
  const getCompanies = async () => {
    const response = await getAllUsers('Company')
    setCompaniesList(response.data.data.queryResult)
  }
  const getStudents = async () => {
    const response = await getAllUsers('Student')
    setStudentsList(response.data.data.queryResult)
  }

  return (
    <>
      {true && (
        <form className='form-control d-flex row' onSubmit={onClickSubmit}>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='jobTitle' className='fs-5 me-3 '>
            <span className='text-danger'>*</span>   Job title 
            </label>
            <input
              type='text'
              name='jobTitle'
              className='form-control'
              defaultValue={data?.jobTitle}
              onChange={handleChange}
            />
            {isSubmitted && !data?.jobTitle && <ErrorMessage errorMsg='this field' />}
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='resourcesFindingJob' className='fs-5 me-3'>
            <span className='text-danger'>*</span>   Resources finding job
            </label>
            <input
              type='text'
              name='resourcesFindingJob'
              className='form-control'
              value={data?.resourcesFindingJob}
              onChange={handleChange}
            />
            {isSubmitted && !data?.resourcesFindingJob && <ErrorMessage errorMsg='this field' />}
          </div>
          <div className='input-data my-3'>
            <label htmlFor='advicesForOthers' className='fs-5 me-3'>
            <span className='text-danger'>*</span>  Advice for others 
            </label>
            <textarea
              name='advicesForOthers'
              className='form-control'
              value={data?.advicesForOthers}
              onChange={handleChange}
            />
            {isSubmitted && !data?.advicesForOthers && <ErrorMessage errorMsg='this field' />}
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='whatYouLikedAndLearned' className='fs-5 me-3'>
              Education Type
            </label>
            <input
              type='text'
              name='whatYouLikedAndLearned'
              className='form-control'
              value={data?.whatYouLikedAndLearned}
              onChange={handleChange}
            />
          </div>
          {/* <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='typeOfWork' className='fs-5 me-3'>
            <span className='text-danger'>*</span>Type of work
            </label>
            <input
              type='text'
              name='typeOfWork'
              className='form-control'
              value={data?.typeOfWork}
              onChange={handleChange}
            />
            {isSubmitted && !data?.typeOfWork && <ErrorMessage errorMsg='this field' />}
          </div> */}
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='typeOfWork' className='fs-5 me-3'>
            <span className='text-danger'>*</span>  Key ways to describe experience
            </label>
            <Multiselect
              displayValue='nameEn'
              onKeyPressFn={function noRefCheck() {}}
              onRemove={function noRefCheck() {}}
              onSelect={function noRefCheck(selectedList: any, selectedItem: any) {
                handleChange(
                  selectedList.map((obj: any) => obj._id),
                  'keyWays'
                )
              }}
              options={keywaysList}
            />
            {isSubmitted && !data?.keyWays && <ErrorMessage errorMsg='this field' />}
          </div>

          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='salary' className='fs-5 me-3'>
              Salary
            </label>
            <input
              type='number'
              name='salary'
              className='form-control'
              value={data?.salary}
              onChange={handleChange}
            />
          </div>

          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='company' className='fs-5 me-3'>
            <span className='text-danger'>*</span>   Company 
            </label>
            <select
              className='form-select'
              aria-label='Select example'
              name='company'
              onChange={handleChange}
              defaultValue={data?.subModel?._id}
              value={data?.subModel?._id}
            >
              <option disabled selected>
                -- select an option --
              </option>

              {companiesList.map((item: any) => (
                <option value={item._id} selected={data?.company?._id === item._id}>
                  {item?.subModel.name}
                </option>
              ))}
            </select>
            {isSubmitted && !data?.company && <ErrorMessage errorMsg='this field' />}
          </div>

          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='user' className='fs-5 me-3'>
              User
            </label>
            <select
              className='form-select'
              aria-label='Select example'
              name='user'
              onChange={handleChange}
              defaultValue={data?.user?._id}
            >
              <option disabled selected>
                -- select an option --
              </option>
              {studentsList.map((item: any) => (
                <option value={item._id} selected={data?.user?._id === item._id}>
                  {item?.subModel.firstName}
                </option>
              ))}
            </select>
          </div>

          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='startDate' className='fs-5 me-3'>
            <span className='text-danger'>*</span>  Start Date 
            </label>

            <input
              type='date'
              name='startDate'
              className='form-control'
              value={formatDate(data?.startDate)}
              onChange={handleChange}
            />
            {isSubmitted && !data?.startDate && <ErrorMessage errorMsg='this field' />}
          </div>

          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='endDate' className='fs-5 me-3'>
              End Date
            </label>
            <input
              type='date'
              name='endDate'
              className='form-control'
              value={formatDate(data?.endDate)}
              onChange={handleChange}
            />
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <div className='form-check form-check-custom form-check-solid form-check-sm'>
              <input
                className='form-check-input'
                type='checkbox'
                value={data?.addToProfile}
                checked={data?.addToProfile}
                name='addToProfile'
                onChange={handleChange}
              />
              <label className='form-check-label fs-5 me-3' htmlFor='addToProfile'>
                Add to profile
              </label>
            </div>
          </div>
          {/* did you get offer obj */}
          <div className='input-data my-3'>
            <p>Did you get an offer to continue with them? </p>
            <label htmlFor='answer' className='fs-5 me-3'>
              Answer
            </label>
            <input
              type='text'
              name='answer'
              className='form-control'
              value={data?.didYouGetOffer?.answer}
              onChange={(e: any) =>
                setGetOffer((_data: any) => ({..._data, [e.target.name]: e.target.value}))
              }
            />
            {isSubmitted && !data?.didYouGetOffer?.answer && <ErrorMessage errorMsg='this field' />}
            <div className='my-3 form-check form-check-custom form-check-solid form-check-sm'>
              <input
                className='form-check-input'
                checked={data?.didYouGetOffer?.visibility}
                type='checkbox'
                value={data?.didYouGetOffer?.visibility}
                name='visibility'
                onChange={(e: any) =>
                  setGetOffer((_data: any) => ({..._data, [e.target.name]: e.target.checked}))
                }
              />
              <label className='form-check-label fs-5 me-3' htmlFor='visibility'>
                Visible
              </label>
            </div>
          </div>

          <div className='input-data my-3'>
            <label htmlFor='howOftenDidYouGetPaid' className='fs-5 me-3'>
              How often did you get paid?
            </label>
            <input
              type='text'
              name='howOftenDidYouGetPaid'
              className='form-control'
              value={data?.howOftenDidYouGetPaid}
              onChange={handleChange}
            />
          </div>

          <div className='input-data my-3'>
            <div className='mb-10'>
              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                <input
                  className='form-check-input'
                  checked={data?.agreeToTerms}
                  type='checkbox'
                  value={data?.agreeToTerms}
                  name='agreeToTerms'
                  onChange={handleChange}
                />
                <label className='form-check-label fs-5 me-3' htmlFor='agreeToTerms'>
                  Agree to terms
                </label>
              </div>
              {isSubmitted && !data?.agreeToTerms && <ErrorMessage errorMsg='this field' />}
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <button className='btn btn-primary' disabled={Show} type='submit'>
              {!loading && ButtonText}
              {loading && (
               <div className="spinner-border text-light" role="status">
               <span className="sr-only">Loading...</span>
             </div>
              )}
            </button>
          </div>
        </form>
      )}
    </>
  )
}
