import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Table } from './components/tablInst'
import { getAlStudentsInInstitution } from '../../../services/user';
import SearchInput from '../../../components/SearchInput'
import { searchInTable } from '../../../utils/useSearchTable'
import Pagination from '../../../components/Pagination'

type Props = {
  model: string
}

export const InstitutionStudentsList = (props: Props) => {

  const parms = useParams()
  const { id, name } = parms
  const { model } = props
  const [userList, seUserList] = useState<Array<any>>([])
  const [loading, setLoading] = useState<any>(false)
  const [dataLength, setDataLength] = useState<any>(0)
  const [searchValue, setSearchValue] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const handleSearchChange = (e: any) => {
    const inputValue = e.target.value
    setSearchValue(inputValue)
    const filteredRows = searchInTable(inputValue, userList)
    setFilteredData(filteredRows)
  }

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleCountChange = (e: any) => {
    setPageSize(e.target.value)
    listAlStudentsInInstitution(e.target.value)
  }

  useEffect(() => {
    listAlStudentsInInstitution(pageSize)
  }, [currentPage])

  const listAlStudentsInInstitution = async (size: any) => {
    setLoading(true)
    const response = await getAlStudentsInInstitution(id, currentPage, size)
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / size))
    setDataLength(response.data.data.queryDataLength)
    seUserList(response.data.data.queryResult)
    setLoading(false)
  }

  return (
    <>
      {/* <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active={`${name} students`} />
        <Link to={`/${model}/create/`}>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
       */}
       <div className='p-4'>
          <SearchInput searchValue={searchValue} handleSearchChange={handleSearchChange} />
          <Table list={userList} model={model} length={dataLength} skelton={loading} />
          <div className='d-flex justify-content-between'>
        
              <>
                <div className='flex justify-end my-6'>
                  <Pagination
                    totalCount={totalPages}
                    onPageChange={handlePaginationChange}
                    currentPage={currentPage}
                  />
                </div>
                <select
                  className='form-select mr-4 mt-4'
                  style={{ width: '75px', height: '50px' }}
                  onChange={handleCountChange}
                  value={pageSize}
                >
                  <option value='10'>10</option>
                  <option value='20'>20</option>
                  <option value='30'>30</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                </select>
              </>
        
          </div>
       </div>
        
      
    </>
  )
}
