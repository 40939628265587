import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Table } from './components/table'
import { PageTitle } from '../../../components/pageTitle'
import { getAllNotificationMethods } from '../../../services/notificationsMethod'
import SearchInput from '../../../components/SearchInput'

export const NotificationsMethod = () => {
  const [notificationMethods, setNotificationMethods] = useState<Array<any>>([])
  const [loading, setLoading] = useState<any>(false)
  const listAllNotificationMethods = async () => {
    setLoading(true)
    const response = await getAllNotificationMethods()
    setNotificationMethods(response.data.data)
    setLoading(false)
  }
  const setDataResult = (response: any) => {
    setNotificationMethods(response.data.data.queryResult)
    setLoading(false)
  }
  useEffect(() => {
    listAllNotificationMethods()
  }, [])
  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Notification Methods' />
        <Link to='/notificationMethod/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
      <SearchInput
        model={'notificationMethod'}
        setLoading={setLoading}
        setDataResult={setDataResult}
        ListAll={listAllNotificationMethods}
      />
      {loading ? (
        <div className="spinner-border text-light" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <Table list={notificationMethods} listALl={listAllNotificationMethods} />
      )}
    </>
  )
}
