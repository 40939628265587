import { useState } from 'react';
import ImageCrop from './ImageUpload'
interface Props {
  setImagefile: any
  type: string
  path?: any
  defaultImg?: any,
  isUser?: any,
  model?: any
}
export const UploadeProfileImage = (props: Props) => {
  const { setImagefile, type, path, isUser, model } = props
  const [show, setShow] = useState(false)
  const [image, setSelectimage] = useState('')
  const [deleteImg, setDelete] = useState(false)
  
  const handleSubmit = (selectimage: any, imagefile: any) => {
    setSelectimage(selectimage)
    setImagefile(imagefile)
  }
  const handleClick = () => {
    const event = new Event('change')
    event.preventDefault()
    setShow(true)
  }

  return (
    <div className='w-full d-flex items-center justify-content-center '>
      {show && <ImageCrop show={show} type={type === 'logo' ? 'image' : 'banner'} setOpen={setShow} 
      handleSubmit={handleSubmit} />}
      <div
        className={`position-relative ${type === 'logo' ? 'rounded-circle' : ''}  avatar-image`}
        style={
          type === 'logo' ? { height: '150px', width: '150px' } : { height: '150px', width: '100%' }
        }
      >
        
        {/* begin::User */}
        <div className={`symbol ${type === 'logo' ? 'symbol-circle' : ''}  symbol-100px w-100 h-100 d-flex align-items-center justify-content-center`}>
          {(image || path )&&!deleteImg? (
           <img src={image || path} alt='' className='w-100 h-100' />
          ) : (
            isUser ?
              <>
                <i className='icon bi bi-person-bounding-box fs-5x'></i>
              </>
              :
              <div className='d-block text-center'>
                <i className="icon bi bi-buildings fs-4x"></i>
                <span className='d-block text-center text-small mt-2'>
                  {type === 'logo' ? <>  <span className='text-danger'>*</span> {model} logo</> : <> <span className='text-danger'>*</span>  {model} banner image</>}
                </span>

              </div>
          )}
        </div>
        {/* end::User */}
        <button className='uploade-icon' onClick={() => handleClick()}>
          <i className='bi bi-plus-lg fs-l'></i>
        </button>
        <button
          className='trash-icon btn btn-icon btn-bg-light btn-active-color-primary btn-sm absolute'
          onClick={() => {
            setSelectimage('')
            setImagefile('null')
            setDelete(true)
          }}
        >
          <i className='bi bi-trash text-danger fs-2'></i>
        </button>
      </div>
    </div>
  )
}
export default UploadeProfileImage
