import { MouseEventHandler, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useThemeMode } from '../../_metronic/partials'
import { deleteAttachments, updateUserAttachmentsStatus } from '../services/user'
import { formatDate } from '../utils/DateFormatter'
import { exportTableToExcel } from '../utils/export'
import { DeleteAttachment } from './delete'

type Props = {
  list: any[],
  handleListAll: any

}

export const AttachmentsTable = (props: Props) => {
  const { list, handleListAll } = props
  const [loading, setLoading] = useState<any>(false)
  const [showModal, setShowModal] = useState(false)
  const [id, setId] = useState('')
  const cancelFn = () => {
    setShowModal(false)
  }
  const showDeleteModal = (_id: any) => {
    setId(_id)
    setShowModal(true)
  }

  const deleteAttachment = async () => {
    setLoading(true)
    const res = await deleteAttachments(id)
    setShowModal(false)
    setLoading(false)
  }

  const delteFn = () => {
    deleteAttachment()
  }
  // 
  const [loadingApprove, setLoadingApprove] = useState(false)
  const [loadingDecline, setLoadingDecline] = useState(false)

  const changeUserAttachmentsStatus = async (id: any, status: any) => {
    setId(id)
    status == 'rejected' ? setLoadingDecline(true) : setLoadingApprove(true)
    const response = await updateUserAttachmentsStatus(id, status)
    if (response) {
      status == 'rejected' ? setLoadingDecline(false) : setLoadingApprove(false)
      handleListAll()
    }

  }
  type Data = typeof list;
  type SortKeys = keyof Data[0];
  type SortOrder = "ascn" | "desc";
  function sortData({
    tableData,
    sortKey,
    reverse,
  }: {
    tableData: Data;
    sortKey: SortKeys;
    reverse: boolean;
  }) {
    if (!sortKey) return tableData;

    const sortedData = shownData.sort((a: any, b: any) => {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  }

  function SortButton({
    sortOrder,
    columnKey,
    sortKey,
    onClick,
  }: {
    sortOrder: SortOrder;
    columnKey: SortKeys;
    sortKey: SortKeys;
    onClick: MouseEventHandler<HTMLButtonElement>;
  }) {
    const { mode } = useThemeMode()
    return (
      <button
        onClick={onClick}
        className={`${mode==='dark'?'text-white':'text-dark'}  ${sortKey === columnKey && sortOrder === "desc"
          ? "sort-button sort-reverse"
          : "sort-button"
          }`}
      >
        ▲
      </button>
    );
  }
  const [sortKey, setSortKey] = useState<any>("studentName");
  const [sortOrder, setSortOrder] = useState<SortOrder>("ascn");

  const sortedData = useCallback(
    () => sortData({ tableData: shownData, sortKey, reverse: sortOrder === "desc" }),
    [list, sortKey, sortOrder]
  );

  function changeSort(key: SortKeys) {
    setSortOrder(sortOrder === "ascn" ? "desc" : "ascn");
    setSortKey(key);
  }

  const [shownData, setShownData] = useState<any>([])

  const headers: { key: SortKeys; label: string }[] = [
    { key: "studentName", label: "Student Name" },
    { key: "name", label: "Name Of Document" },
    { key: "visibility", label: "Profile Visibility" },
    { key: "date", label: "Date Uploaded" },
    { key: "institution", label: "Institution" },
    { key: "status", label: "Status" },

  ];
  useEffect(() => {
    const data = sortedData().map((item: any) => (
      {
        _id: item?._id,
        name: item?.name,
        visibility: item?.visibility,
        date: item.date,
        status: item.status,
        attachment: item?.attachment,
        institution:item?.institution,
        studentName:item?.studentName,
        studentId:item?.studentId

      }
    ))
    setShownData(data)
  }, [sortedData])
  useEffect(() => {
    if (list) {
      const data = list.map((item: any) => (
        {

          _id: item?._id,
          name: item?.name,
          visibility: item?.visibility ? 'Visible' : 'Not Visible',
          date: formatDate(item?.dateUploaded),
          status: item.status,
          attachment: item?.attachment,
          institution:item?.user?.subModel?.institution?.nameEn||'N/A',
          studentName:item?.user?.subModel?.firstName+' '+item?.user?.subModel?.lastName,
          studentId:item?.user?._id
        }
      ))
      setShownData(data)
    }

  }, [list])
  const handleExport = (id: string) => {
    exportTableToExcel(id, 'student-document-reviews.xlsx')
  }
  return (
    <>
      <div>
      <a
          className='text-danger d-flex justify-content-end mx-3 mb-4 align-items-center'
          style={{ fontSize: '14px', fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => {
            handleExport(`student-document-reviews-list`)
          }}
        >
          Export table <i className="bi bi-arrow-bar-up text-danger mx-2"></i>
        </a>
        <div className={`card `}>
          {/* begin::Body */}
          <div className='card-body py-3 '>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' id='student-document-reviews-list'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    {headers.map((row) => {
                      return (
                        <td className={`cursor-pointer ${sortKey === row.key ? 'text-primary' : ''}`}
                          onClick={() => changeSort(row.key)}
                          {...{
                            sortOrder,
                            sortKey,
                          }}>
                          {row.label}

                          <SortButton
                            columnKey={row.key}
                            onClick={() => changeSort(row.key)}
                            {...{
                              sortOrder,
                              sortKey,
                            }}
                          />

                        </td>
                      );
                    })}
                    <td className='text-center'>Actions</td>
                  </tr>
                </thead>
                {/* end::Table head */}
                {shownData?.map((item: any) => {
                  return (
                    <>
                      {/* begin::Table body */}
                      <tbody>
                        <tr>
                        
                        <td>
                            <div className='d-flex align-items-start'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark fw-bold  fs-6'>
                                  <Link className='text-black text-capitalize fw-bold'
                                    to={`/student/details/${item.studentId}/${item?.studentName}`}> {item.studentName}</Link>

                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-start'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark   fs-6'>
                                  {item.name}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='text-dark   d-block fs-6 '>
                              {item?.visibility}
                            </div>
                          </td>
                          <td>
                            {item?.date}
                          </td>
                          <td>
                            {item?.institution}
                          </td>
                          
                          <td>
                            <div className={`${item?.status == 'approved' ? 'text-success' : item?.status == 'rejected' ? 'text-danger' : 'text-info'} fw-bold  d-block fs-6 text-capitalize`}>
                              {item?.status}
                            </div>
                          </td>
                          <td>


                            <div className='d-flex justify-content-end flex-shrink-0'>
                              {item?.status === 'pending' &&
                                <>
                                  <button
                                    className='btn btn-bg-light btn-active-color-primary btn-sm me-2 text-success'
                                    onClick={() => changeUserAttachmentsStatus(item._id, 'approved')}
                                  >
                                    {loadingApprove && item._id === id ?
                                      <div className="spinner-border text-dark" role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                      : 'Approve'
                                    }

                                  </button>
                                  <button
                                    onClick={() => changeUserAttachmentsStatus(item._id, 'rejected')}
                                    className='btn  btn-bg-light btn-active-color-primary btn-sm me-2 text-danger'>

                                    {loadingDecline && item._id === id ?
                                      <div className="spinner-border text-dark" role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                      : 'Decline'
                                    }
                                  </button>
                                </>

                              }
                              <a
                                href={item?.attachment}
                                target="_blank"
                                className='btn  btn-bg-light btn-active-color-primary btn-sm me-1 me-2'
                              >
                                View
                              </a>
                              <button
                                onClick={() => showDeleteModal(item?._id)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'>
                                <i className='bi bi-trash text-danger fs-2'></i>
                              </button>
                            </div>
                          </td>
                        </tr>


                      </tbody>

                      {/* end::Table body */}
                    </>
                  )
                })}
              </table>
              {showModal && (
                <DeleteAttachment
                  show={showModal}
                  onCancel={cancelFn}
                  onDelete={delteFn}
                  loading={loading}

                />
              )}
              {shownData.length == 0 && <div className='flex p-6 text-center'>
                No data available</div>}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      </div>
    </>
  )
}
