import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Table} from './components/table'
import {IMentorShipType} from './module.interface'
import {PageTitle} from '../../../components/pageTitle'
import {getAllMentorShipType} from '../../../services/mentorShipType'

export const MentorShipType = () => {
  const [mentorShipTypeList, setMentorShipTypeList] = useState<Array<IMentorShipType>>([])
  const [loading, setLoading] = useState<any>(false)

  const listMentorShipType = async () => {
    setLoading(true)
    const response = await getAllMentorShipType()

    setMentorShipTypeList(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    listMentorShipType()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Mentor Ship Type' />
        <Link to='/mentor-ship-type/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
      {loading ? (
        <div className="spinner-border text-light" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      ) : (
        <Table list={mentorShipTypeList} listALl={listMentorShipType} />
      )}
    </>
  )
}
