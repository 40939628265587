import {Link} from 'react-router-dom'

type Props = {
  breadCrampList?: Array<any>
  active: string
}

export const PageTitle = (props: Props) => {
  const {breadCrampList, active} = props
  return (
    <>
      <ol className='breadcrumb text-muted fs-6 fw-bold '>
        {breadCrampList &&
          breadCrampList.map((item) => {
            return (
              <li className='breadcrumb-item pe-3'>
                <h3 className='m-0'>
                  <Link to={item.route} className='pe-3'>
                    {item.name}
                  </Link>
                </h3>
              </li>
            )
          })}

        <li className='breadcrumb-item px-3 text-muted'>
          <h3 className='m-0'>{active}</h3>
        </li>
      </ol>
    </>
  )
}
