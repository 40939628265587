import axios from '../axios'
import { BASEURL } from '../environments/environment'

export const getAllCompanies = () => {
  return axios
    .get(`${BASEURL}user?model=Company`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getCompany = (_id: any) => {
  return axios
    .get(`${BASEURL}user/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
export const getUserNewData = (_id: any) => {
  return axios
    .get(`${BASEURL}user/new-version/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateCompany = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}user/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createCompany = (payload: any) => {
  return axios
    .post(`${BASEURL}company`, payload)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteCompany = (_id: string) => {
  return axios
    .delete(`${BASEURL}company/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const CompanyFollowers = (_id: any) => {
  return axios
    .get(`${BASEURL}company/dashboard/users-followed-company/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const listAllCompanyReviews = (id: any) => {
  return axios
    .get(`${BASEURL}review?company=${id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const listAllReviews = (page: any = null, pageSize: any = null) => {
  const pagination = page != null || pageSize != null ? `&page=${page}&pageSize=${pageSize}` : ''
  return axios
    .get(`${BASEURL}review?approved=pending${pagination}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};