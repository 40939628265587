import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllAreaInterest = (page:number=0,pageSize:number=0) => {
    const pagination =  page || pageSize ? `?page=${page}&pageSize=${pageSize}`: ''
  return axios
    .get(`${BASEURL}areaInterest${pagination}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}



export const getAreaInterest = (_id: any) => {
  return axios
    .get(`${BASEURL}areaInterest/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}



export const updateAreaInterest = (_id: any, data:any) => {
  return axios
    .patch(`${BASEURL}areaInterest/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createAreaInterest = (order: number, nameEn: string, nameAr: string) => {
  return axios
    .post(`${BASEURL}areaInterest`, {order: order, nameEn: nameEn, nameAr: nameAr})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteAreaInterest = (_id: string) => {
  return axios
    .delete(`${BASEURL}areaInterest/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}


