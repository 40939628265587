import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Table } from './components/table'
import { IEducationLevel } from './module.interface'
import { PageTitle } from '../../../components/pageTitle'
import { getAllEducationLevels } from '../../../services/educationLevel'
import Pagination from '../../../components/Pagination'
import SearchInput from '../../../components/SearchInput'
import TableBodyLoading from '../../../components/tableBodyLoading'

export const EducationLevel = () => {
  const [educationLevelList, setEducationLevelList] = useState<Array<IEducationLevel>>([])
  const [loading, setLoading] = useState<any>(true)
  const [dataLength, setDataLength] = useState<any>(0)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(5)
  const [searchActive, setSearchActive] = useState(false)

  const listEducationLevels = async () => {
    setLoading(true)
    const response = await getAllEducationLevels(currentPage, pageSize)
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / pageSize))
    setDataLength(response.data.data.queryDataLength)
    setEducationLevelList(response.data.data.queryResult)
    setLoading(false)
  }

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleCountChange = (e: any) => {
    setPageSize(e.target.value)

  }
  const setDataResult = (response: any) => {
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / pageSize))
    setDataLength(response.data.data.queryDataLength)
    setEducationLevelList(response.data.data.queryResult)
    setLoading(false)
  }
  useEffect(() => {
    !searchActive && listEducationLevels()
  }, [currentPage, pageSize])

  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Education Level' />
        <Link to='/education-level/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
      <SearchInput
        model={'educationLevel'}
        setLoading={setLoading}
        setDataResult={setDataResult}
        ListAll={listEducationLevels}
        currentPage={currentPage}
        pageSize={pageSize}
        setSearchActive={setSearchActive}
        setCurrentPage={setCurrentPage}

      />

      <>
       
          <Table list={educationLevelList} listAll={listEducationLevels} length={dataLength} skelton={loading}/>
   
        <div className='d-flex justify-content-between'>

          <div className='d-flex justify-end my-6'>
            <Pagination
              totalCount={totalPages}
              onPageChange={handlePaginationChange}
              currentPage={currentPage}
            />

          </div>
          <select
            className='form-select mr-4 mt-4'
            style={{ width: '75px', height: '50px' }}
            onChange={handleCountChange}
            value={pageSize}
          >
            <option value={pageSize}>{pageSize}</option>
            <option value='10'>10</option>
            <option value='20'>20</option>
            <option value='30'>30</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        </div>
      </>

    </>
  )
}
