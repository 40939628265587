import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Table} from './components/table'
import {ILanguage} from './module.interface'
import {PageTitle} from '../../../components/pageTitle'
import {getAllLanguage} from '../../../services/language'
import Pagination from '../../../components/Pagination'
import SearchInput from '../../../components/SearchInput'

export const Language = () => {
  const [languageList, setLanguageList] = useState<Array<ILanguage>>([])
  const [loading, setLoading] = useState<any>(false)
  const [dataLength, setDataLength] = useState<any>(0)
  const [searchActive, setSearchActive] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(5)

  const listLanguage = async () => {
    setLoading(true)
    const response = await getAllLanguage(currentPage,pageSize)
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / pageSize))
    setDataLength(response.data.data.queryDataLength)
    setLanguageList(response.data.data.queryResult)
    setLoading(false)
  }
  const setDataResult = (response: any) => {
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / pageSize))
    setDataLength(response.data.data.queryDataLength)
    setLanguageList(response.data.data.queryResult)
    setLoading(false)
  }
  const handlePaginationChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleCountChange = (e: any) => {
    setPageSize(e.target.value)
  }

  useEffect(() => {
   !searchActive&& listLanguage()
  }, [currentPage,pageSize])

  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Language' />
        <Link to='/language/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
      <SearchInput
        model={'language'}
        setLoading={setLoading}
        setDataResult={setDataResult}
        ListAll={listLanguage}
        currentPage={currentPage}
        pageSize={pageSize}
        setSearchActive={setSearchActive}
        setCurrentPage={setCurrentPage}

      />
      {loading ? (
        <div className="spinner-border text-light" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      ) : (
        <>
          <Table list={languageList} listALl={listLanguage} length={dataLength} />
          <div className='d-flex justify-content-between'>
       
              <>
                <div className='flex justify-end my-6'>
                  <Pagination
                    totalCount={totalPages}
                    onPageChange={handlePaginationChange}
                    currentPage={currentPage}
                  />
                </div>
                <select
                  className='form-select mr-4 mt-4'
                  style={{width: '75px', height: '50px'}}
                  onChange={handleCountChange}
                  value={pageSize}
                >
                   <option value='10'>5</option>
                  <option value='10'>10</option>
                  <option value='20'>20</option>
                  <option value='30'>30</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                </select>
              </>
           
          </div>
        </>
      )}
    </>
  )
}
