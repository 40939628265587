import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Table } from './components/table'
import { PageTitle } from '../../../components/pageTitle'
import { ICountry } from './module.interface'
import { getAllCountries } from '../../../services/Country'
import SearchInput from '../../../components/SearchInput'
import Pagination from '../../../components/Pagination'

export const Country = () => {
  const [countryList, setCountryList] = useState<Array<ICountry>>([])
  const [loading, setLoading] = useState<any>(false)


  const [totalPages, setTotalPages] = useState(0)
  const [pageSize, setPageSize] = useState(5)
  const [currentPage, setCurrentPage] = useState(0)
  const [searchActive, setSearchActive] = useState(false)

  const listAllCountry = async (Size: any) => {
    setLoading(true)
    const response = await getAllCountries(currentPage, pageSize)
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / pageSize))
    setCountryList(response.data.data.queryResult)
    setLoading(false)
  }

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    !searchActive && listAllCountry(pageSize)
  }, [currentPage, pageSize])

  const handleCountChange = (e: any) => {
    setPageSize(e.target.value)

  }
  const setDataResult = (response: any) => {
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / pageSize))
    setCountryList(response.data.data.queryResult)
    setLoading(false)
  }
  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Country' />
        <Link to='/country/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
      <SearchInput
        model={'country'}
        setLoading={setLoading}
        setDataResult={setDataResult}
        ListAll={listAllCountry}
        pageSize={pageSize}
        setSearchActive={setSearchActive}
        setCurrentPage={setCurrentPage} 
        />

      <Table list={countryList} listALl={listAllCountry} length={pageSize} skelton={loading} />
      <div className='d-flex justify-content-between'>
  
          <>
            <div className='flex justify-end my-6'>
              <Pagination
                totalCount={totalPages}
                onPageChange={handlePaginationChange}
                currentPage={currentPage}

              />
            </div>
            <select
              className='form-select mr-4 mt-4'
              style={{ width: '75px', height: '50px' }}
              onChange={handleCountChange}
              value={pageSize}
            >
              <option value={pageSize}>{pageSize}</option>
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='30'>30</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
          </>
    
      </div>

    </>
  )
}
