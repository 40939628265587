import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { DashboardWrapperInistitutions } from '../pages/dashboard/DashboardWrapperInistitutions'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { DidYouGetOffer } from '../pages/modules/sub-modules/did-you-get-offer'
import { Create } from '../pages/modules/sub-modules/did-you-get-offer/create'
import { Update } from '../pages/modules/sub-modules/did-you-get-offer/update'
import { DomainModel } from '../pages/modules/sub-modules/domain'
import { CreateDomain } from '../pages/modules/sub-modules/domain/create'
import { UpdateDomain } from '../pages/modules/sub-modules/domain/update'
import { DidYouGetPaid } from '../pages/modules/sub-modules/did-you-get-paid'
import { CreatePaid } from '../pages/modules/sub-modules/did-you-get-paid/create'
import { UpdatePaid } from '../pages/modules/sub-modules/did-you-get-paid/update'
import { MentoringProgram } from '../pages/modules/sub-modules/mentoring-program-options'
import { CreateMentoring } from '../pages/modules/sub-modules/mentoring-program-options/create'
import { UpdateMentoring } from '../pages/modules/sub-modules/mentoring-program-options/update'
import { FindingJob } from '../pages/modules/sub-modules/resources-finding-job'
import { CreateFindingJob } from '../pages/modules/sub-modules/resources-finding-job/create'
import { UpdateFindingJob } from '../pages/modules/sub-modules/resources-finding-job/update'
import { SocialMedia } from '../pages/modules/sub-modules/social-media'
import { CreateSocialMedia } from '../pages/modules/sub-modules/social-media/create'
import { UpdateSocialMedia } from '../pages/modules/sub-modules/social-media/update'
import { TypeOfWork } from '../pages/modules/sub-modules/type-of-work'
import { CreateTypeOfWork } from '../pages/modules/sub-modules/type-of-work/create'
import { UpdateTypeOfWork } from '../pages/modules/sub-modules/type-of-work/update'
import { User } from '../pages/modules/user'
import { City } from '../pages/modules/city'
import { CreateCity } from '../pages/modules/city/create'
import { UpdateCity } from '../pages/modules/city/update'
import { Country } from '../pages/modules/country'
import { CreateCountry } from '../pages/modules/country/create'
import { UpdateCountry } from '../pages/modules/country/update'
import { AreaInterest } from '../pages/modules/area-interest'
import { CreateAreaInterest } from '../pages/modules/area-interest/create'
import { UpdateAreaInterest } from '../pages/modules/area-interest/update'
import { Currency } from '../pages/modules/currency'
import { CreateCurrency } from '../pages/modules/currency/create'
import { UpdateCurrency } from '../pages/modules/currency/update'
import { JobRole } from '../pages/modules/job-role'
import { CreateJobRole } from '../pages/modules/job-role/create'
import { UpdateJobRole } from '../pages/modules/job-role/update'
import { JobType } from '../pages/modules/job-type'
import { CreateJobType } from '../pages/modules/job-type/create'
import { UpdateJobType } from '../pages/modules/job-type/update'
import { KeyWays } from '../pages/modules/key-ways'
import { CreateKeyWays } from '../pages/modules/key-ways/create'
import { UpdatekeyWays } from '../pages/modules/key-ways/update'
import { Language } from '../pages/modules/language'
import { CreateLanguage } from '../pages/modules/language/create'
import { UpdateLanguage } from '../pages/modules/language/update'
import { Major } from '../pages/modules/major'
import { CreateMajor } from '../pages/modules/major/create'
import { UpdateMajor } from '../pages/modules/major/update'
import { MentorShipType } from '../pages/modules/mentor-ship-type'
import { CreateMentorShipType } from '../pages/modules/mentor-ship-type/create'
import { UpdateMentorShipType } from '../pages/modules/mentor-ship-type/update'
import { WorkType } from '../pages/modules/work-type'
import { CreateWorkType } from '../pages/modules/work-type/create'
import { UpdateWorkType } from '../pages/modules/work-type/update'
import { Industry } from '../pages/modules/industry'
import CreateIndustry from '../pages/modules/industry/create'
import { UpdateIndustry } from '../pages/modules/industry/update'
import { SubIndustry } from '../pages/modules/sub-industry'
import CreateSubIndustry from '../pages/modules/sub-industry/create'
import { UpdateSubIndustry } from '../pages/modules/sub-industry/update'

import CreateMeetingDuration from '../pages/modules/meetingDuration/create'
import { UpdateMeetingDuration } from '../pages/modules/meetingDuration/update'
import { MeetingDuration } from '../pages/modules/meetingDuration'

import { EducationLevel } from '../pages/modules/educationLevel'
import CreateEducationLevel from '../pages/modules/educationLevel/create'
import { UpdateEducationLevel } from '../pages/modules/educationLevel/update'
import { Review } from '../pages/modules/review'
import { UpdateReview } from '../pages/modules/review/update'
import CreateReview from '../pages/modules/review/create'
import { Jobs } from '../pages/modules/job'
import { ViewJob } from '../pages/modules/job/view'
import CreateJob from '../pages/modules/job/create'
import { UpdateJob } from '../pages/modules/job/update'
import { NotificationsMethod } from '../pages/modules/notificationMethod'
import { CreateNotificationMethod } from '../pages/modules/notificationMethod/create'
import { UpdateNotificationMethod } from '../pages/modules/notificationMethod/update'
import { NotificationsType } from '../pages/modules/notificationType'
import { UpdateNotificationType } from '../pages/modules/notificationType/update'

import { UpdateUser } from '../pages/modules/user/update'
import { CreateUser } from '../pages/modules/user/create'
import { ViewCompany } from '../pages/modules/company/view'
import { ViewMentor } from '../pages/modules/mentor/view'
import { ViewInstitution } from '../pages/modules/institution/view'
import ReviewDetails from '../pages/modules/review/View'
import { Admin } from '../pages/modules/admin'
import { CreateAdmin } from '../pages/modules/admin/create'
import { UpdateAdmin } from '../pages/modules/admin/update'
import { CreateNotificationType } from '../pages/modules/notificationType/create'
import { ViewStudent } from '../pages/modules/student/view'
import { MentorShipGuideLine } from '../pages/modules/mentor-ship-guide-line'
import { CreateMentorShipGuideLine } from '../pages/modules/mentor-ship-guide-line/create'
import { UpdateMentorShipGuideLine } from '../pages/modules/mentor-ship-guide-line/update'
import { ViewJobApplication } from '../pages/modules/job-application/view'
import { InstitutionStudents } from '../pages/modules/user/institutions-students'
import { InstitutionStudentsList } from '../pages/modules/user/institutions-students-list'
import { FormManagement } from '../pages/modules/sub-modules/form-management'
import { UniversalFields } from '../pages/modules/sub-modules/universal-fields'
import { CompanyRelated } from '../pages/modules/sub-modules/company-related'
import { MentoringProgramView } from '../pages/modules/sub-modules/mentoring-program-options/view'
import { BendingAttachments } from '../pages/modules/attachments'
import { UserChanges } from '../pages/modules/user/info-changes'
import { ViewCompanyApproval } from '../pages/modules/company/ViewCompanyApproval'
import { ViewInstitutionApproval } from '../pages/modules/institution/ViewInstitutionApproval'
import { AllReview } from '../pages/modules/review/all-Reviews'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route
          path='/institutions-dashboard'
          element={
            <SuspensedView>
              <DashboardWrapperInistitutions />
            </SuspensedView>
          }
        />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        <Route
          path='form-management'
          element={
            <SuspensedView>
              <FormManagement />
            </SuspensedView>
          }
        />
        <Route
          path='universal-fields'
          element={
            <SuspensedView>
              <UniversalFields />
            </SuspensedView>
          }
        />
        <Route
          path='company-related'
          element={
            <SuspensedView>
              <CompanyRelated />
            </SuspensedView>
          }
        />

        <Route
          path='did-you-get-offer'
          element={
            <SuspensedView>
              <DidYouGetOffer />
            </SuspensedView>
          }
        />
        <Route
          path='/did-you-get-offer/create'
          element={
            <SuspensedView>
              <Create />
            </SuspensedView>
          }
        />
        <Route
          path='/did-you-get-offer/update/:id'
          element={
            <SuspensedView>
              <Update />
            </SuspensedView>
          }
        />
        <Route
          path='domain'
          element={
            <SuspensedView>
              <DomainModel />
            </SuspensedView>
          }
        />
        <Route
          path='/domain/create'
          element={
            <SuspensedView>
              <CreateDomain />
            </SuspensedView>
          }
        />
        <Route
          path='/domain/update/:id'
          element={
            <SuspensedView>
              <UpdateDomain />
            </SuspensedView>
          }
        />
        <Route
          path='did-you-get-paid'
          element={
            <SuspensedView>
              <DidYouGetPaid />
            </SuspensedView>
          }
        />
        <Route
          path='/did-you-get-paid/create'
          element={
            <SuspensedView>
              <CreatePaid />
            </SuspensedView>
          }
        />
        <Route
          path='/did-you-get-paid/update/:id'
          element={
            <SuspensedView>
              <UpdatePaid />
            </SuspensedView>
          }
        />
        <Route
          path='mentoring-program-options'
          element={
            <SuspensedView>
              <MentoringProgram />
            </SuspensedView>
          }
        />
        <Route
          path='/mentoring-program-options/create'
          element={
            <SuspensedView>
              <CreateMentoring />
            </SuspensedView>
          }
        />
         {/* <Route
          path='/mentor-'
          element={
            <SuspensedView>
              <CreateMentoring />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/mentoring-program-options/update/:id'
          element={
            <SuspensedView>
              <UpdateMentoring />
            </SuspensedView>
          }
        />
        <Route
          path='/mentorship-program'
          element={
            <SuspensedView>
              <MentoringProgram />
            </SuspensedView>
          }
        />
        <Route
          path='/mentorship-program/:id'
          element={
            <SuspensedView>
              <MentoringProgramView />
            </SuspensedView>
          }
        />
        <Route
          path='resources-finding-job'
          element={
            <SuspensedView>
              <FindingJob />
            </SuspensedView>
          }
        />
        <Route
          path='/resources-finding-job/create'
          element={
            <SuspensedView>
              <CreateFindingJob />
            </SuspensedView>
          }
        />
        <Route
          path='/resources-finding-job/update/:id'
          element={
            <SuspensedView>
              <UpdateFindingJob />
            </SuspensedView>
          }
        />
        <Route
          path='social-media'
          element={
            <SuspensedView>
              <SocialMedia />
            </SuspensedView>
          }
        />
        <Route
          path='/social-media/create'
          element={
            <SuspensedView>
              <CreateSocialMedia />
            </SuspensedView>
          }
        />
        <Route
          path='/social-media/update/:id'
          element={
            <SuspensedView>
              <UpdateSocialMedia />
            </SuspensedView>
          }
        />
        {/* <Route
          path='type-of-work'
          element={
            <SuspensedView>
              <TypeOfWork />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/type-of-work/create'
          element={
            <SuspensedView>
              <CreateTypeOfWork />
            </SuspensedView>
          }
        />
        <Route
          path='/type-of-work/update/:id'
          element={
            <SuspensedView>
              <UpdateTypeOfWork />
            </SuspensedView>
          }
        />
        {/* <Route
          path='/user'
          element={
            <SuspensedView>
              <User />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/city'
          element={
            <SuspensedView>
              <City />
            </SuspensedView>
          }
        />
        <Route
          path='/city/create'
          element={
            <SuspensedView>
              <CreateCity />
            </SuspensedView>
          }
        />
        <Route
          path='/city/update/:id'
          element={
            <SuspensedView>
              <UpdateCity />
            </SuspensedView>
          }
        />
        <Route
          path='/country'
          element={
            <SuspensedView>
              <Country />
            </SuspensedView>
          }
        />
        <Route
          path='/country/create'
          element={
            <SuspensedView>
              <CreateCountry />
            </SuspensedView>
          }
        />
        <Route
          path='/country/update/:id'
          element={
            <SuspensedView>
              <UpdateCountry />
            </SuspensedView>
          }
        />
        <Route
          path='/area-interest'
          element={
            <SuspensedView>
              <AreaInterest />
            </SuspensedView>
          }
        />
        <Route
          path='/area-interest/create'
          element={
            <SuspensedView>
              <CreateAreaInterest />
            </SuspensedView>
          }
        />
        <Route
          path='/area-interest/update/:id'
          element={
            <SuspensedView>
              <UpdateAreaInterest />
            </SuspensedView>
          }
        />
        <Route
          path='/company-guide-line'
          element={
            <SuspensedView>
              <AreaInterest />
            </SuspensedView>
          }
        />
        <Route
          path='/company-guide-line/create'
          element={
            <SuspensedView>
              <CreateAreaInterest />
            </SuspensedView>
          }
        />
        <Route
          path='/company-guide-line/update/:id'
          element={
            <SuspensedView>
              <UpdateAreaInterest />
            </SuspensedView>
          }
        />
        <Route
          path='/currency'
          element={
            <SuspensedView>
              <Currency />
            </SuspensedView>
          }
        />
        <Route
          path='/currency/create'
          element={
            <SuspensedView>
              <CreateCurrency />
            </SuspensedView>
          }
        />
        <Route
          path='/currency/update/:id'
          element={
            <SuspensedView>
              <UpdateCurrency />
            </SuspensedView>
          }
        />
        <Route
          path='/job-application/details/:id'
          element={
            <SuspensedView>
              <ViewJobApplication />
            </SuspensedView>
          }
        />
        <Route
          path='/jobs'
          element={
            <SuspensedView>
              <Jobs />
            </SuspensedView>
          }
        />
        <Route
          path='/job/details/:id'
          element={
            <SuspensedView>
              <ViewJob />
            </SuspensedView>
          }
        />
        <Route
          path='/jobs/create'
          element={
            <SuspensedView>
              <CreateJob />
            </SuspensedView>
          }
        />
        <Route
          path='/jobs/update/:id'
          element={
            <SuspensedView>
              <UpdateJob />
            </SuspensedView>
          }
        />
        <Route
          path='/job-role'
          element={
            <SuspensedView>
              <JobRole />
            </SuspensedView>
          }
        />
        <Route
          path='/job-role/create'
          element={
            <SuspensedView>
              <CreateJobRole />
            </SuspensedView>
          }
        />
        <Route
          path='/job-role/update/:id'
          element={
            <SuspensedView>
              <UpdateJobRole />
            </SuspensedView>
          }
        />
        <Route
          path='/job-type'
          element={
            <SuspensedView>
              <JobType />
            </SuspensedView>
          }
        />
        <Route
          path='/job-type/create'
          element={
            <SuspensedView>
              <CreateJobType />
            </SuspensedView>
          }
        />
        <Route
          path='/job-type/update/:id'
          element={
            <SuspensedView>
              <UpdateJobType />
            </SuspensedView>
          }
        />
        <Route
          path='/key-ways'
          element={
            <SuspensedView>
              <KeyWays />
            </SuspensedView>
          }
        />
        <Route
          path='/key-ways/create'
          element={
            <SuspensedView>
              <CreateKeyWays />
            </SuspensedView>
          }
        />
        <Route
          path='/key-ways/update/:id'
          element={
            <SuspensedView>
              <UpdatekeyWays />
            </SuspensedView>
          }
        />
        <Route
          path='/language'
          element={
            <SuspensedView>
              <Language />
            </SuspensedView>
          }
        />
        <Route
          path='/language/create'
          element={
            <SuspensedView>
              <CreateLanguage />
            </SuspensedView>
          }
        />
        <Route
          path='/language/update/:id'
          element={
            <SuspensedView>
              <UpdateLanguage />
            </SuspensedView>
          }
        />
        <Route
          path='/major'
          element={
            <SuspensedView>
              <Major />
            </SuspensedView>
          }
        />
        <Route
          path='/major/create'
          element={
            <SuspensedView>
              <CreateMajor />
            </SuspensedView>
          }
        />
        <Route
          path='/major/update/:id'
          element={
            <SuspensedView>
              <UpdateMajor />
            </SuspensedView>
          }
        />
        <Route
          path='/mentor-ship-guide-line'
          element={
            <SuspensedView>
              <MentorShipGuideLine />
            </SuspensedView>
          }
        />
        <Route
          path='/mentor-ship-guide-line/create'
          element={
            <SuspensedView>
              <CreateMentorShipGuideLine />
            </SuspensedView>
          }
        />
        <Route
          path='/mentor-ship-guide-line/update/:id'
          element={
            <SuspensedView>
              <UpdateMentorShipGuideLine />
            </SuspensedView>
          }
        />
        <Route
          path='/mentor-ship-type'
          element={
            <SuspensedView>
              <MentorShipType />
            </SuspensedView>
          }
        />
        <Route
          path='/mentor-ship-type/create'
          element={
            <SuspensedView>
              <CreateMentorShipType />
            </SuspensedView>
          }
        />
        <Route
          path='/mentor-ship-type/update/:id'
          element={
            <SuspensedView>
              <UpdateMentorShipType />
            </SuspensedView>
          }
        />
        <Route
          path='/work-type'
          element={
            <SuspensedView>
              <WorkType />
            </SuspensedView>
          }
        />
        <Route
          path='/work-type/create'
          element={
            <SuspensedView>
              <CreateWorkType />
            </SuspensedView>
          }
        />
        <Route
          path='/work-type/update/:id'
          element={
            <SuspensedView>
              <UpdateWorkType />
            </SuspensedView>
          }
        />
        {/* Industry Routes */}
        <Route
          path='/industry'
          element={
            <SuspensedView>
              <Industry />
            </SuspensedView>
          }
        />
        <Route
          path='/industry/create'
          element={
            <SuspensedView>
              <CreateIndustry />
            </SuspensedView>
          }
        />
        <Route
          path='/industry/update/:id'
          element={
            <SuspensedView>
              <UpdateIndustry />
            </SuspensedView>
          }
        />
        {/* Sub-industry */}
        <Route
          path='/sub-industry'
          element={
            <SuspensedView>
              <SubIndustry />
            </SuspensedView>
          }
        />
        <Route
          path='/sub-industry/create'
          element={
            <SuspensedView>
              <CreateSubIndustry />
            </SuspensedView>
          }
        />
        <Route
          path='/sub-industry/update/:id'
          element={
            <SuspensedView>
              <UpdateSubIndustry />
            </SuspensedView>
          }
        />
        <Route
          path='/education-level'
          element={
            <SuspensedView>
              <EducationLevel />
            </SuspensedView>
          }
        />
        <Route
          path='/meeting-duration'
          element={
            <SuspensedView>
              <MeetingDuration />
            </SuspensedView>
          }
        />
        <Route
          path='/meeting-duration/create'
          element={
            <SuspensedView>
              <CreateMeetingDuration />
            </SuspensedView>
          }
        />
        <Route
          path='/education-level/create'
          element={
            <SuspensedView>
              <CreateEducationLevel />
            </SuspensedView>
          }
        />
        <Route
          path='/education-level/update/:id'
          element={
            <SuspensedView>
              <UpdateEducationLevel />
            </SuspensedView>
          }
        />
        <Route
          path='/admins'
          element={
            <SuspensedView>
              <Admin />
            </SuspensedView>
          }
        />
           <Route
          path='/students-document-reviews'
          element={
            <SuspensedView>
              <BendingAttachments />
            </SuspensedView>
          }
        />
        
        <Route
          path='/admins/create'
          element={
            <SuspensedView>
              <CreateAdmin />
            </SuspensedView>
          }
        />
        <Route
          path='/admins/update/:id'
          element={
            <SuspensedView>
              <UpdateAdmin />
            </SuspensedView>
          }
        />
        <Route
          path='/meeting-duration/update/:id'
          element={
            <SuspensedView>
              <UpdateMeetingDuration />
            </SuspensedView>
          }
        />
        <Route
          path='/notificationMethod'
          element={
            <SuspensedView>
              <NotificationsMethod />
            </SuspensedView>
          }
        />
        <Route
          path='/notificationMethod/create'
          element={
            <SuspensedView>
              <CreateNotificationMethod />
            </SuspensedView>
          }
        />
        <Route
          path='/notificationMethod/update/:id'
          element={
            <SuspensedView>
              <UpdateNotificationMethod />
            </SuspensedView>
          }
        />
        <Route
          path='/reviews'
          element={
            <SuspensedView>
              <Review />
            </SuspensedView>
          }
        />
        <Route
          path='/review/details/:id'
          element={
            <SuspensedView>
              <ReviewDetails />
            </SuspensedView>
          }
        />

        <Route
          path='/all-users'
          element={
            <SuspensedView>
              <User model='users' />
            </SuspensedView>
          }
        />
          <Route
          path='/all-users-changes'
          element={
            <SuspensedView>
              <UserChanges model='users' />
            </SuspensedView>
          }
        />
          <Route
          path='/all-reviews'
          element={
            <SuspensedView>
              <AllReview  />
            </SuspensedView>
          }
        />
        

        <Route
          path='/users/create'
          element={
            <SuspensedView>
              <CreateUser model={'users'} />
            </SuspensedView>
          }
        />

        <Route
          path='/companies'
          element={
            <SuspensedView>
              <User model='Company' />
            </SuspensedView>
          }
        />
        <Route
          path='/reviews/create'
          element={
            <SuspensedView>
              <CreateReview />
            </SuspensedView>
          }
        />
        <Route
          path='/company/details/:id/:name'
          element={
            <SuspensedView>
              <ViewCompany />
            </SuspensedView>
          }
        />
         <Route
          path='/company/approval/:id/:name'
          element={
            <SuspensedView>
              <ViewCompanyApproval />
            </SuspensedView>
          }
        />
        <Route
          path='/notification-type'
          element={
            <SuspensedView>
              <NotificationsType />
            </SuspensedView>
          }
        />
        <Route
          path='/Company/update/:id'
          element={
            <SuspensedView>

              <UpdateUser model={'Company'} />

            </SuspensedView>
          }
        />
        <Route
          path='/Company/create/'
          element={
            <SuspensedView>
              <CreateUser model={'Company'} />
            </SuspensedView>
          }
        />
        <Route
          path='/mentors'
          element={
            <SuspensedView>
              <User model='Mentor' />
            </SuspensedView>
          }
        />
        <Route
          path='/mentor/details/:id/:name'
          element={
            <SuspensedView>
              <ViewMentor />
            </SuspensedView>
          }
        />
       
        <Route
          path='/reviews/update/:id'
          element={
            <SuspensedView>
              <UpdateReview />
            </SuspensedView>
          }
        />
        <Route
          path='/mentor/update/:id'
          element={
            <SuspensedView>
              <UpdateUser model={'Mentor'} />
            </SuspensedView>
          }
        />
        <Route
          path='/mentor/create/'
          element={
            <SuspensedView>
              <CreateUser model={'Mentor'} />
            </SuspensedView>
          }
        />
        <Route
          path='/institution'
          element={
            <SuspensedView>
              <User model='Institution' />
            </SuspensedView>
          }
        />
        <Route
          path='/institution-students'
          element={
            <SuspensedView>
              <InstitutionStudents model='Institution' />
            </SuspensedView>
          }
        />
        <Route
          path='/institution/students-list/:id/:name'
          element={
            <SuspensedView>
              <InstitutionStudentsList model="Student" />
            </SuspensedView>
          }
        />

        <Route
          path='/institution/details/:id/:name'
          element={
            <SuspensedView>
              <ViewInstitution />
            </SuspensedView>
          }
        />
          <Route
          path='/institution/approval/:id/:name'
          element={
            <SuspensedView>
              <ViewInstitutionApproval />
            </SuspensedView>
          }
        />
        <Route
          path='/institution/update/:id'
          element={
            <SuspensedView>
              <UpdateUser model={'Institution'} />
            </SuspensedView>
          }
        />
        <Route
          path='/institution/create/'
          element={
            <SuspensedView>
              <CreateUser model={'Institution'} />
            </SuspensedView>
          }
        />
        <Route
          path='/student'
          element={
            <SuspensedView>
              <User model='Student' />
            </SuspensedView>
          }
        />
        <Route
          path='/notification-type/create'
          element={
            <SuspensedView>
              <CreateNotificationType />
            </SuspensedView>
          }
        />
        <Route
          path='/student/update/:id'
          element={
            <SuspensedView>
              <UpdateUser model={'Student'} />
            </SuspensedView>
          }
        />
        <Route
          path='/student/details/:id/:name'
          element={
            <SuspensedView>
              <ViewStudent />
            </SuspensedView>
          }
        />
        <Route
          path='/notification-type/update/:id'
          element={
            <SuspensedView>
              <UpdateNotificationType />
            </SuspensedView>
          }
        />
        <Route
          path='/student/create/'
          element={
            <SuspensedView>
              <CreateUser model={'Student'} />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='/error/404' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
