type Props = {
  onPageChange: any
  totalCount: number
  currentPage: any
}
export default function Pagination(props: Props) {
  const { onPageChange, totalCount, currentPage } = props

  const onNext = () => {
    currentPage !== (totalCount - 1) && onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    currentPage !== 0 && onPageChange(currentPage - 1)
  }
  const onPageSelect = (index: any) => {
    onPageChange(index)
  }

  return (
    <ul className='pagination'>

      <li className={`page-item previous ${currentPage === 0 ? 'disabled' : ''}`} onClick={() => onPrevious()}>
        <a className='page-link' style={{ cursor: "pointer" }}>
          <i className='previous'></i>
        </a>
      </li>

      <div className="page-numbers mx-2">
        {Array.from({ length: totalCount < 7 ? totalCount : Math.ceil(7) }, (_, index) => (
          <li className='page-item'>
            <a className={`page-link  ${currentPage === index ? 'active-page' : ''}`}
              style={{ cursor: "pointer" }}
              onClick={() => onPageSelect(index)}>
              {index + 1}
            </a>
          </li>
        ))}
        {totalCount > 6 && (
          <>
            {currentPage > 6 && (currentPage !== (totalCount - 1)) && (
              <li className='page-item next'>
                <a className={`page-link  ${currentPage > 6 ? 'active-page' : ''}`}
                  style={{ cursor: "pointer" }}>
                  {currentPage + 1}
                </a>
              </li>
            )}
            <li className='page-item  disabled'>
              <a className='page-link'>
                ....
              </a>
            </li>

            <li className='page-item '>
              <a className={`page-link  ${currentPage == totalCount - 1 ? 'active-page' : ''}`}
                style={{ cursor: "pointer" }}
                onClick={() => onPageSelect(totalCount - 1)}>
                {totalCount - 1}
              </a>
            </li>

          </>

        )}

      </div>

      {currentPage < totalCount && (
        <li className={`page-item previous ${currentPage === totalCount - 1 ? 'disabled' : ''}`} onClick={() => onNext()}>
          <a className='page-link' style={{ cursor: "pointer" }}>
            <i className='next'></i>
          </a>
        </li>
      )}
    </ul>
  )
}
