import { MouseEventHandler, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Delete } from '../delete'

import { deleteUser, updateUser } from '../../../../services/user'
import ChangePasswordModal from '../../../../components/ChangePasswordModal'

import { formatDate } from '../../../../utils/DateFormatter'
import { objectToFormData, sortByKey } from '../../../../utils/helpers'
import TableBodyLoading from '../../../../components/tableBodyLoading'
import { exportTableToExcel } from '../../../../utils/export'
import { useThemeMode } from '../../../../../_metronic/partials'

type Props = {
  list: any[]
  model: any
  // listALl:any
  length: any
  skelton?: boolean
  listAllUser: any
}

export const UsersTableChanges = (props: Props) => {
  const { list, model, skelton, listAllUser } = props
  const [loading, setLoading] = useState<any>(false)
  const [showModal, setShowModal] = useState(false)
  const [_id, setId] = useState<string>('')
  const [loadingApprove, setLoadingApprove] = useState<any>(false)
  const viewModal = (_id: any) => {
    setId(_id)
    setShowModal(true)
  }

  const cancelFn = () => {
    setShowModal(false)
  }

  const delteFn = () => {
    deleteGetOfferFn()
    setShowModal(false)
  }

  const deleteGetOfferFn = async () => {
    setLoading(true)
    const response = await deleteUser(_id)
    if (response) {
      cancelFn()
      setLoading(false)
      // listALl()
      listAllUser()
    }
  }


  const approveUser = async (id: any) => {
    setLoadingApprove(true)
    setId(id)
    const res = await updateUser(id, objectToFormData({ approved: true }))
    if (res.status === 200) {
      setLoadingApprove(false)
      listAllUser()

    }
  }

  type Data = typeof list;
  type SortKeys = keyof Data[0];
  type SortOrder = "ascn" | "desc";
  function sortData({
    tableData,
    sortKey,
    reverse,
  }: {
    tableData: Data;
    sortKey: SortKeys;
    reverse: boolean;
  }) {
    if (!sortKey) return tableData;

    const sortedData = shownData.sort((a: any, b: any) => {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  }

  function SortButton({
    sortOrder,
    columnKey,
    sortKey,
    onClick,
  }: {
    sortOrder: SortOrder;
    columnKey: SortKeys;
    sortKey: SortKeys;
    onClick: MouseEventHandler<HTMLButtonElement>;
  }) {
    const { mode } = useThemeMode()
    return (
      <button
        onClick={onClick}
        className={`${mode === 'dark' ? 'text-white' : 'text-dark'}  ${sortKey === columnKey && sortOrder === "desc"
          ? "sort-button sort-reverse"
          : "sort-button"
          }`}
      >
        ▲
      </button>
    );
  }
  const [sortKey, setSortKey] = useState<any>("model");
  const [sortOrder, setSortOrder] = useState<SortOrder>("ascn");

  const sortedData = useCallback(
    () => sortData({ tableData: shownData, sortKey, reverse: sortOrder === "desc" }),
    [list, sortKey, sortOrder]
  );

  function changeSort(key: SortKeys) {
    setSortOrder(sortOrder === "ascn" ? "desc" : "ascn");
    setSortKey(key);
  }

  const [shownData, setShownData] = useState<any>([])

  const headers: { key: SortKeys; label: string }[] = [
    { key: "model", label: "User Type" },
    { key: "fullName", label: "Full Name" },
    { key: "email", label: "Email" },
    { key: "organization", label: "Organization" },
    // { key: "featuredAsPublic", label: "Featured As Public" },

  ];
  useEffect(() => {
    const data = sortedData().map((item: any) => (
      {
        _id: item?._id,
        model: item?.model,
        fullName: item?.fullName,
        email: item.email,
        organization: item.organization,
        featuredAsPublic: item.featuredAsPublic,
        approved: item?.approved,
        status: item?.status,
        country:item?.country,
        newUpdate:item?.newUpdate

      }
    ))
    setShownData(data)
  }, [sortedData])
  useEffect(() => {
    if (list) {
      const data = list.map((item: any) => (
        {
          _id: item?._id,
          fullName: `${item?.subModel?.firstName || item?.subModel?.name} ${item?.subModel?.lastName || ''}` || 'N/A',
          email: item.email,
          organization: item?.subModel?.company?.subModel?.name || 'N/A',
          model: item?.model,
          featuredAsPublic: item?.subModel?.featuredAsPublic,
          approved: item?.approved,
          status: item?.status,
          country:item?.country?.nameEn,
          newUpdate:item?.newUpdate
        }
      ))
      setShownData(data)
    }

  }, [list])
  const handleExport = (id: string) => {
    exportTableToExcel(id, `${id}.xlsx`)
  }
  return (
    <>
      <div>
        <a
          className='text-danger d-flex justify-content-end mx-3 mb-4 align-items-center'
          style={{ fontSize: '14px', fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => {
            handleExport(`${model}-list`)
          }}
        >
          Export table <i className="bi bi-arrow-bar-up text-danger mx-2"></i>
        </a>
        <div className={`card `}>
          {/* begin::Body */}
          <div className='card-body py-3 '>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}

              <table
                className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
                id={`${model}-list`}
              >
                {/* begin::Table head */}

                <thead>
                  <tr >
                    {headers.map((row) => {
                      return (
                        <td className={`cursor-pointer ${sortKey === row.key ? 'text-primary' : ''}`}
                          onClick={() => changeSort(row.key)}
                          {...{
                            sortOrder,
                            sortKey,
                          }}>
                          <div className='fw-bold'>
                            {row.label}

                            <SortButton
                              columnKey={row.key}
                              onClick={() => changeSort(row.key)}
                              {...{
                                sortOrder,
                                sortKey,
                              }}
                            />
                          </div>


                        </td>
                      );
                    })}
                    {model === 'Mentor' &&


                      <td className={`cursor-pointer ${sortKey === 'featuredAsPublic' ? 'text-primary' : ''}`}
                        onClick={() => changeSort('featuredAsPublic')}
                        {...{
                          sortOrder,
                          sortKey,
                        }}>
                        <div className='fw-bold'>
                          Featured As Public

                          <SortButton
                            columnKey={'featuredAsPublic'}
                            onClick={() => changeSort('featuredAsPublic')}
                            {...{
                              sortOrder,
                              sortKey,
                            }}
                          />
                        </div>
                      </td>}
                    {model === 'Institution' &&


                      <td className={`cursor-pointer ${sortKey === 'featuredAsPublic' ? 'text-primary' : ''}`}
                        onClick={() => changeSort('featuredAsPublic')}
                        {...{
                          sortOrder,
                          sortKey,
                        }}>
                        <div className='fw-bold'>
                          Institution Country

                          <SortButton
                            columnKey={'country'}
                            onClick={() => changeSort('country')}
                            {...{
                              sortOrder,
                              sortKey,
                            }}
                          />
                        </div>
                      </td>}
                    {model === 'Institution' &&


                      <td className={`cursor-pointer ${sortKey === 'featuredAsPublic' ? 'text-primary' : ''}`}
                        onClick={() => changeSort('featuredAsPublic')}
                        {...{
                          sortOrder,
                          sortKey,
                        }}>
                        <div className='fw-bold'>
                          Status

                          <SortButton
                            columnKey={'status'}
                            onClick={() => changeSort('status')}
                            {...{
                              sortOrder,
                              sortKey,
                            }}
                          />
                        </div>
                      </td>}
                    
                      <td className={`cursor-pointer ${sortKey === 'approved' ? 'text-primary' : ''}`}
                        onClick={() => changeSort('approved')}
                        {...{
                          sortOrder,
                          sortKey,
                        }}>
                        <div className='fw-bold text-center'>
                        Actions

                          <SortButton
                            columnKey={'approved'}
                            onClick={() => changeSort('approved')}
                            {...{
                              sortOrder,
                              sortKey,
                            }}
                          />
                        </div>
                      </td>
                 
                  </tr>
                </thead>
                {/* end::Table head */}
                {skelton ?
                  <TableBodyLoading count={4} />
                  :
                  <tbody>

                    {shownData.map((item: any) => {
                      return (
                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark fs-6'>{item?.model}</div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark   fs-6'>
                                  <Link className='text-black text-capitalize fw-bold'
                                    to={`/${item?.model?.toLowerCase()}/details/${item._id}/${item?.fullName}`}> {item.fullName}</Link>

                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark   fs-6'>{item?.email}</div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark   fs-6'>{item?.organization}</div>
                              </div>
                            </div>
                          </td>
                          {model === 'Mentor' &&
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <div className='text-dark   fs-6'>{item?.featuredAsPublic ? 'Yes' : "No"}</div>
                                </div>
                              </div>
                            </td>
                          }
                          
                          {model === 'Institution' &&
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <div className='text-dark   fs-6'>{item.country}</div>
                                </div>
                              </div>
                            </td>
                          }
                          {model === 'Institution' &&
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <div className='text-dark   fs-6'>{item.approved ? 'Active' : "Inactive"}</div>
                                </div>
                              </div>
                            </td>
                          }
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              {item.newUpdate ? (
                          
                                <Link
                                to={`/${item?.model?.toLowerCase()}/approval/${item._id}/${item?.fullName}`}
                                className='btn btn-bg-light btn-active-color-primary btn-sm'
                              >
                                Changes needs approval
                              </Link>
                                 
 
                                
                              ) : (
                                <>
                                       <span className='d-flex align-items-center justify-content-center bg-green-100 text-success px-4 btn-sm '> No Changes  </span>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                }
              </table>
              <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <ChangePasswordModal />
              </div>
              {showModal && (
                <Delete
                  show={showModal}
                  onCancel={cancelFn}
                  onDelete={delteFn}
                  loading={loading}
                  model={model}
                />
              )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      </div>
    </>
  )
}
