import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Table} from './components/table'
import {IMentorShipGuideLine} from './module.interface'
import {PageTitle} from '../../../components/pageTitle'
import {getAllMentorShipGuideLine} from '../../../services/mentorShipGuideLine'

export const MentorShipGuideLine = () => {
  const [mentorShipGuideLineList, setMentorShipGuideLineList] = useState<
    Array<IMentorShipGuideLine>
  >([])
  const [loading, setLoading] = useState<any>(false)

  const listAllMentorShipGuideLine = async () => {
    setLoading(true)
    const response = await getAllMentorShipGuideLine()
    setMentorShipGuideLineList(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    listAllMentorShipGuideLine()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Area Interest' />
        <Link to='/mentor-ship-guide-line/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
      {loading ? (
       <div className="spinner-border text-light" role="status">
       <span className="sr-only">Loading...</span>
     </div>
      ) : (
        <Table list={mentorShipGuideLineList} listALl={listAllMentorShipGuideLine} />
      )}
    </>
  )
}
