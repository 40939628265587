import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Table} from './components/table'
import {IKeyWays} from './module.interface'
import {PageTitle} from '../../../components/pageTitle'
import {getAllKeyWays} from '../../../services/keyWays'

export const KeyWays = () => {
  const [keyWaysList, setKeyWaysList] = useState<Array<IKeyWays>>([])
  const [loading, setLoading] = useState<any>(false)

  const listKeyWays = async () => {
    setLoading(true)
    const response = await getAllKeyWays()


    setKeyWaysList(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    listKeyWays()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Key Takeaways' />
        <Link to='/key-ways/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
      {loading ? (
        <div className="spinner-border text-light" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      ) : (
        <Table list={keyWaysList} listALl={listKeyWays} />
      )}
    </>
  )
}
