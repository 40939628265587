import {useEffect, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import ErrorMessage from '../../../../components/ErrorMessage'
import TextEditor from '../../../../components/textEditor'
import {getAllCities} from '../../../../services/city'
import {getAllCountries} from '../../../../services/Country'
import {getAllCurrency} from '../../../../services/currency'
import {getAllJobType} from '../../../../services/jobType'
import {getAllLanguage} from '../../../../services/language'
import {getAllMajor} from '../../../../services/major'
import {getAllUsers} from '../../../../services/user'
import {getAllWorkType} from '../../../../services/workType'
import {formatDate} from '../../../../utils/DateFormatter'
import {getCitiesOfCountry} from '../../../../utils/filterCities'
import Industries from '../../company/components/Industries'
import JobIndustries from '../../company/components/iobIndustries'
import Questions from './questions'

type Props = {
  onClickSubmit: any
  handleChange: any
  data?: any
  ButtonText: string
  Show: any
  loading: boolean
  isSubmitted: boolean
  loadingPage: any
  updatedData?: any
}

export const DataForm = (props: Props) => {
  const {
    onClickSubmit,
    handleChange,
    data,
    ButtonText,
    Show,
    loading,
    isSubmitted,
    loadingPage,
    updatedData,
  } = props

  const [jobTypesList, setJobTypesList] = useState<any>([])
  const [workTypesList, setWorkTypesList] = useState<any>([])
  const [cityList, setCityList] = useState<any>([])
  const [majorList, setMajorList] = useState<any>([])
  const [langList, setLangList] = useState<any>([])
  const [currencyList, setCurrencyList] = useState<any>([])
  const [companyList, setCompanyList] = useState<any>([])
  const [citiesOfCountry, setCitiesOfCountry] = useState<any>([])
  const [countryList, setCountryList] = useState<any>([])
  const [jobPreferences, setJobPreferences] = useState<any>({})
  const [cityPage, setCityPage] = useState<any>(0)
  const [allCitiesCount, setAllCitiesCount] = useState<any>(0)
  const [selectedCity, setSelectedCity] = useState<any>(null)
  const [loadingCities, setLoadingCities] = useState<any>(false)
  const [unpaid, setUnpaid] = useState<any>(false)
  const [citySearch, setCitySearch] = useState<any>(null)
  const handleCitySearch = (e: any) => {
    setCitySearch(e.target.value)
    e.stopPropagation()
  }
  useEffect(() => {
    // setTimeout(() => {
    //   setLoadingPage(false)
    // }, 10000);
    // setJobPreferences(data?.jobPreferences)
    getJobTypesList()
    getWorkTypesList()
    // getCitiesList()
    getCompanyList()
    getMajorList()
    getLangList()
    getCurrenciesList()
    getCountriesList()
    getCompaniesList()
  }, [])
  useEffect(() => {
    if (data?.salary == 0) {
      setUnpaid(true)
    }
  }, [data?.salary])

  const getJobTypesList = async () => {
    const response = await getAllJobType()
    setJobTypesList(response.data.data.queryResult)
  }
  const handleUnpaid = (value: any) => {
    setUnpaid(value)
    handleChange(null, 'currency')
    handleChange(0, 'salary')
  }
  const getWorkTypesList = async () => {
    const response = await getAllWorkType()
    setWorkTypesList(response.data.data.queryResult)
  }

  const getCitiesList = async (page: any = null) => {
    setLoadingCities(true)
    let cities: any = [...citiesOfCountry]
    const response = await getAllCities(
      null,
      null,
      data?.country?._id ? data?.country?._id : data?.country
    )
    cities = [...cities, ...response.data.data]
    setCitiesOfCountry(cities)
    setAllCitiesCount(response.data.data.wholeModelDataCount)
    setLoadingCities(false)
  }
  useEffect(() => {
    data?.country && getCitiesList()
  }, [data?.country])

  const handleOnCitySelect = (name: any, city: any) => {
    setSelectedCity(city)
    handleChange(city._id, name)
  }
  const handleSeeMore = (e: any) => {
    setCityPage(cityPage + 1)
    e.stopPropagation()
    getCitiesList(cityPage + 1)
  }

  const getCountriesList = async () => {
    const response = await getAllCountries()

    setCountryList(response.data.data.queryResult)
  }
  const getCompanyList = async () => {
    const response = await getAllCities()
    setCityList(response.data.data.queryResult)
  }
  const getMajorList = async () => {
    const response = await getAllMajor()
    setMajorList(response.data.data.queryResult)
  }
  const getLangList = async () => {
    const response = await getAllLanguage()
    setLangList(response.data.data.queryResult)
  }
  const getCurrenciesList = async () => {
    const response = await getAllCurrency()
    setCurrencyList(response.data.data)
  }
  const getCompaniesList = async () => {
    const response = await getAllUsers('Company')
    setCompanyList(response.data.data.queryResult)
  }

  const handleJobPrefChange = (e: any) => {
    if (e.target.type === 'checkbox') {
      // setJobPreferences((_data: any) => ({..._data,  [e.target.name]: e.target.checked}))
      setJobPreferences((_data: any) => ({..._data, [e.target.name]: e.target.checked}))
    } else {
      setJobPreferences((_data: any) => ({
        ..._data,
        [e.target.name]: e.target.value ? e.target.value : null,
      }))
    }
  }
  const handleTextEditorChange = (text: any, name: string) => {
    handleChange(text, name)
  }

  useEffect(() => {
    if (Object.keys(jobPreferences).length > 0) {
      handleChange(
        {
          fluentLanguage: jobPreferences?.fluentLanguage?._id
            ? jobPreferences?.fluentLanguage?._id
            : jobPreferences?.fluentLanguage,
          gpa: jobPreferences?.gpa,
          graduationDate: jobPreferences?.graduationDate,
          languageProficiency: jobPreferences?.languageProficiency?._id
            ? jobPreferences?.languageProficiency?._id
            : jobPreferences?.languageProficiency,
          location: jobPreferences?.location,
          major: jobPreferences?.major,
        },
        'jobPreference'
      )
    }
  }, [jobPreferences])

  useEffect(() => {
    if (!loadingPage) {
      setJobPreferences({
        fluentLanguage: data?.jobPreference?.fluentLanguage?._id
          ? data?.jobPreference?.fluentLanguage?._id
          : data?.jobPreference?.fluentLanguage,
        gpa: data?.jobPreference?.gpa,
        graduationDate: data?.jobPreference?.graduationDate,
        languageProficiency: data?.jobPreference?.languageProficiency?._id
          ? data?.jobPreference?.languageProficiency?._id
          : data?.jobPreference?.languageProficiency,
        location: data?.jobPreference?.location,
        major: data?.jobPreference?.major,
      })
    }
  }, [loadingPage])

  return (
    <>
      {!loadingPage ? (
        <div className='form-control d-flex row'>
          <div className='input-data my-3 '>
            <label htmlFor='company' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Company
            </label>
            <select
              className='form-select'
              aria-label='Select example'
              name='company'
              onChange={handleChange}
              // value={data?.company}
            >
              <option disabled selected>
                -- Select an option --
              </option>
              {companyList.map((item: any) => (
                <option value={item._id} selected={data?.company?._id === item._id}>
                  {item.subModel.name}{' '}
                </option>
              ))}
            </select>
            {isSubmitted && !data?.company && <ErrorMessage errorMsg='Company' />}
          </div>
          <div className='input-data my-3 '>
            <label htmlFor='name' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Job Title/Role
            </label>
            <input
              type='text'
              name='name'
              className='form-control'
              defaultValue={data?.name}
              onChange={handleChange}
            />
            {isSubmitted && !data?.name && <ErrorMessage errorMsg='Name ' />}
          </div>
          {/* <div className='input-data my-3'>
         <label htmlFor='description' className='fs-5 me-3'>
           <span className='text-danger'>*</span>  Description
         </label>
         <textarea
 
           name='description'
           className='form-control'
           value={data?.description}
           onChange={handleChange}
         />
         {isSubmitted && !data?.description && <ErrorMessage errorMsg='description ' />}
       </div> */}

          {/* <div className='input-data my-3'>
         <label htmlFor='jobRole' className='fs-5 me-3'>
           <span className='text-danger'>*</span>  Job Title/Role 
         </label>
         <input
           type='text'
           name='jobRole'
           className='form-control'
           value={data?.jobRole}
           onChange={handleChange}
         />
         {isSubmitted && !data?.jobRole && <ErrorMessage errorMsg='jobRole ' />}
       </div> */}
          <div className='input-data my-3 col-md-6 col-12 '>
            <label htmlFor='startDate' className='fs-5 me-3'>
              Start Date
            </label>
            <input
              type='date'
              name='startDate'
              className='form-control'
              value={formatDate(data?.startDate)}
              defaultValue={formatDate(data?.startDate)}
              onChange={handleChange}
            />
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='endDate' className='fs-5 me-3'>
              End Date
            </label>
            <input
              type='date'
              name='endDate'
              className='form-control'
              value={formatDate(data?.endDate)}
              defaultValue={formatDate(data?.endDate)}
              onChange={handleChange}
            />
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='jobType' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Job Type
            </label>
            <select
              className='form-select'
              aria-label='Select example'
              name='jobType'
              onChange={handleChange}
              value={data?.jobType}
            >
              <option disabled selected>
                -- select an option --
              </option>
              {jobTypesList.map((item: any) => (
                <option value={item._id} selected={data?._id === item._id}>
                  {item.nameEn}
                </option>
              ))}
            </select>
            {isSubmitted && !data?.jobType && <ErrorMessage errorMsg='jobType' />}
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='workType' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Work type
            </label>
            <select
              className='form-select'
              aria-label='Select example'
              name='workType'
              onChange={handleChange}
              // value={data?.workType}
            >
              <option disabled selected>
                -- select an option --
              </option>
              {workTypesList.map((item: any) => (
                <option value={item._id} selected={data?.workType?._id === item._id}>
                  {item.nameEn}
                </option>
              ))}
            </select>
            {isSubmitted && !data?.workType && <ErrorMessage errorMsg='workType ' />}
          </div>

          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='applicationType' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Application Type
            </label>
            <select
              className='form-select'
              aria-label='Select example'
              name='applicationType'
              onChange={handleChange}
              value={data?.applicationType}
            >
              <option disabled selected>
                -- Select an option --
              </option>
              <option value={'internal'}>Internal</option>
              <option value={'external'}>External</option>
            </select>
            {isSubmitted && !data?.applicationType && <ErrorMessage errorMsg='applicationType' />}
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='applicationUrl' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Application URL
            </label>
            <input
              disabled={data?.applicationType === 'internal'}
              type='text'
              name='applicationUrl'
              className='form-control'
              value={data?.applicationUrl}
              onChange={handleChange}
            />
            {isSubmitted && !data?.applicationUrl && data?.applicationType === 'external' && (
              <ErrorMessage errorMsg='applicationUrl' />
            )}
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='country' className='fs-5 me-3'>
              Country
            </label>
            <select
              className='form-select'
              aria-label='Select example'
              name='country'
              onChange={(e: any) => {
                e.target.value != 'null' ? handleChange(e) : handleChange(null, 'country')
                setCitiesOfCountry(getCitiesOfCountry(cityList, e.target.value))
                handleChange(null, 'city')
                setSelectedCity(null)
              }}
            >
              <option value={'null'}>-- No option --</option>
              {countryList.map((item: any) => (
                <option selected={data?.country?._id === item._id} value={item._id}>
                  {item.nameEn}
                </option>
              ))}
            </select>
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='city' className='fs-5 me-3'>
              City
            </label>
            {/* <select
           className='form-select'
           aria-label='Select example'
           name='city'
           onChange={handleChange}
           value={data?.city}
         >
           <option disabled selected>
             -- select an option --
           </option>
           {citiesOfCountry.map((city: any) => (
             <option value={city._id}>{city.nameEn}</option>
           ))}
         </select> */}

            <Dropdown>
              <Dropdown.Toggle className='dropdown-city' id='dropdown-basic'>
                {selectedCity
                  ? selectedCity?.nameEn
                  : data?.city
                  ? data?.city?.nameEn
                  : '-- Select city --'}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className='input-data my-3  m-2'>
                  <input
                    type='text'
                    name='search'
                    placeholder='Search city'
                    className='form-control w-100'
                    onChange={handleCitySearch}
                  />
                </div>
                {(citySearch != '' && citySearch != null
                  ? citiesOfCountry.filter((ele: any) =>
                      ele?.nameEn?.toLowerCase()?.includes(citySearch?.toLowerCase())
                    )
                  : citiesOfCountry
                ).map((city: any) => (
                  <Dropdown.Item
                    onClick={() => handleOnCitySelect('city', city)}
                    className={
                      selectedCity
                        ? selectedCity?._id === city?._id
                          ? 'selected'
                          : ''
                        : data?.city?._id === city?._id
                        ? 'selected'
                        : ''
                    }
                  >
                    {city?.nameEn}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className='input-data my-3'>
            <JobIndustries
              job={true}
              data={data}
              updatedData={updatedData}
              handleChange={handleChange}
            />
          </div>
          {/* ///// */}
          {/* <div className='input-data my-3'>
         <div className=' form-check-solid form-check-sm'>
           <input
             className='form-check-input'
             type='checkbox'
             defaultChecked={false}
             checked={data?.receiveMessage}
             value={data?.receiveMessage}
             name='receiveMessage'
             onChange={handleChange}
           />
           <label className='mx-3 fs-5 me-3' htmlFor='receiveMessage'>
             need to receive messages
           </label>
         </div>
       </div> */}
          <div className='input-data my-3'>
            <label htmlFor='additionalComments' className='fs-5 me-3'>
              Additional Comments
            </label>
            <textarea
              name='additionalComments'
              className='form-control'
              value={data?.additionalComments}
              onChange={handleChange}
            />
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='hoursPerWeek' className='fs-5 me-3'>
              <span className='text-danger'>*</span>Hours per week
            </label>
            <input
              type='number'
              name='hoursPerWeek'
              className='form-control'
              value={data?.hoursPerWeek}
              onChange={handleChange}
            />
            {isSubmitted && !data?.hoursPerWeek && <ErrorMessage errorMsg='hoursPerWeek ' />}
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='deadLine' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Deadline
            </label>
            <input
              type='date'
              name='deadLine'
              className='form-control'
              value={formatDate(data?.deadLine)}
              defaultValue={formatDate(data?.deadLine)}
              onChange={handleChange}
            />
          </div>
          <div className='input-data my-3'>
            <label htmlFor='roleDescription' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Role Description
            </label>
            <TextEditor
              value={data?.roleDescription}
              defaultValue={data?.roleDescription}
              Change={(e: any) => handleTextEditorChange(e, 'roleDescription')}
            />

            {isSubmitted && !data?.roleDescription && <ErrorMessage errorMsg='this' />}
          </div>
          <div className=' my-3'>
            <label htmlFor='benefits' className='fs-5 me-3'>
              Benefits
            </label>
            <TextEditor
              value={data?.benefits}
              defaultValue={data?.benefits}
              Change={(e: any) => handleTextEditorChange(e, 'benefits')}
            />
          </div>

          <div className='input-data my-3'>
            <label htmlFor='moreDetails' className='fs-5 me-3'>
              More Details
            </label>

            <TextEditor
              value={data?.moreDetails}
              defaultValue={data?.moreDetails}
              Change={(e: any) => handleTextEditorChange(e, 'moreDetails')}
            />
          </div>

          {/* Job Pref obj */}
          <div className='page-header mb-3'></div>
          <h5>Job Preferences </h5>

          {/* <div className=' form-check-solid form-check-sm col-md-6 col-12 my-3'>
         <input
           className='form-check-input'
           type='checkbox'
           defaultChecked={false}
           // checked={data?.receiveMessage}
           name='major'
           onChange={handleJobPrefChange}
         />
         <label className='mx-3 fs-5 me-3' htmlFor='major'>
           need to receive messages
         </label>
       </div> */}

          <div className=' form-check-solid form-check-sm col-md-12 col-12 my-3'>
            <input
              className='form-check-input'
              type='checkbox'
              defaultChecked={data?.jobPreference?.location}
              defaultValue={data?.jobPreference?.location}
              // checked={data?.receiveMessage}
              name='location'
              onClick={handleJobPrefChange}
            />
            <label className='mx-3 fs-5 me-3' htmlFor='location'>
              Student should be located in this country
            </label>
          </div>
          <div className='input-data my-3'>
            <label className=' fs-5' htmlFor='workAuthorization'>
              Work Authorization Required
            </label>
            <div className=' form-check-solid form-check-sm'>
              <select
                className='form-select'
                aria-label='Select example'
                name='workAuthorization'
                onChange={handleChange}
              >
                <option disabled selected>
                  -- Select an option --
                </option>
                <option selected={parseInt(data?.workAuthorization) === 0} value={0}>
                  Will be provided
                </option>
                <option selected={parseInt(data?.workAuthorization) === 1} value={1}>
                  Yes
                </option>
                <option selected={parseInt(data?.workAuthorization) === 2} value={2}>
                  No
                </option>
              </select>

              {/* {isSubmitted && !data?.workAuthorization && (
             <ErrorMessage errorMsg='workAuthorization ' />
           )} */}
            </div>
          </div>
          <div className='input-data my-3 '>
            <label htmlFor='major' className='fs-5 me-3'>
              {/* <span className='text-danger'>*</span>      */}
              Major
            </label>
            <select
              className='form-select'
              aria-label='Select example'
              name='major'
              // onChange={handleChange}
              onChange={(e: any) => {
                e.target.value != 'null' ? handleChange(e) : handleChange(null, 'major')
            
              }}
            // value={data?.major}
            >
              <option value={'null'}>-- No option --</option>
              {majorList.map((item: any) => (
                <option value={item._id} selected={data?.major?._id == item?._id}>{item.nameEn}</option>
              ))}
            </select>
            {isSubmitted && !data?.major && <ErrorMessage errorMsg='major' />}
          </div>
          <div className=' form-check-solid form-check-sm col-md-12 col-12 my-3'>
            <input
              className='form-check-input'
              type='checkbox'
              defaultChecked={data?.jobPreference?.major}
              defaultValue={data?.jobPreference?.major}
              // checked={data?.receiveMessage}
              name='major'
              onChange={handleJobPrefChange}
            />
            <label className='mx-3 fs-5 me-3' htmlFor='major'>
              Student should Match this major
            </label>
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='fluentLanguage' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Language Fluency
            </label>
            <select
              className='form-select'
              aria-label='Select example'
              name='fluentLanguage'
              onChange={handleJobPrefChange}
              // defaultValue={data?.jobPreference?.fluentLanguage?._id ? data?.jobPreference?.fluentLanguage?._id : data?.jobPreference?.fluentLanguage}
            >
              <option disabled selected>
                -- Select an option --
              </option>
              {langList.map((item: any) => (
                <option
                  value={item._id}
                  selected={
                    data?.jobPreference?.fluentLanguage?._id == item?._id ||
                    data?.jobPreference?.fluentLanguage == item?._id
                  }
                >
                  {item.nameEn}
                </option>
              ))}
            </select>
            {isSubmitted && !data?.jobPreference?.fluentLanguage && (
              <ErrorMessage errorMsg='Fluency in language' />
            )}
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='languageProficiency' className='fs-5 me-3'>
              Language Proficiency
            </label>
            <select
              className='form-select'
              aria-label='Select example'
              name='languageProficiency'
              onChange={(e: any) => {
                e.target.value != 'null'
                  ? handleJobPrefChange(e)
                  : setJobPreferences((_data: any) => ({..._data, languageProficiency: null}))
              }}
              defaultValue={data?.jobPreference?.languageProficiency}
            >
              <option value={'null'}>-- No option --</option>
              {langList.map((item: any) => (
                <option
                  value={item._id}
                  selected={
                    data?.jobPreference?.languageProficiency?._id == item?._id ||
                    data?.jobPreference?.languageProficiency == item?._id
                  }
                >
                  {item.nameEn}
                </option>
              ))}
            </select>
          </div>

          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='gpa' className='fs-5 me-3 '>
              GPA for Job Post
            </label>
            <input
              type='number'
              max={4}
              name='gpa'
              className='form-control'
              defaultValue={data?.jobPreference?.gpa}
              onChange={handleJobPrefChange}
            />
            {/* {isSubmitted && !data?.jobPreference?.gpa && <ErrorMessage errorMsg='GPA' />} */}
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='datePosted' className='fs-5 me-3'>
              Date Posted
            </label>
            <input
              type='date'
              name='datePosted'
              className='form-control'
              value={formatDate(data?.datePosted)}
              defaultValue={formatDate(data?.datePosted)}
              onChange={handleChange}
            />
          </div>

          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='graduationDate' className='fs-5 me-3 '>
              Graduation Date
            </label>
            <input
              type='date'
              name='graduationDate'
              className='form-control'
              // value={formatDate(data?.jobPreference?.graduationDate)}
              defaultValue={formatDate(data?.jobPreference?.graduationDate)}
              onChange={handleJobPrefChange}
            />
          </div>
          <div className='input-data my-3 col-md-9 col-12 '>
            <label htmlFor='salary' className='fs-5 me-3'>
              Expected Salary (for anticipated work duration)
            </label>
            <input
              type='number'
              name='salary'
              className='form-control'
              value={data?.salary}
              onChange={handleChange}
              disabled={unpaid}
            />
            <div className=' form-check-solid form-check-sm col-md-3 col-12 my-3'>
              <input
                className='form-check-input'
                type='checkbox'
                defaultChecked={unpaid}
                // defaultValue={true}
                checked={unpaid}
                name='Unpaid'
                onChange={(e: any) => handleUnpaid(e.target.checked)}
              />
              <label className='mx-3 fs-5 me-3' htmlFor='Unpaid'>
                Unpaid
              </label>
            </div>
          </div>
          {data?.salary && !unpaid ? (
            <div className='input-data my-3 col-md-3 col-12'>
              <label htmlFor='currency' className='fs-5 me-3'>
                Currency
              </label>
              <select
                className='form-select'
                aria-label='Select example'
                name='currency'
                onChange={handleChange}
                value={data?.currency}
              >
                <option disabled selected>
                  -- select an option --
                </option>
                {currencyList?.map((item: any) => (
                  <option value={item._id}>{item.nameEn}</option>
                ))}
              </select>
            </div>
          ) : (
            ''
          )}
          <div className='page-header mb-4'></div>
          <Questions setJob={handleChange} job={data} />

          <div className='d-flex justify-content-end'>
            <button className='btn btn-primary' onClick={onClickSubmit}>
              {!loading && ButtonText}
              {loading && (
                <div className='spinner-border text-light' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              )}
            </button>
          </div>
        </div>
      ) : (
        <div style={{height: '300px'}} className='d-flex align-items-center justify-content-center'>
          Loading...
        </div>
      )}
    </>
  )
}
