import {useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {UserResetPassword} from '../../services/admin'
import {useAuth} from './core/Auth'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {toast, ToastContainer} from 'react-toastify'
const loginSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})
const initialValues = {
  password: '',
}
export function ChangePassword() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [error, setError] = useState('')
  const [message, setMessage] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const captchaRef = useRef(null)
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      const data = {
        newPassword: values.password,
        passwordResetToken: searchParams.get('passwordResetToken'),
      }
      const response = await UserResetPassword(searchParams.get('userId'), data)
      if (response.status === 200) {
        setLoading(false)
        setMessage(true)
        // toast.success('password changed successfully please login again with your new password !', {
        //   position: "bottom-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
        // navigate('/')
      } else {
        setLoading(false)
        setError(response?.data?.error)
        setSubmitting(false)
        // saveAuth(undefined)
      }
    },
  })
  useEffect(() => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    localStorage.removeItem('userToken')
    localStorage.removeItem('userId')
    localStorage.removeItem('kt-auth-react-v')
  }, [])
  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      <ToastContainer />
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <div>
              <div className='text-center mb-12'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/Edqan_Logo_FF.png')}
                  className='h-25px app-sidebar-logo-default'
                />
              </div>
              <div style={{border: '1px solid gainsboro'}} className='p-8 rounded'>
                <form
                  className='form w-100 '
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id='kt_login_signin_form'
                >
                  {/* begin::Heading */}
                  <div className='text-center mb-11'>
                    <h1 className='text-dark fw-bolder mb-3'>Change Password</h1>
                    {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
                  </div>
                  {/* begin::Heading */}
                  {error && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{error}</span>
                    </div>
                  )}
                  {/* end::Form group */}
                  {/* begin::Form group */}
                  <div className='fv-row mb-3'>
                    <label className='form-label fw-bolder text-dark fs-6 mb-0'>New Password</label>
                    <input
                      type='password'
                      placeholder='password'
                      autoComplete='off'
                      {...formik.getFieldProps('password')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                          'is-valid': formik.touched.password && !formik.errors.password,
                        }
                      )}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className='fv-plugins-message-container  text-danger'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end::Form group */}
                  {/* begin::Action */}
                  <div className='d-grid'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {!loading && <span className='indicator-label'>Continue</span>}
                      {loading && (
                        <div className='spinner-border text-light' role='status'>
                          <span className='sr-only'>Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </form>
                {message && (
                  <p className='bg-success text-white p-4 mt-4 rounded'>
                    password changed successfully please login again with your new password !
                  </p>
                )}
                <div className='form-item mt-4'>
                  <a
                    className='mb-4 d-block'
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      window.location.href = '/'
                    }}
                  >
                    Back to login
                  </a>
                  {/* <ReCAPTCHA
                    sitekey="6Lej16YkAAAAABpmz9vGPEWdDVYjJiX7urjtU2ZJ"
                    ref={captchaRef} /> */}
                </div>
                {/* end::Action */}
              </div>
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}
    </div>
  )
}
