import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllMentors = () => {
  return axios
    .get(`${BASEURL}mentor`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getMentor = (_id: any) => {
  return axios
    .get(`${BASEURL}mentor/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateMentor = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}mentor/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createMentor = (payload:any) => {
  return axios
    .post(`${BASEURL}mentor`, payload)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteMentor= (_id: string) => {
  return axios
    .delete(`${BASEURL}mentor/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}


export const AddAvailableTimeSlot = (id: string, data: any) => {
  return axios
    .patch(`${BASEURL}mentor/availabilityTime/${id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const removeAvailableTimeSlot = (id: string, index: any) => {
  return axios
    .get(`${BASEURL}mentor/delete/availabilityTime/${id}?dateIndex=${index}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};