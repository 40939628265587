import {useState} from 'react'
import {Link} from 'react-router-dom'
// import {Delete} from '../delete'
import {IIndustry} from '../module.interface'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Delete} from '../delete'
import {deleteIndustry} from '../../../../services/industry'
import {exportTableToExcel} from '../../../../utils/export'
import {sortByKey} from '../../../../utils/helpers'

type Props = {
  list: IIndustry[]
  listAll: any
}

export const Table = (props: Props) => {
  const {list, listAll} = props
  const [loading, setLoading] = useState<any>(false)
  const [sortedData, setSortedData] = useState<any>(list)
  const [showModal, setShowModal] = useState(false)
  const [_id, setId] = useState<string>('')

  const viewModal = (_id: any) => {
    setId(_id)
    setShowModal(true)
  }

  const handleCancel = () => {
    setShowModal(false)
  }

  const handleDelete = () => {
    handleDeleteIndustry()
    setShowModal(false)
  }

  const handleDeleteIndustry = async () => {
    setLoading(true)
    const response = await deleteIndustry(_id)
    if (response) {
      handleCancel()
      setLoading(false)
      listAll()
    }
  }

  const handleExport = (id: string) => {
    exportTableToExcel(id, `${id}.xlsx`)
  }

  return (
    <>
      <div>
        {/* <button
          className='btn btn-danger d-flex justify-content-end'
          onClick={() => {
            handleExport(`educationLevels-list`)
          }}
        >
          Export
        </button> */}
        <div className={`card `}>
          {/* begin::Body */}
          <div className='card-body py-3 '>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-150px'>Order</th>
                    <th
                      className='min-w-140px text-hover-primary'
                      onClick={() => {
                        setSortedData(sortByKey(list, 'nameEn'))
                      }}
                    >
                      Name English
                      <i className='bi bi-arrow-down'></i>
                    </th>
                    <th
                      className='min-w-120px text-hover-primary'
                      onClick={() => {
                        setSortedData(sortByKey(list, 'nameAr'))
                      }}
                    >
                      Name Arabic
                      <i className='bi bi-arrow-down'></i>
                    </th>
                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {sortedData.map((offer: any) => {
                  return (
                    <>
                      {/* begin::Table body */}
                      <tbody>
                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark fw-bold text-hover-primary fs-6'>
                                  {offer.order}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='text-dark fw-bold text-hover-primary d-block fs-6 '>
                              {offer.nameEn}
                            </div>
                          </td>
                          <td>
                            <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                              {offer.nameAr}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <Link
                                to={`/industry/update/${offer._id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-5'
                              >
                                <i className='bi bi-pencil-square text-info fs-2'></i>
                              </Link>

                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                onClick={() => viewModal(offer._id)}
                              >
                                 <i className='bi bi-trash text-danger fs-2'></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      {/* end::Table body */}
                    </>
                  )
                })}
              </table>

              {/* end::Table */}

              {showModal && (
                <Delete
                  show={showModal}
                  onCancel={handleCancel}
                  onDelete={handleDelete}
                  loading={loading}
                />
              )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      </div>
    </>
  )
}
