import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Table } from './components/table'
import { ISubIndustry } from './module.interface'
import { PageTitle } from '../../../components/pageTitle'
import { getAllSubIndustry } from '../../../services/subIndustry'
import { searchInTable } from '../../../utils/useSearchTable'
import SearchInput from '../../../components/SearchInput'
import Pagination from '../../../components/Pagination'

export const SubIndustry = () => {
  const [subIndustryList, setSubIndustryList] = useState<Array<ISubIndustry>>([])
  const [loading, setLoading] = useState<any>(false)
  const [dataLength, setDataLength] = useState<any>(0)
  const [searchValue, setSearchValue] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [searchActive, setSearchActive] = useState(false)

  const listSubIndustry = async (size: any) => {
    setLoading(true)
    const response = await getAllSubIndustry(currentPage, size)
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / size))
    setDataLength(response.data.data.queryDataLength)
    setSubIndustryList(response.data.data.queryResult)

    setLoading(false)
  }
  const setDataResult = (response: any) => {
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / pageSize))
    setDataLength(response.data.data.queryDataLength)
    setSubIndustryList(response.data.data.queryResult)
    setLoading(false)
  }

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleCountChange = (e: any) => {
    setPageSize(e.target.value)
    listSubIndustry(e.target.value)
  }

  useEffect(() => {
    listSubIndustry(pageSize)
  }, [currentPage])

  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Sub Industries' />
        <Link to='/sub-industry/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
      <SearchInput
            model={'subIndustry'}
            setLoading={setLoading}
            setDataResult={setDataResult}
            ListAll={listSubIndustry}
            currentPage={currentPage}
            pageSize={pageSize}
            setSearchActive={setSearchActive}
            setCurrentPage={setCurrentPage}
          />
      {loading ? (
        <div className="spinner-border text-light" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <>
       
          <Table list={subIndustryList} listAll={listSubIndustry} length={dataLength} />

          <div className='d-flex justify-content-between'>

            <>
              <div className='flex justify-end my-6'>
                <Pagination
                  totalCount={totalPages}
                  onPageChange={handlePaginationChange}
                  currentPage={currentPage}
                />
              </div>
              <select
                className='form-select mr-4 mt-4'
                style={{ width: '75px', height: '50px' }}
                onChange={handleCountChange}
                value={pageSize}
              >
                <option value='10'>5</option>
                <option value='10'>10</option>
                <option value='20'>20</option>
                <option value='30'>30</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </select>
            </>

          </div>
        </>
      )}
    </>
  )
}
