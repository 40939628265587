
import * as XLSX from 'xlsx';

export function exportTableToExcel(tableId:any, filename:any) {
  const table = document.getElementById(tableId);
  const workbook = XLSX.utils.table_to_book(table);

  // Convert the workbook to a binary Excel file
  const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  // Create a blob with the Excel data
  const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  // Create a download link and trigger the download
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = filename;
  downloadLink.click();
}