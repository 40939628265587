import {ChangeEvent, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../components/pageTitle'
import {createIndustry} from '../../../services/industry'
import {createReview} from '../../../services/reviews'
import {DataForm} from './components/data-form'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
export default function CreateReview() {
  const [formData, setFormData] = useState<any>()
  const [disabled, setDisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)
  const [getOffer, setGetOffer] = useState({})
  const navigate = useNavigate()
  const [isSubmitted, setIsSubmitted] = useState<any>(false)
  const req = [
    'jobTitle',
    'resourcesFindingJob',
    'advicesForOthers',
    // 'typeOfWork',
    'company',
    'startDate',
    'agreeToTerms',
  ]
  const breadCrampList = [
    {
      name: 'Reviews',
      route: '/reviews',
    },
  ]

  const handleChange = (e: any, name = '') => {
    if (name !== '') {
      setFormData((_data: any) => ({..._data, [name]: e}))
    } else {
      setFormData((_data: any) => ({..._data, [e.target.name]: e.target.value}))
      setDisabled(false)
      if (e.target.type === 'checkbox') {
        setFormData({...formData, [e.target.name]: e.target.checked})
      }
    }
  }

  const handleSubmit = (e: any) => {
    setIsSubmitted(true)
    e.preventDefault()
    req.every((key) => key in formData) && newReview()
  }

  const newReview = async () => {
    setLoading(true)
    const response = await createReview({...formData, didYouGetOffer: getOffer})
    if (response.status === 201) {
      toast.success('Done successfully !', {
         position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
       theme: "colored",
        });
      navigate('/reviews')
      setLoading(false)
    } else {
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setLoading(false)
    }
  }

  return (
    <>
       <ToastContainer/>
      <PageTitle breadCrampList={breadCrampList} active='Create' />
      <div className=' w-75 m-auto'>
        <DataForm
          onClickSubmit={handleSubmit}
          handleChange={handleChange}
          setGetOffer={setGetOffer}
          data={DataForm}
          ButtonText='Create'
          Show={disabled}
          loading={loading}
          isSubmitted={isSubmitted}
        />
      </div>
    </>
  )
}
