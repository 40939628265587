import React, {useEffect, useState} from 'react'
import {Chip, Stack} from '@mui/material'

interface QuestionProps {
  setJob: any
  job: any
}
export default function Questions(props: QuestionProps) {
  const {setJob, job} = props
  const [questionsType, setQuestionsType] = useState('text')
  const [question, setQuestion] = useState('')
  const [questionAnswers, setQuestionAnswers] = useState<any>([])
  const [questions, setQuestions] = useState<any>([])
  const [selectedAnswer, setSelectedAnswer] = useState('')

  const handleSelectChangeQuestionType = (e: any) => {
    setQuestionsType(e.target?.value)
  }
  const handleDeleteQuestion = (i: any) => {
    const arr = [...questions]
    arr.splice(i, 1)
    setQuestions(arr)
  }
  const RemoveAnswer = (i: any, answerIndex: number) => {
    const arr = [...questions]
    const question = arr[i]
    if (question.questionChoices.length == 1) {
      arr.splice(i, 1)
    } else {
      question.questionChoices.splice(answerIndex, 1)
      arr[i] = question
    }

    setQuestions(arr)
  }

  const handleSelectAnswer = () => {
    const arr = [...questionAnswers]
    arr.push(selectedAnswer)
    setQuestionAnswers(arr)
    setSelectedAnswer('')
  }

  const handleAddClick = () => {
    const arr = [...questions]
    let q: any = {}
    if (questionsType === 'choice') {
      q = {
        question: question,
        questionType: questionsType,
        questionChoices: questionAnswers,
      }
    } else {
      q = {
        question: question,
        questionType: questionsType,
      }
    }
    setQuestion('')
    setSelectedAnswer('')
    setQuestionAnswers([])
    arr.push(q)
    setQuestions(arr)
  }
  useEffect(() => {
    if (questions.length > 0) {
      setJob(questions, 'questions')
    }
  }, [questions])
  useEffect(() => {
    if (job?.questions?.length > 0) {
      setQuestions(job?.questions)
    }
  }, [job?.questions?.length])

  return (
    <div>
      <label className='mb-4'>Questions</label>
      <div className='d-flex '>
        <div className='w-100'>
          <label>Question type</label>

          <select
            value={questionsType}
            className='form-select'
            aria-label='Select example'
            name='questionType'
            onChange={(e) => handleSelectChangeQuestionType(e)}
          >
            <option value='choice'>Choice</option>
            <option value='text'>Text</option>
          </select>
        </div>
      </div>
      <div className='d-flex gap-4 mt-3'>
        <div className='w-50'>
          <label>Question</label>
          <input
            value={question}
            className='form-control'
            required={false}
            placeholder='Add Question'
            onChange={(e: any) => setQuestion(e.target.value)}
          />
        </div>
        {questionsType === 'choice' && (
          <div className='w-50'>
            <>
              <label>Answer</label>
              <div className='d-flex'>
                <input
                  value={selectedAnswer}
                  className='form-control'
                  required={false}
                  placeholder='Add Answer'
                  onChange={(e: any) => setSelectedAnswer(e.target.value)}
                />
              </div>
            </>
            <Stack direction='row' gap={1} className='flex-wrap mt-4'>
              {questionAnswers?.map((answer: any, index: number) => {
                return <Chip label={answer} />
              })}
            </Stack>
            <div className='d-flex justify-content-end'>
              <button
                className='btn btn-primary'
                disabled={!selectedAnswer}
                onClick={handleSelectAnswer}
              >
                Add answer
              </button>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex justify-content-end mt-2'>
        <button
          onClick={handleAddClick}
          className='btn btn-primary mb-10'
          disabled={
            questionsType === 'choice' ? !question || questionAnswers.length == 0 : !question
          }
        >
          Add this Question
        </button>
      </div>

      {job?.questions?.length > 0 && (
        <ul className='mt-4 p-0' style={{listStyle: 'none'}}>
          {job?.questions?.map((question: any, index: number) => {
            return (
              <li className='block border p-2 mb-2 rounded-xl'>
                <div className='d-flex justify-content-between'>
                  <span className='block'>Q: {question.question}</span>
                  <div className='icon flex justify-end cursor-pointer'>
                    <i
                      className='bi bi-trash text-danger'
                      onClick={() => handleDeleteQuestion(index)}
                    ></i>
                  </div>
                </div>
                {question?.questionChoices?.length > 0 && (
                  <div className='d-flex mt-4 list-decimal px-6 gap-2'>
                    {question?.questionChoices?.map((answer: any, answerIndex: number) => {
                      return (
                        <Chip
                          label={answer}
                          onDelete={() => RemoveAnswer(index, answerIndex)}
                          deleteIcon={
                            <i className='bi bi-trash text-danger ' style={{fontSize: '11px'}}></i>
                          }
                        />
                      )
                    })}
                  </div>
                )}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}
