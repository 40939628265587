import axios from '../axios';
import {BASEURL} from '../environments/environment'

// Company

export const getNumberOfUsersViews = (id:string,startDate:string,endDate:string) => {
  return axios 
    .get(`${BASEURL}company/dashboard/users-viewed-profile/${id}?startDate=${startDate}&endDate=${endDate}`)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};

export const getNumberOfApplications = (id:string,startDate:string,endDate:string) => {
    return axios
      .get(`${BASEURL}company/dashboard/students-applications/${id}?startDate=${startDate}&endDate=${endDate}`)
      .then((res:any) => res)
      .catch((err:any) => err?.response);
  };
  export const getNumberOfUsersFollowedCompany = (id:string,startDate:string,endDate:string) => {
    return axios
      .get(`${BASEURL}company/dashboard/users-followed-company/${id}?startDate=${startDate}&endDate=${endDate}`)
      .then((res:any) => res)
      .catch((err:any) => err?.response);
  };
  
  export const getNumberOfUsersHired = (id:string,startDate:string,endDate:string) => {
    return axios
      .get(`${BASEURL}company/dashboard/users-hired/${id}?startDate=${startDate}&endDate=${endDate}`)
      .then((res:any) => res)
      .catch((err:any) => err?.response);
  };

//   institution


export const getNumberOfInstitutionUsersViews = (id:string,startDate:string,endDate:string) => {
    return axios 
      .get(`${BASEURL}institution/dashboard/users-viewed-profile/${id}?startDate=${startDate}&endDate=${endDate}`)
      .then((res:any) => res)
      .catch((err:any) => err?.response);
  };
  
    export const getNumberOfUsersFollowedInstitution = (id:string) => {
      return axios
        .get(`${BASEURL}institution/dashboard/users-followed-institution/${id}`)
        .then((res:any) => res)
        .catch((err:any) => err?.response);
    };
    export const getNumberOfUsersHiredInstitution = (id:string) => {
      return axios
        .get(`${BASEURL}institution/dashboard/users-hired/${id}`)
        .then((res:any) => res)
        .catch((err:any) => err?.response);
    };
    // export const getNumberOfApplications = (id:string,startDate:string,endDate:string) => {
    //   return axios
    //     .get(`${BASEURL}institution/dashboard/students-applications/${id}?startDate=${startDate}&endDate=${endDate}`)
    //     .then((res:any) => res)
    //     .catch((err:any) => err?.response);
    // };
    
//   Mentoe


export const getNumberOfMentorUsersViews = (id:string,startDate:string,endDate:string) => {
    return axios 
      .get(`${BASEURL}mentor/dashboard/users-viewed-profile/${id}?startDate=${startDate}&endDate=${endDate}`)
      .then((res:any) => res)
      .catch((err:any) => err?.response);
  };
  
    export const getNumberOfUsersMentorshipSession = (id:string,startDate:string,endDate:string,status=null) => {
      return axios
        .get(`${BASEURL}mentor/dashboard/users-mentorshipSession/${id}?startDate=${startDate}&endDate=${endDate}${status?`&status=${status}`:''}`)
        .then((res:any) => res)
        .catch((err:any) => err?.response);
    };
    
    // ////////////////////////
    // Admin
    
    export const getNumberOfJobs = (startDate:string,endDate:string) => {
      return axios 
        .get(`${BASEURL}job/dashboard/total-number?startDate=${startDate}&endDate=${endDate}`)
        .then((res:any) => res)
        .catch((err:any) => err?.response);
    };
    export const getNumberOfCompletedProfiles = (startDate:string,endDate:string) => {
      return axios 
        .get(`${BASEURL}admin/dashboard/users-completed-profile?startDate=${startDate}&endDate=${endDate}`)
        .then((res:any) => res)
        .catch((err:any) => err?.response);
    };
    export const getNumberOfUsersAdded = (startDate:string,endDate:string) => {
      return axios 
        .get(`${BASEURL}admin/dashboard/users-added-in-time-period?startDate=${startDate}&endDate=${endDate}`)
        .then((res:any) => res)
        .catch((err:any) => err?.response);
    };
   
    export const getNumberOfUsersApplied = (startDate:string,endDate:string) => {
      return axios 
        .get(`${BASEURL}admin/dashboard/users-applied-to-jobs?startDate=${startDate}&endDate=${endDate}`)
        .then((res:any) => res)
        .catch((err:any) => err?.response);
    };
    export const getNumberOfUsersHiredAdmin = (startDate:string,endDate:string) => {
      return axios 
        .get(`${BASEURL}admin/dashboard/users-hired?startDate=${startDate}&endDate=${endDate}`)
        .then((res:any) => res)
        .catch((err:any) => err?.response);
    };
    export const getNumberOfUsersShipSession = (startDate:string,endDate:string) => {
      return axios 
        .get(`${BASEURL}admin/dashboard/users-mentorship-sessions?startDate=${startDate}&endDate=${endDate}`)
        .then((res:any) => res)
        .catch((err:any) => err?.response);
    };
    export const getNumberOfUsersShipSessionCompleted = (startDate:string,endDate:string) => {
      return axios 
        .get(`${BASEURL}meeting/dashboard/sessions/completed?startDate=${startDate}&endDate=${endDate}`)
        .then((res:any) => res)
        .catch((err:any) => err?.response);
    };
   
    export const getNumberOfUsersByType= (type:string) => {
      return axios 
        .get(`${BASEURL}admin/dashboard/users-by-type/${type}`)
        .then((res:any) => res)
        .catch((err:any) => err?.response);
    };
    
    export const getAppState= () => {
      return axios 
        .get(`${BASEURL}appState`)
        .then((res:any) => res)
        .catch((err:any) => err?.response);
    };

    export const UpdateAppState= () => {
      return axios 
        .patch(`${BASEURL}appState`)
        .then((res:any) => res)
        .catch((err:any) => err?.response);
    };
    