import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { PageTitle } from '../../../components/pageTitle'
import { getAllUsers } from '../../../services/user'
import SearchInput from '../../../components/SearchInput'
import Pagination from '../../../components/Pagination'
import { UsersTable } from './components/UsersTable'
import { UsersTableChanges } from './components/UsersTableChanges'

type Props = {
  model: string
}

export const UserChanges = (props: Props) => {
  const location = useLocation()
  const { model } = props
  const [userList, seUserList] = useState<Array<any>>([])
  const [loading, setLoading] = useState<any>(false)
  const [dataLength, setDataLength] = useState<any>(0)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [searchActive, setSearchActive] = useState(false)
  const handlePaginationChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleCountChange = (e: any) => {
    setPageSize(e.target.value)
  }

  useEffect(() => {
    !searchActive && listAllUser()
  }, [model, currentPage, pageSize])
  useEffect(() => {
    listAllUser()
  }, [location.pathname])
  const listAllUser = async () => {
    const type = model === 'users' ? 'Institution,Company' : model
    setLoading(true)
    const response = await getAllUsers(type, currentPage, pageSize)
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / pageSize))
    setDataLength(response.data.data.queryDataLength)
    seUserList(response.data.data.queryResult)
    setLoading(false)
  }

  const setDataResult = (response: any) => {
  console.log(model)
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / pageSize))
    setDataLength(response.data.data.queryDataLength)
    if(model!=='users'){
      seUserList(response.data.data.queryResult.filter((el: any) => el.model === model))
    }
    else{
      seUserList(response.data.data.queryResult)
    }
    // seUserList(response.data.data.queryResult.filter((el: any) => el.model !== model))
    setLoading(false)
  }

  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active={model == 'Company' ? 'All Companies/Organizations' : `All ${model} changes list`} />
        <Link to={`/${model}/create/`}>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
      <SearchInput
        model={'user'}
        setLoading={setLoading}
        setDataResult={setDataResult}
        ListAll={listAllUser}
        currentPage={currentPage}
        pageSize={pageSize}
        setSearchActive={setSearchActive}
      />

      <UsersTableChanges list={userList} model={model} length={dataLength} skelton={loading} listAllUser={listAllUser} />
      <div className='d-flex justify-content-between'>

        <>
          <div className='flex justify-end my-6'>
            <Pagination
              totalCount={totalPages}
              onPageChange={handlePaginationChange}
              currentPage={currentPage}

            />
          </div>
          <select
            className='form-select mr-4 mt-4'
            style={{ width: '75px', height: '50px' }}
            onChange={handleCountChange}
            value={pageSize}
          >
            <option value='10'>10</option>
            <option value='20'>20</option>
            <option value='30'>30</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        </>

      </div>


    </>
  )
}
