import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Table} from './components/table'
import {ISocialMedia} from './module.interface'
import {PageTitle} from '../../../../components/pageTitle'
import {getAllSocialMedia} from '../../../../services/socialMedia'
import SearchInput from '../../../../components/SearchInput'

export const SocialMedia = () => {
  const [socialMediaList, setSocialMediaList] = useState<Array<ISocialMedia>>([])
  const [loading, setLoading] = useState<any>(false)

  const listAllISocialMedia = async () => {
    setLoading(true)
    const response = await getAllSocialMedia()
    setSocialMediaList(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    listAllISocialMedia()
  }, [])
  const setDataResult = (response: any) => {
    setSocialMediaList(response.data.data.queryResult)
    setLoading(false)
  }
  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Social Media' />
        <Link to='/social-media/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
      <SearchInput model='socialMedia' setLoading={setLoading} setDataResult={setDataResult} ListAll={listAllISocialMedia} />

      <Table list={socialMediaList} listALl={listAllISocialMedia} />
    </>
  )
}
