import React from 'react'

function ErrorMessage(props: any) {
  const {errorMsg} = props
  return (
    <div>
      <h6 className='mt-3 fs-6 text-danger'>{errorMsg} is required</h6>
    </div>
  )
}

export default ErrorMessage
