import { useState } from 'react';
import { formatDate } from '../../../../utils/DateFormatter'
import { updateApplicationStatus } from '../../../../services/jobApplication'
import { useNavigate } from 'react-router-dom';
type Props = {
  jobData?: any
  handleGetJobApplication: any
}
export default function JobApplicationHeader(props: Props) {
  const { jobData, handleGetJobApplication } = props
  const [_id, setId] = useState<string>('')

  const [loadingApprove, setLoadingApprove] = useState(false)
  const [loadingDecline, setLoadingDecline] = useState(false)
  const changeApplicationStatus = async (id: any, status: any) => {
    setId(id)
    status == '2' ? setLoadingDecline(true) : setLoadingApprove(true)
    const response = await updateApplicationStatus(id, status)
    if (response) {
      status == '2' ? setLoadingDecline(false) : setLoadingApprove(false)
      handleGetJobApplication()
    }
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <span className={`d-flex align-items-center position-absolute me-5 text-white p-2 rounded  bg-success ${jobData?.status === '1' ? 'bg-success' : 'bg-danger'} `} style={{ top: '10px', right: "0px" }}>
        <strong className='me-2 d-block'> Status :</strong> {jobData?.status === '1' ? 'Hired' : jobData?.status === '2' ? 'declined' : 'pending'}
        </span>
        <div className='d-flex justify-content-start mb-4 flex-shrink-0'>
                {jobData?.status === '0' &&
                  <>
                    <button
                      className='btn btn-bg-light btn-active-color-primary btn-sm me-5 text-success'
                      onClick={() => changeApplicationStatus(jobData._id, '1')}
                    >
                      {loadingApprove && jobData._id === _id ?
                        <div className="spinner-border text-light" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                        : 'Hire'
                      }
                    </button>
                    <button
                      onClick={() => changeApplicationStatus(jobData._id, '2')}
                      className='btn  btn-bg-light btn-active-color-primary btn-sm me-5 text-danger'>
                      {loadingDecline && jobData._id === _id ?
                        <div className="spinner-border text-light" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                        : 'Decline'
                      }
                    </button>
                  </>
                }
              </div>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                  Job title :  <span className='text-primary '>  <span className='fw-normal'> {jobData?.job?.name}</span></span>
                  </a>
                </div>
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                    <span className='text-primary me-1'> Company : </span>   <span className='fw-normal'> {jobData?.job?.company?.subModel?.name}</span>
                  </span>
                  <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2 w-auto'>
                    <strong className='text-primary me-1'> Company ID : </strong>  <span className='fw-normal'> {jobData?.job?.company?._id}</span>
                  </span>
                  <span className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2 w-auto'>
                    <strong className='text-primary me-1'> Job ID : </strong>  <span className='fw-normal'>    {jobData._id}</span>
                  </span>
                </div>
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <span
                    className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'
                  >
                    Applicant Name : <span className='fw-normal'> {jobData?.user?.subModel?.firstName} {jobData?.user?.subModel?.middleName}  {jobData?.user?.subModel?.lastName}</span>
                  </span>
                </div>
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <span
                    className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'
                  >
                    Applicant ID : <span className='fw-normal'> {jobData?.user?._id}</span>
                  </span>
                </div>
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <span
                    className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'
                  >
                    Applicant Email : <span className='fw-normal'> {jobData?.user?.email}</span> 
                  </span>
                </div>
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <span
                    className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'
                  >
                    Application Date :<span className='fw-normal'>  {formatDate(jobData.updatedAt)}</span>
                  </span>
                </div>
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <span
                    className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'
                  >
                    CV :  
                    <a href={jobData?.existingCv?.attachment||jobData?.cvPath} target='_blank' className='mx-2'>
                    <span className='fw-normal'>   Click here </span>
                    </a>
                   
                  </span>
                </div>
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 page-header'>
                  <span
                    className='btn btn-md btn-link fw-bolder ms-2 fs-12 py-1 px-3'
                  >
                    Cover Letter :  
                    <a href={jobData?.existingCoverLetter?.attachment||jobData?.coverLetterPath} target='_blank' className='mx-2'>
                    <span className='fw-normal'>   Click here </span>
                    </a>
                  </span>
                </div>
                <div className='d-block flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <strong className='text-primary mb-4 d-block '>Application Questions:{' '}</strong>
                  {jobData?.questionsAnswers.length > 0 ?
                    jobData?.questionsAnswers.map((el: any) => {
                      return (
                        <div className='d-block mb-4' >
                          <strong className='d-block'>{el?.question}</strong>
                          <span className='bg-gray-200 p-2 rounded d-block fw-normal'>{el?.answer}</span>
                        </div>
                      )
                    }) : 'N/A'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
