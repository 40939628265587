import ErrorMessage from '../../../../components/ErrorMessage'

type Props = {
  onClickSubmit: any
  handleChange: any
  data: any
  ButtonText: string
  Show: any
  loading: boolean
  isSubmitted: boolean
}

export const DataForm = (props: Props) => {
  const {onClickSubmit, handleChange, data, ButtonText, Show, loading, isSubmitted} = props

  return (
    <form className='form-control' onSubmit={onClickSubmit}>
      <div className='input-data my-3'>
        <label htmlFor='kind' className='fs-5 me-3'>
          Kind
        </label>
        <select
          className='form-select'
          aria-label='Select example'
          name='kind'
          onChange={handleChange}
          defaultValue={data?.kind}
        >
          <option value={0} selected={data?.kind === 0}>
            email
          </option>
          <option value={1} selected={data?.kind === 1}>
            sms
          </option>
        </select>
        {isSubmitted && !data?.kind && <ErrorMessage errorMsg='kind' />}
      </div>

      <div className='input-data my-3'>
        <label htmlFor='name' className='fs-5 me-3'>
          Name
        </label>
        <input
          type='text'
          name='name'
          className='form-control'
          defaultValue={data?.name}
          onChange={handleChange}
        />
        {isSubmitted && !data?.name && <ErrorMessage errorMsg='name' />}
      </div>

      <div className='d-flex justify-content-end'>
        <button className='btn btn-primary' disabled={Show} type='submit'>
          {!loading && ButtonText}
          {loading && (
             <div className="spinner-border text-light" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
          )}
        </button>
      </div>
    </form>
  )
}
