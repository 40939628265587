export function formatDate(dateString:any) {
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
  const year = date.getFullYear().toString();

  let formattedDate =  `${year}-${month}-${day}`;


  return dateString?formattedDate:'';
}