import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Table} from './components/table'
import {IMeetingDuration} from './module.interface'
import {PageTitle} from '../../../components/pageTitle'
import {getAllMeetingDurations} from '../../../services/meetingDuration'

export const MeetingDuration = () => {
  const [durationList, setDurationList] = useState<Array<IMeetingDuration>>([])
  const [loading, setLoading] = useState<any>(false)

  const listDurations = async () => {
    setLoading(true)
    const response = await getAllMeetingDurations()
    setDurationList(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    listDurations()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Meeting Duration for Mentorship Session' />
        {/* <Link to='/meeting-duration/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link> */}
      </div>
      {loading ? (
        <div className="spinner-border text-light" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      ) : (
        <Table list={durationList||[]} listAll={listDurations} />
      )}
    </>
  )
}
