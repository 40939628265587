import React from 'react'
import {KTSVG} from '../../_metronic/helpers'

export default function ChangePasswordModal() {
  return (
    <div className='modal-dialog bg-white rounded'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Change Password</h5>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            data-bs-dismiss='modal'
            aria-label='Close'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <div className='input-data my-3'>
            <label htmlFor='password' className='fs-5 me-3'>
              Password
            </label>
            <input
              type='password'
              name='password'
              className='form-control'
              // value={data?.password}
              // onChange={handleChange}
            />
          </div>
          <div className='input-data my-3'>
            <label htmlFor='confirmPassword' className='fs-5 me-3'>
              Confirm Password
            </label>
            <input
              type='password'
              name='confirmPassword'
              className='form-control'
              // value={data?.password}
              // onChange={handleChange}
            />
          </div>
        </div>
        <div className='modal-footer'>
          <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
            Close
          </button>
          <button type='button' className='btn btn-primary'>
            Change Password
          </button>
        </div>
      </div>
    </div>
  )
}
