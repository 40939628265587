import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useThemeMode } from '../../../../../_metronic/partials'
import Pagination from '../../../../components/Pagination'
import { deleteJob } from '../../../../services/job'
import { updateApplicationStatus } from '../../../../services/jobApplication'
import { formatDate } from '../../../../utils/DateFormatter'
import { exportTableToExcel } from '../../../../utils/export'
import { sortByKey } from '../../../../utils/helpers'
import { Delete } from '../delete'

type Props = {
  list: any[]
  listAll: any
  length: any
}

export const UserApplicationsTableTable = (props: Props) => {
  const PageSize = 9
  const { list, listAll, length } = props
  const [loading, setLoading] = useState<any>(false)
  // const [sortedData, setSortedData] = useState<any>(list)
  const [showModal, setShowModal] = useState(false)
  const [_id, setId] = useState<string>('')
  const [currentPage, setCurrentPage] = useState(1)
  const [loadingApprove, setLoadingApprove] = useState(false)
  const [loadingDecline, setLoadingDecline] = useState(false)

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return list.slice(firstPageIndex, lastPageIndex)
  }, [currentPage])


  const handleCancel = () => {
    setShowModal(false)
  }

  const handleDelete = () => {
    handleDeleteJob()
    setShowModal(false)
  }

  const handleDeleteJob = async () => {
    setLoading(true)
    const response = await deleteJob(_id)
    if (response) {
      handleCancel()
      setLoading(false)
      listAll()
    }
  }

  const changeApplicationStatus = async (id: any, status: any) => {
    setId(id)
    status == '2' ? setLoadingDecline(true) : setLoadingApprove(true)
    const response = await updateApplicationStatus(id, status)
    if (response) {
      status == '2' ? setLoadingDecline(false) : setLoadingApprove(false)
      listAll()
    }

  }

  type Data = typeof list;
  type SortKeys = keyof Data[0];
  type SortOrder = "ascn" | "desc";
  function sortData({
    tableData,
    sortKey,
    reverse,
  }: {
    tableData: Data;
    sortKey: SortKeys;
    reverse: boolean;
  }) {
    if (!sortKey) return tableData;

    const sortedData = shownData.sort((a: any, b: any) => {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  }

   function SortButton({
    sortOrder,
    columnKey,
    sortKey,
    onClick,
  }: {
    sortOrder: SortOrder;
    columnKey: SortKeys;
    sortKey: SortKeys;
    onClick: MouseEventHandler<HTMLButtonElement>;
  }) {
    const { mode } = useThemeMode()
    return (
      <button
        onClick={onClick}
        className={`${mode==='dark'?'text-white':'text-dark'}  ${sortKey === columnKey && sortOrder === "desc"
          ? "sort-button sort-reverse"
          : "sort-button"
          }`}
      >
        ▲
      </button>
    );
  }
  const [sortKey, setSortKey] = useState<any>("title");
  const [sortOrder, setSortOrder] = useState<SortOrder>("ascn");

  const sortedData = useCallback(
    () => sortData({ tableData: shownData, sortKey, reverse: sortOrder === "desc" }),
    [list, sortKey, sortOrder]
  );

  function changeSort(key: SortKeys) {
    setSortOrder(sortOrder === "ascn" ? "desc" : "ascn");
    setSortKey(key);
  }

  const [shownData, setShownData] = useState<any>([])
  const headers: { key: SortKeys; label: string }[] = [
    { key: "title", label: "Job Title" },
    { key: "name", label: "Company Name" },
    { key: "date", label: " Application Date" },
    { key: "status", label: "Status" },
    { key: "location", label: "Location" },


  ];
  useEffect(() => {
    const data = sortedData().map((item: any) => (
      {
        _id: item?._id,
        company: item?.company,
        date: item?.createdAt,
        title: item.title,
        location: item.location,
        status: item.status,
      }
    ))
    setShownData(data)
  }, [sortedData])
  useEffect(() => {
    if (list) {
      const data = list.map((item: any) => (
        {
          _id: item?._id,
          company:  item?.job?.company?.subModel?.name ,
          date: formatDate(item?.createdAt),
          title: item.job?.name,
          location: item?.job?.city?.country?.nameEn+', ' +item?.job?.city?.nameEn,
          status: item.status,
        }
      ))
      setShownData(data)
    }

  }, [list])
  return (
    <>
      <div>
        {/* <button
          className='btn btn-danger d-flex justify-content-end mx-4'
          onClick={() => {
            handleExport(`educationLevels-list`)
          }}
        >
          Export
        </button> */}
        <div className={`card `}>
          {/* begin::Body */}
          <div className='card-body py-3 '>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr >
                    {headers.map((row) => {
                      return (
                        <td className={`cursor-pointer ${sortKey === row.key ? 'text-primary' : ''}`}
                          onClick={() => changeSort(row.key)}
                          {...{
                            sortOrder,
                            sortKey,
                          }}>
                          {row.label}

                          <SortButton
                            columnKey={row.key}
                            onClick={() => changeSort(row.key)}
                            {...{
                              sortOrder,
                              sortKey,
                            }}
                          />

                        </td>
                      );
                    })}
                    <td className='text-center'>Actions</td>
                  </tr>
                </thead>
                {/* end::Table head */}
                {shownData.map((item: any) => {
                  return (
                    <>
                      {/* begin::Table body */}
                      <tbody>
                        <tr>
                          <td style={{width:'20%'}}>
                            <div className='text-dark fw-bold  d-block fs-6 '>{item?.title}</div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark fw-bold  fs-6'>
                                  {item?.company}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='text-dark fw-bold  d-block fs-6 '>
                              {item.date}
                            </div>
                          </td>
                          <td>
                            <div className='text-dark fw-bold  d-block fs-6 '>
                              {item?.status === '0' ? 'Pending' : item?.status === '1' ? 'Hired' : 'Declined'}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark fw-bold  fs-6'>
                                  {item?.location}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              {item?.status === '0' &&
                                <>
                                  <button
                                    className='btn btn-bg-light btn-active-color-primary btn-sm me-2 text-success'
                                    onClick={() => changeApplicationStatus(item._id, '1')}
                                  >
                                    {loadingApprove && item._id === _id ?
                                      <div className="spinner-border text-light" role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                      : 'Hire'
                                    }

                                  </button>
                                  <button
                                    onClick={() => changeApplicationStatus(item._id, '2')}
                                    className='btn  btn-bg-light btn-active-color-primary btn-sm me-2 text-danger'>

                                    {loadingDecline && item._id === _id ?
                                      <div className="spinner-border text-light" role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                      : 'Decline'
                                    }
                                  </button>
                                </>

                              }
                              {/* <Link
                                to={`/job-application/update/${item._id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-5'
                              >
                                <i className='bi bi-pencil-square text-info fs-2'></i>
                              </Link> */}
                              <Link
                                to={`/job-application/details/${item._id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-2'
                              >
                                <i className='bi bi-eye-fill text-primary fs-2'></i>
                              </Link>
                            </div>
                          </td>


                          {/* <td>
                            <div className='text-dark fw-bold  d-block fs-6'>
                              {formatDate(item?.datePosted)}
                            </div>
                          </td> */}
                          {/* 
                          <td>
                            <div className='text-dark fw-bold  d-block fs-6'>
                              {formatDate(item?.deadLine)}
                            </div>
                          </td> */}

                        </tr>
                      </tbody>
                      {/* end::Table body */}
                    </>
                  )
                })}
              </table>
              {shownData.length === 0 &&
                <div className='flex p-6 text-center'>
                  No data available</div>}
              {/* end::Table */}

              {showModal && (
                <Delete
                  show={showModal}
                  onCancel={handleCancel}
                  onDelete={handleDelete}
                  loading={loading}
                />
              )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      </div>
    </>
  )
}
