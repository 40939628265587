/* eslint-disable react/jsx-no-target-blank */
import { useParams } from 'react-router-dom'
import { useAuth } from '../../../../../app/modules/auth'
import { useThemeMode } from '../../../../partials'
import { SidebarMenuItem } from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const { currentUser } = useAuth()
  const url = window.location
  const { id, name } = useParams()

  const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()

  return (
    <>
      {currentUser?.role === '0' || currentUser?.role === 0 ? (
        <>
          <div>
            <SidebarMenuItemWithSub to='' title='Dashboards' hasBullet={false}>
              <SidebarMenuItem to='dashboard' title='Overview' hasBullet={true} textColor={mode==='light'?'#5e6278':'#ffffff'}/>
             
            </SidebarMenuItemWithSub>
            {/* Users */}
            <SidebarMenuItemWithSub to='' title='Users' hasBullet={false}>
              <SidebarMenuItem to='all-users' title='All Users' hasBullet={true} textColor={mode==='light'?'#5e6278':'#ffffff'}></SidebarMenuItem>
              <SidebarMenuItem to='all-users-changes' title='All Users Changes' hasBullet={true} textColor={mode==='light'?'#5e6278':'#ffffff'}></SidebarMenuItem>
              <SidebarMenuItem to='all-reviews' title='All Pending Reviews ' hasBullet={true} textColor={mode==='light'?'#5e6278':'#ffffff'}></SidebarMenuItem>
              
              {(url.pathname.includes('student/details') || url.pathname.includes('student/details')) && <SidebarMenuItem to={`${url.pathname}`} title={name ? name : ''} hasBullet={true} />}
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub to='' title='Job and Internship Management' hasBullet={false}>
                <SidebarMenuItem to='jobs' title='Job and Internship Postings' hasBullet={true} textColor={mode==='light'?'#5e6278':'#ffffff'}></SidebarMenuItem>
                {url.pathname.includes('job/details')  && <SidebarMenuItem to={`${url.pathname}`} title='Job post details' hasBullet={true} />}
               
              </SidebarMenuItemWithSub>
              <SidebarMenuItemWithSub to='' title='Mentorship Management' hasBullet={false}>
              <SidebarMenuItem to='mentors' title='Mentors' hasBullet={true} textColor={mode==='light'?'#5e6278':'#ffffff'} />
              <SidebarMenuItem to='mentorship-program' title='Mentorship Program' hasBullet={true} textColor={mode==='light'?'#5e6278':'#ffffff'} />
              {/* <SidebarMenuItem to='' title='Mentorship Reviews' hasBullet={true} /> */}
            </SidebarMenuItemWithSub>
            <SidebarMenuItemWithSub to='' title='Content Management' hasBullet={false}>
              <SidebarMenuItem to='institution' title='Institutions' hasBullet={true} textColor={mode==='light'?'#5e6278':'#ffffff'}/>
              {/* {url.pathname.includes('institution/students-list')  && <SidebarMenuItem to={`${url.pathname}`} title={`List of students in ${name}`} hasBullet={true} />} */}
              <SidebarMenuItem to='companies' title='Employers/Organizations' hasBullet={true} textColor={mode==='light'?'#5e6278':'#ffffff'} />
             
            </SidebarMenuItemWithSub >
            <SidebarMenuItemWithSub
                to=''
                title='Form management' 
               
                hasBullet={false}
              >
                <SidebarMenuItem
                  to='form-management' title='Write a review' hasBullet={true} textColor={mode==='light'?'#5e6278':'#ffffff'}
                />
             <SidebarMenuItem to='universal-fields' title='Universal Fields' hasBullet={true} textColor={mode==='light'?'#5e6278':'#ffffff'}/>
              
            </SidebarMenuItemWithSub>
            
            <SidebarMenuItem to='students-document-reviews' title=' Student Document Reviews' hasBullet={false} textColor={mode==='light'?'#5e6278':'#ffffff'} />
        
           
            <SidebarMenuItemWithSub
                to=''
                title='System & Settings'     
                hasBullet={false}
              >
                <SidebarMenuItem to='admins' title=' Admins' hasBullet={false} textColor={mode==='light'?'#5e6278':'#ffffff'} />
            </SidebarMenuItemWithSub>
           
          </div>
        </>
      ) : currentUser?.role === '1' || currentUser?.role === 1 ? (
        <>
          <SidebarMenuItem to='student' title='Students' hasBullet={false} textColor={mode==='light'?'#5e6278':'#ffffff'}/>
        </>
      ) : currentUser?.role === '2' || currentUser?.role === 2 ? (
        <>
          <SidebarMenuItem to='companies' title='Companies' hasBullet={false} textColor={mode==='light'?'#5e6278':'#ffffff'}/>
        </>
      ) : currentUser?.role === '3' || currentUser?.role === 3 ? (
        <>
          <SidebarMenuItem to='institution' title='Institution' hasBullet={false} textColor={mode==='light'?'#5e6278':'#ffffff'}/>
        </>
      ) : currentUser?.role === '4' || currentUser?.role === 4 ? (
        <>
          <SidebarMenuItem to='mentors' title='Mentors' hasBullet={false} textColor={mode==='light'?'#5e6278':'#ffffff'}/>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export { SidebarMenuMain }
