export default function ErrorPage(props:any) {

    const goBack=()=>{
        window.history.back()
        setTimeout(() => {
            window.location.reload()
          }, 500)
        // w
    }
    return (
        <div className="flex bg-gray-300 d-flex justify-content-center align-items-center " style={{height:'100vh'}}>
            <div className="p-4 text-center bg-white" style={{border:'1px solid #dad6d6',borderRadius:'8px'}}>
            <div className="text-center text-danger mb-4" style={{fontSize:'30px',fontWeight:'bold'}} >Oops!</div>
            <div className={"message "} style={{fontSize:'18px'}}>Something went wrong while trying to perform your action, please try again later...</div>
            <button className="btn btn-bg-primary btn-small text-white mt-4 me-4" onClick={()=>window.location.href='/'}>Back to home</button>
            <button className="btn btn-bg-info btn-small text-white mt-4" onClick={()=>goBack()}>Back to previous page</button>
            </div>
        </div>
    );
}