import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllJobs = (page:any=null,pageSize:any=null,searchTerm:any=null) => {
    const pagination =  page!=null || pageSize !=null? `?page=${page}&pageSize=${pageSize}`: ''
  return axios
    .get(`${BASEURL}job${pagination}${searchTerm?searchTerm:''}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
export const getAllJobsByCompany = (id:any,page:any=null,pageSize:any=null,searchTerm:any=null) => {
  const pagination =  page!=null || pageSize !=null? `?page=${page}&pageSize=${pageSize}`: ''
return axios
  .get(`${BASEURL}job/company/${id}${pagination}${searchTerm?searchTerm:''}`)
  .then((res: any) => res)
  .catch((err: any) => err?.response)
}

export const getJob = (_id: any) => {
  return axios
    .get(`${BASEURL}job/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateJob = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}job/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createJob = (companyId:any,payload:any) => {
  return axios
    .post(`${BASEURL}job?companyId=${companyId}`, payload)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteJob = (_id: string) => {
  return axios
    .delete(`${BASEURL}job/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
export const updateJobStatusFromAdmin = (id:any,status:any) => {
  return axios
    .patch(`${BASEURL}job/status/${id}?status=${status}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
