import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllDidYouGetPaid = () => {
  return axios
    .get(`${BASEURL}howDidYouGetPaid`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}



export const getDidYouGetPaid = (_id: any) => {
  return axios
    .get(`${BASEURL}howDidYouGetPaid/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}



export const updateDidYouGetPaid = (_id: any, data:any) => {
  return axios
    .patch(`${BASEURL}howDidYouGetPaid/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createDidYouGetPaid = (order: number, nameEn: string, nameAr: string) => {
  return axios
    .post(`${BASEURL}howDidYouGetPaid`, {order: order, nameEn: nameEn, nameAr: nameAr})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteDidYouGetPaid = (_id: string) => {
  return axios
    .delete(`${BASEURL}HowDidYouGetPaid/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}


