import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const clearMenteeRating = (_id: any) => {
  return axios
    .patch(`${BASEURL}meeting/${_id}`, {
      mentorShipSession: {
        menteeRating: null,
      },
    })
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const clearMentorRating = (_id: any) => {
  return axios
    .patch(`${BASEURL}meeting/mentor-rating/${_id}`, {
      mentorShipSession: {
        mentorRating: null,
      },
    })
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
