
type Props = {
    count: number
}
export default function TableBodyLoading(props: Props) {
    const { count } = props
    return (
        <tbody>
            <tr style={{height:'61.32px'}}>
                {[...Array(count)].map((el: any) => (
                    <td className=' ' >
                        <p className='skeleton skeleton-text'></p>
                    </td>

                ))}
                <td className=' '>
                    <p className='skeleton skeleton-text'></p>
                </td>
            </tr>
            <tr style={{height:'61.32px'}}>
                {[...Array(count)].map((el: any) => (
                    <td className=' '>
                        <p className='skeleton skeleton-text'></p>
                    </td>

                ))}
                <td className=' '>
                    <p className='skeleton skeleton-text'></p>
                </td>
            </tr>
            <tr style={{height:'61.32px'}}>
                {[...Array(count)].map((el: any) => (
                    <td className=' '>
                        <p className='skeleton skeleton-text'></p>
                    </td>

                ))}
                <td className=' '>
                    <p className='skeleton skeleton-text'></p>
                </td>
            </tr>
            
            <tr style={{height:'61.32px'}}>
                {[...Array(count)].map((el: any) => (
                    <td className=' '>
                        <p className='skeleton skeleton-text'></p>
                    </td>

                ))}
                <td className=' '>
                    <p className='skeleton skeleton-text'></p>
                </td>
            </tr>
            <tr style={{height:'61.32px'}}>
                {[...Array(count)].map((el: any) => (
                    <td className=' '>
                        <p className='skeleton skeleton-text'></p>
                    </td>

                ))}
                <td className=' '>
                    <p className='skeleton skeleton-text'></p>
                </td>
            </tr>
        </tbody>

    )
}
