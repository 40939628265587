import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllMeetingDurations = () => {
  return axios
    .get(`${BASEURL}meetingDuration`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getMeetingDuration = (_id: any) => {
  return axios
    .get(`${BASEURL}meetingDuration/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateMeetingDuration= (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}meetingDuration/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createMeetingDuration= (duration: number) => {
  return axios
    .post(`${BASEURL}meetingDuration`, {durationMinutes: duration})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteMeetingDuration = (_id: string) => {
  return axios
    .delete(`${BASEURL}meetingDuration/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
