type Props = {
  onClickSubmit: any
  handleChange: any
  data: any
  ButtonText: string
  Show: any
  loading: boolean
}

export const DataForm = (props: Props) => {
  const {onClickSubmit, handleChange, data, ButtonText, Show, loading} = props

  return (
    <form className='form-control' onSubmit={onClickSubmit}>
      <div className='input-data my-3'>
        <label htmlFor='name' className='fs-5 me-3'>
          Name
        </label>
        <input
          type='text'
          name='name'
          className='form-control'
          value={data.nameEn}
          onChange={handleChange}
        />
      </div>

      <div className='d-flex justify-content-end'>
        <button className='btn btn-primary' disabled={Show} type='submit'>
          {!loading && ButtonText}
          {loading && (
             <div className="spinner-border text-light" role="status">
             <span className="sr-only">Loading...</span>
           </div>
          )}{' '}
        </button>
      </div>
    </form>
  )
}
