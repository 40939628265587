import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Table} from './components/table'
import {IDomainModel} from './module.interface'
import {PageTitle} from '../../../../components/pageTitle'
import {getAllDomain} from '../../../../services/domainServices'
export const DomainModel = () => {
  const [domainModelList, setDomainModelList] = useState<Array<IDomainModel>>([])
  const [loading, setLoading] = useState<any>(false)

  const listAllDomain = async () => {
    setLoading(true)
    const response = await getAllDomain()
    setDomainModelList(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    listAllDomain()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Domian' />
        <Link to='/domain/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>

      <Table list={domainModelList} listALl={listAllDomain} />
    </>
  )
}
