import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useThemeMode } from '../../../../../_metronic/partials'
import Pagination from '../../../../components/Pagination'
import TableBodyLoading from '../../../../components/tableBodyLoading'
import { deleteJob, updateJobStatusFromAdmin } from '../../../../services/job'
import { formatDate } from '../../../../utils/DateFormatter'
import { exportTableToExcel } from '../../../../utils/export'
import { sortByKey } from '../../../../utils/helpers'
import { Delete } from '../delete'

type Props = {
  list: any[]
  listAll: any
  length: any
  skelton?: boolean
}

export const JobsTable = (props: Props) => {
  const PageSize = 9
  const { list, listAll, length, skelton } = props
  const [loading, setLoading] = useState<any>(false)
  const [loadingApproved, setLoadingApproved] = useState<any>(false)
  const [loadingDeclined, setLoadingDeclined] = useState<any>(false)
  // const [sortedData, setSortedData] = useState<any>(list)
  const [showModal, setShowModal] = useState(false)
  const [_id, setId] = useState<string>('')
  const [currentPage, setCurrentPage] = useState(1)
  const [shownData, setShownData] = useState<any>([])
  const { id, name } = useParams()

  const viewModal = (_id: any) => {
    setId(_id)
    setShowModal(true)
  }

  const handleCancel = () => {
    setShowModal(false)
  }

  const handleDelete = () => {
    handleDeleteJob()
    setShowModal(false)
  }

  const handleDeleteJob = async () => {
    setLoading(true)
    const response = await deleteJob(_id)
    if (response) {
      handleCancel()
      setLoading(false)
      listAll()
    }
  }
  const handleExport = (id: string) => {
    exportTableToExcel(id, `${id}.xlsx`)
  }
  // useEffect(() => {
  //   setSortedData(list)
  // }, [list])
  type Data = typeof list;

  type SortKeys = keyof Data[0];

  type SortOrder = "ascn" | "desc";

  function sortData({
    tableData,
    sortKey,
    reverse,
  }: {
    tableData: Data;
    sortKey: SortKeys;
    reverse: boolean;
  }) {
    if (!sortKey) return tableData;

    const sortedData = shownData.sort((a: any, b: any) => {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  }

  function SortButton({
    sortOrder,
    columnKey,
    sortKey,
    onClick,
  }: {
    sortOrder: SortOrder;
    columnKey: SortKeys;
    sortKey: SortKeys;
    onClick: MouseEventHandler<HTMLButtonElement>;
  }) {
    const { mode } = useThemeMode()
    return (
      <button
        onClick={onClick}
        className={`${mode === 'dark' ? 'text-white' : 'text-dark'}  ${sortKey === columnKey && sortOrder === "desc"
          ? "sort-button sort-reverse"
          : "sort-button"
          }`}
      >
        ▲
      </button>
    );
  }
  const [sortKey, setSortKey] = useState<SortKeys>("last_name");
  const [sortOrder, setSortOrder] = useState<SortOrder>("ascn");

  const sortedData = useCallback(
    () => sortData({ tableData: shownData, sortKey, reverse: sortOrder === "desc" }),
    [list, sortKey, sortOrder]
  );

  function changeSort(key: SortKeys) {
    setSortOrder(sortOrder === "ascn" ? "desc" : "ascn");
    setSortKey(key);
  }
  const headers: { key: SortKeys; label: string }[] = [
    { key: "name", label: "Job Title" },
    { key: "employer", label: "Employer" },
    { key: "employer_id", label: "Employer ID #" },
    { key: "location", label: "Location" },
    { key: "date", label: "Date Posted" },
    { key: "deadline", label: "Application Deadline" },
  ];

  useEffect(() => {
    const data = sortedData().map((item: any) => (
      {
        _id: item?._id,
        name: item?.name,
        employer: item.employer,
        employer_id: item.employer_id,
        location: item.location,
        date: item?.date,
        deadline: item?.date,
        status: item?.status
      }
    ))
    setShownData(data)
  }, [sortedData])
  useEffect(() => {
    if (list) {
      const data = list.map((item: any) => (
        {
          _id: item?._id,
          name: item?.name,
          employer: item.company?.subModel?.name || name,
          employer_id: item.company?._id || id,
          location: item?.country?.nameEn ? item?.country?.nameEn + ', ' + item?.city?.nameEn : 'N/A',
          date: formatDate(item?.datePosted),
          deadline: formatDate(item?.deadLine),
          status: item?.status
        }
      ))
      setShownData(data)
    }

  }, [list])
  const updateStatus = async (_id: any, status: string) => {
    setId(_id)
    status === 'declined' ? setLoadingDeclined(true) : setLoadingApproved(true)
    const response = await updateJobStatusFromAdmin(_id, status)
    if (response) {
      status === 'declined' ? setLoadingDeclined(false) : setLoadingApproved(false)
      listAll()
    }
  }
  return (
    <>
      <div>
        <a
          className='text-danger d-flex justify-content-end mx-3 mb-4 align-items-center'
          style={{ fontSize: '14px', fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => {
            handleExport(`educationLevels-list`)
          }}
        >
          Export table <i className="bi bi-arrow-bar-up text-danger mx-2"></i>
        </a>
        <div className={`card `}>
          {/* begin::Body */}
          <div className='card-body py-3 '>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr>
                    {headers.map((row) => {
                      return (
                        <td style={{ width: '15%' }}>
                          {row.label}
                          <SortButton
                            columnKey={row.key}
                            onClick={() => changeSort(row.key)}
                            {...{
                              sortOrder,
                              sortKey,
                            }}
                          />
                        </td>
                      );
                    })}
                    <td className='text-center'>Actions</td>
                  </tr>
                </thead>
                {/* end::Table head */}
                {skelton ?
                  <TableBodyLoading count={7} />
                  :
                  <tbody>
                    {shownData.map((item: any) => {
                      return (

                        <tr>
                          <td style={{ width: '20%' }}>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark   fs-6'>{item?.name}</div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='text-dark   d-block fs-6 '>

                              <Link className='text-black text-capitalize fw-bold' to={`/company/details/${item?.employer_id}/${item?.employer}`}> {item.employer}</Link>
                            </div>
                          </td>
                          <td style={{ width: '10%' }}>
                            <div className='text-dark   d-block fs-6 '>
                              <Link className='text-black fw-bold' to={`/company/details/${item?.employer_id}/${item?.employer}`}> {item.employer_id}</Link>
                            </div>
                          </td>
                          <td>
                            <div className='text-dark   d-block fs-6 '>
                              {item.location}
                            </div>
                          </td>
                          <td>
                            <div className='text-dark   d-block fs-6'>
                              {item?.date}
                            </div>
                          </td>

                          <td>
                            <div className='text-dark   d-block fs-6'>
                              {item?.deadline}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              {item?.status === 'pending' &&
                                < >
                                  <button
                                    onClick={() => updateStatus(item._id, 'approved')}
                                    // to={`/mentoring-program-options/update/${item._id}`}
                                    className='btn  btn-bg-success text-white btn-sm me-1 me-2'
                                  >
                                    {loadingApproved && item._id === id ?
                                      <div className="spinner-border text-light" role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                      : 'Approve'
                                    }                              </button>

                                  <button
                                    onClick={() => updateStatus(item._id, 'declined')}
                                    className='btn btn-bg-danger text-white btn-sm me-2'

                                  >
                                    {loadingDeclined && item._id === id ?
                                      <div className="spinner-border text-light" role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                      : 'Reject'
                                    }

                                  </button>
                                </>}
                              {item?.status === 'approved' && <>
                                <span className='d-flex align-items-center justify-content-center bg-green-100 text-success px-4 btn-sm '> Approved </span>
                              </>
                              }

                              {item?.status === 'declined' && <>
                                <span className='d-flex align-items-center justify-content-center bg-green-100 text-danger px-4 btn-sm '> Declined </span>
                              </>
                              }
                              <a
                                onClick={() => window.location.href = `/jobs/update/${item._id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-2'
                              >
                                <i className='bi bi-pencil-square text-info fs-2'></i>
                              </a>

                              <Link
                                to={`/job/details/${item._id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-2'
                              >
                                <i className='bi bi-eye-fill text-primary fs-2'></i>
                              </Link>


                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                onClick={() => viewModal(item._id)}
                              >
                                <i className='bi bi-trash text-danger fs-2'></i>
                              </button>
                            </div>
                          </td>
                        </tr>


                      )
                    })}
                  </tbody>}

              </table>
              {shownData.length == 0 && !skelton && <div className='flex p-6 text-center'>
                No data available</div>}
              {/* end::Table */}

              {showModal && (
                <Delete
                  show={showModal}
                  onCancel={handleCancel}
                  onDelete={handleDelete}
                  loading={loading}
                />
              )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      </div>
    </>
  )
}
