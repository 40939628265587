import { useEffect, useState } from 'react'
import Alert from '../../../components/alert'

import { Link, useNavigate, useParams } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../components/pageTitle'

import { CompanyFollowers, getCompany } from '../../../services/company'
import { getMentor } from '../../../services/mentor'
import { deleteUser, getUser, updateUser } from '../../../services/user'
import { Analytics } from './components/analytics'
import { InstitutionStudentsList } from '../user/institutions-students-list'
import { objectToFormData } from '../../../utils/helpers'
import { Delete } from '../user/delete'
import { ResetPasswordForUser } from '../../../services/admin'

export const ViewInstitution = () => {
  const [formData, setFormData] = useState<any | undefined>()
  const [loading, setLoading] = useState<any>(false)
  const [active, setActive] = useState<any>(0)
  const { id } = useParams()
  const [showModal, setShowModal] = useState(false)
  const [reset, setReset] = useState<any>(null)
 
  useEffect(() => {
    if (id) {
      handleGetInstitution()
    }
  }, [id])

  const handleGetInstitution = async () => {
    const response = await getUser(id)
    if (response) {
      setFormData(response.data.data)
    }
  }
  const approveUser = async () => {
    const res = await updateUser(id, objectToFormData({ approved: true }))
    if (res.status === 200) {
      handleGetInstitution()
    }
  }
  const delteFn = () => {
    deleteGetOfferFn()
    setShowModal(false)
  }
  const viewModal = () => {
    setShowModal(true)
  }

  const cancelFn = () => {
    setShowModal(false)
  }
  const deleteGetOfferFn = async () => {
    setLoading(true)
    const response = await deleteUser(id)
    if (response) {
      cancelFn()
      setLoading(false)
      // listALl()
    }
  }

  const handleClick = async () => {
    setLoading(true)
    const response = await ResetPasswordForUser(formData?.email)
    if (response.status === 200) {
      setLoading(false)
      setReset(true)
      setTimeout(() => {
        setReset(null)
      }, 3000)
    } else {
      setLoading(false)
      setReset(false)
    }
  }


  return (
    <>

      {formData && (
        <>
          <div className='d-flex justify-content-between flex-col my-3'>
            <PageTitle active={`Institution Details`} />
          </div>
          {showModal && (
            <Delete
              show={showModal}
              onCancel={cancelFn}
              onDelete={delteFn}
              loading={loading}
              model={'student'}
            />
          )}
          {reset && <Alert alertTitle={'Success'} alertMsg={`Edqan sent a password reset link to "${formData.email}".`} type='success' />}
          {reset == false && <Alert alertTitle={'Error'} alertMsg={`Something Went wrong, try again later`} type='error' />}
          <div className='row'>

            <div className='col-sm-12 col-md-12 '>

              <div className='d-flex overflow-auto h-55px mb-5'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <span
                      onClick={() => {
                        setActive(0)
                      }}
                      className={`nav-link text-active-primary me-6 ` + (active === 0 && 'active')}
                    >
                      Details
                    </span>
                  </li>

                  {/* <li
                    className='nav-item'
                    onClick={() => {
                      setActive(2)
                    }}
                  >
                    <span
                      className={`nav-link text-active-primary me-6 ` + (active === 2 && 'active')}
                    >
                      Head Quarters
                    </span>
                  </li> */}
                  <li
                    className='nav-item'
                    onClick={() => {
                      setActive(3)
                    }}
                  >
                    <span
                      className={`nav-link text-active-primary me-6 ` + (active === 3 && 'active')}
                    >
                      Students List
                    </span>
                  </li>
                  <li
                    className='nav-item'
                    onClick={() => {
                      setActive(4)
                    }}
                  >
                    <span
                      className={`nav-link text-active-primary me-6 ` + (active === 4 && 'active')}
                    >
                      Statistics
                    </span>
                  </li>
                </ul>
              </div>
              {active === 0 && (
                <div className='card mb-5 mb-xl-10 relative' id='kt_profile_details_view'>
                 

                  <div className=' mb-4  border' >
                    <div className='w-100 symbol-fixed position-relative'>
                      <img src={formData?.subModel.banner} alt='banner' className='w-100' style={{height:'250px'}} />
                    </div>
                  </div>
                  <div className='card-body p-9'>
                  
                    <div className=' mb-4 position-relative'>
                      <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border' style={{ borderRadius: '50%' }}>
                        {formData?.subModel?.logo ?
                          <img src={formData?.subModel?.logo} alt='profile Image' style={{ borderRadius: '50%' }} /> :
                          <div className='bg-info text-white d-flex align-items-center justify-content-center ' style={{ fontSize: '50px', padding: '10px', borderRadius: '50%', height: '150px', width: '150px' }}>
                            {formData?.subModel?.name.charAt(0).toUpperCase()}
                          </div>}
                      </div>
                      <span className={`d-flex align-items-center position-absolute  text-white p-2 rounded  ${formData?.approved ? 'bg-success' : 'bg-danger'}`} style={{ top: '13px', right: "0px",zIndex:"9999" }}>
                  <strong className='me-2 d-block'> Status :</strong>     {formData?.approved ? 'Approved' : 'Pending'}
                </span>
                    </div>
                    <div className='d-flex flex-column'>
                      <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                        <strong className='text-primary me-1'>School Name : </strong> {formData?.subModel?.name}
                      </strong>
                      <div className='d-flex '>
                        <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                          <strong className='text-primary me-1'> School ID : </strong> {formData?._id}
                        </strong>
                        <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                          <strong className='text-primary me-1'>Website URL : </strong>  {formData?.subModel?.schoolUrl}
                        </strong>


                      </div>
                      <div className='d-flex'>
                        <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                          <strong className='text-primary me-1'> Email : </strong> {formData?.email}
                        </strong>
                        <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                          <strong className='text-primary me-1'>Phone : </strong>   {formData?.subModel?.careerAdvisingPhone}
                        </strong>
                        <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                          <strong className='text-primary me-1'>Country : </strong>   {formData?.country?.nameEn || 'N/A'}
                        </strong>
                        <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                          <strong className='text-primary me-1'>City : </strong>   {formData?.city?.nameEn || 'N/A'}
                        </strong>
                      </div>
                      <div className='d-block page-header'>
                        <strong className='my-4 d-block text-primary'>  Contact Information ( Career Advising )</strong>
                        <div className='d-flex'>
                          <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                            <strong className='text-primary me-1'> Email : </strong> {formData?.subModel?.careerAdvisingEmail}
                          </strong>
                          <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                            <strong className='text-primary me-1'>Phone : </strong>   {formData?.subModel?.careerAdvisingPhone}
                          </strong>
                          <strong className='d-flex align-items-center text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                            <strong className='text-primary me-1'>Location : </strong>   {formData?.subModel?.careerAdvisingLocation?.country?.nameEn || 'N/A'},  {formData?.subModel?.careerAdvisingLocation?.city?.nameEn || 'N/A'}
                          </strong>


                        </div>
                        <strong className='d-block  text-black bg-gray-300 p-2 rounded  me-5 mb-2'>
                            <strong className='text-primary me-1 d-block'> Background : </strong> {formData?.subModel?.background}
                          </strong>
                      </div>
                   
                      {/* <span className='d-flex align-items-center text-black border p-2 mt-4 rounded  me-2 mb-2'>
                    <strong className='text-primary me-1'> Agree to Terms : </strong>

                    <span className='fw-bolder fs-6 text-dark bg-gray-200  rounded me-2 p-2'>{formData?.subModel?.agreeToTerms ? 'Yes' : 'No'}
                    </span>
                  </span> */}
                      <div className='row my-7'>
                        <label className='col-lg-4 fw-bold '>Address</label>

                        <div className='col-lg-8'>
                          {formData?.subModel?.addresses?.length > 0 ?
                            formData?.subModel?.addresses?.map((item: any) => {
                              return <span className='d-block fw-bolder fs-6 text-dark'>{item}</span>
                            }) : 'N/A'}
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold '>Privacy</label>

                        <div className='col-lg-8 fv-row'>
                          <span className='fw-bold fs-6'>{formData?.subModel?.accountVisibility || 'N/A'}</span>
                        </div>
                      </div>
                    
                    </div>
                    <div className='page-header mb-4'></div>
                  <div>
                    <h3 className='fw-bold text-gray-900 mb-4 mt-4' >Social Media</h3>
                    {formData?.socialMediaLinks?.length > 0 ? formData?.socialMediaLinks?.map((item: any) => {
                      return (
                        <div className='mb-4'>
                          <label className='col-lg-4 fw-bold text-capitalize fs-4 mb-2 text-info'>
                            {item?.socialMedia.nameEn}
                          </label>
                          {item?.url.map((el: any) => {
                            return <a href={el} target='_blank' className='d-block'>{el}</a>
                          })}
                        </div>
                      )
                    }) : 'N/A'}
                  </div>
                  <div className='page-header mb-4'></div>
                    <div className='d-flex justify-content-end w-100'>
                      <div className='d-flex justify-content-start flex-shrink-0'>

                        <Link
                          to={`/institution/update/${id}`}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-5'
                        >
                          <i className='bi bi-pencil-square text-info fs-2'></i>
                        </Link>



                        <button
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-4'
                          onClick={() => viewModal()}
                        >
                          <i className='bi bi-trash text-danger fs-2'></i>
                        </button>
                        {!formData?.approved ? (
                          <>
                            <button
                              className='btn  btn-bg-light btn-active-color-primary btn-sm me-2'
                              onClick={() => approveUser()}
                            >
                              {/* <i className='bi bi-check2 fs-2 text-success'></i> */}
                              Approve
                            </button>
                          </>
                        ) : (
                          <>

                          </>
                        )}
                        <button
                          className='btn btn-danger btn-sm btn-sm me-1  ml-2 d-flex justify-content-center me-2'
                          type='button'
                          onClick={() => handleClick()}
                        >
                          {loading ?
                            <div className="spinner-border text-light" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            : 'Send password reset to user'
                          }

                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              )}

              {active === 3 && (
                <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>

                  <InstitutionStudentsList model={'Student'} />
                </div>
              )}
              {active === 4 && (
                <div className='card mb-5 mb-xl-10 p-9 ' id='kt_profile_details_view'>
                  <Analytics id={id} />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}
