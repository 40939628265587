import {ChangeEvent, useEffect, useState} from 'react'
import {ICity} from './module.interface'
import {DataForm} from './components/data-form'
import {useNavigate, useParams} from 'react-router-dom'
import {PageTitle} from '../../../components/pageTitle'
import {getCity, updateCity} from '../../../services/city'
import Alert from '../../../components/alert'

export const UpdateCity = () => {
  const [disabled, setdisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)
  const [alert, setAlert] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState<any>(false)
  const req = ['order', 'nameEn', 'nameAr', 'country']
  const navigate = useNavigate()
  const {id} = useParams()

  const [formData, setFormData] = useState<any>()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((_data: any) => ({..._data, [e.target.name]: e.target.value}))
    setdisabled(false)
  }

  useEffect(() => {
    if (id) {
      getCityFn()
    }
  }, [id])

  const getCityFn = async () => {
    const response = await getCity(id)
 
    if (response) {
      const data = {
        order: response.data.data.order,
        nameEn: response.data.data.nameEn,
        nameAr: response.data.data.nameAr,
        country: response.data.data.country,
      }
      setFormData(data)
    }
  }

  const onClickSubmitBtn = (e: any) => {
    setIsSubmitted(true)
    e.preventDefault()
    req.every((key) => formData.hasOwnProperty(key) && formData[key] !== '') && updateCityFn()
  }

  const updateCityFn = async () => {
    setLoading(true)
    const response = await updateCity(id, formData)
    if (response.status == 200) {
      goToCity()
      setLoading(false)
    } else {
      setAlert(true)
      setLoading(false)
    }
  }

  const goToCity = () => {
    navigate('/universal-fields')
  }

  const breadCrampList = [
    {
      name: 'City',
      route: '/universal-fields',
    },
  ]

  return (
    <>
      <PageTitle breadCrampList={breadCrampList} active='Update' />
      <div className='w-75 m-auto'>
        <DataForm
          onClickSubmit={onClickSubmitBtn}
          handleChange={handleChange}
          data={formData}
          ButtonText='Update'
          Show={disabled}
          loading={loading}
          isSubmitted={isSubmitted}
        />
        {alert && <Alert alertTitle='Error' alertMsg='Please try again later' />}
      </div>
    </>
  )
}
