import {ChangeEvent, useState} from 'react'

import {DataForm} from './components/data-form'
import {useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../components/pageTitle'

import {createNotificationMethods} from '../../../services/notificationsMethod'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
export const CreateNotificationMethod = () => {
  const [formData, setFormData] = useState<any>()
  const [disabled, setdisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)
  const [isSubmitted, setIsSubmitted] = useState<any>(false)
  const req = ['kind', 'name']
  const navigate = useNavigate()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((_data: any) => ({..._data, [e.target.name]: e.target.value}))
    setdisabled(false)
  }

  const onClickSubmitBtn = (e: any) => {
    setIsSubmitted(true)
    e.preventDefault()
    req.every((key) => key in formData) && newNotificationMethod()
  }

  const newNotificationMethod = async () => {
    setLoading(true)
    const response = await createNotificationMethods({
      kind: parseInt(formData.kind),
      name: formData.typeName,
    })
    if (response.status === 201) {
      toast.success('Done successfully !', {
         position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
       theme: "colored",
        });
      navigate('/notificationMethod')
      setLoading(false)
    } else {
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setLoading(false)
    }
  }

  const breadCrampList = [
    {
      name: 'Notification Methods',
      route: '/notificationMethod',
    },
  ]
  return (
    <>
       <ToastContainer/>
      <PageTitle breadCrampList={breadCrampList} active='Create' />
      <div className=' w-75 m-auto'>
        <DataForm
          onClickSubmit={onClickSubmitBtn}
          handleChange={handleChange}
          data={DataForm}
          ButtonText='Create'
          Show={disabled}
          loading={loading}
          isSubmitted={isSubmitted}
        />
      </div>
    </>
  )
}
