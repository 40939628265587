import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllKeyWays = () => {
  return axios
    .get(`${BASEURL}keyWays`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getKeyWays = (_id: any) => {
  return axios
    .get(`${BASEURL}keyWays/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateKeyWays = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}keyWays/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createKeyWays = (order: number, nameEn: string, nameAr: string) => {
  return axios
    .post(`${BASEURL}keyWays`, {order: order, nameEn: nameEn, nameAr: nameAr})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteKeyWays = (_id: string) => {
  return axios
    .delete(`${BASEURL}keyWays/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
