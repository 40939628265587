import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Table } from './components/table'
import { IAreaInterest } from './module.interface'
import { getAllAreaInterest } from '../../../services/areaInterest'
import { PageTitle } from '../../../components/pageTitle'
import Pagination from '../../../components/Pagination'
import SearchInput from '../../../components/SearchInput'

export const AreaInterest = () => {
  const [areaInterestList, setAreaInterestList] = useState<Array<IAreaInterest>>([])
  const [dataLength, setDataLength] = useState(0)
  const [loading, setLoading] = useState<any>(false)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(5)
  const [searchActive, setSearchActive] = useState(false)

  const listAllAreaInterest = async () => {
    setLoading(true)
    const response = await getAllAreaInterest(currentPage, pageSize)
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / pageSize))
    setAreaInterestList(response.data.data.queryResult)
    setDataLength(response.data.data.queryDataLength)
    setLoading(false)
  }

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    listAllAreaInterest()
  }, [currentPage, pageSize])

  const handleCountChange = (e: any) => {
    setPageSize(e.target.value)
  }
  const setDataResult = (response: any) => {
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / pageSize))
    setDataLength(response.data.data.queryDataLength)
    setAreaInterestList(response.data.data.queryResult)
    setLoading(false)
  }
  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Area Interest' />
        <Link to='/area-interest/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
      <SearchInput
        model={'areaInterest'}
        setLoading={setLoading}
        setDataResult={setDataResult}
        ListAll={listAllAreaInterest}
        currentPage={currentPage}
        pageSize={pageSize}
        setSearchActive={setSearchActive}
        setCurrentPage={setCurrentPage}

      />
      {loading ? (
        <div className="spinner-border text-light" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <>
          <Table list={areaInterestList} listALl={listAllAreaInterest} length={dataLength} />

          <div className='d-flex justify-content-between'>

            <>
              <div className='flex justify-end my-6'>
                <Pagination
                  totalCount={totalPages}
                  onPageChange={handlePaginationChange}
                  currentPage={currentPage}
                />
              </div>
              <select
                className='form-select mr-4 mt-4'
                style={{ width: '75px', height: '50px' }}
                onChange={handleCountChange}
                value={pageSize}
              >
                <option value='5'>5</option>
                <option value='10'>10</option>
                <option value='20'>20</option>
                <option value='30'>30</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </select>
            </>

          </div>
        </>
      )}
    </>
  )
}
