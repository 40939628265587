import { useEffect, useState } from 'react'
import { Table } from './components/table'
import { IReview } from './module.interface'
import { listAllCompanyReviews, listAllReviews } from '../../../services/company'
import Pagination from '../../../components/Pagination'
import { AllTable } from './components/all-table'

export const AllReview = (props: any) => {
  const { companyID } = props
  const [reviewsList, setReviewsList] = useState<Array<IReview>>([])
  const [loading, setLoading] = useState<any>(false)
  const [dataLength, setDataLength] = useState<any>(0)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const listReviews = async () => {
    setLoading(true)
    const response = await listAllReviews(currentPage, pageSize)
    setDataLength(response.data.data.queryDataLength)
    setReviewsList(response.data.data.queryResult)
    setLoading(false)
  }
  const handlePaginationChange = (page: number) => {
    setCurrentPage(page)
  }
  const handleCountChange = (e: any) => {
    setPageSize(e.target.value)
    listReviews()
  }

  useEffect(() => {
    listReviews()
  }, [])

  return (

    <div className='pt-4'>
      <AllTable list={reviewsList} listAll={listReviews} length={dataLength} skelton={loading} />
      <div className='d-flex justify-content-between'>

        <>
          <div className='flex justify-end my-6'>
            <Pagination
              totalCount={totalPages}
              onPageChange={handlePaginationChange}
              currentPage={currentPage}
            />
          </div>
          <select
            className='form-select mr-4 mt-4'
            style={{ width: '75px', height: '50px' }}
            onChange={handleCountChange}
            value={pageSize}
          >
            <option value='10'>10</option>
            <option value='20'>20</option>
            <option value='30'>30</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        </>

      </div>
    </div>

  )
}
