import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllDidYouGetOffer = () => {
  return axios
    .get(`${BASEURL}didYouGetOffer`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getDidYouGetOffer = (_id: any) => {
  return axios
    .get(`${BASEURL}didYouGetOffer/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateDidYouGetOffer = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}didYouGetOffer/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createDidYouGetOffer = (order: number, nameEn: string, nameAr: string) => {
  return axios
    .post(`${BASEURL}didYouGetOffer`, {order: order, nameEn: nameEn, nameAr: nameAr})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteDidYouGetOffer = (_id: string) => {
  return axios
    .delete(`${BASEURL}didYouGetOffer/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
