import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllCities = (page:any=null,pageSize:any=null,countryId:any=null) => {
 
   const pagination =  page!=null && pageSize ? `?page=${page}&pageSize=${pageSize}`: ''
  return axios
    .get(`${BASEURL}city${countryId?`/country/${countryId}`:''}${pagination}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getCity = (_id: any) => {
  return axios
    .get(`${BASEURL}city/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateCity = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}city/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createCity = (order: number, nameEn: string, nameAr: string,country:string) => {
  return axios
    .post(`${BASEURL}city`, {order: order, nameEn: nameEn, nameAr: nameAr , country:country})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteCity = (_id: string) => {
  return axios
    .delete(`${BASEURL}city/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
