import {ChangeEvent, useState} from 'react'
import {ILanguage} from './module.interface'
import {DataForm} from './components/data-form'
import {useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../components/pageTitle'
import {createLanguage} from '../../../services/language'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
export const CreateLanguage = () => {
  const [formData, setFormData] = useState<ILanguage>({
    order: 0,
    nameEn: '',
    nameAr: '',
  })
  const [disabled, setdisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)
  const [isSubmitted, setIsSubmitted] = useState<any>(false)
  const req = ['order', 'nameEn', 'nameAr']
  const navigate = useNavigate()
  type ObjectKey = keyof typeof formData

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((_data) => ({..._data, [e.target.name]: e.target.value}))
    setdisabled(false)
  }

  const onClickSubmitBtn = (e: any) => {
    setIsSubmitted(true)
    e.preventDefault()

    req.every((key) => formData.hasOwnProperty(key) && formData[key as ObjectKey] !== '') &&
      newLanguage()
  }

  const newLanguage = async () => {
    setLoading(true)
    const response = await createLanguage(formData.order, formData.nameEn, formData.nameAr)
    if (response.status === 201) {
      toast.success('Done successfully !', {
         position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
       theme: "colored",
        });
      goToLanguage()
      setLoading(false)
    } else {
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setLoading(false)
    }
  }

  const goToLanguage = () => {
    navigate('/universal-fields')
  }

  const breadCrampList = [
    {
      name: 'Language',
      route: '/universal-fields',
    },
  ]
  return (
    <>
       <ToastContainer/>
      <PageTitle breadCrampList={breadCrampList} active='Create' />
      <div className=' w-75 m-auto'>
        <DataForm
          onClickSubmit={onClickSubmitBtn}
          handleChange={handleChange}
          data={DataForm}
          ButtonText='Create'
          Show={disabled}
          loading={loading}
          isSubmitted={isSubmitted}
        />
      </div>
    </>
  )
}
