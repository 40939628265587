import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { Delete } from '../delete'
import { ISocialMedia } from '../module.interface'
import { deleteSocialMedia, getAllSocialMedia } from '../../../../../services/socialMedia'
import { useThemeMode } from '../../../../../../_metronic/partials'
import { toast } from 'react-toastify'

type Props = {
  list: ISocialMedia[]
  listALl: any
}

export const Table = (props: Props) => {
  const { list, listALl } = props
  const [loading, setLoading] = useState<any>(false)

  const [_id, setId] = useState<string>('')

  const [showModal, setShowModal] = useState(false)

  const viewModal = (_id: any) => {
    setId(_id)
    setShowModal(true)
  }

  const cancelFn = () => {
    setShowModal(false)
  }

  const delteFn = () => {
    deleteSocialMediaFn()
    setShowModal(false)
  }

  const deleteSocialMediaFn = async () => {
    setLoading(true)
    const response = await deleteSocialMedia(_id)
    if (response.status === 200) {
      toast.success('Deleted successfully !', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      cancelFn()
      setLoading(false)
      listALl()
    } else {
      setLoading(false)
      cancelFn()
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }
  type Data = typeof list;
  type SortKeys = keyof Data[0];
  type SortOrder = "ascn" | "desc";
  function sortData({
    tableData,
    sortKey,
    reverse,
  }: {
    tableData: Data;
    sortKey: SortKeys;
    reverse: boolean;
  }) {
    if (!sortKey) return tableData;

    const sortedData = shownData.sort((a: any, b: any) => {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  }

   function SortButton({
    sortOrder,
    columnKey,
    sortKey,
    onClick,
  }: {
    sortOrder: SortOrder;
    columnKey: SortKeys;
    sortKey: SortKeys;
    onClick: MouseEventHandler<HTMLButtonElement>;
  }) {
    const { mode } = useThemeMode()
    return (
      <button
        onClick={onClick}
        className={`${mode==='dark'?'text-white':'text-dark'}  ${sortKey === columnKey && sortOrder === "desc"
          ? "sort-button sort-reverse"
          : "sort-button"
          }`}
      >
        ▲
      </button>
    );
  }
  const [sortKey, setSortKey] = useState<any>("order");
  const [sortOrder, setSortOrder] = useState<SortOrder>("ascn");

  const sortedData = useCallback(
    () => sortData({ tableData: shownData, sortKey, reverse: sortOrder === "desc" }),
    [list, sortKey, sortOrder]
  );

  function changeSort(key: SortKeys) {
    setSortOrder(sortOrder === "ascn" ? "desc" : "ascn");
    setSortKey(key);
  }

  const [shownData, setShownData] = useState<any>([])

  const headers: { key: SortKeys; label: string }[] = [
    { key: "order", label: "Order" },
    { key: "nameEn", label: "Name English" },
    { key: "nameAr", label: "Name Arabic" },


  ];
  useEffect(() => {
    const data = sortedData().map((item: any) => (
      {
        _id: item?._id,
        nameEn: item?.nameEn,
        nameAr: item?.nameAr,
        order: item?.order
      }
    ))
    setShownData(data)
  }, [sortedData])
  useEffect(() => {
    if (list) {
      const data = list.map((item: any) => (
        {

          _id: item?._id,
          nameEn: item?.nameEn,
          nameAr: item?.nameAr,
          order: item?.order

        }
      ))
      setShownData(data)
    }

  }, [list])
  return (
    <>
      <div>
        <div className={`card `}>
          {/* begin::Body */}
          <div className='card-body py-3 '>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    {headers.map((row) => {
                      return (
                        <td className={`cursor-pointer ${sortKey === row.key ? 'text-primary' : ''}`}
                          onClick={() => changeSort(row.key)}
                          {...{
                            sortOrder,
                            sortKey,
                          }}>
                          {row.label}

                          <SortButton
                            columnKey={row.key}
                            onClick={() => changeSort(row.key)}
                            {...{
                              sortOrder,
                              sortKey,
                            }}
                          />

                        </td>
                      );
                    })}
                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {shownData.map((offer:any) => {
                  return (
                    <>
                      {/* begin::Table body */}
                      <tbody>
                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark fw-bold text-hover-primary fs-6'>
                                  {offer.order}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='text-dark fw-bold text-hover-primary d-block fs-6 '>
                              {offer.nameEn}
                            </div>
                          </td>
                          <td>
                            <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                              {offer.nameAr}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <Link
                                to={`/social-media/update/${offer._id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-5'
                              >
                                <i className='bi bi-pencil-square text-info fs-2'></i>
                              </Link>

                              <Link
                                to='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                onClick={() => viewModal(offer._id)}
                              >
                                <i className='bi bi-trash text-danger fs-2'></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      {/* end::Table body */}
                    </>
                  )
                })}
              </table>
              {/* <div className='d-flex flex-stack flex-wrap pt-10'>
                <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 10 of 50 entries</div>

                <ul className='pagination'>
                  <li className='page-item previous'>
                    <a href='#' className='page-link'>
                      <i className='previous'></i>
                    </a>
                  </li>

                  <li className='page-item active'>
                    <a href='#' className='page-link'>
                      1
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      2
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      3
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      4
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      5
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      6
                    </a>
                  </li>

                  <li className='page-item next'>
                    <a href='#' className='page-link'>
                      <i className='next'></i>
                    </a>
                  </li>
                </ul>
              </div> */}
              {/* end::Table */}

              {showModal && (
                <Delete show={showModal} onCancel={cancelFn} onDelete={delteFn} loading={loading} />
              )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      </div>
    </>
  )
}
