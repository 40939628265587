import React from 'react'

type Props = {
  type?:string
  alertTitle: string
  alertMsg: string
}
export default function Alert(props: Props) {
  const {alertTitle, alertMsg,type} = props

  return (
    <div style={{position:'absolute', right:'10px',bottom:'10px',zIndex:'9999'}}>
      <div className={`alert alert-dismissible shadow-lg  ${type==='success'?'bg-success':'bg-danger'}  d-flex flex-column flex-sm-row p-5 mb-10`}>
        {/* <span className='svg-icon svg-icon-2hx svg-icon-danger me-4 mb-5 mb-sm-0'>...</span> */}
        <div className='d-flex flex-column  pe-0 pe-sm-10'>
          <h5 className={`mb-1  `}>{alertTitle}</h5>
          <span className='mb-1 '>{alertMsg}</span>
        </div>

        <button
          type='button'
          className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
          data-bs-dismiss='alert'
        >
          <span className='svg-icon svg-icon-1 svg-icon-primary'>
            <i className='bi bi-x-lg'></i>
          </span>
        </button>
      </div>
    </div>
  )
}
