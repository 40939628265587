import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const signIn = (email: string, password: string ) => {
    return axios
      .post(`${BASEURL}admin/login`, {email,password})
      .then((res: any) => res)
      .catch((err: any) => err?.response);
  };

  export function getUserById(id: string) {
    return axios
    .get(`${BASEURL}admin/${id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};