import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllFindingJob = () => {
  return axios
    .get(`${BASEURL}resourcesFindingJob`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}



export const getMentoringProgramOptions = (_id: any) => {
  return axios
    .get(`${BASEURL}resourcesFindingJob/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}


export const updateFindingJob = (_id: any, data:any) => {
  return axios
    .patch(`${BASEURL}resourcesFindingJob/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createFindingJob = (order: number, nameEn: string, nameAr: string) => {
  return axios
    .post(`${BASEURL}resourcesFindingJob`, {order: order, nameEn: nameEn, nameAr: nameAr})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteFindingJob = (_id: string) => {
  return axios
    .delete(`${BASEURL}resourcesFindingJob/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
