import {useEffect, useState} from 'react'
import {getAllAreaInterest} from '../../../../services/areaInterest'
import {getAllEducationLevels} from '../../../../services/educationLevel'
import {getAllLanguage} from '../../../../services/language'
import {getAllMajor} from '../../../../services/major'
import {getAllUsers} from '../../../../services/user'
import Multiselect from 'multiselect-react-dropdown'
import ErrorMessage from '../../../../components/ErrorMessage'
import {formatDate} from '../../../../utils/DateFormatter'
import {Form} from 'react-bootstrap'
import {getAllJobRole} from '../../../../services/jobRole'
import UploadeProfileImage from '../../user/components/uploadeProfileImage'
type Props = {
  handleChange: any
  data: any
  action: any
  isSubmitted: boolean
  formData?: any
}
export const StudentDataForm = (props: Props) => {
  const {handleChange, data, action, isSubmitted, formData} = props
  const [institutions, setInstitutions] = useState<any>([])
  const [major, setMajor] = useState<any>([])
  const [languages, setLanguages] = useState<any>([])
  const [areaOfInterest, setAreaOfInterest] = useState<any>([])
  const [educationLevels, setEducationLevels] = useState<any>([])
  const [jobRoleList, setJobRoleList] = useState<any>([])
  const [imagefile, setImagefile] = useState<any>()
  useEffect(() => {
    getInstitutionList()
    getMajorList()
    getLangList()
    getEducationList()
    getAreaOfInterestList()
    listJobRole()
  }, [])
  useEffect(() => {
    handleChange(data ? data?.subModel?.enrolledInHighSchool : 'true', 'enrolledInHighSchool')
  }, [data?.subModel?.enrolledInHighSchool])
  const listJobRole = async () => {
    const response = await getAllJobRole()
    setJobRoleList(response.data.data.queryResult)
  }
  const getInstitutionList = async () => {
    const response = await getAllUsers('Institution')
    setInstitutions(response.data.data.queryResult)
  }
  const getMajorList = async () => {
    const response = await getAllMajor()
    setMajor(response.data.data.queryResult)
  }
  const getLangList = async () => {
    const response = await getAllLanguage()
    setLanguages(response.data.data.queryResult)
  }
  const getEducationList = async () => {
    const response = await getAllEducationLevels()
    setEducationLevels(response.data.data.queryResult)
  }
  const getAreaOfInterestList = async () => {
    const response = await getAllAreaInterest()
    setAreaOfInterest(response.data.data.queryResult)
  }
  useEffect(() => {
    // setFormData((_data: any) => ({ ..._data, profileImage: imagefile }))
    if (imagefile || imagefile == 'null') {
      handleChange(imagefile != 'null' ? imagefile : '', 'profileImage')
    }
  }, [imagefile])

  return (
    <div className=' d-flex row'>
      {/* {action && <UploadeProfileImage isUser={true} setImagefile={setImagefile} type='logo' path={data?.profileImage} />} */}

      <div className='input-data my-3 col-md-4 col-12'>
        <label htmlFor='firstName' className='fs-5 me-3'>
          <span className='text-danger'>*</span> First Name
        </label>
        <input
          type='text'
          name='firstName'
          className='form-control'
          defaultValue={data?.subModel?.firstName}
          onChange={handleChange}
        />
        {isSubmitted && !data?.firstName && <ErrorMessage errorMsg='This field' />}
      </div>
      <div className='input-data my-3 col-md-4 col-12'>
        <label htmlFor='middleName' className='fs-5 me-3'>
          Middle Name
        </label>
        <input
          type='text'
          name='middleName'
          className='form-control'
          defaultValue={data?.subModel?.middleName}
          onChange={handleChange}
        />
      </div>
      <div className='input-data my-3 col-md-4 col-12'>
        <label htmlFor='lastName' className='fs-5 me-3'>
          <span className='text-danger'>*</span> Last Name
        </label>
        <input
          type='text'
          name='lastName'
          className='form-control'
          defaultValue={data?.subModel?.lastName}
          onChange={handleChange}
        />
        {isSubmitted && !data?.lastName && <ErrorMessage errorMsg='This field' />}
      </div>
      <div className='input-data my-3 col-md-6 col-12'>
        <label htmlFor='email' className='fs-5 me-3'>
          <span className='text-danger'>*</span> Primary Email
        </label>
        <input
          type='email'
          name='email'
          className='form-control'
          defaultValue={data?.email}
          onChange={handleChange}
        />
        {isSubmitted && !data?.email && <ErrorMessage errorMsg='This field' />}
      </div>
      <div className='input-data my-3 col-md-6 col-12'>
        <label htmlFor='secondaryEmail' className='fs-5 me-3'>
          Secondary Email
        </label>
        <input
          type='email'
          name='secondaryEmail'
          className='form-control'
          defaultValue={data?.subModel?.secondaryEmail}
          onChange={handleChange}
        />
      </div>
      {!action && (
        <div className='input-data my-3 col-md-6 col-12'>
          <label htmlFor='password' className='fs-5 me-3'>
            <span className='text-danger'>*</span> Password
          </label>
          <input
            type='password'
            name='password'
            className='form-control'
            defaultValue={data?.subModel?.password}
            onChange={handleChange}
          />
          {isSubmitted && !data?.password && <ErrorMessage errorMsg='This field' />}
        </div>
      )}
      <div className='input-data my-3 col-md-6 col-12'>
        <label htmlFor='gender' className='fs-5 me-3'>
          <span className='text-danger'>*</span> Gender
        </label>
        <select
          className='form-select'
          aria-label='Select example'
          name='gender'
          onChange={handleChange}
          defaultValue={data?.subModel?.gender}
        >
          <option disabled selected>
            -- Select an option --
          </option>
          <option value='male' selected={data?.subModel?.gender === 'male'}>
            Male
          </option>
          <option value='female' selected={data?.subModel?.gender === 'female'}>
            Female
          </option>
        </select>
        {isSubmitted && !data?.gender && <ErrorMessage errorMsg='This field' />}
      </div>
      <div className='input-data my-3 col-md-12 col-12'>
        <label htmlFor='fluentLanguage' className='fs-5 me-3'>
          Language Fluency
        </label>
        <Multiselect
          displayValue='nameEn'
          onKeyPressFn={function noRefCheck() {}}
          selectedValues={data?.subModel?.fluentLanguage}
          onRemove={function noRefCheck(selectedList: any, selectedItem: any) {
            handleChange(
              selectedList.map((obj: any) => obj._id),
              'fluentLanguage'
            )
          }}
          onSelect={function noRefCheck(selectedList: any, selectedItem: any) {
            handleChange(
              selectedList.map((obj: any) => obj._id),
              'fluentLanguage'
            )
          }}
          options={languages}
        />
      </div>
      <div className='input-data my-3 col-md-12 col-12'>
        <label htmlFor='gender' className='fs-5 me-3'>
          Language Proficiency
        </label>
        <Multiselect
          displayValue='nameEn'
          onKeyPressFn={function noRefCheck() {}}
          onRemove={function noRefCheck(selectedList: any, selectedItem: any) {
            handleChange(
              selectedList.map((obj: any) => obj._id),
              'proficientLanguage'
            )
          }}
          onSelect={function noRefCheck(selectedList: any, selectedItem: any) {
            handleChange(
              selectedList.map((obj: any) => obj._id),
              'proficientLanguage'
            )
          }}
          selectedValues={data?.subModel?.proficientLanguage}
          options={languages}
        />
      </div>
      <div>
        <div className='input-data my-3'>
          <label htmlFor='institution' className='fs-5 me-3'>
            Institution
          </label>
          <select
            className='form-select'
            aria-label='Select example'
            name='institution'
            onChange={(e) =>
              e.target.value != 'null' ? handleChange(e) : handleChange('', 'institution')
            }
            defaultValue={data?.subModel?.institution}
          >
            <option value={'null'} selected>
              -- No option --
            </option>
            {institutions.map((item: any) => (
              <option selected={data?.subModel?.institution?._id === item._id} value={item._id}>
                {item.subModel.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='input-data my-3 col-12'>
        <div className=' form-check-solid form-check-sm px-3'>
          <label className='fs-5 ' htmlFor='enrolledInHighSchool'>
            <span className='text-danger'>*</span> Enrolled in Higher Education
          </label>
          <Form>
            <div className='mb-3 d-flex mt-2 gap-8'>
              <Form.Check
                checked={
                  formData?.enrolledInHighSchool == true ||
                  formData?.enrolledInHighSchool === 'true' ||
                  data?.enrolledInHighSchool == true ||
                  data?.enrolledInHighSchool === 'true'
                }
                value='true'
                type='radio'
                label={`Yes`}
                id={`yes`}
                name='enrolledInHighSchool'
                onChange={handleChange}
              />
              <Form.Check
                onChange={handleChange}
                name='enrolledInHighSchool'
                checked={
                  formData?.enrolledInHighSchool === false ||
                  formData?.enrolledInHighSchool === 'false' ||
                  data?.enrolledInHighSchool == false ||
                  data?.enrolledInHighSchool === 'false'
                }
                type='radio'
                label={`No`}
                id={`no`}
                value='false'
              />
            </div>
          </Form>
          {isSubmitted && !data?.enrolledInHighSchool && <ErrorMessage errorMsg='This field' />}
        </div>
      </div>
      {/* IF YEEES */}
      {formData?.enrolledInHighSchool == true ||
      formData?.enrolledInHighSchool === 'true' ||
      data?.enrolledInHighSchool == true ||
      data?.enrolledInHighSchool === 'true' ? (
        <>
          {' '}
          <div className='input-data my-3 col-md-12 col-12'>
            <label htmlFor='major' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Major/Area of study
            </label>
            {/* <select
                className='form-select'
                aria-label='Select example'
                name='major'
                onChange={handleChange}
                defaultValue={data?.subModel?.major}
              >
                <option disabled selected>
                  -- Select an option --
                </option>
                {major.map((item: any) => (
                  <option selected={data?.subModel?.major?._id === item._id} value={item._id}>{item.nameEn}</option>
                ))}
              </select> */}
            <Multiselect
              displayValue='nameEn'
              onKeyPressFn={function noRefCheck() {}}
              onRemove={function noRefCheck() {}}
              onSelect={function noRefCheck(selectedList: any, selectedItem: any) {
                handleChange(
                  selectedList.map((obj: any) => obj._id),
                  'major'
                )
              }}
              selectedValues={data?.subModel?.major}
              options={major}
            />
            {/* <Multiselect
              displayValue='nameEn'
              onKeyPressFn={function noRefCheck() { }}
              onRemove={function noRefCheck() { }}
              onSelect={function noRefCheck(selectedList: any, selectedItem: any) {
                handleChange(
                  selectedList.map((obj: any) => obj._id),
                  'major'
                )
              }}
              selectedValues={data?.subModel?.major}
              options={major}
            /> */}
          </div>
          <div className='input-data my-3 col-md-6 col-12'>
            <label htmlFor='graduationDate' className='fs-5 me-3'>
              <span className='text-danger'>*</span> Graduation Date
            </label>
            <input
              type='date'
              name='graduationDate'
              // value={formatDate(data?.subModel?.graduationDate)}
              defaultValue={formatDate(data?.subModel?.graduationDate)}
              className='form-control'
              onChange={handleChange}
            />
          </div>
        </>
      ) : formData?.enrolledInHighSchool === false ||
        formData?.enrolledInHighSchool === 'false' ||
        data?.enrolledInHighSchool == false ||
        data?.enrolledInHighSchool === 'false' ? (
        <div className='input-data my-3 col-md-6 col-12'>
          <label htmlFor='areaOfInterest' className='fs-5 me-3'>
            <span className='text-danger'>*</span> Area of Interest
          </label>
          <select
            className='form-select'
            aria-label='Select example'
            name='areaOfInterest'
            onChange={handleChange}
            defaultValue={data?.subModel?.areaOfInterest}
          >
            <option disabled selected>
              -- Select an option --
            </option>
            {areaOfInterest.map((item: any) => (
              <option selected={data?.subModel?.areaOfInterest?._id === item._id} value={item._id}>
                {item.nameEn}
              </option>
            ))}
          </select>
        </div>
      ) : (
        ''
      )}
      <div className='input-data my-3 col-md-6 col-12'>
        <label htmlFor='educationLevel' className='fs-5 me-3'>
          <span className='text-danger'>*</span> Education Level
        </label>
        <select
          className='form-select'
          aria-label='Select example'
          name='educationLevel'
          onChange={handleChange}
          defaultValue={data?.subModel?.educationLevel}
        >
          <option disabled selected>
            -- select an option --
          </option>
          {educationLevels.map((item: any) => (
            <option selected={data?.subModel?.educationLevel?._id === item._id} value={item._id}>
              {item.nameEn}
            </option>
          ))}
        </select>
      </div>
      {/* IF NO higher education */}
      <div className='input-data my-3 col-md-12 col-12'>
        <label className='fs-5 me-3'>Interested Job Roles</label>
        <Multiselect
          displayValue='nameEn'
          onKeyPressFn={function noRefCheck() {}}
          onRemove={function noRefCheck() {}}
          onSelect={function noRefCheck(selectedList: any, selectedItem: any) {
            handleChange(
              selectedList.map((obj: any) => obj._id),
              'jobMajors'
            )
          }}
          selectedValues={data?.subModel?.jobMajors}
          options={jobRoleList}
        />
      </div>
      {/* UPDATE */}
      {/* {action && ( */}
      <>
        {/* <CareerInterest handleChange={handleChange} data={data} />
              <Education handleChange={handleChange} data={data} />
              <Projects handleChange={handleChange} data={data} />
              <Courses handleChange={handleChange} data={data} />
              <Skills handleChange={handleChange} data={data?.subModel.skills} />
              <Organizations handleChange={handleChange} data={data?.subModel.organizations} /> */}
        <div className='input-data my-3 col-md-12 col-12'>
          <label htmlFor='brief' className='fs-5 me-3'>
            Brief
          </label>
          <textarea
            name='brief'
            className='form-control'
            defaultValue={data?.subModel?.brief}
            onChange={handleChange}
          />
        </div>
        {/* <div className='input-data my-3'>
                <div className='form-check form-check-custom form-check-solid form-check-sm'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={data?.subModel?.student}
                    defaultValue={data?.subModel?.student}
                    name='student'
                    onChange={handleChange}
                  />
                  <label className='form-check-label fs-5 me-3' htmlFor='accountVisibility'>
                    student
                  </label>
                </div>
              </div> */}
        <div className='input-data my-3 col-md-6 col-12'>
          <label htmlFor='gpa' className='fs-5 me-3'>
            GPA
          </label>
          <input
            type='text'
            name='gpa'
            className='form-control'
            defaultValue={data?.subModel?.gpa}
            value={formData?.hasOwnProperty('gpa') ? formData.gpa : data?.subModel?.gpa}
            onChange={handleChange}
          />
          <button className='btn btn-primary mt-5' onClick={(e) => handleChange('', 'gpa')}>
            clear GPA
          </button>
        </div>
        <div className='input-data my-3 col-md-6 col-12'>
          <label htmlFor='accountVisibility' className='fs-5 me-3'>
            Account Visibility
          </label>
          <select
            className='form-select'
            aria-label='Select example'
            name='accountVisibility'
            onChange={handleChange}
            defaultValue={data?.subModel?.accountVisibility}
          >
            <option disabled selected>
              -- select an option --
            </option>
            {/* <option selected={data?.subModel?.accountVisibility === 'community'} value="community">Community</option> */}
            <option selected={data?.subModel?.accountVisibility === 'employer'} value='employer'>
              Employers and Educational Institutions
            </option>
            <option selected={data?.subModel?.accountVisibility === 'hidden'} value='hidden'>
              Private
            </option>
          </select>
        </div>

        <div className='input-data my-3'>
          <div className='form-check-solid form-check-sm'>
            <input
              className='form-check-input'
              type='checkbox'
              defaultChecked={data?.subModel?.agreeToTerms}
              defaultValue={data?.subModel?.agreeToTerms}
              name='agreeToTerms'
              onChange={handleChange}
            />
            <label className=' fs-5 mx-3' htmlFor='agreeToTerms'>
              Agree to terms
            </label>
          </div>
        </div>
      </>
    </div>
  )
}
