import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllJobType = (page:number=0,pageSize:number=0) => {
    const pagination =  page || pageSize ? `?page=${page}&pageSize=${pageSize}`: ''
  return axios
    .get(`${BASEURL}jobType${pagination}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getJobType = (_id: any) => {
  return axios
    .get(`${BASEURL}jobType/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateJobType = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}jobType/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createJobType = (order: number, nameEn: string, nameAr: string) => {
  return axios
    .post(`${BASEURL}jobType`, {order: order, nameEn: nameEn, nameAr: nameAr})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteJobType = (_id: string) => {
  return axios
    .delete(`${BASEURL}jobType/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
