import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { Delete } from '../delete'
import { IDomainModel } from '../module.interface'
import { deleteDomain, getAllDomain } from '../../../../../services/domainServices'
import { toast } from 'react-toastify'

type Props = {
  list: IDomainModel[]
  listALl: any
}

export const Table = (props: Props) => {
  const { list, listALl } = props
  const [_id, setId] = useState<string>('')

  const [loading, setLoading] = useState<any>(false)
  const [showModal, setShowModal] = useState(false)

  const viewModal = (_id: any) => {
    setId(_id)
    setShowModal(true)
  }

  const cancelFn = () => {
    setShowModal(false)
  }

  const delteFn = () => {
    deleteDomainFn()
    setShowModal(false)
  }

  const deleteDomainFn = async () => {
    setLoading(true)
    const response = await deleteDomain(_id)
    if (response.status === 200) {
      toast.success('Deleted successfully !', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      cancelFn()
      setLoading(false)
      listALl()
    } else {
      setLoading(false)
      cancelFn()
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  return (
    <>
      <div>
        <div className={`card `}>
          {/* begin::Body */}
          <div className='card-body py-3 '>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    {/* <th className='min-w-150px'>Order</th> */}
                    <th className='min-w-140px'>Name</th>
                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {list.map((domain) => {
                  return (
                    <>
                      {/* begin::Table body */}
                      <tbody>
                        <tr>
                          <td>
                            <div className='text-dark fw-bold text-hover-primary d-block fs-6 '>
                              {domain.name}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <Link
                                to={`/domain/update/${domain._id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-5'
                              >
                                <i className='bi bi-pencil-square text-info fs-2'></i>
                              </Link>

                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                onClick={() => viewModal(domain._id)}
                              >
                                <i className='bi bi-trash text-danger fs-2'></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      {/* end::Table body */}
                    </>
                  )
                })}
              </table>
              {/* <div className='d-flex flex-stack flex-wrap pt-10'>
                <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 10 of 50 entries</div>

                <ul className='pagination'>
                  <li className='page-item previous'>
                    <a href='#' className='page-link'>
                      <i className='previous'></i>
                    </a>
                  </li>

                  <li className='page-item active'>
                    <a href='#' className='page-link'>
                      1
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      2
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      3
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      4
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      5
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      6
                    </a>
                  </li>

                  <li className='page-item next'>
                    <a href='#' className='page-link'>
                      <i className='next'></i>
                    </a>
                  </li>
                </ul>
              </div> */}
              {/* end::Table */}

              {showModal && <Delete show={showModal} onCancel={cancelFn} onDelete={delteFn} loading={loading} />}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      </div>
    </>
  )
}
