/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { StudentRoutes } from './StudentRoutes'
import { CompaniesRoutes } from './CompaniesRoutes'
import { InstitutionRoutes } from './InstitutionRoutes'
import { MentorRoutes } from './MentorRoutes'
import { ChangePassword } from '../modules/auth/ChangePassword'
// import jwtDecode from 'jwt-decode'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()


  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>

        <Route element={<App />}>

          {/* <Route path='*' element={<ErrorsPage />} /> */}
          <Route path='logout' element={<Logout />} />
          <Route path='reset-password/*' element={<ChangePassword />} />

          {currentUser ? (
            <>
              <Route path='/*' element={
                currentUser.role === '0' || currentUser.role === 0 ? <PrivateRoutes /> :
                  currentUser.role === '1' || currentUser.role === 1 ? <StudentRoutes /> :
                    currentUser.role === '2' || currentUser.role === 2 ? <CompaniesRoutes /> :
                      currentUser.role === '3' || currentUser.role === 3 ? <InstitutionRoutes /> :
                        currentUser.role === '4' || currentUser.role === 4 ? <MentorRoutes /> : ''

              } />
              <Route index
                element={<Navigate to={
                  (currentUser.role === '0' || currentUser.role === 0) ? '/dashboard' :
                    (currentUser.role === '1' || currentUser.role === 1) ? '/student' :
                      (currentUser.role === '2' || currentUser.role === 2) ? '/companies' :
                        (currentUser.role === '3' || currentUser.role === 3) ? '/institution' :
                          (currentUser.role === '4' || currentUser.role === 4) ? '/mentors' :

                            ''}
                />} />

            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='reset-password/*' element={<ChangePassword />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}

        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
