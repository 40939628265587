import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Table } from './components/table'
import { IWorkType } from './module.interface'
import { PageTitle } from '../../../components/pageTitle'
import { getAllWorkType } from '../../../services/workType'
import Pagination from '../../../components/Pagination'
import SearchInput from '../../../components/SearchInput'

export const WorkType = () => {
  const [workTypeList, setWorkTypeList] = useState<Array<IWorkType>>([])
  const [loading, setLoading] = useState<any>(false)
  const [dataLength, setDataLength] = useState<any>(0)

  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(5)
  const [searchActive, setSearchActive] = useState(false)
  const listWorkType = async () => {
    setLoading(true)
    const response = await getAllWorkType(currentPage, pageSize)
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / pageSize))
    setDataLength(response.data.data.queryDataLength)
    setWorkTypeList(response.data.data.queryResult)
    setLoading(false)
  }
  const setDataResult = (response: any) => {
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / pageSize))
    setDataLength(response.data.data.queryDataLength)
    setWorkTypeList(response.data.data.queryResult)
    setLoading(false)
  }
  const handlePaginationChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleCountChange = (e: any) => {
    setPageSize(e.target.value)

  }

  useEffect(() => {
    !searchActive && listWorkType()
  }, [pageSize, currentPage])

  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Work Type' />
        <Link to='/work-type/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
      <SearchInput
            model={'workType'}
            setLoading={setLoading}
            setDataResult={setDataResult}
            ListAll={listWorkType}
            currentPage={currentPage}
            pageSize={pageSize}
            setSearchActive={setSearchActive}
            setCurrentPage={setCurrentPage}

          />
      {loading ? (
        <div className="spinner-border text-light" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <>
         

          <Table list={workTypeList} listALl={listWorkType} length={dataLength} />

          <div className='d-flex justify-content-between'>

            <>
              <div className='flex justify-end my-6'>
                <Pagination
                  totalCount={totalPages}
                  onPageChange={handlePaginationChange}
                  currentPage={currentPage}
                />
              </div>
              <select
                className='form-select mr-4 mt-4'
                style={{ width: '75px', height: '50px' }}
                onChange={handleCountChange}
                value={pageSize}
              >
                <option value='10'>5</option>
                <option value='10'>10</option>
                <option value='20'>20</option>
                <option value='30'>30</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </select>
            </>

          </div>
        </>
      )}
    </>
  )
}
