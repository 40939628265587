import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllMentorShipType = () => {
  return axios
    .get(`${BASEURL}mentorShipType`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getMentorShipType = (_id: any) => {
  return axios
    .get(`${BASEURL}mentorShipType/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateMentorShipType = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}mentorShipType/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createMentorShipType = (order: number, nameEn: string, nameAr: string) => {
  return axios
    .post(`${BASEURL}mentorShipType`, {order: order, nameEn: nameEn, nameAr: nameAr})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteMentorShipType = (_id: string) => {
  return axios
    .delete(`${BASEURL}mentorShipType/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
