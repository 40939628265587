import React, {ChangeEvent, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../components/pageTitle'
import {createEducationLevel} from '../../../services/educationLevel'
import {createJob} from '../../../services/job'
import {DataForm} from './components/data-form'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
export default function CreateJob() {
  const [formData, setFormData] = useState<any>([])
  const [disabled, setDisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)
  const [isSubmitted, setIsSubmitted] = useState<any>(false)
  const [loadingPage, setLoadingPage] = useState<any>(false)
  const req = [
    'name',
    'roleDescription',
    'jobType',
    'workType',
    'applicationType',
    'jobRole',
    'hoursPerWeek',
    'major',
  ]
  const navigate = useNavigate()

  const breadCrampList = [
    {
      name: 'Jobs',
      route: '/jobs',
    },
  ]

  const handleChange = (e: any, name = '') => {
   
    if (name !== '') {
      setFormData((_data: any) => ({..._data, [name]: e}))
    } else {
      setFormData((_data: any) => ({..._data, [e.target.name]: e?.target?.name==='workAuthorization'?parseInt(e.target.value):e.target.value}))
      setDisabled(false)
      if (e.target.type === 'checkbox') {
        setFormData({...formData, [e.target.name]: e.target.checked})
      }
    }
 
  }

  const handleSubmit = (e: any) => {
    setIsSubmitted(true)
    e.preventDefault()
    req.every((key) => console.log(key,formData[key])) 
    // req.every((key) => key in formData && formData[key] !== '') && 
    newJob()
  }

  const newJob = async () => {
    setLoading(true)
    const response = await createJob(formData?.company,formData)
    if (response.status === 201) {
      toast.success('Done successfully !', {
         position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
       theme: "colored",
        });
      navigate('/jobs')
      setLoading(false)
    } else {
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setLoading(false)
    }
  }

  return (
    <>
       <ToastContainer/>
      <PageTitle breadCrampList={breadCrampList} active='Create' />
      <div className=' w-75 m-auto'>
        <DataForm
        loadingPage={loadingPage}
          onClickSubmit={handleSubmit}
          handleChange={handleChange}
          ButtonText='Create'
          data={formData}
          Show={disabled}
          loading={loading}
          isSubmitted={isSubmitted}
        />
      </div>
    </>
  )
}
