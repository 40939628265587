import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Delete } from '../delete'
import { IMentorShipType } from '../module.interface'
import { KTSVG } from '../../../../../_metronic/helpers'
import { deleteMentorShipType } from '../../../../services/mentorShipType'
import { exportTableToExcel } from '../../../../utils/export'
import { toast } from 'react-toastify'

type Props = {
  list: IMentorShipType[]
  listALl: any
}

export const Table = (props: Props) => {
  const { list, listALl } = props
  const [loading, setLoading] = useState<any>(false)

  const [showModal, setShowModal] = useState(false)
  const [_id, setId] = useState<string>('')

  const viewModal = (_id: any) => {
    setId(_id)
    setShowModal(true)
  }

  const cancelFn = () => {
    setShowModal(false)
  }

  const delteFn = () => {
    deleteMentorShipTypeFn()
    setShowModal(false)
  }

  const deleteMentorShipTypeFn = async () => {
    setLoading(true)
    const response = await deleteMentorShipType(_id)
    if (response.status === 200) {
      toast.success('Deleted successfully !', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      cancelFn()
      setLoading(false)
      listALl()
    } else {
      setLoading(false)
      cancelFn()
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }
  const handleExport = (id: string) => {
    exportTableToExcel(id, `${id}.xlsx`)
  }

  return (
    <>
      <div>
        {/* <button
          className='btn btn-danger d-flex justify-content-end'
          onClick={() => {
            handleExport(`mentorshipetype-list`)
          }}
        >
          Export
        </button> */}
        <div className={`card `}>
          {/* begin::Body */}
          <div className='card-body py-3 '>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-150px'>Order</th>
                    <th className='min-w-140px'>Name English</th>
                    <th className='min-w-120px'>Name Arabic</th>
                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {list.map((offer) => {
                  return (
                    <>
                      {/* begin::Table body */}
                      <tbody>
                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark fw-bold text-hover-primary fs-6'>
                                  {offer.order}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='text-dark fw-bold text-hover-primary d-block fs-6 '>
                              {offer.nameEn}
                            </div>
                          </td>
                          <td>
                            <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                              {offer.nameAr}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <Link
                                to={`/mentor-ship-type/update/${offer._id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-5'
                              >
                                <i className='bi bi-pencil-square text-info fs-2'></i>
                              </Link>

                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                onClick={() => viewModal(offer._id)}
                              >
                                <i className='bi bi-trash text-danger fs-2'></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      {/* end::Table body */}
                    </>
                  )
                })}
              </table>
              {/* <div className='d-flex flex-stack flex-wrap pt-10'>
                <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 10 of 50 entries</div>

                <ul className='pagination'>
                  <li className='page-item previous'>
                    <a href='#' className='page-link'>
                      <i className='previous'></i>
                    </a>
                  </li>

                  <li className='page-item active'>
                    <a href='#' className='page-link'>
                      1
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      2
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      3
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      4
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      5
                    </a>
                  </li>

                  <li className='page-item'>
                    <a href='#' className='page-link'>
                      6
                    </a>
                  </li>

                  <li className='page-item next'>
                    <a href='#' className='page-link'>
                      <i className='next'></i>
                    </a>
                  </li>
                </ul>
              </div> */}
              {/* end::Table */}

              {showModal && (
                <Delete show={showModal} onCancel={cancelFn} onDelete={delteFn} loading={loading} />
              )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      </div>
    </>
  )
}
