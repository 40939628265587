import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Table} from './components/table'
import {IFindJob} from './module.interface'
import {PageTitle} from '../../../../components/pageTitle'
import {getAllFindingJob} from '../../../../services/resourcesFindingJob'

export const FindingJob = () => {
  const [findJobList, setFindJobList] = useState<Array<IFindJob>>([])
  const [loading, setLoading] = useState<any>(false)

  const listAllIFindingJob = async () => {
    setLoading(true)
    const response = await getAllFindingJob()
    setFindJobList(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    listAllIFindingJob()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Finding Job' />
        <Link to='/resources-finding-job/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>

      <Table list={findJobList} listALl={listAllIFindingJob} />
    </>
  )
}
