import { useEffect, useState } from 'react'
import { getAllIndustry } from '../../../../services/industry'
import { getAllSubIndustry } from '../../../../services/subIndustry'
type Props = {
  data: any
  handleChange: any
  job?: any
  updatedData?: any
}
export default function JobIndustries(props: Props) {
  const { data, handleChange, job, updatedData } = props
  const [subIndustries, setSubIndustries] = useState<any>([])
  const [industries, setIndustries] = useState<any>([])
  const [industry, setIndustry] = useState<any>(null)
  const [subIndustry, setSubIndustry] = useState<any>(null)
  useEffect(() => {
    getIndustriesList()
    getSubIndustriesList()
  }, [])
  const getIndustriesList = async () => {
    const response = await getAllIndustry()
    setIndustries(response?.data?.data)
  }
  const getSubIndustriesList = async () => {
    const response = await getAllSubIndustry()
    setSubIndustries(response.data.data.queryResult)
  }
  useEffect(() => {
    if (data?.industry) {
      setIndustry(industry?industry:data?.industry?._id)
      setSubIndustry(subIndustry?subIndustry:data?.subIndustry?._id)
    }
  }, [data])

  return (
    <>
      <label htmlFor='companySize' className='fs-5 me-3 mb-4'>
      <span className='text-danger'>*</span>   Industry
      </label>
      <select
        className='form-select'
        aria-label='Select example'
        name='industry'
        onChange={(e: any) => {
          setIndustry(e.target.value)
          e.target.value != 'null' ? handleChange(e.target.value, 'industry') : handleChange(null, 'industry')
          handleChange(null, 'subIndustry')
          setSubIndustry(null)
        }}
      >
        <option value={'null'} selected={industry === 'null'}>-- No option --</option>
        {industries.map((item: any) => (
          <option selected={industry === item._id} value={item._id}>
            {item.nameEn}
          </option>
        ))}
      </select>
      {industry && (
        <> <label htmlFor='companySize' className='fs-5 me-3 my-4'>
          <span className='text-danger'>*</span>   Sub Industry
        </label>
          <select
            className='form-select'
            aria-label='Select example'
            name='subIndustry'
            onChange={(e: any) => {
              e.target.value != 'null' ? handleChange(e.target.value, 'subIndustry') : handleChange(null, 'subIndustry')
              e.target.value != 'null' ? setSubIndustry(e.target.value) : setSubIndustry(null)
            }}
          >
            <option value={'null'} selected={subIndustry === null}>-- No option --</option>
            {subIndustries.filter((item: any) => item?.industry == industry).map((item: any) => (
              <option selected={subIndustry === item._id} value={item._id}>
                {item.nameEn}
              </option>
            ))}
          </select>
        </>
      )}
    </>
  )
}
