import React, {ChangeEvent, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {PageTitle} from '../../../components/pageTitle'
import {getUser, updateUser} from '../../../services/user'
import {DataForm} from './components/data-form'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
type Props = {
  model: any
}

export const UpdateUser = (props: Props) => {
  const {model} = props

  const [disabled, setdisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)
  const navigate = useNavigate()
  const {id} = useParams()

  const [formData, setFormData] = useState<any>()
  const [userData, setUserData] = useState<any>({})
  const [isSubmitted, setIsSubmitted] = useState<any>(false)
  const [isLoadingData, setIsLoadingData] = useState<any>(false)
  const req = {
    Company: ['name', 'webSite', 'headQuarters', 'companySize', 'phoneNumber'],
    Mentor: [
      'firstName',
      'lastName',
      'currentTitle',
      'password',
      'linkedInUrl',
      'mentorShipTypes',
      'about',
    ],
  }
  type ObjectKey = keyof typeof req

  const handleChange = (e: any, name = '') => {
    if (name !== '') {
      setFormData((_data: any) => ({..._data, [name]: e}))
    } else {
      if (e.target.type === 'checkbox') {
        setFormData((_data: any) => ({..._data, [e.target.name]: e.target.checked}))
      } else {
        setFormData((_data: any) => ({..._data, [e.target.name]: e.target.value}))
      }
    }
  }

  useEffect(() => {
    if (id) {
      handleGetUser()
    }
  }, [id])

  const handleGetUser = async () => {
    setIsLoadingData(true)
    const response = await getUser(id)
    if (response) {
      setUserData(response.data.data)
      setIsLoadingData(false)
    }
  }

  const onClickSubmitBtn = (e: any) => {
    setIsSubmitted(true)
    e.preventDefault()
    handleUpdateUser()
  }

  const mapData = (data: any) => {
    const formDataBody = new FormData()

    for (const property in data) {
      if (typeof data[property] === 'string' || data[property] === 'boolean') {
        formDataBody.append(property, data[property])
      } else {
        if (property === 'socialMediaLinks') {
          let final = []
          for (const item in data[property]) {
            final.push(data[property][item])
          }
          formDataBody.append(property, JSON.stringify(final))
        } else if (
          property === 'logo' ||
          property === 'coverPhoto' ||
          property === 'banner' ||
          property === 'profileImage'
        ) {
          formDataBody.append(property, data[property])
        } else {
          formDataBody.append(property, JSON.stringify(data[property]))
        }
      }
    }

    return formDataBody
  }

  const handleUpdateUser = async () => {
    setLoading(true)
    const response = await updateUser(id, mapData(formData))
    if (response.status == 200) {
      const link: any = getNavigationLink()
      toast.success('ْUpdates successfully !', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })
      navigate(link)
      setLoading(false)
    } else {
      toast.error(response.data.error, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })

      setLoading(false)
    }
  }

  const getNavigationLink = () => {
    if (model) {
      switch (model.toLocaleLowerCase()) {
        case 'company':
          return '/companies'
        case 'mentor':
          return '/mentors'
        case 'institution':
          return '/institution'

        case 'student':
          return '/all-users'
      }
    }
  }

  function getbreadCrampList() {
    if (model) {
      switch (model.toLocaleLowerCase()) {
        case 'company':
          return [
            {
              name: 'Companies',
              route: '/companies',
            },
          ]
        case 'mentor':
          return [
            {
              name: 'Mentors',
              route: '/mentors',
            },
          ]
        case 'institution':
          return [
            {
              name: 'Institution',
              route: '/institution',
            },
          ]
        case 'student':
          return [
            {
              name: 'Students & Professionals',
              route: '/all-users',
            },
          ]
      }
    }
  }

  return (
    <>
      <div className='page-header mb-8'>
        <PageTitle breadCrampList={getbreadCrampList()} active='Update User Information' />
      </div>
      <ToastContainer />
      <div className='w-75 m-auto'>
        <DataForm
          onClickSubmit={onClickSubmitBtn}
          handleChange={handleChange}
          data={formData}
          setFormData={setFormData}
          ButtonText='Update'
          // Show={disabled}
          loading={loading}
          model={model}
          action='update'
          setUserData={setUserData}
          userData={userData}
          isSubmitted={isSubmitted}
          isLoadingData={isLoadingData}
          userType={model}
        />
      </div>
    </>
  )
}
