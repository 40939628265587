import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllTypeOfWork = () => {
  return axios
    .get(`${BASEURL}typeOfWork`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getTypeOfWork = (_id: any) => {
  return axios
    .get(`${BASEURL}typeOfWork/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateTypeOfWork = (_id: any,data:any) => {
  return axios
    .patch(`${BASEURL}typeOfWork/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createTypeOfWork = (order: number, nameEn: string, nameAr: string) => {
  return axios
    .post(`${BASEURL}typeOfWork`, {order:order, nameEn: nameEn , nameAr:nameAr})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteTypeOfWork = (_id: string) => {
  return axios
    .delete(`${BASEURL}typeOfWork/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
