import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllIndustry = (searchValue=null) => {
  return axios
    .get(`${BASEURL}industry${searchValue?`?term=${searchValue}`:''}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getIndustry = (_id: any) => {
  return axios
    .get(`${BASEURL}industry/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateIndustry = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}industry/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createIndustry = (order: number, nameEn: string, nameAr: string) => {
  return axios
    .post(`${BASEURL}industry`, {order: order, nameEn: nameEn, nameAr: nameAr})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteIndustry = (_id: string) => {
  return axios
    .delete(`${BASEURL}industry/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
