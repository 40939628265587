import {ChangeEvent, useEffect, useState} from 'react'
import {DataForm} from './components/data-form'
import {useNavigate, useParams} from 'react-router-dom'
import {PageTitle} from '../../../components/pageTitle'

import {getJob, updateJob} from '../../../services/job'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
export const UpdateJob = () => {

  const [disabled, setdisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)
  const [loadingPage, setLoadingPage] = useState<any>(true)
  const navigate = useNavigate()
  const {id} = useParams()
  const req = [
    'name',
    'roleDescription',
    'jobType',
    'workType',
    'applicationType',
    'jobRole',
    'hoursPerWeek',
    'major',
  ]
  const [formData, setFormData] = useState<any>()
  const [updatedData, setUpdatedData] = useState<any>()
  const [isSubmitted, setIsSubmitted] = useState<any>(false)

  const handleChange = (e: any, name = '') => {
    if (name !== '') {
      setUpdatedData((_data: any) => ({..._data, [name]: e}))
      setFormData((_data: any) => ({..._data, [name]: e}))
    } else {
      setUpdatedData((_data: any) => ({..._data, [e.target.name]: e.target.value}))
      setFormData((_data: any) => ({..._data, [e.target.name]: e.target.value}))
      if (e.target.type === 'checkbox') {
        setUpdatedData({...formData, [e.target.name]: e.target.checked})
        setFormData({...formData, [e.target.name]: e.target.checked})
      }
    }
   
  }
  useEffect(() => {
    if (id) {
      handleJob()
    }
  }, [id])

  const handleJob = async () => {
    setLoadingPage(true)
    const response = await getJob(id)
    if (response) {
      setFormData(response.data.data)
      setLoadingPage(false)
      
    }
  }

  const onClickSubmitBtn = (e: any) => {
   
    setIsSubmitted(true)
    e.preventDefault()
    // req.every((key) => key in formData && formData[key] !== '') &&
     handleUpdateJob()
  }

  const handleUpdateJob = async () => {
     setLoading(true)

    const response = await updateJob(id, updatedData)
    if (response.status == 200) {
      toast.success('Done successfully !', {
         position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
       theme: "colored",
        });
      navigate('/jobs')
      setLoading(false)
    } else {
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setLoading(false)
    }
  }

  const breadCrampList = [
    {
      name: 'Jobs',
      route: '/jobs',
    },
  ]

  return (
    <>
    <ToastContainer/>
    <div className='page-header mb-4'>
    <PageTitle breadCrampList={breadCrampList} active='Update job Post' />

    </div>
     
      <div className='w-75 m-auto'>
        <DataForm
        loadingPage={loadingPage}
          onClickSubmit={onClickSubmitBtn}
          handleChange={handleChange}
          data={formData}
          ButtonText='Update'
          Show={disabled}
          loading={loading}
          isSubmitted={isSubmitted}
          updatedData={updatedData}
        />
      </div>
    </>
  )
}
