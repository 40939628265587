import {useRef, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'

import {useFormik} from 'formik'

import {useAuth} from '../core/Auth'

import {signIn} from '../../../services/authServices'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import {adminGetResetPassword} from '../../../services/admin'
import ReCAPTCHA from 'react-google-recaptcha'
import {useNavigate} from 'react-router-dom'
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('User name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState(false)
  const [showForgetPassword, setShowForgetPassword] = useState(false)
  const captchaRef = useRef(null)
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      const response = await signIn(values.email, values.password)

      if (response.status === 200) {
        localStorage.setItem('userId', response.data.data.admin._id)
        localStorage.setItem('userToken', response.data.data.token)
        setLoading(false)
        saveAuth(response.data.data.token)
        setCurrentUser(response.data.data.admin)
        navigate('/')
      } else {
        setLoading(false)
        setError('The login details are incorrect')
        setSubmitting(false)
        saveAuth(undefined)
      }
    },
  })
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setMessage(false)
    setError('')
    setLoading(true)

    const response = await adminGetResetPassword(email)

    if (response.status === 200) {
      setLoading(false)
      setMessage(true)
    } else {
      setLoading(false)
      setError('The Email is incorrect')
    }
  }

  return (
    <div>
      <div className='text-center mb-12'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/Edqan_Logo_FF.png')}
          className='h-25px app-sidebar-logo-default'
        />
      </div>
      <div style={{border: '1px solid gainsboro'}} className='p-8 rounded'>
        {showForgetPassword ? (
          <form
            className='form w-100 '
            onSubmit={handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            {/* begin::Heading */}
            <div className='text-center mb-11'>
              <h1 className='text-dark fw-bolder mb-3'>Forgot password</h1>
            </div>
            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                Enter your email to send reset link
              </label>
              <input
                placeholder='Email'
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                onChange={(e: any) => {
                  setEmail(e.target.value)
                  setMessage(false)
                }}
                type='email'
                name='email'
                autoComplete='off'
              />
              {message && (
                <p className='bg-success text-white p-4 mt-4 rounded'>
                  Edqan sent a password reset link to "{email}". If you don't see the email, check
                  your spam folder.
                </p>
              )}
              {error && <p className='text-danger'>{error}</p>}
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container  text-danger'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              )}
            </div>

            <div className='d-grid'>
              <button
                type='submit'
                id='kt_forget_in_submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Continue</span>}
                {loading && (
                  <div className='spinner-border text-light' role='status'>
                    <span className='sr-only'>Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </form>
        ) : (
          <form
            className='form w-100 '
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            {/* begin::Heading */}
            <div className='text-center mb-11'>
              <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
              {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
            </div>
            {/* begin::Heading */}

            {error && (
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>{error}</span>
              </div>
            )}

            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
              <input
                placeholder='Email'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container  text-danger'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-3'>
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
              <input
                type='password'
                placeholder='password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container  text-danger'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='d-grid'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Continue</span>}
                {loading && (
                  <div className='spinner-border text-light' role='status'>
                    <span className='sr-only'>Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </form>
        )}

        <div className='form-item mt-4'>
          <a
            className='mb-4 d-block'
            style={{cursor: 'pointer'}}
            onClick={() => {
              setShowForgetPassword(!showForgetPassword)
              setMessage(false)
            }}
          >
            {showForgetPassword ? 'Back to login' : 'Forgot password'}
          </a>

          {/* <ReCAPTCHA
            sitekey="6Lej16YkAAAAABpmz9vGPEWdDVYjJiX7urjtU2ZJ"
            ref={captchaRef} /> */}
        </div>
        {/* end::Action */}
      </div>
    </div>
  )
}
