import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Table} from './components/table'
import {IMentoringProgram} from './module.interface'
import {PageTitle} from '../../../../components/pageTitle'
import {getAllMentoringProgramOptions} from '../../../../services/mentoringProgramOptions'
import {getAllMentorShipType} from '../../../../services/mentorShipType'
import {getSessions} from '../../../../services/user'
import Pagination from '../../../../components/Pagination'

export const MentoringProgram = () => {
  const [mentoringProgramList, setMentoringProgramList] = useState<Array<IMentoringProgram>>([])
  const [loading, setLoading] = useState<any>(false)
  const [userList, seUserList] = useState<Array<any>>([])

  const [dataLength, setDataLength] = useState<any>(0)
  const [searchValue, setSearchValue] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const listAllIMentoringProgram = async (size:any) => {
    setLoading(true)
   
    const response = await getSessions(true,currentPage,(size?size:pageSize))
    setMentoringProgramList(response.data.data.queryResult)
    setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / (size?size:pageSize)))
    setDataLength(response.data.data.queryDataLength)
    setLoading(false)
  }

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleCountChange = (e: any) => {
    setPageSize(e.target.value)
    listAllIMentoringProgram(e.target.value)
  }
  useEffect(() => {
    listAllIMentoringProgram(pageSize)
  }, [currentPage])

  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Mentoring Program' />
    
      </div>

      <Table list={mentoringProgramList} listALl={listAllIMentoringProgram} skelton={loading}/>
      <div className='d-flex justify-content-between'>
            
              <>
                <div className='flex justify-end my-6'>
                  <Pagination
                    totalCount={totalPages}
                    onPageChange={handlePaginationChange}
                    currentPage={currentPage}
                  />
                </div>
                <select
                  className='form-select mr-4 mt-4'
                  style={{ width: '75px', height: '50px' }}
                  onChange={handleCountChange}
                  value={pageSize}
                >
                  <option value='10'>10</option>
                  <option value='20'>20</option>
                  <option value='30'>30</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                </select>
              </>
          
          </div>
    </>
  )
}
