import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Table } from './components/table'
import { IIndustry } from './module.interface'
import { getAllIndustry } from '../../../services/industry'
import { PageTitle } from '../../../components/pageTitle'
import { searchAllModels } from '../../../services/search'

export const Industry = () => {
  const [industryList, setIndustryList] = useState<Array<IIndustry>>([])
  const [loading, setLoading] = useState<any>(false)
  const [searchValue, setSearchValue] = useState<any>('')
  const listIndustry = async () => {
    setLoading(true)
    const response = await getAllIndustry(searchValue)
    setIndustryList(response.data.data)
    setLoading(false)
  }
  const handleSearchChange = async () => {
    setLoading(true)
    const response = await searchAllModels('industry', searchValue, null, null)
    setIndustryList(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    if (!searchValue||searchValue == '') {
      listIndustry()
      return
    }
    if (searchValue != null) {
     
      const getData = setTimeout(() => {
        handleSearchChange()
      }, 800)
      return () => clearTimeout(getData)
    }
  }, [searchValue])
  return (
    <>
      <div className='d-flex justify-content-between my-3 page-header'>
        <PageTitle active='Industries' />
        <Link to='/industry/create'>
          <button className='btn btn-danger d-flex justify-content-end'> Create</button>
        </Link>
      </div>
      <div className='d-flex align-items-center position-relative my-4'>
        <input
          autoComplete="off"

          type='text'
          name='searchBar'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px  border bg-white'
          placeholder='Search'
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>
      {loading ? (
        <div className="spinner-border text-light" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (

        <Table list={industryList} listAll={listIndustry} />
      )}
    </>
  )
}
