import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllMentoringProgramOptions = () => {
  return axios
    .get(`${BASEURL}mentoringProgramOptions`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getMentoringProgramOptions = (_id: any) => {
  return axios
    .get(`${BASEURL}mentoringProgramOptions/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateMentoringProgramOptions = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}mentoringProgramOptions/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createMentoringProgramOptions = (order: number, nameEn: string, nameAr: string) => {
  return axios
    .post(`${BASEURL}mentoringProgramOptions`, {order: order, nameEn: nameEn, nameAr: nameAr})
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteMentoringProgramOptions = (_id: string) => {
  return axios
    .delete(`${BASEURL}mentoringProgramOptions/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
export const updateMeetingStatusFromAdmin = (_id: string,status:string) => {
  return axios
    .patch(`${BASEURL}meeting/${_id}`,{
      "mentorShipSession": {
        "approved": status 
        }
    })
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
