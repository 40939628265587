import * as React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers';

export default function SelectTime(props: any) {
  const { value, setValue, minTime } = props

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>

      <MobileTimePicker
        minTime={minTime}
        value={value}
        onChange={setValue}

        renderInput={(params) =>
          <TextField

            fullWidth {...params}
            onKeyDown={(e) => {
              e.preventDefault();
            }} />}
      />

    </LocalizationProvider>
  );
}