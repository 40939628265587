import ErrorMessage from '../../../../components/ErrorMessage'

type Props = {
  onClickSubmit: any
  handleChange: any
  data: any
  ButtonText: string
  Show: any
  loading: boolean
  isSubmitted: boolean
}

export const DataForm = (props: Props) => {
  const {onClickSubmit, handleChange, data, ButtonText, Show, loading, isSubmitted} = props
  return (
    <form className='form-control' onSubmit={onClickSubmit}>
      <div className='input-data my-3'>
        <label htmlFor='duration' className='fs-5 me-3 mb-2'>
          Meeting Duration in Minutes *
        </label>
        <input
          type='number'
          name='duration'
          placeholder='duration in Minutes'
          className='form-control'
          value={data.duration}
          onChange={handleChange}
        />
        {isSubmitted && (!data?.duration) && <ErrorMessage errorMsg='duration' />}
      </div>

      <div className='d-flex justify-content-end'>
        <button className='btn btn-primary' disabled={!data?.duration} type='submit'>
          {!loading && ButtonText}
          {loading && (
            <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          )}
        </button>
      </div>
    </form>
  )
}
