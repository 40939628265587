import {MouseEventHandler, useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useThemeMode} from '../../_metronic/partials'
import {formatDate} from '../utils/DateFormatter'

type Props = {
  list: any[]
}

export const MentorShipSessionTable = (props: Props) => {
  const {list} = props

  type Data = typeof list
  type SortKeys = keyof Data[0]
  type SortOrder = 'ascn' | 'desc'
  function sortData({
    tableData,
    sortKey,
    reverse,
  }: {
    tableData: Data
    sortKey: SortKeys
    reverse: boolean
  }) {
    if (!sortKey) return tableData

    const sortedData = shownData.sort((a: any, b: any) => {
      return a[sortKey] > b[sortKey] ? 1 : -1
    })

    if (reverse) {
      return sortedData.reverse()
    }

    return sortedData
  }

  function SortButton({
    sortOrder,
    columnKey,
    sortKey,
    onClick,
  }: {
    sortOrder: SortOrder
    columnKey: SortKeys
    sortKey: SortKeys
    onClick: MouseEventHandler<HTMLButtonElement>
  }) {
    const {mode} = useThemeMode()
    return (
      <button
        onClick={onClick}
        className={`${mode === 'dark' ? 'text-white' : 'text-dark'}  ${
          sortKey === columnKey && sortOrder === 'desc' ? 'sort-button sort-reverse' : 'sort-button'
        }`}
      >
        ▲
      </button>
    )
  }
  const [sortKey, setSortKey] = useState<any>('name')
  const [sortOrder, setSortOrder] = useState<SortOrder>('ascn')

  const sortedData = useCallback(
    () => sortData({tableData: shownData, sortKey, reverse: sortOrder === 'desc'}),
    [list, sortKey, sortOrder]
  )

  function changeSort(key: SortKeys) {
    setSortOrder(sortOrder === 'ascn' ? 'desc' : 'ascn')
    setSortKey(key)
  }

  const [shownData, setShownData] = useState<any>([])
  const headers: {key: SortKeys; label: string}[] = [
    {key: 'name', label: 'Participant'},
    {key: 'date', label: 'Date'},
    {key: 'type', label: 'Mentorship Type'},
    {key: 'sessionId', label: 'Session Id'},
    {key: 'status', label: 'Status'},
    {key: 'reviews', label: 'Reviews'},
  ]
  useEffect(() => {
    const data = sortedData().map((item: any) => ({
      _id: item?._id,
      name: item?.name,
      date: item?.date,
      type: item.type,
      sessionId: item.sessionId,
      status: item.status,
      reviews: item.reviews,
      menteeId: item?.menteeId,
    }))
    setShownData(data)
  }, [sortedData])
  useEffect(() => {
    if (list) {
      let userType = window.location.href.includes('/mentor') ? 'mentor' : 'mentee'
      // user1 is the student
      const data = list.map((item: any) => ({
        _id: item?._id,
        menteeId: item?.user1?.user?._id,
        name: item?.user1?.user?.subModel?.firstName + ' ' + item?.user1?.user?.subModel?.lastName,
        date: formatDate(item?.date),
        type: item?.mentorShipSession?.mentorShipType?.nameEn || 'N/A',
        sessionId: item.sessionId,
        status: item?.mentorShipSession?.status,
        // reviews: item?.mentorShipSession?.menteeRating || 'N/A',
        reviews:
          (userType == 'mentor'
            ? item?.mentorShipSession?.mentorRating
            : item?.mentorShipSession?.menteeRating) || 'N/A',
      }))
      setShownData(data)
    }
  }, [list])
  return (
    <div className='table-responsive'>
      {/* begin::Table */}
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        {/* begin::Table head */}
        <thead>
          <tr>
            {headers.map((row) => {
              return (
                <td
                  className={`cursor-pointer ${sortKey === row.key ? 'text-primary' : ''}`}
                  onClick={() => changeSort(row.key)}
                  {...{
                    sortOrder,
                    sortKey,
                  }}
                >
                  {row.label}

                  <SortButton
                    columnKey={row.key}
                    onClick={() => changeSort(row.key)}
                    {...{
                      sortOrder,
                      sortKey,
                    }}
                  />
                </td>
              )
            })}
          </tr>
        </thead>
        {/* end::Table head */}
        {console.log(shownData)}
        {shownData.map((item: any) => {
          return (
            <>
              {/* begin::Table body */}
              <tbody>
                <tr>
                  <td>
                    <div className='text-dark   d-block fs-6 '>
                      <div className='text-dark   fs-6'>
                        <Link
                          className='text-black text-capitalize fw-bold'
                          to={`/student/details/${item.menteeId}/${item?.name}`}
                        >
                          {' '}
                          {item.name}
                        </Link>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='text-dark   d-block fs-6 '>{item?.date}</div>
                  </td>
                  <td>
                    <div className='text-dark   d-block fs-6 '>{item?.type}</div>
                  </td>
                  <td>
                    <div className='text-dark   d-block fs-6 '>{item?._id}</div>
                  </td>
                  <td>
                    <div className='text-dark   d-block fs-6'>{item?.status}</div>
                  </td>

                  <td>
                    <div className='text-dark   d-block fs-6'>
                      <Link
                        className='text-black text-capitalize fw-bold'
                        to={`/mentorship-program/${item._id}`}
                      >
                        {' '}
                        {item?.reviews.rate}
                      </Link>
                    </div>
                  </td>
                </tr>
              </tbody>
              {/* end::Table body */}
            </>
          )
        })}
      </table>
    </div>
  )
}
