
import { Card, CardContent, CircularProgress, Container, Grid, Typography } from '@mui/material';
import AddAvailabilityTimeDatePicker from './AvalibiltyCalender';
import SelectTime from './SelectTime';

import styles from './availbilty.module.scss'
import { useEffect, useState } from 'react';
import moment from 'moment';

// import { useDispatch, useSelector } from 'react-redux';

import dayjs, { Dayjs } from 'dayjs'

import Moment from 'react-moment';
import { AddAvailableTimeSlot, removeAvailableTimeSlot } from '../../../../services/mentor';
import { toast, ToastContainer } from 'react-toastify';

const AddAvailabilityTimeMentor = (props: any) => {
    const [minTimeFrom, setMinTimeFrom] = useState<Dayjs>(dayjs(new Date()));
    const user = props?.user
    // const token = useSelector((state: any) => state.auth.token);

    // const dispatch = useDispatch()

    const [date, setValueDate] = useState<any>(dayjs(new Date()));
    const [today, setToday] = useState<any>(dayjs(new Date()));
    const [from, setValueFrom] = useState<Dayjs>(dayjs(new Date()));
    const [loading, setLoading] = useState(false)
    const [newDate, setNewDate] = useState('');
    const [timeList, setTimeList] = useState<any>([]);
    const [availableDays, setAvailableDays] = useState<any>([]);
    const [deletedIndex, setDeletedIndex] = useState<any>(null);
    const [loadingDelete, setLoadingDelete] = useState<any>(false);

    const getUserData = async () => {
        // const response = await getUserInfo(user._id)
        // if (response) {
        //     dispatch(addAuthInfo(
        //         {
        //             token: token,
        //             user: response.data.data
        //         }
        //     ))
        // }
        props?.handleGetMentor()
    }
    const handleAddAvalibilty = async (e: any) => {
        e.preventDefault()
        const globDate = moment(date.toString()).utc()
        const times = [...timeList]
        const TimesCount = user.subModel?.availabilityTime.map((item: any) => moment(item).format('L')).filter((item: any) => item == date.format('L')).length

        if (TimesCount < 5) {
            setLoading(true)
            const data = {
                "availabilityTime": globDate?.toISOString()

            }

            const response = await AddAvailableTimeSlot(user._id, data)
            if (response.status === 200) {
                await getUserData()
                // times.push(from.format('HH:mm'))
                // setTimeList(times)
                setLoading(false)
                // dispatch(addAlertInfo(
                //     {
                //         show: true,
                //         message: 'Data updated successfully',
                //     }
                // ))
                toast.success('Done successfully !', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            else {
                setLoading(false)
                // dispatch(addAlertInfo(
                //     {
                //         show: true,
                //         message: response.data.error,
                //         type: 'error'
                //     }
                // ))
                toast.error(response.data.error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

            // if (times.includes(from.format('HH:mm'))) {
            //     dispatch(addAlertInfo(
            //         {
            //             show: true,
            //             message: "You already have available appointment in this time slot",
            //             type: 'error'
            //         }
            //     ))
            // }
            // else {

        }
        else {
            toast.error('You have reached the maximum limit of 5 sessions for the day. To schedule a new session, please delete one of your existing sessions for that day.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            // dispatch(addAlertInfo(
            //     {
            //         show: true,
            //         message: 'You have reached the maximum limit of 5 sessions for the day. To schedule a new session, please delete one of your existing sessions for that day.',
            //         type: 'error'
            //     }
            // ))
        }


    }

    useEffect(() => {
        const days = user.subModel?.availabilityTime.map((item: any) => moment(item).format('L'))
        setAvailableDays(days.filter((item: any, index: any) => days.indexOf(item) === index))
    }, [date, user]
    )
    const handleDeleteItem = async (index: any) => {
        setLoadingDelete(true)
        setDeletedIndex(index)

        const response = await removeAvailableTimeSlot(user._id, index)

        if (response.status === 200) {
            await getUserData()
            setLoadingDelete(false)
            // dispatch(addAlertInfo(
            //     {
            //         show: true,
            //         message: 'Time slot removed successfully',
            //     }
            // ))
        }
        else {
            setLoadingDelete(false)
            // dispatch(addAlertInfo(
            //     {
            //         show: true,
            //         message: response.data.error,
            //         type: 'error'
            //     }
            // ))
        }
    }
    return (
        <div className='page'>
  <ToastContainer/>
            <Container>
                <h2 className='title '>Mentor Available Time Slots</h2>
                {/* <p className='main-description  '>Please provide your available time slots here so mentees  can see them to schedule with you. You will have the option to approve or decline a request once you get it.</p>
                <p className='main-description header-underline mt-2'>You can book a maximum of 5 sessions per day, and you may schedule sessions up to 2 months in advance from the current date</p> */}
                <div className='m-t-50'>
                    <Grid container spacing={2}>
                        <Grid item md={6} className='h-auto'>
                            <form className='post h-full'>
                                <Grid container>
                                    <Grid item md={12}>
                                        <div className='form-item'>
                                            <label>Select the Date</label>

                                            <AddAvailabilityTimeDatePicker value={date} setValue={setValueDate} />
                                        </div>
                                    </Grid>
                                    <Grid item md={12} className='flex gap-2'>
                                        <div className='form-item w-full'>
                                            <label>Select Starting Time</label>
                                            <SelectTime
                                                value={date}
                                                setValue={setValueDate}
                                                minTime={date.format('MMM DD, YYYY').toString() === today.format('MMM DD, YYYY').toString() ? minTimeFrom : null}

                                            />

                                        </div>
                                        {/* <div className='form-item w-1/2'>
                                            <label>Select Time To</label>
                                            <SelectTime value={to} setValue={setValueTo} />
                                        </div> */}

                                    </Grid>
                                    <div className='custom-action  w-full mt-2'>
                                        <button onClick={(e: any) => handleAddAvalibilty(e)} >
                                            {loading ?
                                                <div className="spinner-border text-dark" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            :
                                                'Add time slot'}
                                        </button>
                                    </div>
                                </Grid>
                            </form>
                        </Grid>
                        <Grid item md={6} className='h-auto'>
                            <Card className="post h-full">

                                <div className='form-item '>
                                    <label>Existing time slots</label>

                                </div>
                                {availableDays.length > 0 ?
                                    availableDays.map((day: any) => {
                                        return (
                                            <div className="student-card mb-2">
                                                <h3 className='secondary-title '><span >
                                                    <Moment format='MMMM DD, YYYY'>{day}</Moment>
                                                    {/* {day?.date.format('MMM DD, YYYY').toString()} */}
                                                </span>
                                                    {newDate}
                                                </h3>
                                                <ul className={styles['appointments-list']}>
                                                    {user.subModel?.availabilityTime?.map(((time: any, index: number) => {
                                                        return (
                                                            <>
                                                                {day == moment(time).format('L') &&
                                                                    <li>
                                                                        <div className={styles['time']}>
                                                                            <Moment format='hh:mm A'>{time}</Moment>
                                                                        </div>
                                                                        <div className={styles['icon']}>
                                                                            {loadingDelete && deletedIndex == index ?
                                                                                <CircularProgress size="20px" /> :
                                                                                <i className="bi bi-x" onClick={() => handleDeleteItem(index)}></i>
                                                                                // <CloseIcon onClick={() => handleDeleteItem(index)} />

                                                                            }
                                                                        </div>
                                                                    </li>}
                                                            </>

                                                        )
                                                    }))
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    }) :
                                    <>No Data added yet</>}


                            </Card>
                        </Grid>
                    </Grid>
                </div>

            </Container>

        </div >


    );
};

export default AddAvailabilityTimeMentor;