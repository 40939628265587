import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import jwtDecode from 'jwt-decode'
import { useState } from 'react'

const saveUserData =()=>{
  // const [userData , setUserData] = useState(null)
  const encodeToken:any =localStorage.getItem('token')
  const decodeToken = jwtDecode(encodeToken);
}

// saveUserData={saveUserData}
// saveUserData={saveUserData}
const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout  />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
