import axios from '../axios'
import {BASEURL} from '../environments/environment'

export const getAllCountries = (page:any=null,pageSize:number=0) => {

  const pagination =  page!=null && pageSize ? `?page=${page}&pageSize=${pageSize}`: ''
  return axios
    .get(`${BASEURL}country${pagination}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const getCountry = (_id: any) => {
  return axios
    .get(`${BASEURL}country/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const updateCountry = (_id: any, data: any) => {
  return axios
    .patch(`${BASEURL}country/${_id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const createCountry = (order: number, nameEn: string, nameAr: string) => {
  return axios
    .post(`${BASEURL}country`, {order: order, nameEn: nameEn, nameAr: nameAr })
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}

export const deleteCountry = (_id: string) => {
  return axios
    .delete(`${BASEURL}country/${_id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response)
}
