import {ChangeEvent, useState} from 'react'
import {ISocialMedia} from './module.interface'
import {PageTitle} from '../../../../components/pageTitle'
import {DataForm} from './components/data-form'
import {createSocialMedia} from '../../../../services/socialMedia'
import {useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify'

export const CreateSocialMedia = () => {
  const [formData, setFormData] = useState<ISocialMedia>({
    order: 0,
    nameEn: '',
    nameAr: '',
  })
  const navigate = useNavigate()
  const [disabled, setdisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((_data) => ({..._data, [e.target.name]: e.target.value}))
    setdisabled(false)
  }

  const onClickSubmitBtn = (e: any) => {
    e.preventDefault()
    newSocialMedia()
  }

  const newSocialMedia = async () => {
    setLoading(true)
    const response = await createSocialMedia(formData.order, formData.nameEn, formData.nameAr)
    if (response.status == 201) {
      toast.success('Done successfully !', {
         position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
       theme: "colored",
        });
      goToSocialMedia()
      setLoading(false)
    } else {
      
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setLoading(false)
    }
  }

  const goToSocialMedia = () => {
    navigate('/universal-fields')
  }

  const breadCrampList = [
    {
      name: 'Social Media',
      route: '/universal-fields',
    },
  ]
  return (
    <>
      <PageTitle breadCrampList={breadCrampList} active='Create' />
      <div className=' w-75 m-auto'>
        <DataForm
          onClickSubmit={onClickSubmitBtn}
          handleChange={handleChange}
          data={DataForm}
          ButtonText='Create'
          Show={disabled}
          loading={loading}
        />
      </div>
    </>
  )
}
