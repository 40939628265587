import {ChangeEvent, useEffect, useState} from 'react'
import {IIndustry} from './module.interface'
import {DataForm} from './components/data-form'
import {useNavigate, useParams} from 'react-router-dom'
import {PageTitle} from '../../../components/pageTitle'
import {updateIndustry, getIndustry} from '../../../services/industry'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
export const UpdateIndustry = () => {
  const [disabled, setdisabled] = useState<any>(true)
  const [loading, setLoading] = useState<any>(false)

  const navigate = useNavigate()
  const {id} = useParams()
  const [isSubmitted, setIsSubmitted] = useState<any>(false)
  const req = ['order', 'nameEn', 'nameAr']
  const [formData, setFormData] = useState<IIndustry>({
    order: 0,
    nameEn: '',
    nameAr: '',
  })
  type ObjectKey = keyof typeof formData

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((_data) => ({..._data, [e.target.name]: e.target.value}))
    setdisabled(false)
  }

  useEffect(() => {
    if (id) {
      handleGetIndustry()
    }
  }, [id])

  const handleGetIndustry = async () => {
    const response = await getIndustry(id)
    if (response) {
      const data = {
        order: response.data.data.order,
        nameEn: response.data.data.nameEn,
        nameAr: response.data.data.nameAr,
      }
      setFormData(data)
    }
  }

  const onClickSubmitBtn = (e: any) => {
    e.preventDefault()
    setIsSubmitted(true)
    req.every((key) => formData.hasOwnProperty(key) && formData[key as ObjectKey] !== '') &&
      handleUpdateIndustry()
  }

  const handleUpdateIndustry = async () => {
    setLoading(true)
    const response = await updateIndustry(id, formData)
    if (response.status == 200) {
      toast.success('Done successfully !', {
         position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
       theme: "colored",
        });
      goToCurrency()
      setLoading(false)
    } else {
      toast.error(response.data.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setLoading(false)
    }
  }

  const goToCurrency = () => {
    navigate('/universal-fields')
  }

  const breadCrampList = [
    {
      name: 'Industries',
      route: '/universal-fields',
    },
  ]

  return (
    <>
       <ToastContainer/>
      <PageTitle breadCrampList={breadCrampList} active='Update' />
      <div className='w-75 m-auto'>
        <DataForm
          onClickSubmit={onClickSubmitBtn}
          handleChange={handleChange}
          data={formData}
          ButtonText='Update'
          Show={disabled}
          loading={loading}
          isSubmitted={isSubmitted}
        />
      </div>
    </>
  )
}
